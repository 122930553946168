.lightbox {
  z-index: 99 !important;
}

.lightbox__image,
.lightbox__thumbnail {
  text-align: center;
  display: inline-block;
  max-height: 15em;

  & img {
    width: auto !important;
    height: auto;
    max-height: 13rem;
    max-width: 100% !important;
  }
}
