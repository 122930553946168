/* rtl:begin:ignore */
:root[dir=ltr] body {
  direction: ltr;
  text-align: left;
  left: calc(-1 * var(--scrollbar-width, 0));
  font-family: poppins, sans-serif;
  font-weight: 500;
}
:root[dir=ltr] .kt-aside--enabled.kt-subheader--fixed .kt-subheader {
  left: var(--aside-width, 240px);
  right: 0;
}
:root[dir=ltr] .kt-aside--enabled .kt-header.kt-header--fixed {
  left: var(--aside-width, 240px);
  right: 0;
}
:root[dir=ltr] .kt-aside--fixed .kt-aside {
  left: 0;
  right: auto;
}
:root[dir=ltr] .kt-aside--fixed .kt-wrapper {
  padding-left: var(--aside-width, 240px);
  padding-right: 0;
}
:root[dir=ltr] .dropdown-item {
  text-align: left;
}
:root[dir=ltr] .kt-radio-inline .kt-radio:last-child {
  margin-left: 15px;
  margin-right: 0;
}
:root[dir=ltr] [class^=fa-angle-]::before,
:root[dir=ltr] [class*=" fa-angle-"]::before {
  transform: scaleX(-1);
}

/* rtl:end:ignore */
.kt-pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.kt-pagination .kt-pagination__links, .kt-pagination .kt-pagination__links li a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.kt-pagination .kt-pagination__links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.kt-pagination .kt-pagination__links li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  border-radius: 4px;
}
.kt-pagination .kt-pagination__links li:first-of-type {
  margin-right: 0;
}
.kt-pagination .kt-pagination__links li a {
  font-weight: 500;
  color: rgb(116, 120, 141);
  font-size: 1rem;
  padding: 0 0.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  margin: 0;
}
.kt-pagination .kt-pagination__links .kt-pagination__link--active a {
  color: rgb(255, 255, 255);
}
.kt-pagination .kt-pagination__toolbar {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
}
.kt-pagination .kt-pagination__toolbar .form-control {
  padding: 0;
  margin-right: 10px;
  height: 30px;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  outline: none;
  border: none;
}
.kt-pagination .kt-pagination__toolbar .pagination__desc {
  flex-grow: 1;
  font-weight: 500;
  color: rgb(116, 120, 141);
}
.kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--last {
  background: rgba(93, 120, 255, 0.1);
}
.kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--active {
  background: rgb(93, 120, 255);
}
.kt-pagination.kt-pagination--brand .kt-pagination__links a[disabled=disabled], .kt-pagination.kt-pagination--brand .kt-pagination__links a.disabled {
  color: rgb(147, 162, 221);
  background: rgb(240, 243, 255);
  opacity: 0.3;
}
.kt-pagination.kt-pagination--brand .kt-pagination__links li:hover {
  background: rgb(93, 120, 255);
  transition: color 0.3s ease;
}
.kt-pagination.kt-pagination--brand .kt-pagination__links li:hover a {
  color: rgb(255, 255, 255);
}
.kt-pagination.kt-pagination--brand .kt-pagination__links li:hover a i {
  color: rgb(255, 255, 255) !important;
}
.kt-pagination.kt-pagination--brand .kt-pagination__toolbar .form-control {
  background: rgba(93, 120, 255, 0.1);
}
.kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--last {
  background: rgba(255, 255, 255, 0.1);
}
.kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--active {
  background: rgb(255, 255, 255);
}
.kt-pagination.kt-pagination--light .kt-pagination__links li:hover {
  background: rgb(255, 255, 255);
  transition: color 0.3s ease;
}
.kt-pagination.kt-pagination--light .kt-pagination__links li:hover a {
  color: rgb(255, 255, 255);
}
.kt-pagination.kt-pagination--light .kt-pagination__links li:hover a i {
  color: rgb(255, 255, 255) !important;
}
.kt-pagination.kt-pagination--light .kt-pagination__toolbar .form-control {
  background: rgba(255, 255, 255, 0.1);
}
.kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--last {
  background: rgba(40, 42, 60, 0.1);
}
.kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--active {
  background: rgb(40, 42, 60);
}
.kt-pagination.kt-pagination--dark .kt-pagination__links li:hover {
  background: rgb(40, 42, 60);
  transition: color 0.3s ease;
}
.kt-pagination.kt-pagination--dark .kt-pagination__links li:hover a {
  color: rgb(255, 255, 255);
}
.kt-pagination.kt-pagination--dark .kt-pagination__links li:hover a i {
  color: rgb(255, 255, 255) !important;
}
.kt-pagination.kt-pagination--dark .kt-pagination__toolbar .form-control {
  background: rgba(40, 42, 60, 0.1);
}
.kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--last {
  background: rgba(88, 103, 221, 0.1);
}
.kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--active {
  background: rgb(88, 103, 221);
}
.kt-pagination.kt-pagination--primary .kt-pagination__links li:hover {
  background: rgb(88, 103, 221);
  transition: color 0.3s ease;
}
.kt-pagination.kt-pagination--primary .kt-pagination__links li:hover a {
  color: rgb(255, 255, 255);
}
.kt-pagination.kt-pagination--primary .kt-pagination__links li:hover a i {
  color: rgb(255, 255, 255) !important;
}
.kt-pagination.kt-pagination--primary .kt-pagination__toolbar .form-control {
  background: rgba(88, 103, 221, 0.1);
}
.kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--last {
  background: rgba(10, 187, 135, 0.1);
}
.kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--active {
  background: rgb(10, 187, 135);
}
.kt-pagination.kt-pagination--success .kt-pagination__links li:hover {
  background: rgb(10, 187, 135);
  transition: color 0.3s ease;
}
.kt-pagination.kt-pagination--success .kt-pagination__links li:hover a {
  color: rgb(255, 255, 255);
}
.kt-pagination.kt-pagination--success .kt-pagination__links li:hover a i {
  color: rgb(255, 255, 255) !important;
}
.kt-pagination.kt-pagination--success .kt-pagination__toolbar .form-control {
  background: rgba(10, 187, 135, 0.1);
}
.kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--last {
  background: rgba(85, 120, 235, 0.1);
}
.kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--active {
  background: rgb(85, 120, 235);
}
.kt-pagination.kt-pagination--info .kt-pagination__links li:hover {
  background: rgb(85, 120, 235);
  transition: color 0.3s ease;
}
.kt-pagination.kt-pagination--info .kt-pagination__links li:hover a {
  color: rgb(255, 255, 255);
}
.kt-pagination.kt-pagination--info .kt-pagination__links li:hover a i {
  color: rgb(255, 255, 255) !important;
}
.kt-pagination.kt-pagination--info .kt-pagination__toolbar .form-control {
  background: rgba(85, 120, 235, 0.1);
}
.kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--last {
  background: rgba(255, 184, 34, 0.1);
}
.kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--active {
  background: rgb(255, 184, 34);
}
.kt-pagination.kt-pagination--warning .kt-pagination__links li:hover {
  background: rgb(255, 184, 34);
  transition: color 0.3s ease;
}
.kt-pagination.kt-pagination--warning .kt-pagination__links li:hover a {
  color: rgb(255, 255, 255);
}
.kt-pagination.kt-pagination--warning .kt-pagination__links li:hover a i {
  color: rgb(255, 255, 255) !important;
}
.kt-pagination.kt-pagination--warning .kt-pagination__toolbar .form-control {
  background: rgba(255, 184, 34, 0.1);
}
.kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--last {
  background: rgba(253, 57, 122, 0.1);
}
.kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--active {
  background: rgb(253, 57, 122);
}
.kt-pagination.kt-pagination--danger .kt-pagination__links li:hover {
  background: rgb(253, 57, 122);
  transition: color 0.3s ease;
}
.kt-pagination.kt-pagination--danger .kt-pagination__links li:hover a {
  color: rgb(255, 255, 255);
}
.kt-pagination.kt-pagination--danger .kt-pagination__links li:hover a i {
  color: rgb(255, 255, 255) !important;
}
.kt-pagination.kt-pagination--danger .kt-pagination__toolbar .form-control {
  background: rgba(253, 57, 122, 0.1);
}
.kt-pagination.kt-pagination--circle .kt-pagination__links li {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
}
.kt-pagination.kt-pagination--lg .kt-pagination__links li a {
  font-size: 1.3rem;
  min-width: 35px;
  min-height: 35px;
}
.kt-pagination.kt-pagination--lg .kt-pagination__toolbar .form-control {
  height: 35px;
  font-size: 1.2rem;
  padding: 0.2rem 0 0.2rem 0.2rem;
}
.kt-pagination.kt-pagination--sm .kt-pagination__links li a {
  font-size: 1rem;
  min-width: 25px;
  min-height: 25px;
}
.kt-pagination.kt-pagination--sm .kt-pagination__toolbar .form-control {
  height: 25px;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .kt-pagination .kt-pagination__links {
    display: flex;
    flex-wrap: wrap;
  }
  .kt-pagination .kt-pagination__links li {
    margin: 0.3rem 0.5rem 0.3rem 0;
  }
  .kt-pagination .kt-pagination__links li a {
    font-size: 0.9rem;
    min-width: 25px;
    min-height: 25px;
  }
  .kt-pagination .kt-pagination__toolbar .form-control {
    height: 25px;
    font-size: 0.9rem;
    padding: 0.2rem 0 0.2rem 0.2rem;
  }
}
.kt-notes .kt-notes__items {
  margin: 0;
  padding: 0;
}
.kt-notes .kt-notes__items .kt-notes__item {
  left: 8px;
  border-left: 2px solid rgb(235, 237, 242);
  padding: 0 0 50px 45px;
  position: relative;
  margin: 0 5px 0 17px;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media {
  position: absolute;
  top: 0;
  left: -25.5px;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media img {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media .kt-notes__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(235, 237, 242);
  border-radius: 50%;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media .kt-notes__icon i {
  color: rgb(167, 171, 195);
  font-size: 1.8rem;
  line-height: 1rem;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media .kt-notes__user {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 1.2rem;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(235, 237, 242);
  color: rgb(167, 171, 195);
  border-radius: 50%;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media .kt-notes__circle {
  width: 25px;
  height: 25px;
  display: block;
  border: 2px solid rgb(235, 237, 242);
  margin-left: 12px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content {
  background-color: rgb(247, 248, 252);
  padding: 0.5rem 1rem 1rem 1.5rem;
  border-radius: 4px;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section .kt-notes__info {
  display: flex;
  flex-flow: wrap;
  align-items: baseline;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section .kt-notes__info .kt-notes__title {
  font-size: 1.1rem;
  color: rgb(108, 114, 147);
  font-weight: 500;
  padding-right: 0.5rem;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section .kt-notes__info .kt-notes__title:hover {
  color: rgb(93, 120, 255);
  transition: color 0.3s ease;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section .kt-notes__info .kt-notes__desc {
  color: rgb(167, 171, 195);
  font-weight: 400;
  padding-right: 1rem;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section .kt-notes__dropdown .btn-icon {
  width: 26px;
  height: 26px;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__body {
  color: rgb(167, 171, 195);
  font-weight: 400;
  padding-right: 3rem;
  padding-top: 0.5rem;
  display: block;
}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 10px;
  right: 100%;
  margin-right: -3.5rem;
  border-right: solid 10px rgb(247, 248, 252);
  border-bottom: solid 17px transparent;
  border-left: solid 17px transparent;
  border-top: solid 17px transparent;
}
.kt-notes .kt-notes__items .kt-notes__item:last-child {
  border-left: 0;
}
.kt-notes .kt-notes__items .kt-notes__item.kt-notes__item--clean .kt-notes__content {
  background-color: rgb(255, 255, 255);
  padding: 0;
}
.kt-notes .kt-notes__items .kt-notes__item.kt-notes__item--clean .kt-notes__content::after {
  display: none;
}
.kt-notes .kt-notes__items .kt-notes__item:last-child .kt-notes__media .kt-notes__circle {
  margin-left: 14px;
}

@media (max-width: 1024px) {
  .kt-notes .kt-notes__items .kt-notes__item {
    margin: 0 7px 0 17px;
  }
  .kt-notes .kt-notes__items .kt-notes__item .kt-notes__content {
    padding: 0.5rem 1rem 0.7rem 1rem;
  }
  .kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__desc {
    padding-top: 0.5rem;
    display: block;
  }
  .kt-notes .kt-notes__items .kt-notes__item .kt-notes__content::after {
    margin-right: -3.8rem;
  }
}
.dashboard-portlet .kt-portlet__head {
  min-height: 40px;
  padding: 0 20px;
}
.dashboard-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  font-size: 1rem;
  color: rgb(255, 255, 255);
}

/* rtl:begin:ignore */
body {
  overflow-x: hidden;
}

.error-page {
  background: rgb(8, 22, 51);
  min-height: 100%;
}

.police-tape {
  background: rgb(226, 187, 45), linear-gradient(180deg, #eed887 0%, rgb(226, 187, 45) 5%, rgb(226, 187, 45) 90%, #e5c243 95%, #0e0b02 100%);
  padding: 0.125em;
  font-size: 2.5em;
  text-align: center;
  white-space: nowrap;
}

.police-tape--1 {
  transform: rotate(10deg);
  position: absolute;
  top: 50%;
  left: -5%;
  right: 8%;
  z-index: 2;
  margin-top: 0;
  color: rgb(34, 34, 34);
}

.police-tape--2 {
  transform: rotate(-8deg);
  position: absolute;
  top: 50%;
  left: -9%;
  right: -5%;
  color: rgb(34, 34, 34);
}

.ghost {
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  height: 100vh;
}

.ghost--columns {
  display: flex;
  flex-grow: 1;
  flex-basis: 200px;
  align-content: stretch;
}

.ghost--navbar {
  flex: 0 0 60px;
  background: linear-gradient(0deg, rgb(39, 41, 45) 0, rgb(39, 41, 45) 10px, transparent 10px);
  border-bottom: 2px solid rgb(8, 22, 51);
}

.ghost--column {
  flex: 1 0 30%;
  border-width: 0;
  border-style: solid;
  border-color: rgb(39, 41, 45);
  border-left-width: 10px;
  background-color: #07122a;
}
.ghost--column:nth-child(1) .code:nth-child(1) {
  margin-left: 3em;
}
.ghost--column:nth-child(1) .code:nth-child(2) {
  margin-left: 4em;
}
.ghost--column:nth-child(1) .code:nth-child(3) {
  margin-left: 4.5em;
}
.ghost--column:nth-child(1) .code:nth-child(4) {
  margin-left: 3.5em;
}
.ghost--column:nth-child(2) .code:nth-child(1) {
  margin-left: 3.5em;
}
.ghost--column:nth-child(2) .code:nth-child(2) {
  margin-left: 2em;
}
.ghost--column:nth-child(2) .code:nth-child(3) {
  margin-left: 5.5em;
}
.ghost--column:nth-child(2) .code:nth-child(4) {
  margin-left: 3.5em;
}
.ghost--column:nth-child(3) .code:nth-child(1) {
  margin-left: 5em;
}
.ghost--column:nth-child(3) .code:nth-child(2) {
  margin-left: 4em;
}
.ghost--column:nth-child(3) .code:nth-child(3) {
  margin-left: 2em;
}
.ghost--column:nth-child(3) .code:nth-child(4) {
  margin-left: 1.5em;
}

.ghost--main {
  background-color: rgb(8, 22, 51);
  border-top: 15px solid #303338;
  flex: 1 0 100px;
}

.code {
  display: block;
  width: 100px;
  background-color: rgb(39, 41, 45);
  height: 1em;
  margin: 1em;
}
.ghost--main .code {
  height: 2em;
  width: 200px;
}

/* rtl:end:ignore */
.kt-bg-light-success {
  background-color: rgba(10, 187, 135, 0.1) !important;
}

.kt-bg-light-brand {
  background-color: rgba(93, 120, 255, 0.1) !important;
}

.kt-media.kt-media--circle img {
  border-radius: 50%;
}

.kt-media.kt-media--sm img {
  width: 100%;
  max-width: 30px;
  height: 30px;
}

.kt-chat .kt-portlet__head {
  align-items: center;
  flex-grow: 1;
}
.kt-chat .kt-chat__head {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
}
.kt-chat .kt-chat__head .kt-chat__label .kt-chat__title {
  font-size: 1.1rem;
  color: rgb(72, 70, 91);
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.kt-chat .kt-chat__head .kt-chat__label .kt-chat__title:hover {
  color: rgb(93, 120, 255);
  transition: color 0.3s ease;
}
.kt-chat .kt-chat__head .kt-chat__label .kt-chat__status {
  color: rgb(116, 120, 141);
  font-size: 0.9rem;
  font-weight: 500;
  display: block;
}
.kt-chat .kt-chat__head .kt-chat__left {
  flex-grow: 1;
  text-align: left;
}
.kt-chat .kt-chat__head .kt-chat__right {
  flex-grow: 1;
  text-align: right;
}
.kt-chat .kt-chat__head .kt-chat__center {
  flex-grow: 1;
  text-align: center;
}
.kt-chat .kt-chat__messages .kt-chat__message {
  margin-bottom: 1rem;
}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__user .kt-chat__username {
  padding: 0 0.4rem;
  font-size: 1.1rem;
  color: rgb(72, 70, 91);
  font-weight: 500;
}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__user .kt-chat__username:hover {
  color: rgb(93, 120, 255);
  transition: color 0.3s ease;
}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__user .kt-chat__datetime {
  color: rgb(116, 120, 141);
  font-size: 0.9rem;
  font-weight: 400;
}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__text {
  display: inline-block;
  padding: 1.2rem 1.7rem;
  padding: 1.1rem 1.6rem 1.1rem 3.5rem;
  margin-top: 0.8rem;
  color: rgb(108, 114, 147);
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 4px;
}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__text b {
  color: rgb(89, 93, 110);
  font-weight: 0;
}
.kt-chat .kt-chat__messages .kt-chat__message.kt-chat__message--right {
  text-align: left;
}
.kt-chat .kt-chat__messages .kt-chat__message.kt-chat__message--right .kt-chat__text {
  text-align: right;
  padding: 1.1rem 1.6rem 1.1rem 3.5rem;
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid {
  width: 100%;
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message {
  padding: 1.3rem 1.7rem;
  margin: 1.5rem 0;
  display: inline-block;
  border-radius: 4px;
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message:first-child {
  margin-top: 0;
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message .kt-chat__text {
  padding: 0;
  margin-top: 0.5rem;
  background: none !important;
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message.kt-chat__message--right {
  float: right;
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message.kt-chat__message--brand {
  background: rgba(93, 120, 255, 0.1);
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message.kt-chat__message--light {
  background: rgba(255, 255, 255, 0.1);
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message.kt-chat__message--dark {
  background: rgba(40, 42, 60, 0.1);
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message.kt-chat__message--primary {
  background: rgba(88, 103, 221, 0.1);
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message.kt-chat__message--success {
  background: rgba(10, 187, 135, 0.1);
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message.kt-chat__message--info {
  background: rgba(85, 120, 235, 0.1);
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message.kt-chat__message--warning {
  background: rgba(255, 184, 34, 0.1);
}
.kt-chat .kt-chat__messages.kt-chat__messages--solid .kt-chat__message.kt-chat__message--danger {
  background: rgba(253, 57, 122, 0.1);
}
.kt-chat .kt-chat__input .kt-chat__editor textarea {
  width: 100%;
  border: 0;
  outline: none;
  background-color: transparent;
  color: rgb(162, 165, 185);
}
.kt-chat .kt-chat__input .kt-chat__editor textarea::placeholder {
  color: rgb(162, 165, 185);
  opacity: 1;
}
.kt-chat .kt-chat__input .kt-chat__toolbar {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kt-chat .kt-chat__input .kt-chat__toolbar .kt_chat__tools a i {
  margin-right: 1.1rem;
  color: rgb(205, 209, 233);
  font-size: 1.4rem;
}
.kt-chat .kt-chat__input .kt-chat__toolbar .kt_chat__tools a i:hover {
  color: rgb(93, 120, 255);
  transition: color 0.3s ease;
}
@media (max-width: 1024px) {
  .kt-chat .kt-chat__messages .kt-chat__message {
    margin-bottom: 0.75rem;
  }
  .kt-chat .kt-chat__messages .kt-chat__message .kt-chat__user .kt-chat__username {
    padding: 0 0.25rem;
    font-size: 1rem;
  }
  .kt-chat .kt-chat__messages .kt-chat__message .kt-chat__text {
    display: inline-block;
    padding: 0.75rem 1rem;
    margin-top: 0.5rem;
  }
  .kt-chat .kt-chat__messages .kt-chat__message.kt-chat__message--right .kt-chat__text {
    padding: 0.75rem 1rem;
  }
  .kt-chat .kt-chat__input .kt-chat__toolbar {
    margin-top: 0.75rem;
  }
  .kt-chat .kt-chat__input .kt-chat__toolbar .kt_chat__tools a i {
    margin-right: 0.75rem;
    font-size: 1.3rem;
  }
}

.kt-todo__files {
  padding-top: 10px;
}
.kt-todo__files.kt-todo__file {
  display: block;
  padding-top: 0.7rem;
}
.kt-todo__files.kt-todo__filei {
  padding-right: 0.5rem;
  font-size: 15px;
}
.kt-todo__files.kt-todo__filea {
  color: rgb(116, 120, 141);
  font-weight: 500;
}
.kt-todo__files.kt-todo__filea:hover {
  color: rgb(93, 120, 255);
  transition: all 0.3s ease;
}

.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: rgb(229, 229, 229);
  border: none;
  cursor: default;
  height: 30px;
  outline: none;
  padding: 0;
  margin-top: 15px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}
.chip .fa-times {
  color: rgb(255, 255, 255);
  cursor: pointer;
  width: 1em;
  font-size: 20px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}
.chip .fa-times:hover {
  color: rgb(253, 57, 122);
}
.chip .chip-icon {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: rgb(10, 187, 135);
  font-size: 1.45rem;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.chip .chip-icon i {
  color: rgb(255, 255, 255);
}
.chip .chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}
.chip .chip-content span {
  color: rgb(73, 80, 87);
}
.chip .chip-close {
  display: flex;
  align-items: center;
}
.chip .custom-chip {
  border-radius: 16px;
  padding-left: 12px;
  padding-right: 12px;
}
.chip .bg-defult {
  background-color: #e5e5e5;
}

.height3 {
  height: 300px;
}

.height6 {
  height: 600px;
}

.width40 {
  width: 40px;
}

.width60 {
  width: 60px;
}

.width80 {
  width: 80px;
}

.width85 {
  width: 85px;
}

.width100 {
  width: 100px;
}

.minwidth200 {
  min-width: 200px;
}

.minwidth300 {
  min-width: 300px;
}

.maxw-200 {
  max-width: 200px;
}

.width120 {
  width: 120px;
}

.width150 {
  width: 150px;
}

.table-fixed {
  table-layout: fixed;
}

.full-cell {
  width: 1%;
  white-space: nowrap;
}

.wrap-cell {
  overflow-wrap: anywhere;
}

.cursor-pointer {
  cursor: pointer;
}

.borderless > * {
  border: 0 !important;
}

.en-font {
  font-family: poppins, sans-serif;
  /* rtl:ignore */
  text-align: left !important;
}

.modal-header-centerd {
  flex: 1;
  display: flex;
  justify-content: center;
}

@media print {
  /* rtl:ignore */
  div[dir=ltr] {
    text-align: left;
  }
}
.line-height-md {
  line-height: 25px;
}

.nooutline:focus {
  outline: none !important;
}

.multiline-text {
  white-space: pre-line;
}

.kt-media img {
  border-radius: 4px;
  width: 30px;
  height: 30px;
}

header.modal-header .kt-ribbon {
  top: -4px;
}

.kt-ribbon__target {
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
}
.kt-ribbon__target.ribbon-left {
  top: -2px; /* rtl:ignore */
  left: 10px; /* rtl:ignore */
}
.kt-ribbon__target.ribbon-right {
  top: -2px; /* rtl:ignore */
  right: 10px; /* rtl:ignore */
}

[dir] .te-ww-container .tui-editor-contents:first-child {
  font-family: var(--font-family-sans-serif, IRANSans);
}

.lightbox {
  z-index: 99 !important;
}

.lightbox__image,
.lightbox__thumbnail {
  text-align: center;
  display: inline-block;
  max-height: 15em;
}
.lightbox__image img,
.lightbox__thumbnail img {
  width: auto !important;
  height: auto;
  max-height: 13rem;
  max-width: 100% !important;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table tr {
    border-bottom: 3px solid rgb(221, 221, 221);
    display: block;
    margin-bottom: 0.625em;
  }
  table td {
    border-bottom: 1px solid rgb(221, 221, 221);
    display: block;
    text-align: left;
  }
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: right;
    font-weight: bold;
  }
  table td:last-child {
    border-bottom: 0;
  }
  .kt-subheader .kt-subheader__toolbar {
    display: block !important;
  }
  .kt-subheader .kt-subheader__toolbar button {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 0 !important;
  }
}
.auto-complete .multiselect__select {
  display: none !important;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Regular"), url("~@/assets/fonts/poppins/Poppins-Regular.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"), url("~@/assets/fonts/poppins/Poppins-Light.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins SemiBold"), url("~@/assets/fonts/poppins/Poppins-SemiBold.woff") format("woff");
}
.popover, .tui-editor-contents p {
  font-family: var(--font-family-sans-serif, IRANSans);
}

.info-tooltip-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-icon {
  position: relative;
}
.profile-icon i {
  font-size: 60px;
  background: rgb(242, 243, 247);
  border-radius: 10px;
  padding: 10px;
  color: rgb(93, 120, 255);
}
.profile-icon .success-flag {
  position: absolute;
  right: -20px;
  top: -20px;
  font-size: 30px;
  background: none;
}

.profile-flag {
  width: 30px;
  border-radius: 5px;
}

.flag-badges i {
  font-size: 1.3rem;
}
.flag-badges i.la-expeditedssl {
  font-size: 1.7rem;
}
.flag-badges i.la-exchange::before {
  position: relative;
  right: 2px;
  top: 2px;
}

.kt-widget.kt-widget--user-profile-3 .kt-widget__top {
  display: flex;
  align-items: flex-start;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__media {
  margin-top: 0.2rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__media img {
  width: 110px;
  border-radius: 8px;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic {
  margin-top: 0.2rem;
  width: 100%;
  max-width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-radius: 8px;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--brand {
  background: rgba(93, 120, 255, 0.1);
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--light {
  background: rgba(255, 255, 255, 0.1);
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--dark {
  background: rgba(40, 42, 60, 0.1);
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--primary {
  background: rgba(88, 103, 221, 0.1);
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--success {
  background: rgba(10, 187, 135, 0.1);
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--info {
  background: rgba(85, 120, 235, 0.1);
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--warning {
  background: rgba(255, 184, 34, 0.1);
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--danger {
  background: rgba(253, 57, 122, 0.1);
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content {
  width: 100%;
  padding-right: 1.7rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__user {
  display: flex;
  align-items: center;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__username {
  font-size: 1.3rem;
  color: rgb(70, 68, 87);
  font-weight: 500;
  align-items: center;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__username:hover {
  color: rgb(93, 120, 255);
  transition: color 0.3s ease;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__username i {
  font-size: 1.2rem;
  color: rgb(87, 201, 116);
  padding-right: 0.5rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__action .btn {
  font-weight: 600;
  margin-right: 0.5rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead {
  padding: 0.6rem 0 0.8rem 0;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead a {
  color: rgb(167, 171, 195);
  font-weight: 500;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead a:hover {
  color: rgb(93, 120, 255);
  transition: color 0.3s ease;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead a i {
  padding-left: 0.5rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info {
  display: flex;
  flex-flow: row wrap;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__desc {
  color: rgb(108, 114, 147);
  font-weight: 400;
  padding-left: 1rem;
  flex-grow: 1;
  margin-bottom: 0.5rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress {
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
  margin: 0.1rem 0;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress .kt-widget__text {
  padding-right: 1rem;
  color: rgb(108, 114, 147);
  font-weight: 500;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress .kt-widget__stats {
  padding-left: 1rem;
  color: rgb(70, 68, 87);
  font-weight: 600;
  font-size: 1.1rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border-top: 1px solid rgb(235, 237, 242);
  margin-top: 1rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 2rem 0 0 1.5rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__icon i {
  font-size: 2.7rem;
  color: rgb(173, 177, 199);
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details {
  padding-right: 1rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details .kt-widget__title {
  display: block;
  color: rgb(167, 171, 195);
  font-weight: 600;
  font-size: 0.95rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details .kt-widget__value {
  display: block;
  color: rgb(70, 68, 87);
  font-weight: 600;
  font-size: 1.2rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details .kt-widget__value span {
  color: rgb(70, 68, 87) !important;
  font-weight: 600 !important;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details a.kt-widget__value {
  font-size: 0.95rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details a.kt-widget__value:hover {
  color: rgb(93, 120, 255);
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details .kt-section__content {
  padding-left: 0.7rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__form {
  margin-top: 1.5rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__body .kt-widget__item {
  padding: 0.7rem 0;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__body .kt-widget__item .kt-widget__contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__body .kt-widget__item .kt-widget__contact .kt-widget__label {
  color: rgb(70, 68, 87);
  font-weight: 600;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__body .kt-widget__item .kt-widget__contact .kt-widget__data {
  color: rgb(167, 171, 195);
  font-weight: 400;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__body .kt-widget__item .kt-widget__contact a.kt-widget__data:hover {
  color: rgb(93, 120, 255);
  transition: color 0.3s ease;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__body .kt-widget__item .kt-widget__contact:last-child {
  padding-bottom: 0;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead span.kt-widget__title {
  color: rgb(167, 171, 195);
  font-weight: 500;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead span.kt-widget__value {
  direction: ltr;
}
@media (max-width: 768px) {
  .kt-widget.kt-widget--user-profile-3 {
    padding-top: 0.5rem;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top {
    display: flex;
    align-items: flex-start;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__media {
    margin-top: 0.5rem;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__media img {
    max-width: 60px;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic {
    width: 60px;
    height: 60px;
    margin-top: 0.5rem;
    font-size: 1.3rem;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content {
    padding-left: 1rem;
    margin-top: 0;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__username {
    padding-bottom: 0.6rem;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead {
    padding: 1.2rem 0;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead a:not(:first-child):not(:last-child) {
    padding: 0.5rem 1rem 0.5rem 0;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info {
    display: flex;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__desc {
    padding-bottom: 1rem;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress {
    width: 100%;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__bottom {
    padding-top: 1rem;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item {
    padding: 1rem 1rem 0 0;
  }
  .kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__icon i {
    font-size: 2.5rem;
  }
}

.role-wrapper {
  margin-top: 0.7rem;
  text-align: center;
}
.role-wrapper .ml-2 {
  margin: 0 !important;
}

.grid-divider {
  overflow-x: hidden;
  position: relative;
}
.grid-divider > [class*=col-]:nth-child(n+1):not(:last-child)::after {
  content: "";
  background-color: rgb(235, 237, 242);
  position: absolute;
  top: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .grid-divider > [class*=col-]:nth-child(n+1):not(:last-child)::after {
    width: 100%;
    height: 1px;
    left: 0;
    top: -4px;
  }
}
@media (min-width: 768px) {
  .grid-divider > [class*=col-]:nth-child(n+1):not(:last-child)::after {
    width: 1px;
    height: auto;
    left: -1px;
  }
}