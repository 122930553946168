.btn {
  background: transparent;
  outline: none !important;
  vertical-align: middle;
}
.btn:focus:not(.btn-elevate) {
  box-shadow: none !important;
}
.btn.btn-clean {
  border: 0;
  background: transparent;
  color: #93a2dd;
}
.btn.btn-clean i {
  color: #93a2dd;
}
.btn.btn-clean.active,
.btn.btn-clean:active,
.btn.btn-clean:hover,
.show > .btn.btn-clean {
  color: #5d78ff;
  background: #f0f3ff;
}
.btn.btn-clean.active i,
.btn.btn-clean:active i,
.btn.btn-clean:hover i,
.show > .btn.btn-clean i {
  color: #5d78ff;
}
.btn.btn-default {
  background: transparent;
  color: #a7abc3;
  border: 1px solid #e8ecfa;
}
.btn.btn-default i {
  color: #93a2dd;
}
.btn.btn-default.active,
.btn.btn-default:active,
.btn.btn-default:hover,
.show > .btn.btn-default {
  color: #fff;
  background: #5d78ff;
  border-color: #5d78ff;
}
.btn.btn-default.active i,
.btn.btn-default:active i,
.btn.btn-default:hover i,
.show > .btn.btn-default i {
  color: #fff;
}
.btn-brand {
  color: #fff;
  background-color: #5d78ff;
  border-color: #5d78ff;
}
.btn-brand:hover {
  color: #fff;
  background-color: #3758ff;
  border-color: #2a4eff;
}
.btn-brand.focus,
.btn-brand:focus {
  box-shadow: 0 0 0 0.2rem rgba(117, 140, 255, 0.5);
}
.btn-brand.disabled,
.btn-brand:disabled {
  color: #fff;
  background-color: #5d78ff;
  border-color: #5d78ff;
}
.btn-brand:not(:disabled):not(.disabled).active,
.btn-brand:not(:disabled):not(.disabled):active,
.show > .btn-brand.dropdown-toggle {
  color: #fff;
  background-color: #2a4eff;
  border-color: #1d43ff;
}
.btn-brand:not(:disabled):not(.disabled).active:focus,
.btn-brand:not(:disabled):not(.disabled):active:focus,
.show > .btn-brand.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(117, 140, 255, 0.5);
}
.btn-brand + .btn.dropdown-toggle {
  position: relative;
}
.btn-brand + .btn.dropdown-toggle:before {
  display: block;
  content: ' ';
  border-left: 1px solid #fff;
  position: absolute;
  top: 3px;
  left: -1px;
  bottom: 3px;
  opacity: 0.3;
}
.btn-brand + .btn.dropdown-toggle.active:before,
.btn-brand + .btn.dropdown-toggle:focus:before,
.btn-brand + .btn.dropdown-toggle:hover:before {
  display: none;
}
.btn-light {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-light:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-light.active,
.btn-light:active,
.btn-light:focus,
.btn-light:hover,
.show > .btn-light.dropdown-toggle {
  background-color: #f7f8fa;
  border-color: #f7f8fa;
}
.btn-light + .btn.dropdown-toggle {
  position: relative;
}
.btn-light + .btn.dropdown-toggle:before {
  display: block;
  content: ' ';
  border-left: 1px solid #282a3c;
  position: absolute;
  top: 3px;
  left: -1px;
  bottom: 3px;
  opacity: 0.3;
}
.btn-light + .btn.dropdown-toggle.active:before,
.btn-light + .btn.dropdown-toggle:focus:before,
.btn-light + .btn.dropdown-toggle:hover:before {
  display: none;
}
.btn-dark {
  color: #fff;
  background-color: #282a3c;
  border-color: #282a3c;
}
.btn-dark:hover {
  color: #fff;
  background-color: #191a25;
  border-color: #14151d;
}
.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 74, 89, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #282a3c;
  border-color: #282a3c;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #14151d;
  border-color: #0f0f16;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 74, 89, 0.5);
}
.btn-dark + .btn.dropdown-toggle {
  position: relative;
}
.btn-dark + .btn.dropdown-toggle:before {
  display: block;
  content: ' ';
  border-left: 1px solid #fff;
  position: absolute;
  top: 3px;
  left: -1px;
  bottom: 3px;
  opacity: 0.3;
}
.btn-dark + .btn.dropdown-toggle.active:before,
.btn-dark + .btn.dropdown-toggle:focus:before,
.btn-dark + .btn.dropdown-toggle:hover:before {
  display: none;
}
.btn-primary {
  color: #fff;
  background-color: #5867dd;
  border-color: #5867dd;
}
.btn-primary:hover {
  color: #fff;
  background-color: #384ad7;
  border-color: #2e40d4;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #5867dd;
  border-color: #5867dd;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2e40d4;
  border-color: #293ccc;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);
}
.btn-primary + .btn.dropdown-toggle {
  position: relative;
}
.btn-primary + .btn.dropdown-toggle:before {
  display: block;
  content: ' ';
  border-left: 1px solid #fff;
  position: absolute;
  top: 3px;
  left: -1px;
  bottom: 3px;
  opacity: 0.3;
}
.btn-primary + .btn.dropdown-toggle.active:before,
.btn-primary + .btn.dropdown-toggle:focus:before,
.btn-primary + .btn.dropdown-toggle:hover:before {
  display: none;
}
.btn-accent {
  color: #fff;
  background-color: #563d7c;
  border-color: #563d7c;
}
.btn-accent:hover {
  color: #fff;
  background-color: #31204a;
  border-color: #31204a;
}
.btn-accent.focus,
.btn-accent:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);
}
.btn-accent.disabled,
.btn-accent:disabled {
  color: #fff;
  background-color: #6a5e7a;
  border-color: #6a5e7a;
}
.btn-accent:not(:disabled):not(.disabled).active,
.btn-accent:not(:disabled):not(.disabled):active,
.show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #563d7c;
  border-color: #563d7c;
}
.btn-accent:not(:disabled):not(.disabled).active:focus,
.btn-accent:not(:disabled):not(.disabled):active:focus,
.show > .btn-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);
}
.btn-accent + .btn.dropdown-toggle {
  position: relative;
}
.btn-accent + .btn.dropdown-toggle:before {
  display: block;
  content: ' ';
  border-left: 1px solid #fff;
  position: absolute;
  top: 3px;
  left: -1px;
  bottom: 3px;
  opacity: 0.3;
}
.btn-accent + .btn.dropdown-toggle.active:before,
.btn-accent + .btn.dropdown-toggle:focus:before,
.btn-accent + .btn.dropdown-toggle:hover:before {
  display: none;
}
.btn-success {
  color: #fff;
  background-color: #0abb87;
  border-color: #0abb87;
}
.btn-success:hover {
  color: #fff;
  background-color: #08976d;
  border-color: #078b64;
}
.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 197, 153, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #0abb87;
  border-color: #0abb87;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #078b64;
  border-color: #077e5b;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 197, 153, 0.5);
}
.btn-success + .btn.dropdown-toggle {
  position: relative;
}
.btn-success + .btn.dropdown-toggle:before {
  display: block;
  content: ' ';
  border-left: 1px solid #fff;
  position: absolute;
  top: 3px;
  left: -1px;
  bottom: 3px;
  opacity: 0.3;
}
.btn-success + .btn.dropdown-toggle.active:before,
.btn-success + .btn.dropdown-toggle:focus:before,
.btn-success + .btn.dropdown-toggle:hover:before {
  display: none;
}
.btn-info {
  color: #fff;
  background-color: #5578eb;
  border-color: #5578eb;
}
.btn-info:hover {
  color: #fff;
  background-color: #335de7;
  border-color: #2754e6;
}
.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 140, 238, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #5578eb;
  border-color: #5578eb;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #2754e6;
  border-color: #1c4be4;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 140, 238, 0.5);
}
.btn-info + .btn.dropdown-toggle {
  position: relative;
}
.btn-info + .btn.dropdown-toggle:before {
  display: block;
  content: ' ';
  border-left: 1px solid #fff;
  position: absolute;
  top: 3px;
  left: -1px;
  bottom: 3px;
  opacity: 0.3;
}
.btn-info + .btn.dropdown-toggle.active:before,
.btn-info + .btn.dropdown-toggle:focus:before,
.btn-info + .btn.dropdown-toggle:hover:before {
  display: none;
}
.btn-warning {
  color: #212529;
  background-color: #ffb822;
  border-color: #ffb822;
}
.btn-warning:hover {
  color: #212529;
  background-color: #fbaa00;
  border-color: #eea200;
}
.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 162, 35, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffb822;
  border-color: #ffb822;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #eea200;
  border-color: #e19900;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 162, 35, 0.5);
}
.btn-warning + .btn.dropdown-toggle {
  position: relative;
}
.btn-warning + .btn.dropdown-toggle:before {
  display: block;
  content: ' ';
  border-left: 1px solid #111;
  position: absolute;
  top: 3px;
  left: -1px;
  bottom: 3px;
  opacity: 0.3;
}
.btn-warning + .btn.dropdown-toggle.active:before,
.btn-warning + .btn.dropdown-toggle:focus:before,
.btn-warning + .btn.dropdown-toggle:hover:before {
  display: none;
}
.btn-danger {
  color: #fff;
  background-color: #fd397a;
  border-color: #fd397a;
}
.btn-danger:hover {
  color: #fff;
  background-color: #fd1361;
  border-color: #fc0758;
}
.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 87, 142, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #fd397a;
  border-color: #fd397a;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #fc0758;
  border-color: #f40252;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 87, 142, 0.5);
}
.btn-danger + .btn.dropdown-toggle {
  position: relative;
}
.btn-danger + .btn.dropdown-toggle:before {
  display: block;
  content: ' ';
  border-left: 1px solid #fff;
  position: absolute;
  top: 3px;
  left: -1px;
  bottom: 3px;
  opacity: 0.3;
}
.btn-danger + .btn.dropdown-toggle.active:before,
.btn-danger + .btn.dropdown-toggle:focus:before,
.btn-danger + .btn.dropdown-toggle:hover:before {
  display: none;
}
.btn-secondary {
  background-color: initial;
  border: 1px solid #ebedf2;
  color: #6c7293;
}
.btn-secondary.active,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover,
.show > .btn-secondary.dropdown-toggle {
  color: #6c7293;
  border-color: #ebedf2 !important;
  background-color: #f4f5f8 !important;
}
.btn-outline-light {
  color: #fff;
  border-color: #fff;
}
.btn-outline-light:hover {
  color: #282a3c;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem hsla(0, 0%, 100%, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #fff;
  background-color: initial;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsla(0, 0%, 100%, 0.5);
}
.btn-outline-primary {
  color: #5867dd;
  border-color: #5867dd;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #5867dd;
  border-color: #5867dd;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #5867dd;
  background-color: initial;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #5867dd;
  border-color: #5867dd;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);
}
.btn-outline-success {
  color: #0abb87;
  border-color: #0abb87;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #0abb87;
  border-color: #0abb87;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #0abb87;
  background-color: initial;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #0abb87;
  border-color: #0abb87;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);
}
.btn-outline-danger {
  color: #fd397a;
  border-color: #fd397a;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #fd397a;
  border-color: #fd397a;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #fd397a;
  background-color: initial;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fd397a;
  border-color: #fd397a;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);
}
.btn-outline-hover-success {
  background: transparent;
}
.btn-outline-hover-success.btn-clean {
  border: 1px solid transparent;
}
.btn-outline-hover-success.active,
.btn-outline-hover-success:active,
.btn-outline-hover-success:hover,
.show > .btn-outline-hover-success {
  color: #0abb87;
  border-color: #0abb87;
  background: transparent;
}
.btn.btn-elevate {
  transition: all 0.3s ease 0s;
}
.btn.btn-brand.btn-elevate {
  box-shadow: 0 4px 16px 0 rgba(93, 120, 255, 0.15);
}
.btn.btn-brand.btn-elevate.active,
.btn.btn-brand.btn-elevate:active,
.btn.btn-brand.btn-elevate:focus,
.btn.btn-brand.btn-elevate:hover,
.show > .btn.btn-brand.btn-elevate {
  box-shadow: 0 9px 16px 0 rgba(93, 120, 255, 0.25) !important;
}
.btn-group-sm.show > .btn.btn-brand.btn-elevate,
.btn-group-sm > .btn.btn-brand.btn-elevate.active,
.btn-group-sm > .btn.btn-brand.btn-elevate:active,
.btn-group-sm > .btn.btn-brand.btn-elevate:focus,
.btn-group-sm > .btn.btn-brand.btn-elevate:hover,
.btn.btn-brand.btn-elevate.active.btn-sm,
.btn.btn-brand.btn-elevate:active.btn-sm,
.btn.btn-brand.btn-elevate:focus.btn-sm,
.btn.btn-brand.btn-elevate:hover.btn-sm,
.show > .btn.btn-brand.btn-elevate.btn-sm {
  box-shadow: 0 6px 16px 0 rgba(93, 120, 255, 0.25) !important;
}
.btn.btn-light.btn-elevate {
  box-shadow: 0 4px 16px 0 hsla(0, 0%, 60%, 0.15);
}
.btn.btn-light.btn-elevate.active,
.btn.btn-light.btn-elevate:active,
.btn.btn-light.btn-elevate:focus,
.btn.btn-light.btn-elevate:hover,
.show > .btn.btn-light.btn-elevate {
  box-shadow: 0 9px 16px 0 hsla(0, 0%, 60%, 0.25) !important;
  background-color: initial;
  border-color: transparent;
}
.btn-group-sm.show > .btn.btn-light.btn-elevate,
.btn-group-sm > .btn.btn-light.btn-elevate.active,
.btn-group-sm > .btn.btn-light.btn-elevate:active,
.btn-group-sm > .btn.btn-light.btn-elevate:focus,
.btn-group-sm > .btn.btn-light.btn-elevate:hover,
.btn.btn-light.btn-elevate.active.btn-sm,
.btn.btn-light.btn-elevate:active.btn-sm,
.btn.btn-light.btn-elevate:focus.btn-sm,
.btn.btn-light.btn-elevate:hover.btn-sm,
.show > .btn.btn-light.btn-elevate.btn-sm {
  box-shadow: 0 6px 16px 0 hsla(0, 0%, 60%, 0.25) !important;
}
.btn.btn-dark.btn-elevate {
  box-shadow: 0 4px 16px 0 rgba(40, 42, 60, 0.15);
}
.btn.btn-dark.btn-elevate.active,
.btn.btn-dark.btn-elevate:active,
.btn.btn-dark.btn-elevate:focus,
.btn.btn-dark.btn-elevate:hover,
.show > .btn.btn-dark.btn-elevate {
  box-shadow: 0 9px 16px 0 rgba(40, 42, 60, 0.25) !important;
}
.btn-group-sm.show > .btn.btn-dark.btn-elevate,
.btn-group-sm > .btn.btn-dark.btn-elevate.active,
.btn-group-sm > .btn.btn-dark.btn-elevate:active,
.btn-group-sm > .btn.btn-dark.btn-elevate:focus,
.btn-group-sm > .btn.btn-dark.btn-elevate:hover,
.btn.btn-dark.btn-elevate.active.btn-sm,
.btn.btn-dark.btn-elevate:active.btn-sm,
.btn.btn-dark.btn-elevate:focus.btn-sm,
.btn.btn-dark.btn-elevate:hover.btn-sm,
.show > .btn.btn-dark.btn-elevate.btn-sm {
  box-shadow: 0 6px 16px 0 rgba(40, 42, 60, 0.25) !important;
}
.btn.btn-primary.btn-elevate {
  box-shadow: 0 4px 16px 0 rgba(88, 103, 221, 0.15);
}
.btn.btn-primary.btn-elevate.active,
.btn.btn-primary.btn-elevate:active,
.btn.btn-primary.btn-elevate:focus,
.btn.btn-primary.btn-elevate:hover,
.show > .btn.btn-primary.btn-elevate {
  box-shadow: 0 9px 16px 0 rgba(88, 103, 221, 0.25) !important;
}
.btn-group-sm.show > .btn.btn-primary.btn-elevate,
.btn-group-sm > .btn.btn-primary.btn-elevate.active,
.btn-group-sm > .btn.btn-primary.btn-elevate:active,
.btn-group-sm > .btn.btn-primary.btn-elevate:focus,
.btn-group-sm > .btn.btn-primary.btn-elevate:hover,
.btn.btn-primary.btn-elevate.active.btn-sm,
.btn.btn-primary.btn-elevate:active.btn-sm,
.btn.btn-primary.btn-elevate:focus.btn-sm,
.btn.btn-primary.btn-elevate:hover.btn-sm,
.show > .btn.btn-primary.btn-elevate.btn-sm {
  box-shadow: 0 6px 16px 0 rgba(88, 103, 221, 0.25) !important;
}
.btn.btn-success.btn-elevate {
  box-shadow: 0 4px 16px 0 rgba(10, 187, 135, 0.15);
}
.btn.btn-success.btn-elevate.active,
.btn.btn-success.btn-elevate:active,
.btn.btn-success.btn-elevate:focus,
.btn.btn-success.btn-elevate:hover,
.show > .btn.btn-success.btn-elevate {
  box-shadow: 0 9px 16px 0 rgba(10, 187, 135, 0.25) !important;
}
.btn-group-sm.show > .btn.btn-success.btn-elevate,
.btn-group-sm > .btn.btn-success.btn-elevate.active,
.btn-group-sm > .btn.btn-success.btn-elevate:active,
.btn-group-sm > .btn.btn-success.btn-elevate:focus,
.btn-group-sm > .btn.btn-success.btn-elevate:hover,
.btn.btn-success.btn-elevate.active.btn-sm,
.btn.btn-success.btn-elevate:active.btn-sm,
.btn.btn-success.btn-elevate:focus.btn-sm,
.btn.btn-success.btn-elevate:hover.btn-sm,
.show > .btn.btn-success.btn-elevate.btn-sm {
  box-shadow: 0 6px 16px 0 rgba(10, 187, 135, 0.25) !important;
}
.btn.btn-info.btn-elevate {
  box-shadow: 0 4px 16px 0 rgba(85, 120, 235, 0.15);
}
.btn.btn-info.btn-elevate.active,
.btn.btn-info.btn-elevate:active,
.btn.btn-info.btn-elevate:focus,
.btn.btn-info.btn-elevate:hover,
.show > .btn.btn-info.btn-elevate {
  box-shadow: 0 9px 16px 0 rgba(85, 120, 235, 0.25) !important;
}
.btn-group-sm.show > .btn.btn-info.btn-elevate,
.btn-group-sm > .btn.btn-info.btn-elevate.active,
.btn-group-sm > .btn.btn-info.btn-elevate:active,
.btn-group-sm > .btn.btn-info.btn-elevate:focus,
.btn-group-sm > .btn.btn-info.btn-elevate:hover,
.btn.btn-info.btn-elevate.active.btn-sm,
.btn.btn-info.btn-elevate:active.btn-sm,
.btn.btn-info.btn-elevate:focus.btn-sm,
.btn.btn-info.btn-elevate:hover.btn-sm,
.show > .btn.btn-info.btn-elevate.btn-sm {
  box-shadow: 0 6px 16px 0 rgba(85, 120, 235, 0.25) !important;
}
.btn.btn-warning.btn-elevate {
  box-shadow: 0 4px 16px 0 rgba(255, 184, 34, 0.15);
}
.btn.btn-warning.btn-elevate.active,
.btn.btn-warning.btn-elevate:active,
.btn.btn-warning.btn-elevate:focus,
.btn.btn-warning.btn-elevate:hover,
.show > .btn.btn-warning.btn-elevate {
  box-shadow: 0 9px 16px 0 rgba(255, 184, 34, 0.25) !important;
}
.btn-group-sm.show > .btn.btn-warning.btn-elevate,
.btn-group-sm > .btn.btn-warning.btn-elevate.active,
.btn-group-sm > .btn.btn-warning.btn-elevate:active,
.btn-group-sm > .btn.btn-warning.btn-elevate:focus,
.btn-group-sm > .btn.btn-warning.btn-elevate:hover,
.btn.btn-warning.btn-elevate.active.btn-sm,
.btn.btn-warning.btn-elevate:active.btn-sm,
.btn.btn-warning.btn-elevate:focus.btn-sm,
.btn.btn-warning.btn-elevate:hover.btn-sm,
.show > .btn.btn-warning.btn-elevate.btn-sm {
  box-shadow: 0 6px 16px 0 rgba(255, 184, 34, 0.25) !important;
}
.btn.btn-danger.btn-elevate {
  box-shadow: 0 4px 16px 0 rgba(253, 57, 122, 0.15);
}
.btn.btn-danger.btn-elevate.active,
.btn.btn-danger.btn-elevate:active,
.btn.btn-danger.btn-elevate:focus,
.btn.btn-danger.btn-elevate:hover,
.show > .btn.btn-danger.btn-elevate {
  box-shadow: 0 9px 16px 0 rgba(253, 57, 122, 0.25) !important;
}
.btn-group-sm.show > .btn.btn-danger.btn-elevate,
.btn-group-sm > .btn.btn-danger.btn-elevate.active,
.btn-group-sm > .btn.btn-danger.btn-elevate:active,
.btn-group-sm > .btn.btn-danger.btn-elevate:focus,
.btn-group-sm > .btn.btn-danger.btn-elevate:hover,
.btn.btn-danger.btn-elevate.active.btn-sm,
.btn.btn-danger.btn-elevate:active.btn-sm,
.btn.btn-danger.btn-elevate:focus.btn-sm,
.btn.btn-danger.btn-elevate:hover.btn-sm,
.show > .btn.btn-danger.btn-elevate.btn-sm {
  box-shadow: 0 6px 16px 0 rgba(253, 57, 122, 0.25) !important;
}
.btn.btn-square {
  border-radius: 0;
}
.btn.btn-pill {
  border-radius: 2rem;
}
.btn.btn-upper {
  text-transform: uppercase;
}
.btn.btn-bold {
  font-weight: 600;
}
.btn.btn-wide {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.btn.btn-font-sm {
  font-size: 0.85rem;
}
.btn {
  align-items: center;
}
.btn [class^='la-'] {
  font-size: 1.5rem;
}
.btn [class^='fa-'],
.btn [class^='flaticon-'] {
  font-size: 1.2rem;
}
.btn i {
  padding-right: 0.5rem;
  vertical-align: middle;
  line-height: 0;
}
.btn-group-sm > .btn [class^='la-'],
.btn.btn-sm [class^='la-'] {
  font-size: 1.3rem;
}
.btn-group-sm > .btn [class^='fa-'],
.btn-group-sm > .btn [class^='flaticon-'],
.btn.btn-sm [class^='fa-'],
.btn.btn-sm [class^='flaticon-'] {
  font-size: 1rem;
}
.btn-group-sm > .btn i,
.btn.btn-sm i {
  padding-right: 0.35rem;
}
.btn-group-lg > .btn [class^='la-'],
.btn.btn-lg [class^='la-'] {
  font-size: 1.7rem;
}
.btn-group-lg > .btn [class^='fa-'],
.btn.btn-lg [class^='fa-'] {
  font-size: 1.4rem;
}
.btn-group-lg > .btn [class^='flaticon-'],
.btn.btn-lg [class^='flaticon-'] {
  font-size: 1.3rem;
}
.btn-group-lg > .btn i,
.btn.btn-lg i {
  padding-right: 0.75rem;
}
.btn.btn-label-brand {
  background-color: rgba(93, 120, 255, 0.1);
  color: #5d78ff;
  cursor: text !important;
}
a.btn.btn-label-brand,
button.btn.btn-label-brand,
input.btn.btn-label-brand {
  cursor: pointer !important;
}
a.btn.btn-label-brand.active,
a.btn.btn-label-brand:focus,
a.btn.btn-label-brand:hover,
button.btn.btn-label-brand.active,
button.btn.btn-label-brand:focus,
button.btn.btn-label-brand:hover,
input.btn.btn-label-brand.active,
input.btn.btn-label-brand:focus,
input.btn.btn-label-brand:hover {
  background-color: #5d78ff;
  color: #fff;
}
.btn.btn-label-dark {
  background-color: rgba(40, 42, 60, 0.1);
  color: #282a3c;
  cursor: text !important;
}
a.btn.btn-label-dark,
button.btn.btn-label-dark,
input.btn.btn-label-dark {
  cursor: pointer !important;
}
a.btn.btn-label-dark.active,
a.btn.btn-label-dark:focus,
a.btn.btn-label-dark:hover,
button.btn.btn-label-dark.active,
button.btn.btn-label-dark:focus,
button.btn.btn-label-dark:hover,
input.btn.btn-label-dark.active,
input.btn.btn-label-dark:focus,
input.btn.btn-label-dark:hover {
  background-color: #282a3c;
  color: #fff;
}
.btn.btn-label-primary {
  background-color: rgba(88, 103, 221, 0.1);
  color: #5867dd;
  cursor: text !important;
}
a.btn.btn-label-primary,
button.btn.btn-label-primary,
input.btn.btn-label-primary {
  cursor: pointer !important;
}
a.btn.btn-label-primary.active,
a.btn.btn-label-primary:focus,
a.btn.btn-label-primary:hover,
button.btn.btn-label-primary.active,
button.btn.btn-label-primary:focus,
button.btn.btn-label-primary:hover,
input.btn.btn-label-primary.active,
input.btn.btn-label-primary:focus,
input.btn.btn-label-primary:hover {
  background-color: #5867dd;
  color: #fff;
}
.btn.btn-label-success {
  background-color: rgba(10, 187, 135, 0.1);
  color: #0abb87;
  cursor: text !important;
}
a.btn.btn-label-success,
button.btn.btn-label-success,
input.btn.btn-label-success {
  cursor: pointer !important;
}
a.btn.btn-label-success.active,
a.btn.btn-label-success:focus,
a.btn.btn-label-success:hover,
button.btn.btn-label-success.active,
button.btn.btn-label-success:focus,
button.btn.btn-label-success:hover,
input.btn.btn-label-success.active,
input.btn.btn-label-success:focus,
input.btn.btn-label-success:hover {
  background-color: #0abb87;
  color: #fff;
}
.btn.btn-label-info {
  background-color: rgba(85, 120, 235, 0.1);
  color: #5578eb;
  cursor: text !important;
}
a.btn.btn-label-info,
button.btn.btn-label-info,
input.btn.btn-label-info {
  cursor: pointer !important;
}
a.btn.btn-label-info.active,
a.btn.btn-label-info:focus,
a.btn.btn-label-info:hover,
button.btn.btn-label-info.active,
button.btn.btn-label-info:focus,
button.btn.btn-label-info:hover,
input.btn.btn-label-info.active,
input.btn.btn-label-info:focus,
input.btn.btn-label-info:hover {
  background-color: #5578eb;
  color: #fff;
}
.btn.btn-label-warning {
  background-color: rgba(255, 184, 34, 0.1);
  color: #ffb822;
  cursor: text !important;
}
a.btn.btn-label-warning,
button.btn.btn-label-warning,
input.btn.btn-label-warning {
  cursor: pointer !important;
}
a.btn.btn-label-warning.active,
a.btn.btn-label-warning:focus,
a.btn.btn-label-warning:hover,
button.btn.btn-label-warning.active,
button.btn.btn-label-warning:focus,
button.btn.btn-label-warning:hover,
input.btn.btn-label-warning.active,
input.btn.btn-label-warning:focus,
input.btn.btn-label-warning:hover {
  background-color: #ffb822;
  color: #111;
}
.btn.btn-label-danger {
  background-color: rgba(253, 57, 122, 0.1);
  color: #fd397a;
  cursor: text !important;
}
a.btn.btn-label-danger,
button.btn.btn-label-danger,
input.btn.btn-label-danger {
  cursor: pointer !important;
}
a.btn.btn-label-danger.active,
a.btn.btn-label-danger:focus,
a.btn.btn-label-danger:hover,
button.btn.btn-label-danger.active,
button.btn.btn-label-danger:focus,
button.btn.btn-label-danger:hover,
input.btn.btn-label-danger.active,
input.btn.btn-label-danger:focus,
input.btn.btn-label-danger:hover {
  background-color: #fd397a;
  color: #fff;
}
.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.btn.btn-icon i {
  padding: 0;
  margin: 0;
  line-height: 0;
}
.btn.btn-icon {
  height: 3rem;
  width: 3rem;
}
.btn-group-sm > .btn.btn-icon,
.btn.btn-icon.btn-sm {
  height: 2.5rem;
  width: 2.5rem;
}
.btn-group-lg > .btn.btn-icon,
.btn.btn-icon.btn-lg {
  height: 4rem;
  width: 4rem;
}
.btn.btn-icon.btn-icon-md [class^='la-'] {
  font-size: 1.3rem;
}
.btn.btn-icon.btn-icon-md [class^='fa-'],
.btn.btn-icon.btn-icon-md [class^='flaticon-'] {
  font-size: 1.2rem;
}
.btn.btn-icon.btn-brand.active i,
.btn.btn-icon.btn-brand:focus i,
.btn.btn-icon.btn-brand:hover i,
.btn.btn-icon.btn-brand i {
  color: #fff;
}
.btn.btn-icon.btn-light.active i,
.btn.btn-icon.btn-light:focus i,
.btn.btn-icon.btn-light:hover i,
.btn.btn-icon.btn-light i {
  color: #282a3c;
}
.btn.btn-icon.btn-dark.active i,
.btn.btn-icon.btn-dark:focus i,
.btn.btn-icon.btn-dark:hover i,
.btn.btn-icon.btn-dark i,
.btn.btn-icon.btn-info.active i,
.btn.btn-icon.btn-info:focus i,
.btn.btn-icon.btn-info:hover i,
.btn.btn-icon.btn-info i,
.btn.btn-icon.btn-primary.active i,
.btn.btn-icon.btn-primary:focus i,
.btn.btn-icon.btn-primary:hover i,
.btn.btn-icon.btn-primary i,
.btn.btn-icon.btn-success.active i,
.btn.btn-icon.btn-success:focus i,
.btn.btn-icon.btn-success:hover i,
.btn.btn-icon.btn-success i {
  color: #fff;
}
.btn.btn-icon.btn-warning.active i,
.btn.btn-icon.btn-warning:focus i,
.btn.btn-icon.btn-warning:hover i,
.btn.btn-icon.btn-warning i {
  color: #111;
}
.btn.btn-icon.btn-danger.active i,
.btn.btn-icon.btn-danger:focus i,
.btn.btn-icon.btn-danger:hover i,
.btn.btn-icon.btn-danger i {
  color: #fff;
}
.btn-icon-h {
  height: 3rem;
}
.btn-group-sm > .btn-icon-h.btn,
.btn-icon-h.btn-sm {
  height: 2.25rem;
}
.btn-group-lg > .btn-icon-h.btn,
.btn-icon-h.btn-lg {
  height: 4rem;
}
.input-group-append .btn.btn-icon,
.input-group-prepend .btn.btn-icon {
  height: auto;
}
.btn-group.btn-pill {
  border-radius: 2rem;
  overflow: hidden;
}
.btn-group.btn-pill .btn-brand,
.btn-group.btn-pill .btn-danger,
.btn-group.btn-pill .btn-dark,
.btn-group.btn-pill .btn-info,
.btn-group.btn-pill .btn-light,
.btn-group.btn-pill .btn-primary,
.btn-group.btn-pill .btn-success,
.btn-group.btn-pill .btn-warning {
  border-width: 1px;
  border-style: solid;
}
@media (max-width: 768px) {
  .btn-group.btn-pill {
    border-radius: 0;
  }
  .btn-group.btn-pill .nav {
    justify-content: center;
  }
  .btn-group.btn-pill .btn {
    margin-bottom: 0.5rem;
    border-radius: 2rem;
  }
}
.badge {
  font-weight: 400;
}
.accordion .card {
  margin: 0;
}
.accordion .card .card-header {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-bottom: 0;
}
.accordion .card .card-header .card-title {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1rem;
  color: #5d78ff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.accordion .card .card-header .card-title > i {
  margin-right: 1rem;
  font-size: 1.4rem;
  color: #5d78ff;
}
.accordion .card .card-header .card-title.collapsed {
  color: #6c7293;
}
.accordion .card .card-header .card-title.collapsed > i {
  color: #a7abc3;
}
.accordion .card .card-header .card-title:after {
  right: 1rem;
}
.accordion .card .card-body {
  padding: 1.25rem;
  font-size: 1rem;
}
.accordion.accordion-toggle-arrow .card .card-header .card-title {
  color: #5d78ff;
  position: relative;
}
.accordion.accordion-toggle-arrow .card .card-header .card-title:after {
  position: absolute;
  font-size: 1rem;
  font-family: LineAwesome;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  content: '';
  color: #5d78ff;
}
.accordion.accordion-toggle-arrow .card .card-header .card-title.collapsed {
  color: #a7abc3;
}
.accordion.accordion-toggle-arrow .card .card-header .card-title.collapsed:after {
  color: #a7abc3;
  content: '';
}
.row.row-no-padding {
  margin-left: 0;
  margin-right: 0;
}
.row.row-no-padding > div {
  padding-left: 0;
  padding-right: 0;
}
.row[class*='row-col-separator-'] > div {
  border-bottom: 1px solid #ebedf2;
}
.row[class*='row-col-separator-'] > div:last-child {
  border-bottom: 0;
}
@media (min-width: 1400px) {
  .row.row-col-separator-xl > div {
    border-bottom: 0;
    border-right: 1px solid #ebedf2;
  }
  .row.row-col-separator-xl > div:last-child {
    border-right: 0;
  }
}
.progress {
  background-color: #ebedf2;
}
.progress .progress-bar {
  transition: all 0.5s ease;
}
code {
  background-color: #f7f8fa;
  padding: 0.15rem 0.25rem;
  border-radius: 4px;
}
.ps > .ps__rail-y,
.ps > .ps__rail-y:focus,
.ps > .ps__rail-y:hover {
  width: 4px;
}
.ps > .ps__rail-y > .ps__thumb-y {
  width: 4px;
  border-radius: 4px;
}
.ps > .ps__rail-y > .ps__thumb-y:focus,
.ps > .ps__rail-y > .ps__thumb-y:hover {
  width: 4px;
}
.ps > .ps__rail-x,
.ps > .ps__rail-x:focus,
.ps > .ps__rail-x:hover {
  height: 4px;
}
.ps > .ps__rail-x > .ps__thumb-x {
  top: 0;
  height: 4px;
  border-radius: 4px;
}
.ps > .ps__rail-x > .ps__thumb-x:focus,
.ps > .ps__rail-x > .ps__thumb-x:hover {
  top: 0;
  height: 4px;
}
.ps > .ps__rail-x {
  background: transparent;
}
.ps > .ps__rail-x:focus,
.ps > .ps__rail-x:hover {
  opacity: 1;
  background: transparent;
}
.ps > .ps__rail-x:focus > .ps__thumb-x,
.ps > .ps__rail-x:hover > .ps__thumb-x {
  opacity: 1;
}
.ps > .ps__rail-x > .ps__thumb-x,
.ps > .ps__rail-x > .ps__thumb-x:focus,
.ps > .ps__rail-x > .ps__thumb-x:hover {
  background: #d8dce6;
  opacity: 1;
}
.ps > .ps__rail-y {
  background: transparent;
}
.ps > .ps__rail-y:focus,
.ps > .ps__rail-y:hover {
  background: transparent;
  opacity: 1;
}
.ps > .ps__rail-y:focus > .ps__thumb-y,
.ps > .ps__rail-y:hover > .ps__thumb-y {
  opacity: 1;
}
.ps > .ps__rail-y > .ps__thumb-y,
.ps > .ps__rail-y > .ps__thumb-y:focus,
.ps > .ps__rail-y > .ps__thumb-y:hover {
  background: #d8dce6;
  opacity: 1;
}
.dropzone {
  padding: 20px;
  text-align: center;
  cursor: pointer;
}
.dropzone,
.dropzone .dz-preview .dz-image {
  border-radius: 4px;
}
.dropzone {
  border: 2px dashed #ebedf2;
}
.toast {
  background-position: calc(100% - (100% - 15px)) 50% !important;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  border: 0;
}
.toast .toast-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.fa-lg {
  font-size: 1.33333em !important;
}
.fa-3x {
  font-size: 3em !important;
}
.swal2-popup {
  border-radius: 4px;
  padding: 3rem;
}
.swal2-popup .swal2-title {
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0 0 1rem;
}
.swal2-popup .swal2-content {
  font-weight: 400;
  font-size: 1rem;
}
.swal2-popup .btn {
  margin: 15px 5px 0;
}
.swal2-popup .swal2-styled:focus {
  box-shadow: none;
}
.swal2-container {
  overflow-y: hidden !important;
}
body.swal2-height-auto {
  height: 100% !important;
}
.swal2-popup .swal2-title {
  color: #464457;
}
.swal2-popup .swal2-content {
  color: #a7abc3;
}
body {
  background: #f2f3f8;
}
.multiselect,
.multiselect__input,
.multiselect__single,
.multiselect__tag {
  font-size: 1rem !important;
}
.right-align {
  text-align: left !important;
  justify-content: flex-start !important;
}
.multiselect__placeholder {
  padding: 0 !important;
  position: relative;
  top: 2px;
}
.loading {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  overflow: hidden;
  background: grey;
  opacity: 0.6;
}
.loader {
  font-size: 10px;
  border: 0.8em solid #dadbdf;
  border-right-color: #3aa6a5;
  animation: load8 1.1s linear infinite;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -4.05em;
}
.symbol > img {
  width: 100%;
  max-width: 50px;
  height: 50px;
}
@media (min-width: 576px) {
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}
@media (min-width: 992px) {
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}
@media (min-width: 1200px) {
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}
@media (min-width: 1400px) {
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}
.kt-invoice-2 .kt-invoice__body table tbody tr td {
  background-color: initial;
  padding: 1rem 0;
  border-top: none;
  font-weight: 700;
  font-size: 1.1rem;
  color: #595d6e;
}
.kt-portlet--tabs .nav-tabs.nav-tabs-line {
  margin: 0 0 -1px 0.5rem;
  border-color: transparent !important;
}
.kt-portlet--tabs .nav-tabs.nav-tabs-line .nav-item {
  display: flex;
  align-items: stretch;
}
.kt-portlet--tabs .nav-tabs.nav-tabs-line .nav-item .nav-link {
  display: flex;
  align-items: center;
}
.kt-widget--user-profile-4 .kt-widget__media i {
  font-size: 60px;
  position: relative;
  right: 12px;
}
.kt-widget.kt-widget--user-profile-2 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head {
  display: flex;
  align-items: center;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__img {
  max-width: 90px;
  border-radius: 50%;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  font-size: 1.5rem;
  border-radius: 50%;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--brand {
  background: rgba(54, 108, 243, 0.1);
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--light {
  background: hsla(0, 0%, 100%, 0.1);
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--dark {
  background: rgba(40, 42, 60, 0.1);
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--primary {
  background: rgba(88, 103, 221, 0.1);
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--success {
  background: rgba(29, 201, 183, 0.1);
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--info {
  background: rgba(85, 120, 235, 0.1);
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--warning {
  background: rgba(255, 184, 34, 0.1);
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--danger {
  background: rgba(253, 57, 122, 0.1);
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info {
  padding-left: 1rem;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info .kt-widget__username {
  font-size: 1.4rem;
  color: #48465b;
  font-weight: 500;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info .kt-widget__username:hover {
  color: #366cf3;
  transition: color 0.3s ease;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info .kt-widget__titel {
  font-size: 1.4rem;
  color: #48465b;
  font-weight: 500;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info .kt-widget__titel:hover {
  color: #366cf3;
  transition: color 0.3s ease;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info .kt-widget__desc {
  display: block;
  font-weight: 500;
  font-size: 1.1rem;
  padding-top: 0.4rem;
  color: #74788d;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body {
  flex: 1;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__section {
  padding: 1rem 0;
  color: #595d6e;
  font-weight: 400;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__section a {
  padding-right: 0.3rem;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__content {
  display: flex;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__content .kt-widget__stats {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 1.7rem;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__content .kt-widget__stats .kt-widget__icon i {
  font-size: 2.7rem;
  color: #a2a5b9;
  font-weight: 400;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__content .kt-widget__stats .kt-widget__details {
  padding-left: 1rem;
}
.kt-widget.kt-widget--user-profile-2
  .kt-widget__body
  .kt-widget__content
  .kt-widget__stats
  .kt-widget__details
  .kt-widget__title {
  display: block;
  color: #595d6e;
  font-weight: 500;
  font-size: 0.95rem;
}
.kt-widget.kt-widget--user-profile-2
  .kt-widget__body
  .kt-widget__content
  .kt-widget__stats
  .kt-widget__details
  .kt-widget__value {
  display: block;
  color: #48465b;
  font-weight: 600;
  font-size: 1.2rem;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item {
  padding: 0.7rem 0;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item .kt-widget__contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item .kt-widget__contact .kt-widget__label {
  color: #48465b;
  font-weight: 600;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item .kt-widget__contact .kt-widget__data {
  color: #74788d;
  font-weight: 400;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item .kt-widget__contact a.kt-widget__data:hover {
  color: #366cf3;
  transition: color 0.3s ease;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item .kt-widget__contact:last-child {
  padding-bottom: 0;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__footer {
  margin-top: 2rem;
}
.kt-widget.kt-widget--user-profile-2 .kt-widget__footer .btn {
  font-size: 1rem;
  font-weight: 600;
  padding: 1.1rem 0;
  width: 100%;
}
.kt-card-profile {
  padding: 5px 0;
  margin: 0;
}
.kt-card-profile .kt-card-profile__pic {
  padding: 0 5px 0 0;
  text-align: center;
}
.kt-card-profile .kt-card-profile__pic .kt-card-profile__pic-wrapper {
  padding: 8px;
  display: inline-block;
  margin: 2rem auto;
  border-radius: 100%;
  border: 2px solid #ebedf2;
}
.kt-card-profile .kt-card-profile__pic img {
  text-align: center;
  max-width: 130px !important;
  margin: 0 auto !important;
  border-radius: 100%;
}
.kt-card-profile .kt-card-profile__pic .kt-card-profile__no-photo {
  height: 130px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 100%;
}
.kt-card-profile .kt-card-profile__pic .kt-card-profile__no-photo > span {
  line-height: 0;
  font-weight: 700;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.kt-card-profile .kt-card-profile__title {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
}
.kt-card-profile .kt-card-profile__details {
  text-align: center;
  padding: 0;
}
.kt-card-profile .kt-card-profile__details .kt-card-profile__name {
  display: block;
  padding: 0;
  font-size: 1.3rem;
  font-weight: 500;
  color: #6c7293;
}
.kt-card-profile .kt-card-profile__details .kt-card-profile__email {
  display: inline-block;
  padding: 6px 0 0;
  font-size: 500;
  color: #6c7293;
}
.kt-user-card {
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem 1rem;
  background-color: #f9fafe;
}
.kt-user-card .kt-user-card__avatar .kt-badge,
.kt-user-card .kt-user-card__avatar img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}
.kt-user-card .kt-user-card__avatar .kt-badge {
  font-size: 2.2rem;
}
.kt-user-card .kt-user-card__name {
  flex-grow: 1;
  color: #6c7293;
  font-size: 1.3rem;
  font-weight: 500;
  padding-left: 1rem;
}
.kt-user-card .kt-user-card__badge {
  padding-left: 0.5rem;
}
.kt-user-card.kt-user-card--skin-light .kt-user-card__name {
  color: #6c7293;
}
.kt-user-card.kt-user-card--skin-dark .kt-user-card__name {
  color: #fff;
}
.kt-user-card.kt-user-card--skin-dark .kt-user-card__avatar .kt-badge {
  background-color: hsla(0, 0%, 100%, 0.1);
}
.kt-user-card-v2 {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.kt-user-card-v2 .kt-user-card-v2__pic {
  padding-right: 0.5rem;
}
.kt-user-card-v2 .kt-user-card-v2__pic .kt-badge {
  width: 40px;
  height: 40px;
}
.kt-user-card-v2 .kt-user-card-v2__pic img {
  border-radius: 50%;
  max-width: 40px;
  max-height: 40px;
}
.kt-user-card-v2 .kt-user-card-v2__details {
  line-height: 0;
}
.kt-user-card-v2 .kt-user-card-v2__details .kt-user-card-v2__name {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition: color 0.3s ease;
  color: #6c7293;
}
.kt-user-card-v2 .kt-user-card-v2__details a.kt-user-card-v2__name:hover {
  transition: color 0.3s ease;
  color: #5d78ff;
}
.kt-user-card-v2 .kt-user-card-v2__details .kt-user-card-v2__desc,
.kt-user-card-v2 .kt-user-card-v2__details .kt-user-card-v2__email {
  margin-top: 3px;
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 400;
  color: #a7abc3;
}
.kt-user-card-v2 .kt-user-card-v2__details a.kt-user-card-v2__email:hover {
  transition: color 0.3s ease;
  color: #5d78ff;
}
.kt-user-card-v3 {
  display: flex;
  align-items: center;
}
.kt-user-card-v3 .kt-user-card-v3__avatar > img {
  height: 120px;
  width: 120px;
  border-radius: 7px;
}
.kt-user-card-v3 .kt-user-card-v3__detalis {
  padding-left: 1.5rem;
}
.kt-user-card-v3 .kt-user-card-v3__detalis a.kt-user-card-v3__name {
  font-size: 1.2rem;
  font-weight: 500;
  color: #33323f;
  text-decoration: none;
  padding-bottom: 0.8rem;
  transition: all 0.3s;
}
.kt-user-card-v3 .kt-user-card-v3__detalis a.kt-user-card-v3__name:hover {
  transition: all 0.3s;
  color: #5d78ff;
}
.kt-user-card-v3 .kt-user-card-v3__detalis .kt-user-card-v3__desc {
  font-size: 1rem;
  font-weight: 400;
  color: #b2afc6;
  padding: 0.8rem 0;
}
.kt-user-card-v3 .kt-user-card-v3__detalis .kt-user-card-v3__info a.kt-user-card-v3__item {
  display: flex;
  align-items: center;
  padding-bottom: 0.8rem;
}
.kt-user-card-v3 .kt-user-card-v3__detalis .kt-user-card-v3__info a.kt-user-card-v3__item > i {
  line-height: 0;
  font-size: 1.4rem;
}
.kt-user-card-v3 .kt-user-card-v3__detalis .kt-user-card-v3__info a.kt-user-card-v3__item .kt-user-card-v3__tag {
  font-size: 0.9rem;
  font-weight: 400;
  color: #9e9bb1;
  padding-left: 0.8rem;
  transition: all 0.3s;
}
.kt-user-card-v3 .kt-user-card-v3__detalis .kt-user-card-v3__info a.kt-user-card-v3__item:hover .kt-user-card-v3__tag {
  transition: all 0.3s;
  color: #5d78ff;
}
.kt-head {
  padding: 2.5rem 1rem;
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.kt-head.kt-head--space-sm {
  padding: 1.5rem 1rem;
}
.kt-head.kt-head--space-lg {
  padding: 3rem 1rem;
}
.kt-head.kt-head--fit-x {
  padding-left: 0;
  padding-right: 0;
}
.kt-head.kt-head--fit-b {
  padding-bottom: 0 !important;
}
.kt-head.kt-head--fit-t {
  padding-top: 0 !important;
}
.kt-head.kt-head--space-bottom-sm {
  padding-bottom: 1rem;
}
.kt-head .kt-head__title {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
}
.kt-head .kt-head__sub {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}
.kt-head.kt-head--skin-dark .kt-head__title {
  color: #fff;
}
.kt-head.kt-head--skin-dark .kt-head__sub {
  color: #f0f0f0;
}
.kt-head.kt-head--skin-dark .nav.nav-tabs-line {
  border-bottom-color: hsla(0, 0%, 100%, 0.3) !important;
}
.kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link {
  color: hsla(0, 0%, 100%, 0.5);
}
.kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link > i {
  color: hsla(0, 0%, 100%, 0.4);
}
.kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link.active,
.kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link.active > i,
.kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link:hover,
.kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link:hover > i {
  color: #fff;
}
.kt-head.kt-head--skin-light {
  background-color: #f9fafe;
}
.kt-head.kt-head--skin-light .kt-head__title {
  color: #6c7293;
}
.kt-head.kt-head--skin-light .kt-head__sub {
  color: #a7abc3;
}
.kt-head .nav {
  margin: 1rem 0 0;
}
.kt-head-skin-light-bg-color {
  background-color: #f9fafe;
}
.kt-offcanvas-panel {
  background-color: #fff;
  box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.13);
  padding: 2.5rem;
}
.kt-offcanvas-panel .kt-offcanvas-panel__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__title {
  font-size: 1.4rem;
  font-weight: 500;
  color: #464457;
  margin: 0;
  padding: 0;
}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__title small {
  color: #a7abc3;
  font-size: 1rem;
}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #f7f8fa;
  transition: all 0.3s;
  border-radius: 4px;
}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__close i {
  transition: all 0.3s;
  font-size: 0.8rem;
  color: #a7abc3;
}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__close:hover {
  transition: all 0.3s;
  background-color: #5d78ff;
}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__close:hover i {
  transition: all 0.3s;
  color: #fff;
}
.kt-offcanvas-panel .kt-offcanvas-panel__body {
  position: relative;
}
.kt-offcanvas-panel .kt-offcanvas-panel__body .kt-offcanvas-panel__section {
  padding: 0;
  font-size: 1.3rem;
  font-weight: 500;
  color: #6c7293;
  margin: 2rem 0 1.5rem;
}
.kt-offcanvas-panel .kt-offcanvas-panel__body .kt-offcanvas-panel__section.kt-offcanvas-panel__section--first {
  margin-top: 0;
}
.kt-offcanvas-panel-close {
  display: none;
}
.kt-offcanvas-panel {
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  backface-visibility: hidden;
  width: 425px !important;
  transition: all 0.3s ease;
  right: -445px;
}
.kt-offcanvas-panel.kt-offcanvas-panel--on {
  transition: all 0.3s ease;
  right: 0;
}
.kt-offcanvas-panel--left .kt-offcanvas-panel {
  left: -445px;
  right: auto;
}
.kt-offcanvas-panel--left .kt-offcanvas-panel.kt-offcanvas-panel--on {
  transition: all 0.3s ease;
  left: 0;
  right: auto;
}
.kt-offcanvas-panel-close {
  width: 25px;
  height: 25px;
  top: 1px;
  z-index: 1002;
  transition: all 0.3s ease;
  position: fixed;
  border: 0;
  box-shadow: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -25px;
}
.kt-offcanvas-panel-close > i {
  line-height: 0;
  font-size: 1.4rem;
}
.kt-offcanvas-panel-close:hover {
  text-decoration: none;
}
.kt-offcanvas-panel--left .kt-offcanvas-panel-close {
  right: auto;
  left: -25px;
}
.kt-offcanvas-panel--on .kt-offcanvas-panel-close {
  transition: all 0.3s ease;
  right: 399px;
}
.kt-offcanvas-panel--on.kt-offcanvas-panel--left .kt-offcanvas-panel-close {
  right: auto;
  left: 399px;
}
.kt-offcanvas-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  animation: kt-animate-fade-in 0.3s linear 1;
  background: rgba(0, 0, 0, 0.1);
}
.kt-demo-panel-toggle {
  background-color: #fff;
  cursor: pointer;
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 200px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.13);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.kt-demo-panel-toggle span {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #5d78ff;
  letter-spacing: 3px;
}
.kt-demo-panel {
  background-color: #fff;
  box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.13);
  padding: 2.5rem;
}
.kt-demo-panel .kt-demo-panel__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__title {
  font-size: 1.4rem;
  font-weight: 500;
  color: #464457;
  margin: 0;
  padding: 0;
}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__title small {
  color: #a7abc3;
  font-size: 1.1rem;
}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #f7f8fa;
  transition: all 0.3s;
  border-radius: 4px;
}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__close i {
  transition: all 0.3s;
  font-size: 0.8rem;
  color: #a7abc3;
}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__close:hover {
  transition: all 0.3s;
  background-color: #5d78ff;
}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__close:hover i {
  transition: all 0.3s;
  color: #fff;
}
.kt-demo-panel .kt-demo-panel__body {
  position: relative;
}
.kt-demo-panel .kt-demo-panel__body .ps__rail-y {
  opacity: 1;
}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item {
  display: block;
  margin-bottom: 2rem;
  outline: 0;
}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item:last-child {
  margin-bottom: 0;
}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item:hover {
  text-decoration: none;
}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: #6c7293;
  text-align: center;
  margin-bottom: 0.5rem;
}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-preview {
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
  border: 4px solid #ebedf2;
  border-radius: 4px;
}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-preview img {
  width: 100%;
}
.kt-demo-panel
  .kt-demo-panel__body
  .kt-demo-panel__item
  .kt-demo-panel__item-preview
  .kt-demo-panel__item-preview-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
  opacity: 0;
}
.kt-demo-panel
  .kt-demo-panel__body
  .kt-demo-panel__item
  .kt-demo-panel__item-preview
  .kt-demo-panel__item-preview-overlay
  .btn {
  margin-right: 5px;
}
.kt-demo-panel
  .kt-demo-panel__body
  .kt-demo-panel__item
  .kt-demo-panel__item-preview
  .kt-demo-panel__item-preview-overlay
  .btn:last-child {
  margin-right: 0;
}
.kt-demo-panel
  .kt-demo-panel__body
  .kt-demo-panel__item
  .kt-demo-panel__item-preview
  .kt-demo-panel__item-preview-overlay
  .btn.btn-light {
  background-color: #fff;
  color: #a7abc3;
}
.kt-demo-panel
  .kt-demo-panel__body
  .kt-demo-panel__item
  .kt-demo-panel__item-preview
  .kt-demo-panel__item-preview-overlay
  .btn.btn-light:hover {
  background-color: #fff;
  color: #6c7293;
}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item.kt-demo-panel__item--active .kt-demo-panel__item-preview {
  transition: all 0.3s;
  border: 4px solid #5d78ff;
}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item:hover .kt-demo-panel__item-preview {
  transition: all 0.3s;
}
.kt-demo-panel
  .kt-demo-panel__body
  .kt-demo-panel__item:hover
  .kt-demo-panel__item-preview
  .kt-demo-panel__item-preview-overlay {
  transition: all 0.3s;
  opacity: 1;
}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__purchase {
  margin-bottom: 2rem;
  display: block;
}
.kt-demo-panel-close {
  display: none;
}
.kt-demo-panel {
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  backface-visibility: hidden;
  width: 350px !important;
  transition: all 0.3s ease;
  right: -370px;
}
.kt-demo-panel.kt-demo-panel--on {
  transition: all 0.3s ease;
  right: 0;
}
.kt-demo-panel--left .kt-demo-panel {
  left: -370px;
  right: auto;
}
.kt-demo-panel--left .kt-demo-panel.kt-demo-panel--on {
  transition: all 0.3s ease;
  left: 0;
  right: auto;
}
.kt-demo-panel-close {
  width: 25px;
  height: 25px;
  top: 1px;
  z-index: 1002;
  transition: all 0.3s ease;
  position: fixed;
  border: 0;
  box-shadow: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -25px;
}
.kt-demo-panel-close > i {
  line-height: 0;
  font-size: 1.4rem;
}
.kt-demo-panel-close:hover {
  text-decoration: none;
}
.kt-demo-panel--left .kt-demo-panel-close {
  right: auto;
  left: -25px;
}
.kt-demo-panel--on .kt-demo-panel-close {
  transition: all 0.3s ease;
  right: 324px;
}
.kt-demo-panel--on.kt-demo-panel--left .kt-demo-panel-close {
  right: auto;
  left: 324px;
}
.kt-demo-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.1);
  animation: kt-animate-fade-in 0.3s linear 1;
  background: rgba(0, 0, 0, 0.05);
}
.kt-quick-panel {
  background-color: #fff;
  box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.13);
  padding: 0;
}
.kt-quick-panel .kt-quick-panel__close {
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #f7f8fa;
  transition: all 0.3s;
  border-radius: 4px;
}
.kt-quick-panel .kt-quick-panel__close i {
  transition: all 0.3s;
  font-size: 0.8rem;
  color: #a7abc3;
}
.kt-quick-panel .kt-quick-panel__close:hover {
  transition: all 0.3s;
  background-color: #5d78ff;
}
.kt-quick-panel .kt-quick-panel__close:hover i {
  transition: all 0.3s;
  color: #fff;
}
.kt-quick-panel .kt-quick-panel__nav {
  display: flex;
  position: relative;
  padding: 1.5rem 0 0;
  align-items: center;
}
.kt-quick-panel .kt-quick-panel__nav .nav {
  width: 100%;
  padding: 0 1.5rem;
  margin: 0;
}
.kt-quick-panel .kt-quick-panel__nav .nav .nav-item {
  margin-right: 1.5rem;
}
.kt-quick-panel .kt-quick-panel__nav .nav .nav-item .nav-link {
  font-size: 1rem;
  color: #a7abc3;
}
.kt-quick-panel .kt-quick-panel__nav .nav .nav-item:last-child {
  margin-right: 0;
}
.kt-quick-panel .kt-quick-panel__content {
  padding: 1.5rem 0;
}
.kt-quick-panel .kt-quick-panel__content .ps > .ps__rail-y {
  right: 5px !important;
}
.kt-quick-panel .kt-quick-panel__content-padding-x {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.kt-quick-panel-close {
  display: none;
}
.kt-quick-panel {
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  backface-visibility: hidden;
  width: 425px !important;
  transition: all 0.3s ease;
  right: -445px;
}
.kt-quick-panel.kt-quick-panel--on {
  transition: all 0.3s ease;
  right: 0;
}
.kt-quick-panel--left .kt-quick-panel {
  left: -445px;
  right: auto;
}
.kt-quick-panel--left .kt-quick-panel.kt-quick-panel--on {
  transition: all 0.3s ease;
  left: 0;
  right: auto;
}
.kt-quick-panel-close {
  width: 25px;
  height: 25px;
  top: 1px;
  z-index: 1002;
  transition: all 0.3s ease;
  position: fixed;
  border: 0;
  box-shadow: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -25px;
}
.kt-quick-panel-close > i {
  line-height: 0;
  font-size: 1.4rem;
}
.kt-quick-panel-close:hover {
  text-decoration: none;
}
.kt-quick-panel--left .kt-quick-panel-close {
  right: auto;
  left: -25px;
}
.kt-quick-panel--on .kt-quick-panel-close {
  transition: all 0.3s ease;
  right: 399px;
}
.kt-quick-panel--on.kt-quick-panel--left .kt-quick-panel-close {
  right: auto;
  left: 399px;
}
.kt-quick-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  animation: kt-animate-fade-in 0.3s linear 1;
}
.kt-quick-panel {
  background: #fff;
  box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.75);
}
.kt-quick-panel-overlay {
  background: rgba(0, 0, 0, 0.1);
}
.kt-quick-panel-close {
  background-color: #e8e8f2;
}
.kt-quick-panel-close > i {
  color: #968ad4;
}
.kt-quick-panel-close:hover {
  background-color: #5d78ff;
}
.kt-quick-panel-close:hover > i {
  color: #fff;
}
.kt-sticky-toolbar {
  width: 46px;
  position: fixed;
  top: 30%;
  right: 0;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  z-index: 50;
  background: #fff;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 3px 0 0 3px;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item {
  margin: 0;
  padding: 5px;
  text-align: center;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item > a {
  background: #f4f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  transition: background 0.3s ease;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item > a > i {
  transition: color 0.3s ease;
  font-size: 1.3rem;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item > a:hover {
  transition: background 0.3s ease;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item > a:hover > i {
  transition: color 0.3s ease;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--brand > a > i {
  color: #5d78ff;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--brand:hover > a {
  background: #5d78ff;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--brand:hover > a > i,
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--light > a > i {
  color: #fff;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--light:hover > a {
  background: #fff;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--dark > a > i,
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--light:hover > a > i {
  color: #282a3c;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--dark:hover > a {
  background: #282a3c;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--dark:hover > a > i {
  color: #fff;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--primary > a > i {
  color: #5867dd;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--primary:hover > a {
  background: #5867dd;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--primary:hover > a > i {
  color: #fff;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--success > a > i {
  color: #0abb87;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--success:hover > a {
  background: #0abb87;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--success:hover > a > i {
  color: #fff;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--info > a > i {
  color: #5578eb;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--info:hover > a {
  background: #5578eb;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--info:hover > a > i {
  color: #fff;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--warning > a > i {
  color: #ffb822;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--warning:hover > a {
  background: #ffb822;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--warning:hover > a > i {
  color: #111;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--danger > a > i {
  color: #fd397a;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--danger:hover > a {
  background: #fd397a;
}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--danger:hover > a > i {
  color: #fff;
}
.kt-scroll {
  position: relative;
  overflow: hidden;
}
.kt-quick-search {
  padding: 1.5rem;
}
.kt-quick-search .kt-quick-search__form {
  padding: 0;
  background-color: #36344d;
}
.kt-quick-search .kt-quick-search__form .input-group,
.kt-quick-search .kt-quick-search__form .input-group-append,
.kt-quick-search .kt-quick-search__form .input-group-prepend {
  background-color: initial;
}
.kt-quick-search .kt-quick-search__form .input-group-append .input-group-text,
.kt-quick-search .kt-quick-search__form .input-group-prepend .input-group-text {
  background-color: initial;
  border: 0;
}
.kt-quick-search .kt-quick-search__form .form-control {
  background: none;
  outline: none !important;
  box-shadow: none;
  color: #575962;
  background-color: initial;
  border: 0;
  padding-left: 0;
  padding-right: 0;
}
.kt-quick-search .kt-quick-search__form .form-control::-moz-placeholder {
  color: #656872;
  opacity: 1;
}
.kt-quick-search .kt-quick-search__form .form-control:-ms-input-placeholder {
  color: #656872;
}
.kt-quick-search .kt-quick-search__form .form-control::-webkit-input-placeholder {
  color: #656872;
}
.kt-quick-search .kt-quick-search__form i {
  font-size: 1.3rem;
  color: #64688d;
}
.kt-quick-search .kt-quick-search__form .kt-quick-search__close {
  cursor: pointer;
  display: none;
  transition: all 0.3s;
}
.kt-quick-search .kt-quick-search__form .kt-quick-search__close:hover {
  transition: all 0.3s;
  color: #5d78ff;
}
.kt-quick-search .kt-quick-search__wrapper {
  display: none;
  margin: 1.5rem;
}
.kt-quick-search .kt-quick-search__wrapper.ps {
  margin-right: 0.5rem;
}
.kt-quick-search .kt-quick-search__result {
  padding: 0;
  margin: 0;
}
.kt-quick-search .kt-quick-search__result .kt-quick-search__category {
  display: flex;
  padding: 0;
  margin: 1.5rem 0 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  color: #5d78ff;
}
.kt-quick-search .kt-quick-search__result .kt-quick-search__category.kt-quick-search__category--first {
  margin-top: 0;
}
.kt-quick-search .kt-quick-search__result .kt-quick-search__message {
  display: flex;
  padding: 1rem 0;
  font-weight: 500;
  font-size: 1.1rem;
}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  margin: 0.5rem 0;
  outline: none;
}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item .kt-quick-search__item-icon {
  width: 2.5rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  color: #a7abc3;
}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item .kt-quick-search__item-icon i {
  font-size: 1.5rem;
}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item .kt-quick-search__item-icon img {
  border-radius: 50%;
  display: block;
  max-width: 28px !important;
}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item .kt-quick-search__item-text {
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: 1rem;
  color: #a7abc3;
  transition: all 0.3s;
}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item:hover {
  text-decoration: none;
}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item:hover .kt-quick-search__item-text {
  transition: all 0.3s;
  color: #5d78ff;
}
.kt-quick-search.kt-quick-search--has-result .kt-quick-search__wrapper {
  display: block;
}
.kt-quick-search.kt-quick-search--inline {
  padding: 0;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form {
  margin: 0 1.5rem;
  padding: 1rem 0;
  background-color: #fff;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .input-group:before {
  right: 0 !important;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .input-group-prepend .input-group-text {
  padding-left: 0;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .input-group-append .input-group-text {
  padding-right: 0;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .form-control {
  color: #6c7293;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .form-control::-moz-placeholder {
  color: #a7abc3;
  opacity: 1;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .form-control:-ms-input-placeholder {
  color: #a7abc3;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .form-control::-webkit-input-placeholder {
  color: #a7abc3;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form i {
  font-size: 1.3rem;
  color: #a7abc3;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__wrapper {
  margin: 1.5rem;
  display: none;
  padding-top: 0;
  padding-bottom: 0;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__wrapper.ps {
  margin-right: 0.5rem;
}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__result {
  padding: 0;
}
.kt-quick-search.kt-quick-search--inline.kt-quick-search--has-result .kt-quick-search__form {
  border-bottom: 1px solid #ebedf2;
}
.kt-quick-search.kt-quick-search--inline.kt-quick-search--has-result .kt-quick-search__wrapper {
  display: block;
}
.kt-quick-search.kt-quick-search--inline.kt-quick-search--has-result
  .kt-quick-search__wrapper
  .kt-quick-search__result {
  padding-top: 0;
  padding-bottom: 0;
}
.kt-subheader {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 10px 25px;
  background-color: #fff;
}
.kt-subheader .btn {
  display: inline-flex;
  margin-left: 0.5rem;
  height: 32px !important;
  padding-top: 0;
  padding-bottom: 0;
}
.kt-subheader .btn i {
  font-size: 1rem !important;
}
.kt-subheader .btn.btn-icon {
  width: 32px !important;
}
.kt-subheader .kt-subheader__main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
}
.kt-subheader .kt-subheader__main .btn {
  margin-left: 0;
  margin-right: 0.5rem;
}
.kt-subheader .kt-subheader__main .kt-subheader__title {
  margin: 0;
  padding: 0 1rem 0 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #434349;
}
.kt-subheader .kt-subheader__main .kt-subheader__title small {
  padding-left: 0.25rem;
  font-size: 0.9rem;
  color: #a7abc3;
}
.kt-subheader .kt-subheader__main .kt-subheader__desc {
  padding: 0 0.5rem 0 0;
  font-size: 1rem;
  font-weight: 500;
  color: #959cb6;
}
.kt-subheader .kt-subheader__main .kt-subheader__separator {
  display: block;
  width: 12px;
  height: 4px;
  border-radius: 2px;
  background: #e7e8ef;
  margin: 0 1rem 0 0;
}
.kt-subheader .kt-subheader__main .kt-subheader__separator.kt-subheader__separator--v {
  width: 1px;
  height: 22px;
}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs {
  display: flex;
  align-items: center;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home {
  padding: 0 0.65rem 0 0;
  transition: all 0.3s;
}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home > i {
  font-size: 1rem;
  color: #c5cbe7;
}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home:hover > i {
  transition: all 0.3s;
  color: #5d78ff;
}
.kt-subheader
  .kt-subheader__main
  .kt-subheader__breadcrumbs
  .kt-subheader__breadcrumbs-home.kt-subheader__breadcrumbs-home--active
  > i {
  color: #5d78ff;
}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link {
  padding: 0 0.35rem 0 0;
  font-size: 1rem;
  font-weight: 500;
  color: #959cb6;
  transition: all 0.3s;
}
.kt-subheader
  .kt-subheader__main
  .kt-subheader__breadcrumbs
  .kt-subheader__breadcrumbs-link.kt-subheader__breadcrumbs-link--active,
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link:hover {
  color: #5d78ff;
}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link:hover {
  transition: all 0.3s;
}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-separator {
  display: flex;
  justify-content: content;
  align-items: center;
  padding: 0 0.35rem 0 0;
}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-separator:after {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  content: ' ';
  background: #e7e8ef;
}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs i {
  position: relative;
  top: 2px;
  margin-right: 5px;
  color: #cfd0d2;
}
.kt-subheader .kt-subheader__toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
}
.kt-subheader .kt-subheader__toolbar .btn {
  margin-left: 0.25rem;
}
.kt-subheader .kt-subheader__btn-primary {
  background: #f2f3f7;
  color: #f2f3f7;
  font-weight: 500;
}
.kt-subheader .kt-subheader__btn-primary i {
  color: #959cb6;
}
.kt-subheader .kt-subheader__btn-primary.active,
.kt-subheader .kt-subheader__btn-primary:active,
.kt-subheader .kt-subheader__btn-primary:focus,
.kt-subheader .kt-subheader__btn-primary:hover {
  background: #5d78ff;
}
.kt-subheader .kt-subheader__btn-primary.active i,
.kt-subheader .kt-subheader__btn-primary:active i,
.kt-subheader .kt-subheader__btn-primary:focus i,
.kt-subheader .kt-subheader__btn-primary:hover i {
  color: #fff;
}
.kt-subheader .kt-subheader__btn-secondary {
  color: #959cb6;
  font-weight: 500;
}
.kt-subheader .kt-subheader__btn-secondary i {
  color: #aab1df;
}
.kt-subheader .kt-subheader__btn-secondary.active,
.kt-subheader .kt-subheader__btn-secondary:active,
.kt-subheader .kt-subheader__btn-secondary:focus,
.kt-subheader .kt-subheader__btn-secondary:hover {
  background: #f2f3f7;
  color: #5d78ff;
}
.kt-subheader .kt-subheader__btn-daterange {
  background: #f2f3f7;
}
.kt-subheader .kt-subheader__btn-daterange .kt-subheader__btn-daterange-title {
  display: inline-block;
  color: #959cb6;
  font-weight: 500;
}
.kt-subheader .kt-subheader__btn-daterange .kt-subheader__btn-daterange-date {
  display: inline-block;
  color: #5d78ff;
  margin-right: 0.75rem;
  font-weight: 500;
}
.kt-subheader .kt-subheader__btn-daterange i {
  color: #5d78ff;
}
.kt-subheader .kt-subheader__btn-daterange.active,
.kt-subheader .kt-subheader__btn-daterange:active,
.kt-subheader .kt-subheader__btn-daterange:focus,
.kt-subheader .kt-subheader__btn-daterange:hover {
  background: #f2f3f7;
}
.kt-subheader .kt-subheader__btn-daterange.active i,
.kt-subheader .kt-subheader__btn-daterange:active i,
.kt-subheader .kt-subheader__btn-daterange:focus i,
.kt-subheader .kt-subheader__btn-daterange:hover i {
  color: #5d78ff;
}
.kt-subheader .kt-subheader__search {
  width: 175px;
  display: inline-block;
}
.kt-subheader .kt-subheader__search .form-control {
  font-size: 0.9rem;
  height: 33px;
  background: #f2f3f7;
  border-color: #f2f3f7 !important;
}
.kt-subheader .kt-subheader__search .kt-input-icon__icon i {
  font-size: 1.2rem;
  color: #5d78ff;
}
.kt-subheader--transparent .kt-subheader {
  background-color: initial;
  padding: 20px 25px;
}
.kt-subheader--transparent .kt-subheader .kt-subheader__main .kt-subheader__separator {
  background: #dedfe9;
}
.kt-subheader--transparent
  .kt-subheader
  .kt-subheader__main
  .kt-subheader__breadcrumbs
  .kt-subheader__breadcrumbs-home
  > i {
  color: #bac1e3;
}
.kt-subheader--transparent
  .kt-subheader
  .kt-subheader__main
  .kt-subheader__breadcrumbs
  .kt-subheader__breadcrumbs-link {
  color: #959cb6;
}
.kt-subheader--transparent
  .kt-subheader
  .kt-subheader__main
  .kt-subheader__breadcrumbs
  .kt-subheader__breadcrumbs-separator:after {
  background: #dedfe9;
}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary {
  background: #eaecf2;
  color: #f2f3f7;
}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary i {
  color: #959cb6;
}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary.active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:focus,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover {
  background: #5d78ff;
}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary.active i,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:active i,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:focus i,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover i {
  color: #fff;
}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary {
  color: #8c94b0;
}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary i {
  color: #9fa7db;
}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange.active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange:active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange:focus,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange:hover,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary.active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary:active,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary:focus,
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary:hover {
  background: #eaecf2;
}
.kt-subheader--transparent .kt-subheader .kt-subheader__search .form-control {
  background: #eaecf2;
  border-color: #eaecf2 !important;
}
.kt-badge {
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  font-size: 0.8rem;
}
.kt-badge.kt-badge--bold {
  font-weight: 500;
}
.kt-badge.kt-badge--bolder {
  font-weight: 600;
}
.kt-badge.kt-badge--boldest {
  font-weight: 700;
}
.kt-badge.kt-badge--md {
  height: 24px;
  width: 24px;
  font-size: 1rem;
}
.kt-badge.kt-badge--lg {
  height: 40px;
  width: 40px;
  font-size: 1.2rem;
}
.kt-badge.kt-badge--xl {
  height: 50px;
  width: 50px;
  font-size: 1.3rem;
  font-weight: 700;
}
.kt-badge.kt-badge--brand {
  color: #fff;
  background: #5d78ff;
}
.kt-badge.kt-badge--outline.kt-badge--brand {
  background: transparent;
  color: #5d78ff;
  border: 1px solid #5d78ff;
}
.kt-badge.kt-badge--outline.kt-badge--brand.kt-badge--outline-2x {
  border: 2px solid #5d78ff;
}
.kt-badge.kt-badge--light {
  color: #282a3c;
  background: #fff;
}
.kt-badge.kt-badge--outline.kt-badge--light {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.kt-badge.kt-badge--outline.kt-badge--light.kt-badge--outline-2x {
  border: 2px solid #fff;
}
.kt-badge.kt-badge--dark {
  color: #fff;
  background: #282a3c;
}
.kt-badge.kt-badge--outline.kt-badge--dark {
  background: transparent;
  color: #282a3c;
  border: 1px solid #282a3c;
}
.kt-badge.kt-badge--outline.kt-badge--dark.kt-badge--outline-2x {
  border: 2px solid #282a3c;
}
.kt-badge.kt-badge--primary {
  color: #fff;
  background: #5867dd;
}
.kt-badge.kt-badge--outline.kt-badge--primary {
  background: transparent;
  color: #5867dd;
  border: 1px solid #5867dd;
}
.kt-badge.kt-badge--outline.kt-badge--primary.kt-badge--outline-2x {
  border: 2px solid #5867dd;
}
.kt-badge.kt-badge--success {
  color: #fff;
  background: #0abb87;
}
.kt-badge.kt-badge--outline.kt-badge--success {
  background: transparent;
  color: #0abb87;
  border: 1px solid #0abb87;
}
.kt-badge.kt-badge--outline.kt-badge--success.kt-badge--outline-2x {
  border: 2px solid #0abb87;
}
.kt-badge.kt-badge--info {
  color: #fff;
  background: #5578eb;
}
.kt-badge.kt-badge--outline.kt-badge--info {
  background: transparent;
  color: #5578eb;
  border: 1px solid #5578eb;
}
.kt-badge.kt-badge--outline.kt-badge--info.kt-badge--outline-2x {
  border: 2px solid #5578eb;
}
.kt-badge.kt-badge--warning {
  color: #111;
  background: #ffb822;
}
.kt-badge.kt-badge--outline.kt-badge--warning {
  background: transparent;
  color: #ffb822;
  border: 1px solid #ffb822;
}
.kt-badge.kt-badge--outline.kt-badge--warning.kt-badge--outline-2x {
  border: 2px solid #ffb822;
}
.kt-badge.kt-badge--danger {
  color: #fff;
  background: #fd397a;
}
.kt-badge.kt-badge--outline.kt-badge--danger {
  background: transparent;
  color: #fd397a;
  border: 1px solid #fd397a;
}
.kt-badge.kt-badge--outline.kt-badge--danger.kt-badge--outline-2x {
  border: 2px solid #fd397a;
}
.kt-badge.kt-badge--unified-brand {
  color: #5d78ff;
  background: rgba(93, 120, 255, 0.1);
}
.kt-badge.kt-badge--unified-light {
  color: #fff;
  background: hsla(0, 0%, 100%, 0.1);
}
.kt-badge.kt-badge--unified-dark {
  color: #282a3c;
  background: rgba(40, 42, 60, 0.1);
}
.kt-badge.kt-badge--unified-primary {
  color: #5867dd;
  background: rgba(88, 103, 221, 0.1);
}
.kt-badge.kt-badge--unified-success {
  color: #0abb87;
  background: rgba(10, 187, 135, 0.1);
}
.kt-badge.kt-badge--unified-info {
  color: #5578eb;
  background: rgba(85, 120, 235, 0.1);
}
.kt-badge.kt-badge--unified-warning {
  color: #ffb822;
  background: rgba(255, 184, 34, 0.1);
}
.kt-badge.kt-badge--unified-danger {
  color: #fd397a;
  background: rgba(253, 57, 122, 0.1);
}
.kt-badge.kt-badge--inline {
  height: 0;
  width: auto;
  padding: 0.75rem;
  border-radius: 2px;
  white-space: nowrap;
}
.kt-badge.kt-badge--inline.kt-badge--md {
  padding: 0.8rem 0.6rem;
}
.kt-badge.kt-badge--inline.kt-badge--lg {
  padding: 0.9rem 0.7rem;
}
.kt-badge.kt-badge--inline.kt-badge--xl {
  padding: 1rem 0.8rem;
}
.kt-badge.kt-badge--pill {
  border-radius: 2rem;
}
.kt-badge.kt-badge--rounded {
  border-radius: 4px;
}
.kt-badge.kt-badge--dot {
  display: inline-block;
  line-height: 4px;
  min-height: 4px;
  min-width: 4px;
  height: 4px;
  width: 4px;
  font-size: 0;
  vertical-align: middle;
  text-align: center;
}
.kt-badge.kt-badge--dot.kt-badge--md {
  line-height: 6px;
  min-height: 6px;
  min-width: 6px;
  height: 6px;
  width: 6px;
}
.kt-badge.kt-badge--dot.kt-badge--lg {
  line-height: 8px;
  min-height: 8px;
  min-width: 8px;
  height: 8px;
  width: 8px;
}
.kt-badge.kt-badge--dot.kt-badge--xl {
  line-height: 10px;
  min-height: 10px;
  min-width: 10px;
  height: 10px;
  width: 10px;
}
.kt-badge.kt-badge--square {
  border-radius: 0;
}
.kt-badge.kt-badge--elevate {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.kt-divider {
  display: flex;
  justify-content: center;
  align-items: center;
}
.kt-divider > span:first-child,
.kt-divider > span:last-child {
  width: 100%;
  height: 1px;
  flex: 1;
  background: #ebecf1;
  display: inline-block;
}
.kt-divider > span:not(:first-child):not(:last-child) {
  padding: 0 2rem;
}
.kt-link {
  text-decoration: none;
  position: relative;
  display: inline-block;
  color: #5d78ff;
}
.kt-link:after {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  top: 1rem;
  left: 0;
  width: 0;
  transition: width 0.3s ease;
}
.kt-link:hover {
  text-decoration: none !important;
}
.kt-link:hover:after {
  width: 100%;
}
.kt-link:hover {
  color: #3e5fff;
}
.kt-link:hover:after {
  border-bottom: 1px solid #3e5fff;
  opacity: 0.3;
}
.kt-link.kt-link--brand {
  color: #5d78ff;
}
.kt-link.kt-link--brand:hover {
  color: #2a4eff;
}
.kt-link.kt-link--brand:hover:after {
  border-bottom: 1px solid #2a4eff;
  opacity: 0.3;
}
.kt-link.kt-link--light {
  color: #fff;
}
.kt-link.kt-link--light:hover {
  color: #e6e6e6;
}
.kt-link.kt-link--light:hover:after {
  border-bottom: 1px solid #e6e6e6;
  opacity: 0.3;
}
.kt-link.kt-link--dark {
  color: #282a3c;
}
.kt-link.kt-link--dark:hover {
  color: #14151d;
}
.kt-link.kt-link--dark:hover:after {
  border-bottom: 1px solid #14151d;
  opacity: 0.3;
}
.kt-link.kt-link--primary {
  color: #5867dd;
}
.kt-link.kt-link--primary:hover {
  color: #2e40d4;
}
.kt-link.kt-link--primary:hover:after {
  border-bottom: 1px solid #2e40d4;
  opacity: 0.3;
}
.kt-link.kt-link--success {
  color: #0abb87;
}
.kt-link.kt-link--success:hover {
  color: #078b64;
}
.kt-link.kt-link--success:hover:after {
  border-bottom: 1px solid #078b64;
  opacity: 0.3;
}
.kt-link.kt-link--info {
  color: #5578eb;
}
.kt-link.kt-link--info:hover {
  color: #2754e6;
}
.kt-link.kt-link--info:hover:after {
  border-bottom: 1px solid #2754e6;
  opacity: 0.3;
}
.kt-link.kt-link--warning {
  color: #ffb822;
}
.kt-link.kt-link--warning:hover {
  color: #eea200;
}
.kt-link.kt-link--warning:hover:after {
  border-bottom: 1px solid #eea200;
  opacity: 0.3;
}
.kt-link.kt-link--danger {
  color: #fd397a;
}
.kt-link.kt-link--danger:hover {
  color: #fc0758;
}
.kt-link.kt-link--danger:hover:after {
  border-bottom: 1px solid #fc0758;
  opacity: 0.3;
}
.kt-section {
  padding: 0;
  margin: 0 0 2rem;
}
.kt-section.kt-section--space-sm {
  margin-bottom: 1rem;
}
.kt-section.kt-section--space-md {
  margin-bottom: 2rem;
}
.kt-section.kt-section--space-lg {
  margin-bottom: 2.5rem;
}
.kt-section.kt-section--space-xl {
  margin-bottom: 3rem;
}
.kt-section .kt-section__heading,
.kt-section .kt-section__title {
  display: block;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0;
  margin: 0 0 1.75rem;
  color: #464457;
}
.kt-section .kt-section__heading.kt-section__title-sm,
.kt-section .kt-section__title.kt-section__title-sm {
  font-size: 1.1rem;
}
.kt-section .kt-section__heading.kt-section__title-md,
.kt-section .kt-section__title.kt-section__title-md {
  font-size: 1.4rem;
}
.kt-section .kt-section__heading.kt-section__title-lg,
.kt-section .kt-section__title.kt-section__title-lg {
  font-size: 1.5rem;
}
.kt-section .kt-section__desc {
  font-weight: 400;
}
.kt-section .kt-section__desc,
.kt-section .kt-section__info {
  display: block;
  font-size: 1rem;
  padding: 0;
  margin: 0 0 1rem;
  color: #6c7293;
}
.kt-section .kt-section__content {
  display: block;
  padding: 0;
  margin: 0;
}
.kt-section .kt-section__content.kt-section__content--border {
  padding: 1.5rem;
  border: 3px solid #ebedf2;
}
.kt-section .kt-section__content.kt-section__content--solid {
  padding: 1.5rem;
  border-left: 4px solid #e2e5ec;
  background-color: #f7f8fa;
  border-radius-top-left: 4px;
  border-radius-top-right: 4px;
}
.kt-section .kt-section__content.kt-section__content--x-fit {
  padding-left: 0;
  padding-right: 0;
}
.kt-section .kt-section__content.kt-section__content--fit {
  padding: 0;
}
.kt-section.kt-section--last {
  margin-bottom: 0;
}
.kt-heading {
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0;
  margin: 1.75rem 0;
  color: #464457;
}
.kt-heading.kt-heading--center {
  text-align: center;
}
.kt-heading.kt-heading--right {
  text-align: right;
}
.kt-heading.kt-heading--space-sm {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.kt-heading.kt-heading--space-md {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.kt-heading.kt-heading--space-lg {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.kt-heading.kt-heading--space-xl {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.kt-heading.kt-heading--sm {
  font-size: 1.2rem;
}
.kt-heading.kt-heading--md {
  font-size: 1.4rem;
}
.kt-heading.kt-heading--lg {
  font-size: 1.6rem;
}
.kt-heading.kt-heading--xl {
  font-size: 2rem;
}
.kt-heading.kt-heading--medium {
  font-weight: 600;
}
.kt-heading.kt-heading--bold {
  font-weight: 700;
}
.kt-heading.kt-heading--bolder {
  font-weight: 900;
}
.kt-heading.kt-heading--thin {
  font-weight: 300;
}
.kt-heading.kt-heading--no-top-margin {
  margin-top: 0;
}
.kt-heading.kt-heading--no-bottok-margin {
  margin-bottom: 0;
}
.kt-separator {
  height: 0;
  margin: 20px 0;
  border-bottom: 1px solid #ebedf2;
}
.kt-separator.kt-separator--dashed {
  border-bottom: 1px dashed #ebedf2;
}
.kt-separator.kt-separator--border-solid {
  border-bottom: 1px solid #ebedf2;
}
.kt-separator.kt-separator--border-dashed {
  border-bottom: 1px dashed #ebedf2;
}
.kt-separator.kt-separator--border-2x {
  border-bottom-width: 2px;
}
.kt-separator.kt-separator--space-xs {
  margin: 0.5rem 0;
}
.kt-separator.kt-separator--space-sm {
  margin: 1rem 0;
}
.kt-separator.kt-separator--space-md {
  margin: 2rem 0;
}
.kt-separator.kt-separator--space-lg {
  margin: 2.5rem 0;
}
.kt-separator.kt-separator--space-xl {
  margin: 3rem 0;
}
.kt-separator.kt-separator--md {
  margin: 30px 0;
}
.kt-separator.kt-separator--lg {
  margin: 40px 0;
}
.kt-separator.kt-separator--xl {
  margin: 60px 0;
}
.kt-separator.kt-separator--sm {
  margin: 20px 0;
}
.kt-separator.kt-separator--fit {
  margin-left: -25px;
  margin-right: -25px;
}
.kt-separator.kt-separator--brand {
  border-bottom: 1px solid #5d78ff;
}
.kt-separator.kt-separator--brand.kt-separator--dashed {
  border-bottom: 1px dashed #5d78ff;
}
.kt-separator.kt-separator--light {
  border-bottom: 1px solid #fff;
}
.kt-separator.kt-separator--light.kt-separator--dashed {
  border-bottom: 1px dashed #fff;
}
.kt-separator.kt-separator--dark {
  border-bottom: 1px solid #282a3c;
}
.kt-separator.kt-separator--dark.kt-separator--dashed {
  border-bottom: 1px dashed #282a3c;
}
.kt-separator.kt-separator--primary {
  border-bottom: 1px solid #5867dd;
}
.kt-separator.kt-separator--primary.kt-separator--dashed {
  border-bottom: 1px dashed #5867dd;
}
.kt-separator.kt-separator--success {
  border-bottom: 1px solid #0abb87;
}
.kt-separator.kt-separator--success.kt-separator--dashed {
  border-bottom: 1px dashed #0abb87;
}
.kt-separator.kt-separator--info {
  border-bottom: 1px solid #5578eb;
}
.kt-separator.kt-separator--info.kt-separator--dashed {
  border-bottom: 1px dashed #5578eb;
}
.kt-separator.kt-separator--warning {
  border-bottom: 1px solid #ffb822;
}
.kt-separator.kt-separator--warning.kt-separator--dashed {
  border-bottom: 1px dashed #ffb822;
}
.kt-separator.kt-separator--danger {
  border-bottom: 1px solid #fd397a;
}
.kt-separator.kt-separator--danger.kt-separator--dashed {
  border-bottom: 1px dashed #fd397a;
}
.kt-svg-icon {
  height: 23px;
  width: 23px;
}
.kt-svg-icon.kt-svg-icon--brand g [fill],
.kt-svg-icon g [fill] {
  fill: #5d78ff;
}
.kt-svg-icon.kt-svg-icon--light g [fill] {
  fill: #fff;
}
.kt-svg-icon.kt-svg-icon--dark g [fill] {
  fill: #282a3c;
}
.kt-svg-icon.kt-svg-icon--primary g [fill] {
  fill: #5867dd;
}
.kt-svg-icon.kt-svg-icon--success g [fill] {
  fill: #0abb87;
}
.kt-svg-icon.kt-svg-icon--info g [fill] {
  fill: #5578eb;
}
.kt-svg-icon.kt-svg-icon--warning g [fill] {
  fill: #ffb822;
}
.kt-svg-icon.kt-svg-icon--danger g [fill] {
  fill: #fd397a;
}
.kt-svg-icon.kt-svg-icon--sm {
  height: 20px;
  width: 20px;
}
.kt-svg-icon.kt-svg-icon--md {
  height: 30px;
  width: 30px;
}
.kt-svg-icon.kt-svg-icon--lg {
  height: 40px;
  width: 40px;
}
.kt-svg-icon.kt-svg-icon--xl {
  height: 50px;
  width: 50px;
}
.kt-demo-icon {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
}
.kt-demo-icon .kt-demo-icon__preview {
  display: flex;
  padding-right: 20px;
  flex: 0 0 40px;
}
.kt-demo-icon .kt-demo-icon__preview i {
  line-height: 0;
  vertical-align: middle;
  font-size: 2.5rem !important;
}
.kt-demo-icon .kt-demo-icon__class {
  color: #a7abc3;
  display: flex;
  flex-grow: 1;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
}
.kt-demo-icon:hover {
  background-color: #f7f8fa;
}
.kt-demo-icon.kt-demo-icon--overlay {
  position: relative;
}
.kt-demo-icon.kt-demo-icon--overlay svg {
  height: 40px;
  width: 40px;
}
.kt-demo-icon.kt-demo-icon--overlay .kt-demo-icon__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  transition: all 0.3 ease;
  opacity: 0;
}
.kt-demo-icon.kt-demo-icon--overlay .kt-demo-icon__overlay .btn {
  margin-right: 10px;
}
.kt-demo-icon.kt-demo-icon--overlay .kt-demo-icon__overlay .btn:last-child {
  margin-right: 0;
}
.kt-demo-icon.kt-demo-icon--overlay:hover {
  background-color: initial;
}
.kt-demo-icon.kt-demo-icon--overlay:hover .kt-demo-icon__overlay {
  transition: all 0.3 ease;
  opacity: 1;
}
.kt-mycart .kt-mycart__head {
  display: flex;
  justify-content: space-between;
  padding: 2.8rem 2rem;
  background-position: 50%;
  background-size: cover;
}
.kt-mycart .kt-mycart__head .kt-mycart__info {
  display: flex;
  align-items: center;
}
.kt-mycart .kt-mycart__head .kt-mycart__info .kt-mycart__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background: rgba(56, 80, 168, 0.65);
  border-radius: 4px;
}
.kt-mycart .kt-mycart__head .kt-mycart__info .kt-mycart__icon i {
  font-size: 1.4rem;
  font-weight: 500;
  position: absolute;
}
.kt-mycart .kt-mycart__head .kt-mycart__info .kt-mycart__title {
  padding-left: 1rem;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0;
}
.kt-mycart .kt-mycart__head .kt-mycart__button {
  display: flex;
  align-items: center;
}
.kt-mycart .kt-mycart__head .kt-mycart__button .btn {
  padding: 0.3rem 0.5rem;
}
.kt-mycart .kt-mycart__body {
  margin: 0.5rem 0;
}
.kt-mycart .kt-mycart__body .kt-mycart__item {
  border-bottom: 1px solid #ebedf2;
}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__title {
  color: #5e5b70;
  font-size: 1.1rem;
  font-weight: 500;
}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__desc {
  padding: 0.5rem 0;
  color: #a7abc3;
  font-weight: 400;
}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__action {
  display: flex;
  align-items: center;
}
.kt-mycart
  .kt-mycart__body
  .kt-mycart__item
  .kt-mycart__container
  .kt-mycart__info
  .kt-mycart__action
  .kt-mycart__price {
  margin-right: 0.5rem;
  color: #6c7293;
  font-size: 1.2rem;
  font-weight: 500;
}
.kt-mycart
  .kt-mycart__body
  .kt-mycart__item
  .kt-mycart__container
  .kt-mycart__info
  .kt-mycart__action
  .kt-mycart__text {
  color: #a7abc3;
  font-weight: 400;
}
.kt-mycart
  .kt-mycart__body
  .kt-mycart__item
  .kt-mycart__container
  .kt-mycart__info
  .kt-mycart__action
  .kt-mycart__quantity {
  margin-left: 0.5rem;
  color: #6c7293;
  font-size: 1.2rem;
  font-weight: 500;
}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__action .btn {
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  width: 19px !important;
  height: 19px !important;
  padding-top: 0.1rem;
}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__action .btn:hover {
  color: #fff;
}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info span {
  display: block;
}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__pic {
  width: 5rem;
}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__pic img {
  width: 5rem;
  height: 5rem;
  background-position: 50%;
  background-size: cover;
  border-radius: 4px;
}
.kt-mycart .kt-mycart__body .ps__rail-y .ps__thumb-y {
  right: 5px !important;
}
.kt-mycart .kt-mycart__footer {
  padding: 0.4rem 2rem 1.5rem;
}
.kt-mycart .kt-mycart__footer .kt-mycart__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__subtitel span {
  display: block;
  color: #5e5b70;
  font-size: 1rem;
  font-weight: 500;
}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__subtitel span:not(:first-child):not(:last-child) {
  padding: 0.4rem 0;
}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__subtitel span:last-child {
  font-size: 1.1rem;
  font-weight: 600;
}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__prices span {
  display: block;
  text-align: right;
  color: #a7abc3;
  font-size: 1.2rem;
  font-weight: 500;
}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__prices span:not(:first-child):not(:last-child) {
  padding: 0.3rem 0;
}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__prices span:last-child {
  font-weight: 700;
  font-size: 1.3rem;
}
.kt-mycart .kt-mycart__footer .kt-mycart__button {
  margin: 1.5rem 0 0.8rem;
}
.kt-nav {
  padding: 0.5rem 0;
  margin: 0;
  list-style: none;
  border: 0;
}
.kt-nav,
.kt-nav .kt-nav__item {
  display: block;
}
.kt-nav .kt-nav__item > .kt-nav__link {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 0.55rem 1.75rem;
  cursor: pointer;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-text {
  font-weight: 500;
  flex-grow: 1;
  font-size: 1rem;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-icon {
  flex: 0 0 30px;
  font-size: 1.25rem;
  line-height: 0;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet {
  text-align: center;
  flex: 0 0 10px;
  margin-right: 10px;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet > span {
  vertical-align: middle;
  display: inline-block;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--dot > span {
  width: 4px;
  height: 4px;
  border-radius: 100%;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--line > span {
  width: 8px;
  height: 2px;
  border-radius: 2px;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-badge {
  padding: 0 0 0 5px;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-arrow {
  text-align: right;
  flex: 0 0 20px;
  font-size: 0.8rem;
  font-family: LineAwesome;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-arrow:before {
  content: '';
  display: inline-block;
  transition: all 0.3s ease;
}
.kt-nav .kt-nav__item > .kt-nav__link.collapsed .kt-nav__link-arrow {
  font-family: LineAwesome;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}
.kt-nav .kt-nav__item > .kt-nav__link.collapsed .kt-nav__link-arrow:before {
  content: '';
  transition: all 0.3s ease;
}
.kt-nav .kt-nav__item > .kt-nav__sub {
  padding: 0;
  margin: 0;
}
.kt-nav .kt-nav__item > .kt-nav__sub .kt-nav__item > .kt-nav__link {
  padding: 0.5rem 1.5rem 0.5rem 2.5rem;
}
.kt-nav .kt-nav__item.kt-nav__item--last {
  padding-bottom: 0;
}
.kt-nav .kt-nav__item.kt-nav__item--custom {
  padding: 0.5rem 1.75rem;
}
.kt-nav .kt-nav__section {
  margin-top: 1rem;
  padding: 0 1.75rem;
}
.kt-nav .kt-nav__section.kt-nav__section--first {
  margin-top: 0;
}
.kt-nav .kt-nav__section .kt-nav__section-text {
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.kt-nav .kt-nav__separator {
  margin: 0.75rem 0 1.35rem;
  height: 0;
  display: block;
}
.kt-nav.kt-nav--fit {
  padding: 0;
}
.kt-nav.kt-nav--fit .kt-nav__item > .kt-nav__link {
  padding-left: 0;
  padding-right: 0;
}
.kt-nav.kt-nav--lg-space .kt-nav__item {
  margin: 0.5rem 0;
}
.kt-nav.kt-nav--lg-space .kt-nav__item .kt-nav__link {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.kt-nav.kt-nav--lg-space .kt-nav__item:first-child {
  margin-top: 0;
}
.kt-nav.kt-nav--lg-space .kt-nav__item:last-child {
  margin-bottom: 0;
}
.kt-nav.kt-nav--md-space .kt-nav__item {
  margin: 0.25rem 0;
}
.kt-nav.kt-nav--md-space .kt-nav__item .kt-nav__link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.kt-nav.kt-nav--md-space .kt-nav__item:first-child {
  margin-top: 0;
}
.kt-nav.kt-nav--md-space .kt-nav__item:last-child {
  margin-bottom: 0;
}
.kt-nav.kt-nav--bold .kt-nav__item .kt-nav__link .kt-nav__link-text {
  font-weight: 500;
}
.kt-nav.kt-nav--thin .kt-nav__item .kt-nav__link .kt-nav__link-text {
  font-weight: 300;
}
.kt-nav.kt-nav--lg-font .kt-nav__item .kt-nav__link .kt-nav__link-text {
  font-size: 1.15rem;
}
.kt-nav.kt-nav--xl-font .kt-nav__item .kt-nav__link .kt-nav__link-text {
  font-size: 1.25rem;
}
.kt-nav.kt-nav--v2 .kt-nav__item {
  border-radius: 2rem;
  overflow: hidden;
}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active .kt-nav__link {
  background-color: #5d78ff;
}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active .kt-nav__link .kt-nav__link-text {
  color: #fff;
}
.kt-nav.kt-nav--v3 .kt-nav__item {
  margin: 0;
}
.kt-nav.kt-nav--v3 .kt-nav__item .kt-nav__link {
  transition: all 0.3s;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-left: 2px solid transparent;
}
.kt-nav.kt-nav--v3 .kt-nav__item .kt-nav__link.active,
.kt-nav.kt-nav--v3 .kt-nav__item .kt-nav__link.kt-nav__link--active {
  transition: all 0.3s;
  background-color: #f7f8fa;
  border-left: 2px solid #5d78ff;
}
.kt-nav.kt-nav--v3 .kt-nav__item .kt-nav__link.active .kt-nav__link-text,
.kt-nav.kt-nav--v3 .kt-nav__item .kt-nav__link.kt-nav__link--active .kt-nav__link-text {
  color: #5d78ff;
}
.kt-nav.kt-nav--v3 .kt-nav__item .kt-nav__link:hover {
  transition: all 0.3s;
  background-color: #f7f8fa;
}
.kt-nav.kt-nav--v3 .kt-nav__item .kt-nav__link:hover .kt-nav__link-text {
  color: #5d78ff;
}
.kt-nav .kt-nav__item > .kt-nav__link {
  transition: all 0.3s;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-text {
  color: #6c7293;
  font-weight: 400;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-arrow {
  color: #a7abc3;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-icon {
  color: #adb1c7;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--dot > span,
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--line > span {
  background-color: #adb1c7;
}
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link,
.kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link {
  background-color: #f7f8fa;
  transition: all 0.3s;
}
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-arrow,
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-icon,
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-text,
.kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link .kt-nav__link-arrow,
.kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link .kt-nav__link-icon,
.kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link .kt-nav__link-text {
  color: #5d78ff;
}
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--dot > span,
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--line > span,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span {
  background-color: #5d78ff;
}
.kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub > .kt-nav__link.collapsed {
  background-color: #f7f8fa;
  transition: all 0.3s;
}
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub
  > .kt-nav__link.collapsed
  .kt-nav__link-arrow,
.kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub > .kt-nav__link.collapsed .kt-nav__link-icon,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub
  > .kt-nav__link.collapsed
  .kt-nav__link-text {
  color: #5d78ff;
}
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub
  > .kt-nav__link.collapsed
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub
  > .kt-nav__link.collapsed
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span {
  background-color: #5d78ff;
}
.kt-nav .kt-nav__item.kt-nav__item--disabled {
  opacity: 0.7;
}
.kt-nav .kt-nav__section .kt-nav__section-text {
  color: #5d78ff;
}
.kt-nav .kt-nav__section .kt-nav__section-icon {
  color: #adb1c7;
}
.kt-nav .kt-nav__separator {
  border-bottom: 1px solid #f7f8fa;
}
.kt-nav .kt-nav__separator.kt-nav__separator--dashed {
  border-bottom: 1px dashed #f7f8fa;
}
.kt-nav.kt-nav--v2 .kt-nav__item > .kt-nav__link {
  transition: none;
}
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link {
  background-color: #f4f4f9;
  transition: none;
}
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-arrow,
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-icon,
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-text {
  color: #5d78ff;
}
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span,
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span {
  background-color: #f4f4f9;
}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link {
  background-color: #5d78ff;
  transition: none;
}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-arrow,
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-icon,
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-text {
  color: #fff;
}
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span,
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span {
  background-color: #fff;
}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link {
  background-color: #5d78ff;
}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link .kt-nav__link-arrow,
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link .kt-nav__link-icon,
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link .kt-nav__link-text {
  color: #fff;
}
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active:hover
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span,
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active:hover
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span {
  background-color: #fff;
}
.kt-grid-nav {
  padding: 0;
  margin: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.kt-grid-nav > .kt-grid-nav__row {
  display: table-row;
}
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 3.3rem 0.75rem;
  transition: all 0.3s;
}
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item:hover {
  text-decoration: none;
  transition: all 0.3s;
}
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item .kt-grid-nav__icon {
  text-align: center;
  font-size: 2.5rem;
}
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item .kt-grid-nav__title {
  display: block;
  line-height: 1;
  text-align: center;
  margin: 10px 0 0;
  font-size: 1.2rem;
  font-weight: 500;
}
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item .kt-grid-nav__desc {
  display: block;
  line-height: 1;
  text-align: center;
  margin: 10px 0 0;
  font-size: 1rem;
  font-weight: 400;
}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item {
  border-right: 1px solid #ebedf2;
  border-bottom: 1px solid #ebedf2;
}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item:last-child {
  border-right: 0;
}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item .kt-grid-nav__icon,
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item .kt-grid-nav__title {
  color: #5d78ff;
}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item .kt-grid-nav__desc {
  color: #a7abc3;
}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item:hover {
  background: #f7f8fa;
}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item:hover .kt-grid-nav__icon {
  color: #6c7293;
}
.kt-grid-nav .kt-grid-nav__row:last-child .kt-grid-nav__item {
  border-bottom: 0 !important;
}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item {
  border-right: 1px solid hsla(0, 0%, 100%, 0.1);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item:last-child {
  border-right: 0;
}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item .kt-grid-nav__icon {
  color: #5d78ff;
}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item .kt-grid-nav__title {
  color: #fff;
}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item .kt-grid-nav__desc {
  color: hsla(0, 0%, 100%, 0.5);
}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item:hover {
  background: transparent;
}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item:hover .kt-grid-nav__icon {
  color: #5d78ff;
}
.kt-grid-nav-v2 {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.kt-grid-nav-v2 .kt-grid-nav-v2__item {
  display: flex;
  width: calc(50% - 3px);
  min-height: 180px;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: #f7f8fa;
  transition: all 0.3s;
}
.kt-grid-nav-v2 .kt-grid-nav-v2__item .kt-grid-nav-v2__item-icon {
  display: inline-block;
  text-align: center;
  margin-bottom: 0.5rem;
}
.kt-grid-nav-v2 .kt-grid-nav-v2__item .kt-grid-nav-v2__item-icon i {
  font-size: 2.25rem;
  color: #5d78ff;
}
.kt-grid-nav-v2 .kt-grid-nav-v2__item .kt-grid-nav-v2__item-title {
  display: inline-block;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #5d5b6f;
}
.kt-grid-nav-v2 .kt-grid-nav-v2__item:hover {
  transition: all 0.3s;
  text-transform: none;
  background: #5d78ff;
}
.kt-grid-nav-v2 .kt-grid-nav-v2__item:hover .kt-grid-nav-v2__item-icon i,
.kt-grid-nav-v2 .kt-grid-nav-v2__item:hover .kt-grid-nav-v2__item-title {
  color: #fff;
}
.kt-grid-nav-v2 .kt-grid-nav-v2__item:nth-of-type(odd) {
  margin: 3px 3px 3px 0;
}
.kt-grid-nav-v2 .kt-grid-nav-v2__item:nth-of-type(2n) {
  margin: 3px 0 3px 3px;
}
.kt-notification .kt-notification__item {
  display: flex;
  align-items: center;
  padding: 1.1rem 1.5rem;
  border-bottom: 1px solid #f7f8fa;
  transition: background-color 0.3s ease;
}
.kt-notification .kt-notification__item:last-child {
  border-bottom: 1px solid transparent;
}
.kt-notification .kt-notification__item .kt-notification__item-icon {
  display: flex;
  flex: 0 0 2.5rem;
  align-items: center;
}
.kt-notification .kt-notification__item .kt-notification__item-icon i {
  font-size: 1.4rem;
  color: #5d78ff;
}
.kt-notification .kt-notification__item .kt-notification__item-icon svg {
  height: 24px;
  width: 24px;
  margin-left: -4px;
}
.kt-notification .kt-notification__item .kt-notification__item-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.kt-notification .kt-notification__item .kt-notification__item-details .kt-notification__item-title {
  transition: color 0.3s ease;
  font-size: 1rem;
  font-weight: 400;
  color: #6c7293;
}
.kt-notification .kt-notification__item .kt-notification__item-details .kt-notification__item-time {
  font-size: 1rem;
  font-weight: 300;
  color: #a7abc3;
}
.kt-notification .kt-notification__item:hover {
  transition: background-color 0.3s ease;
  text-decoration: none;
  background-color: #f7f8fa;
}
.kt-notification .kt-notification__item:hover .kt-notification__item-details .kt-notification__item-title {
  transition: color 0.3s ease;
  color: #5d78ff;
}
.kt-notification .kt-notification__item.kt-notification__item--read {
  opacity: 0.5;
}
.kt-notification .kt-notification__item:after {
  border: 0;
  font-family: LineAwesome;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  content: '';
  font-size: 0.8rem;
  line-height: 0;
  position: relative;
  color: #a7abc3;
}
.kt-notification .kt-notification__custom {
  display: flex;
  align-items: center;
  border-top: 1px solid #f7f8fa;
  padding: 1.6rem 1.5rem;
}
.kt-notification.kt-notification--fit .kt-notification__item {
  padding: 0.55rem 0;
}
.kt-notification.kt-notification--fit .kt-notification__item:hover {
  background-color: initial;
}
.kt-notification.kt-notification--fit .kt-notification__item:hover .kt-notification__item-title {
  color: #5d78ff;
}
.kt-notification-item-padding-x {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.kt-notification-item-margin-x {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.kt-notification-v2 {
  margin: 0;
}
.kt-notification-v2 .kt-notification-v2__item {
  display: flex;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  transition: all 0.3s;
}
.kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50%;
}
.kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__item-icon i {
  font-size: 1.5rem;
}
.kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__itek-wrapper {
  padding-left: 1.5rem;
  flex: 1 1 auto;
}
.kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__itek-wrapper .kt-notification-v2__item-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: #5d5b6f;
}
.kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__itek-wrapper .kt-notification-v2__item-desc {
  font-size: 1rem;
  font-weight: 300;
  color: #b3b1c2;
}
.kt-notification-v2 .kt-notification-v2__item:hover {
  background-color: #f7f8fa;
  border-radius: 5px;
  transition: all 0.3s;
}
.kt-notification-v2 .kt-notification-v2__item:hover .kt-notification-v2__item-icon {
  background-color: #ebedf2;
  transition: all 0.3s;
}
.kt-notification__foot {
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  margin: 0 auto;
}
.kt-list-pics > a {
  margin-right: 0.25rem;
}
.kt-list-pics > a > img {
  width: 3.36rem;
  border-radius: 50%;
  border-radius: 3px;
}
.kt-list-pics.kt-list-pics--circle > a > img {
  width: 3.36rem;
  border-radius: 50%;
}
.kt-list-pics.kt-list-pics--sm > a > img,
.kt-list-pics.kt-list-pics-rounded--sm > a > img {
  width: 2.14rem;
}
.kt-list-timeline .kt-list-timeline__group {
  margin: 0 0 2rem;
}
.kt-list-timeline .kt-list-timeline__heading {
  margin: 0 0 1.25rem;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
}
.kt-list-timeline .kt-list-timeline__items {
  position: relative;
  padding: 0;
  margin: 0;
}
.kt-list-timeline .kt-list-timeline__items:before {
  background-color: #ebedf2;
  position: absolute;
  display: block;
  content: '';
  width: 1px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 3px;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0.3rem 0;
  margin: 0.5rem 0;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:first-child:before,
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:last-child:before {
  position: absolute;
  display: block;
  content: '';
  width: 1px;
  height: 50%;
  top: 0;
  bottom: 0;
  left: 3px;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:last-child:before {
  top: auto;
  bottom: 0;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge {
  text-align: left;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  width: 20px;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge:before {
  background-color: #ebedf2;
  position: absolute;
  display: block;
  content: '';
  width: 7px;
  height: 7px;
  left: 0;
  top: 50%;
  margin-top: -3.5px;
  border-radius: 100%;
}
.kt-list-timeline
  .kt-list-timeline__items
  .kt-list-timeline__item
  .kt-list-timeline__badge.kt-list-timeline__badge--brand:before {
  background-color: #5d78ff;
}
.kt-list-timeline
  .kt-list-timeline__items
  .kt-list-timeline__item
  .kt-list-timeline__badge.kt-list-timeline__badge--light:before {
  background-color: #fff;
}
.kt-list-timeline
  .kt-list-timeline__items
  .kt-list-timeline__item
  .kt-list-timeline__badge.kt-list-timeline__badge--dark:before {
  background-color: #282a3c;
}
.kt-list-timeline
  .kt-list-timeline__items
  .kt-list-timeline__item
  .kt-list-timeline__badge.kt-list-timeline__badge--primary:before {
  background-color: #5867dd;
}
.kt-list-timeline
  .kt-list-timeline__items
  .kt-list-timeline__item
  .kt-list-timeline__badge.kt-list-timeline__badge--success:before {
  background-color: #0abb87;
}
.kt-list-timeline
  .kt-list-timeline__items
  .kt-list-timeline__item
  .kt-list-timeline__badge.kt-list-timeline__badge--info:before {
  background-color: #5578eb;
}
.kt-list-timeline
  .kt-list-timeline__items
  .kt-list-timeline__item
  .kt-list-timeline__badge.kt-list-timeline__badge--warning:before {
  background-color: #ffb822;
}
.kt-list-timeline
  .kt-list-timeline__items
  .kt-list-timeline__item
  .kt-list-timeline__badge.kt-list-timeline__badge--danger:before {
  background-color: #fd397a;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__icon {
  text-align: left;
  vertical-align: middle;
  display: table-cell;
  width: 30px;
  padding: 0 5px 0 0;
  line-height: 0;
  color: #a7abc3;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class^='la-'] {
  font-size: 1.6rem;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class^='fa-'] {
  font-size: 1.2rem;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class^='flaticon-'] {
  font-size: 1.6rem;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__text {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  padding: 0 0 0 5px;
  font-size: 1rem;
  color: #6c7293;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__text:hover {
  text-decoration: none;
  color: #2739c1;
  font-weight: 400;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__time {
  display: table-cell;
  text-align: right;
  vertical-align: middle;
  width: 300px;
  padding: 0 0 0 5px;
  font-size: 0.9rem;
  color: #a7abc3;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:first-child {
  padding-top: 0;
  margin-top: 0;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:first-child:before,
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:last-child:before {
  background-color: #fff;
}
.kt-list-timeline
  .kt-list-timeline__items
  .kt-list-timeline__item.kt-list-timeline__item--read
  .kt-list-timeline__badge,
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item.kt-list-timeline__item--read .kt-list-timeline__icon,
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item.kt-list-timeline__item--read .kt-list-timeline__text,
.kt-list-timeline
  .kt-list-timeline__items
  .kt-list-timeline__item.kt-list-timeline__item--read
  .kt-list-timeline__time {
  opacity: 0.5;
}
.kt-timeline-v1 {
  position: relative;
  width: 100%;
}
.kt-timeline-v1:after {
  content: '';
  position: absolute;
  width: 5px;
  top: 0;
  margin-top: 0.1rem;
  bottom: 3rem;
  left: 50%;
  margin-left: -2.5px;
  background-color: #e6eaf5;
}
.kt-timeline-v1.kt-timeline-v1--center {
  margin: 0 auto;
}
.kt-timeline-v1 .kt-timeline-v1__items {
  padding-bottom: 6rem;
  position: relative;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__marker {
  width: 20px;
  height: 5px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  background-color: #e6eaf5;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item {
  position: relative;
  margin-left: 0;
  width: 50%;
  min-height: 3rem;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-arrow {
  color: #f7f8fc;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-circle {
  background: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-circle > div {
  border-radius: 50%;
  width: 6px;
  height: 6px;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time {
  position: absolute;
  display: inline-block;
  margin-top: -2.14rem;
  font-weight: 700;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time > span {
  padding-left: 5px;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-content {
  position: relative;
  border-radius: 4px;
  padding: 2.14rem;
  background-color: #f7f8fc;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-content .kt-timeline-v1__item-title {
  font-size: 1.2rem;
  font-weight: 500;
}
.kt-timeline-v1
  .kt-timeline-v1__items
  .kt-timeline-v1__item
  .kt-timeline-v1__item-content
  .kt-timeline-v1__item-actions,
.kt-timeline-v1
  .kt-timeline-v1__items
  .kt-timeline-v1__item
  .kt-timeline-v1__item-content
  .kt-timeline-v1__item-title
  + .kt-timeline-v1__item-body {
  margin-top: 1.25rem;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-content .kt-timeline-v1__item-desc {
  color: #a7abc3;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--left {
  left: 0;
  padding-right: 2.85rem;
  position: relative;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--left .kt-timeline-v1__item-circle {
  right: -10px;
  top: 20px;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--left .kt-timeline-v1__item-time {
  right: 3.8rem;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--left:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: 15px;
  left: 100%;
  margin-left: -3rem;
  border: 17px solid transparent;
  border-left: 10px solid #f7f8fc;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--right {
  left: 50%;
  padding-left: 2.86rem;
  top: -3.5rem;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--right .kt-timeline-v1__item-circle {
  top: 20px;
  left: -10px;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--right .kt-timeline-v1__item-time {
  left: 3.57rem;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--right:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: 15px;
  right: 100%;
  margin-right: -3rem;
  border: 17px solid transparent;
  border-right: 10px solid #f7f8fc;
}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--first {
  top: 3.5rem;
}
.kt-timeline-v1
  .kt-timeline-v1__items
  .kt-timeline-v1__item.kt-timeline-v1__item--first
  + .kt-timeline-v1__item--right {
  top: -1rem;
}
.kt-timeline-v1.kt-timeline-v1--justified:after {
  left: 0;
  bottom: 0;
}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items {
  position: static;
  margin-bottom: 40px;
  padding: 20px 0;
}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__marker {
  left: 0;
  top: 0;
}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item {
  width: 100%;
  top: 0 !important;
  left: 0 !important;
  margin-top: 50px !important;
  padding-left: 2.25rem;
  padding-right: 0;
  position: relative;
}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: 15px;
  left: 0;
  margin-left: 3px;
  border: 17px solid transparent;
  border-right: 10px solid #f7f8fc;
}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time {
  position: absolute;
  display: inline-block;
  margin-top: -2.14rem;
}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-circle {
  left: -10px;
  top: 20px;
}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time {
  right: 100%;
  left: 3.57rem;
}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--first {
  margin-top: 20px !important;
}
.kt-timeline-v2 {
  position: relative;
}
.kt-timeline-v2:before {
  content: '';
  position: absolute;
  left: 4.85rem;
  width: 0.214rem;
  top: 5px;
  bottom: 5px;
  height: calc(100% - 5px);
  background-color: #e7e9f5;
}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item {
  display: table;
  position: relative;
  margin-bottom: 1.75rem;
}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-time {
  display: table-cell;
  font-size: 1.3rem;
  font-weight: 500;
  vertical-align: top;
  position: absolute;
  padding-top: 0.18rem;
  color: #a7abc3;
}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-cricle {
  border-radius: 50%;
  border: 0.89rem solid #fff;
  z-index: 1;
  top: 0.2rem;
  left: 4.12rem;
  position: relative;
}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-cricle > i {
  font-size: 1.78rem;
  top: -0.93rem;
  left: -0.57rem;
  position: absolute;
}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-text {
  display: table-cell;
  vertical-align: top;
  font-size: 1rem;
  padding: 0.35rem 0 0 5rem;
  color: #a7abc3;
}
.kt-timeline-v2
  .kt-timeline-v2__items
  .kt-timeline-v2__item
  .kt-timeline-v2__item-text.kt-timeline-v2__item-text--bold {
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 0.21rem;
  color: #a7abc3;
}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-text > span {
  font-weight: 700;
}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-list-pics {
  display: table-cell;
  vertical-align: top;
}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item:last-child {
  margin-bottom: 0;
}
.kt-timeline-v3 .kt-timeline-v3__item {
  display: table;
  margin-bottom: 1rem;
  position: relative;
}
.kt-timeline-v3 .kt-timeline-v3__item:before {
  position: absolute;
  display: block;
  width: 0.28rem;
  border-radius: 0.3rem;
  height: 70%;
  left: 5.1rem;
  top: 0.46rem;
  content: '';
}
.kt-timeline-v3 .kt-timeline-v3__item .kt-timeline-v3__item-time {
  display: table-cell;
  vertical-align: top;
  padding-top: 0.6rem;
  font-weight: 500;
  font-size: 1.3rem;
  position: absolute;
  text-align: right;
  width: 3.57rem;
  color: #a7abc3;
}
.kt-timeline-v3 .kt-timeline-v3__item .kt-timeline-v3__item-desc {
  display: table-cell;
  width: 100%;
  vertical-align: top;
  padding-left: 7rem;
}
.kt-timeline-v3 .kt-timeline-v3__item .kt-timeline-v3__item-desc .kt-timeline-v3__item-text {
  font-size: 1rem;
  font-weight: 400;
  color: #a7abc3;
}
.kt-timeline-v3
  .kt-timeline-v3__item
  .kt-timeline-v3__item-desc
  .kt-timeline-v3__item-user-name
  .kt-timeline-v3__itek-link {
  font-size: 0.9rem;
  text-decoration: none;
}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--brand:before {
  background: #5d78ff;
}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--light:before {
  background: #fff;
}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--dark:before {
  background: #282a3c;
}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--primary:before {
  background: #5867dd;
}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--success:before {
  background: #0abb87;
}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--info:before {
  background: #5578eb;
}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--warning:before {
  background: #ffb822;
}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--danger:before {
  background: #fd397a;
}
.kt-page--loading * {
  transition: none !important;
}
.kt-page-loader {
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
}
.kt-page--loading .kt-page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.kt-page-loader.kt-page-loader--non-block {
  background: transparent;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: auto;
}
.kt-page--loading-non-block .kt-page-loader.kt-page-loader--non-block {
  display: inline-block;
}
.kt-page-loader.kt-page-loader--logo {
  flex-direction: column;
  align-items: center;
}
.kt-page-loader.kt-page-loader--logo img {
  display: block;
}
.kt-page-loader.kt-page-loader--logo .kt-spinner {
  margin-top: 3rem;
}
.kt-page-loader.kt-page-loader--logo .kt-spinner:before {
  width: 26px;
  height: 26px;
  margin-top: -13px;
  margin-left: -13px;
}
.kt-animate-fade-in-up {
  animation: kt-animate-fade-in-up 0.5s;
}
.kt-animate-fade-out {
  animation: kt-animate-fade-out 0.5s;
}
.kt-animate-fade-in {
  animation: kt-animate-fade-in 0.5s;
}
.kt-animate-blink {
  animation: kt-animate-blink 1s step-start 0s infinite;
  animation-fill-mode: none;
}
.kt-animate-shake {
  animation: kt-animate-shake 0.1s ease-in 0.1s infinite alternate;
  animation-fill-mode: none;
}
.kt-animate-pause {
  animation-play-state: paused;
}
.kt-notransition {
  transition: none !important;
}
.kt-margin-0 {
  margin: 0 !important;
}
.kt-margin-t-0 {
  margin-top: 0 !important;
}
.kt-margin-b-0 {
  margin-bottom: 0 !important;
}
.kt-margin-l-0 {
  margin-left: 0 !important;
}
.kt-margin-r-0 {
  margin-right: 0 !important;
}
.kt-padding-0 {
  padding: 0 !important;
}
.kt-padding-t-0 {
  padding-top: 0 !important;
}
.kt-padding-b-0 {
  padding-bottom: 0 !important;
}
.kt-padding-l-0 {
  padding-left: 0 !important;
}
.kt-padding-r-0 {
  padding-right: 0 !important;
}
.kt-margin-5 {
  margin: 5px !important;
}
.kt-margin-t-5 {
  margin-top: 5px !important;
}
.kt-margin-b-5 {
  margin-bottom: 5px !important;
}
.kt-margin-l-5 {
  margin-left: 5px !important;
}
.kt-margin-r-5 {
  margin-right: 5px !important;
}
.kt-padding-5 {
  padding: 5px !important;
}
.kt-padding-t-5 {
  padding-top: 5px !important;
}
.kt-padding-b-5 {
  padding-bottom: 5px !important;
}
.kt-padding-l-5 {
  padding-left: 5px !important;
}
.kt-padding-r-5 {
  padding-right: 5px !important;
}
.kt-margin-10 {
  margin: 10px !important;
}
.kt-margin-t-10 {
  margin-top: 10px !important;
}
.kt-margin-b-10 {
  margin-bottom: 10px !important;
}
.kt-margin-l-10 {
  margin-left: 10px !important;
}
.kt-margin-r-10 {
  margin-right: 10px !important;
}
.kt-padding-10 {
  padding: 10px !important;
}
.kt-padding-t-10 {
  padding-top: 10px !important;
}
.kt-padding-b-10 {
  padding-bottom: 10px !important;
}
.kt-padding-l-10 {
  padding-left: 10px !important;
}
.kt-padding-r-10 {
  padding-right: 10px !important;
}
.kt-margin-15 {
  margin: 15px !important;
}
.kt-margin-t-15 {
  margin-top: 15px !important;
}
.kt-margin-b-15 {
  margin-bottom: 15px !important;
}
.kt-margin-l-15 {
  margin-left: 15px !important;
}
.kt-margin-r-15 {
  margin-right: 15px !important;
}
.kt-padding-15 {
  padding: 15px !important;
}
.kt-padding-t-15 {
  padding-top: 15px !important;
}
.kt-padding-b-15 {
  padding-bottom: 15px !important;
}
.kt-padding-l-15 {
  padding-left: 15px !important;
}
.kt-padding-r-15 {
  padding-right: 15px !important;
}
.kt-margin-20 {
  margin: 20px !important;
}
.kt-margin-t-20 {
  margin-top: 20px !important;
}
.kt-margin-b-20 {
  margin-bottom: 20px !important;
}
.kt-margin-l-20 {
  margin-left: 20px !important;
}
.kt-margin-r-20 {
  margin-right: 20px !important;
}
.kt-padding-20 {
  padding: 20px !important;
}
.kt-padding-t-20 {
  padding-top: 20px !important;
}
.kt-padding-b-20 {
  padding-bottom: 20px !important;
}
.kt-padding-l-20 {
  padding-left: 20px !important;
}
.kt-padding-r-20 {
  padding-right: 20px !important;
}
.kt-margin-25 {
  margin: 25px !important;
}
.kt-margin-t-25 {
  margin-top: 25px !important;
}
.kt-margin-b-25 {
  margin-bottom: 25px !important;
}
.kt-margin-l-25 {
  margin-left: 25px !important;
}
.kt-margin-r-25 {
  margin-right: 25px !important;
}
.kt-padding-25 {
  padding: 25px !important;
}
.kt-padding-t-25 {
  padding-top: 25px !important;
}
.kt-padding-b-25 {
  padding-bottom: 25px !important;
}
.kt-padding-l-25 {
  padding-left: 25px !important;
}
.kt-padding-r-25 {
  padding-right: 25px !important;
}
.kt-margin-30 {
  margin: 30px !important;
}
.kt-margin-t-30 {
  margin-top: 30px !important;
}
.kt-margin-b-30 {
  margin-bottom: 30px !important;
}
.kt-margin-l-30 {
  margin-left: 30px !important;
}
.kt-margin-r-30 {
  margin-right: 30px !important;
}
.kt-padding-30 {
  padding: 30px !important;
}
.kt-padding-t-30 {
  padding-top: 30px !important;
}
.kt-padding-b-30 {
  padding-bottom: 30px !important;
}
.kt-padding-l-30 {
  padding-left: 30px !important;
}
.kt-padding-r-30 {
  padding-right: 30px !important;
}
.kt-margin-35 {
  margin: 35px !important;
}
.kt-margin-t-35 {
  margin-top: 35px !important;
}
.kt-margin-b-35 {
  margin-bottom: 35px !important;
}
.kt-margin-l-35 {
  margin-left: 35px !important;
}
.kt-margin-r-35 {
  margin-right: 35px !important;
}
.kt-padding-35 {
  padding: 35px !important;
}
.kt-padding-t-35 {
  padding-top: 35px !important;
}
.kt-padding-b-35 {
  padding-bottom: 35px !important;
}
.kt-padding-l-35 {
  padding-left: 35px !important;
}
.kt-padding-r-35 {
  padding-right: 35px !important;
}
.kt-margin-40 {
  margin: 40px !important;
}
.kt-margin-t-40 {
  margin-top: 40px !important;
}
.kt-margin-b-40 {
  margin-bottom: 40px !important;
}
.kt-margin-l-40 {
  margin-left: 40px !important;
}
.kt-margin-r-40 {
  margin-right: 40px !important;
}
.kt-padding-40 {
  padding: 40px !important;
}
.kt-padding-t-40 {
  padding-top: 40px !important;
}
.kt-padding-b-40 {
  padding-bottom: 40px !important;
}
.kt-padding-l-40 {
  padding-left: 40px !important;
}
.kt-padding-r-40 {
  padding-right: 40px !important;
}
.kt-margin-45 {
  margin: 45px !important;
}
.kt-margin-t-45 {
  margin-top: 45px !important;
}
.kt-margin-b-45 {
  margin-bottom: 45px !important;
}
.kt-margin-l-45 {
  margin-left: 45px !important;
}
.kt-margin-r-45 {
  margin-right: 45px !important;
}
.kt-padding-45 {
  padding: 45px !important;
}
.kt-padding-t-45 {
  padding-top: 45px !important;
}
.kt-padding-b-45 {
  padding-bottom: 45px !important;
}
.kt-padding-l-45 {
  padding-left: 45px !important;
}
.kt-padding-r-45 {
  padding-right: 45px !important;
}
.kt-margin-50 {
  margin: 50px !important;
}
.kt-margin-t-50 {
  margin-top: 50px !important;
}
.kt-margin-b-50 {
  margin-bottom: 50px !important;
}
.kt-margin-l-50 {
  margin-left: 50px !important;
}
.kt-margin-r-50 {
  margin-right: 50px !important;
}
.kt-padding-50 {
  padding: 50px !important;
}
.kt-padding-t-50 {
  padding-top: 50px !important;
}
.kt-padding-b-50 {
  padding-bottom: 50px !important;
}
.kt-padding-l-50 {
  padding-left: 50px !important;
}
.kt-padding-r-50 {
  padding-right: 50px !important;
}
.kt-margin-55 {
  margin: 55px !important;
}
.kt-margin-t-55 {
  margin-top: 55px !important;
}
.kt-margin-b-55 {
  margin-bottom: 55px !important;
}
.kt-margin-l-55 {
  margin-left: 55px !important;
}
.kt-margin-r-55 {
  margin-right: 55px !important;
}
.kt-padding-55 {
  padding: 55px !important;
}
.kt-padding-t-55 {
  padding-top: 55px !important;
}
.kt-padding-b-55 {
  padding-bottom: 55px !important;
}
.kt-padding-l-55 {
  padding-left: 55px !important;
}
.kt-padding-r-55 {
  padding-right: 55px !important;
}
.kt-margin-60 {
  margin: 60px !important;
}
.kt-margin-t-60 {
  margin-top: 60px !important;
}
.kt-margin-b-60 {
  margin-bottom: 60px !important;
}
.kt-margin-l-60 {
  margin-left: 60px !important;
}
.kt-margin-r-60 {
  margin-right: 60px !important;
}
.kt-padding-60 {
  padding: 60px !important;
}
.kt-padding-t-60 {
  padding-top: 60px !important;
}
.kt-padding-b-60 {
  padding-bottom: 60px !important;
}
.kt-padding-l-60 {
  padding-left: 60px !important;
}
.kt-padding-r-60 {
  padding-right: 60px !important;
}
.kt-margin-65 {
  margin: 65px !important;
}
.kt-margin-t-65 {
  margin-top: 65px !important;
}
.kt-margin-b-65 {
  margin-bottom: 65px !important;
}
.kt-margin-l-65 {
  margin-left: 65px !important;
}
.kt-margin-r-65 {
  margin-right: 65px !important;
}
.kt-padding-65 {
  padding: 65px !important;
}
.kt-padding-t-65 {
  padding-top: 65px !important;
}
.kt-padding-b-65 {
  padding-bottom: 65px !important;
}
.kt-padding-l-65 {
  padding-left: 65px !important;
}
.kt-padding-r-65 {
  padding-right: 65px !important;
}
.kt-margin-70 {
  margin: 70px !important;
}
.kt-margin-t-70 {
  margin-top: 70px !important;
}
.kt-margin-b-70 {
  margin-bottom: 70px !important;
}
.kt-margin-l-70 {
  margin-left: 70px !important;
}
.kt-margin-r-70 {
  margin-right: 70px !important;
}
.kt-padding-70 {
  padding: 70px !important;
}
.kt-padding-t-70 {
  padding-top: 70px !important;
}
.kt-padding-b-70 {
  padding-bottom: 70px !important;
}
.kt-padding-l-70 {
  padding-left: 70px !important;
}
.kt-padding-r-70 {
  padding-right: 70px !important;
}
.kt-margin-75 {
  margin: 75px !important;
}
.kt-margin-t-75 {
  margin-top: 75px !important;
}
.kt-margin-b-75 {
  margin-bottom: 75px !important;
}
.kt-margin-l-75 {
  margin-left: 75px !important;
}
.kt-margin-r-75 {
  margin-right: 75px !important;
}
.kt-padding-75 {
  padding: 75px !important;
}
.kt-padding-t-75 {
  padding-top: 75px !important;
}
.kt-padding-b-75 {
  padding-bottom: 75px !important;
}
.kt-padding-l-75 {
  padding-left: 75px !important;
}
.kt-padding-r-75 {
  padding-right: 75px !important;
}
.kt-margin-80 {
  margin: 80px !important;
}
.kt-margin-t-80 {
  margin-top: 80px !important;
}
.kt-margin-b-80 {
  margin-bottom: 80px !important;
}
.kt-margin-l-80 {
  margin-left: 80px !important;
}
.kt-margin-r-80 {
  margin-right: 80px !important;
}
.kt-padding-80 {
  padding: 80px !important;
}
.kt-padding-t-80 {
  padding-top: 80px !important;
}
.kt-padding-b-80 {
  padding-bottom: 80px !important;
}
.kt-padding-l-80 {
  padding-left: 80px !important;
}
.kt-padding-r-80 {
  padding-right: 80px !important;
}
.kt-margin-85 {
  margin: 85px !important;
}
.kt-margin-t-85 {
  margin-top: 85px !important;
}
.kt-margin-b-85 {
  margin-bottom: 85px !important;
}
.kt-margin-l-85 {
  margin-left: 85px !important;
}
.kt-margin-r-85 {
  margin-right: 85px !important;
}
.kt-padding-85 {
  padding: 85px !important;
}
.kt-padding-t-85 {
  padding-top: 85px !important;
}
.kt-padding-b-85 {
  padding-bottom: 85px !important;
}
.kt-padding-l-85 {
  padding-left: 85px !important;
}
.kt-padding-r-85 {
  padding-right: 85px !important;
}
.kt-margin-90 {
  margin: 90px !important;
}
.kt-margin-t-90 {
  margin-top: 90px !important;
}
.kt-margin-b-90 {
  margin-bottom: 90px !important;
}
.kt-margin-l-90 {
  margin-left: 90px !important;
}
.kt-margin-r-90 {
  margin-right: 90px !important;
}
.kt-padding-90 {
  padding: 90px !important;
}
.kt-padding-t-90 {
  padding-top: 90px !important;
}
.kt-padding-b-90 {
  padding-bottom: 90px !important;
}
.kt-padding-l-90 {
  padding-left: 90px !important;
}
.kt-padding-r-90 {
  padding-right: 90px !important;
}
.kt-margin-95 {
  margin: 95px !important;
}
.kt-margin-t-95 {
  margin-top: 95px !important;
}
.kt-margin-b-95 {
  margin-bottom: 95px !important;
}
.kt-margin-l-95 {
  margin-left: 95px !important;
}
.kt-margin-r-95 {
  margin-right: 95px !important;
}
.kt-padding-95 {
  padding: 95px !important;
}
.kt-padding-t-95 {
  padding-top: 95px !important;
}
.kt-padding-b-95 {
  padding-bottom: 95px !important;
}
.kt-padding-l-95 {
  padding-left: 95px !important;
}
.kt-padding-r-95 {
  padding-right: 95px !important;
}
.kt-margin-100 {
  margin: 100px !important;
}
.kt-margin-t-100 {
  margin-top: 100px !important;
}
.kt-margin-b-100 {
  margin-bottom: 100px !important;
}
.kt-margin-l-100 {
  margin-left: 100px !important;
}
.kt-margin-r-100 {
  margin-right: 100px !important;
}
.kt-padding-100 {
  padding: 100px !important;
}
.kt-padding-t-100 {
  padding-top: 100px !important;
}
.kt-padding-b-100 {
  padding-bottom: 100px !important;
}
.kt-padding-l-100 {
  padding-left: 100px !important;
}
.kt-padding-r-100 {
  padding-right: 100px !important;
}
.kt-opacity-1 {
  opacity: 0.1;
}
.kt-opacity-2 {
  opacity: 0.2;
}
.kt-opacity-3 {
  opacity: 0.3;
}
.kt-opacity-4 {
  opacity: 0.4;
}
.kt-opacity-5 {
  opacity: 0.5;
}
.kt-opacity-6 {
  opacity: 0.6;
}
.kt-opacity-7 {
  opacity: 0.7;
}
.kt-opacity-8 {
  opacity: 0.8;
}
.kt-opacity-9 {
  opacity: 0.9;
}
.kt-radius-100 {
  border-radius: 100%;
}
.kt-valign-top {
  vertical-align: top;
}
.kt-space-5 {
  display: block;
  height: 0;
  margin-bottom: 5px;
}
.kt-space-10 {
  display: block;
  height: 0;
  margin-bottom: 10px;
}
.kt-space-15 {
  display: block;
  height: 0;
  margin-bottom: 15px;
}
.kt-space-20 {
  display: block;
  height: 0;
  margin-bottom: 20px;
}
.kt-space-30 {
  display: block;
  height: 0;
  margin-bottom: 30px;
}
.kt-space-40 {
  display: block;
  height: 0;
  margin-bottom: 40px;
}
.kt-valign-middle {
  vertical-align: middle;
}
.kt-valign-bottom {
  vertical-align: bottom;
}
.kt-block-center {
  margin-left: auto;
  margin-right: auto;
}
.kt-align-right {
  text-align: right;
}
.kt-align-left {
  text-align: left;
}
.kt-align-center {
  text-align: center;
}
.kt-pull-right {
  float: right;
}
.kt-pull-left {
  float: left;
}
.kt-img-rounded {
  border-radius: 50% !important;
}
.kt-block-inline {
  display: inline-block;
}
.kt-icon-middle:before {
  vertical-align: middle;
}
.kt-hidden,
.kt-hide {
  display: none !important;
}
.kt-font-sm {
  font-size: 0.8rem;
}
.kt-font-md {
  font-size: 0.9rem;
}
.kt-font-ml {
  font-size: 1rem;
}
.kt-font-lg {
  font-size: 1.2rem;
}
.kt-font-xl {
  font-size: 1.5rem;
}
.kt-clearfix:after,
.kt-clearfix:before {
  content: ' ';
  display: table;
}
.kt-clearfix:after {
  clear: both;
}
.kt-marginless {
  margin: 0 !important;
}
.kt-img-centered {
  text-align: center;
}
.kt-font-transform-u {
  text-transform: uppercase;
}
.kt-font-transform-l {
  text-transform: lowercase;
}
.kt-font-transform-c {
  text-transform: capitalize;
}
.kt-font-transform-i {
  text-transform: none;
}
.kt-font-regular {
  font-weight: 400 !important;
}
.kt-font-bold {
  font-weight: 500 !important;
}
.kt-font-bolder {
  font-weight: 600 !important;
}
.kt-font-boldest {
  font-weight: 700 !important;
}
.kt-font-brand {
  color: #5d78ff !important;
}
.kt-font-hover-brand:hover {
  color: #0029f6;
}
.kt-font-inverse-brand {
  color: #fff !important;
}
.kt-bg-brand,
.kt-bg-fill-brand {
  background-color: #5d78ff !important;
}
.kt-bg-fill-brand,
.kt-bg-fill-brand > i,
.kt-font-light {
  color: #fff !important;
}
.kt-font-hover-light:hover {
  color: #ccc;
}
.kt-font-inverse-light {
  color: #282a3c !important;
}
.kt-bg-fill-light,
.kt-bg-light {
  background-color: #fff !important;
}
.kt-bg-fill-light,
.kt-bg-fill-light > i,
.kt-font-dark {
  color: #282a3c !important;
}
.kt-font-hover-dark:hover {
  color: #000;
}
.kt-font-inverse-dark {
  color: #fff !important;
}
.kt-bg-dark,
.kt-bg-fill-dark {
  background-color: #282a3c !important;
}
.kt-bg-fill-dark,
.kt-bg-fill-dark > i {
  color: #fff !important;
}
.kt-font-primary {
  color: #5867dd !important;
}
.kt-font-hover-primary:hover {
  color: #2332ac;
}
.kt-font-inverse-primary {
  color: #fff !important;
}
.kt-bg-fill-primary,
.kt-bg-primary {
  background-color: #5867dd !important;
}
.kt-bg-fill-primary,
.kt-bg-fill-primary > i {
  color: #fff !important;
}
.kt-font-success {
  color: #0abb87 !important;
}
.kt-font-hover-success:hover {
  color: #055a41;
}
.kt-font-inverse-success {
  color: #fff !important;
}
.kt-bg-fill-success,
.kt-bg-success {
  background-color: #0abb87 !important;
}
.kt-bg-fill-success,
.kt-bg-fill-success > i {
  color: #fff !important;
}
.kt-font-info {
  color: #5578eb !important;
}
.kt-font-hover-info:hover {
  color: #173fc3;
}
.kt-font-inverse-info {
  color: #fff !important;
}
.kt-bg-fill-info,
.kt-bg-info {
  background-color: #5578eb !important;
}
.kt-bg-fill-info,
.kt-bg-fill-info > i {
  color: #fff !important;
}
.kt-font-warning {
  color: #ffb822 !important;
}
.kt-font-hover-warning:hover {
  color: #bb7f00;
}
.kt-font-inverse-warning {
  color: #111 !important;
}
.kt-bg-fill-warning,
.kt-bg-warning {
  background-color: #ffb822 !important;
}
.kt-bg-fill-warning,
.kt-bg-fill-warning > i {
  color: #111 !important;
}
.kt-font-danger {
  color: #fd397a !important;
}
.kt-font-hover-danger:hover {
  color: #ce0246;
}
.kt-font-inverse-danger {
  color: #fff !important;
}
.kt-bg-danger,
.kt-bg-fill-danger {
  background-color: #fd397a !important;
}
.kt-bg-fill-danger,
.kt-bg-fill-danger > i {
  color: #fff !important;
}
.kt-label-font-color-1 {
  color: #adb1c7 !important;
}
.kt-label-bg-color-1 {
  background-color: #adb1c7 !important;
}
.kt-label-font-color-2 {
  color: #a7abc3 !important;
}
.kt-label-bg-color-2 {
  background-color: #a7abc3 !important;
}
.kt-label-font-color-3 {
  color: #6c7293 !important;
}
.kt-label-bg-color-3 {
  background-color: #6c7293 !important;
}
.kt-label-font-color-4 {
  color: #464457 !important;
}
.kt-label-bg-color-4 {
  background-color: #464457 !important;
}
.kt-shape-font-color-1 {
  color: #f0f3ff !important;
}
.kt-shape-bg-color-1 {
  background-color: #f0f3ff !important;
}
.kt-shape-font-color-2 {
  color: #e8ecfa !important;
}
.kt-shape-bg-color-2 {
  background-color: #e8ecfa !important;
}
.kt-shape-font-color-3 {
  color: #93a2dd !important;
}
.kt-shape-bg-color-3 {
  background-color: #93a2dd !important;
}
.kt-shape-font-color-4 {
  color: #646c9a !important;
}
.kt-shape-bg-color-4 {
  background-color: #646c9a !important;
}
.kt-visible-desktop,
.kt-visible-desktop-inline,
.kt-visible-desktop-inline-block,
.kt-visible-desktop-table,
.kt-visible-desktop-table-cell,
.kt-visible-mobile,
.kt-visible-mobile-inline,
.kt-visible-mobile-inline-block,
.kt-visible-mobile-table,
.kt-visible-mobile-table-cell,
.kt-visible-tablet,
.kt-visible-tablet-and-mobile,
.kt-visible-tablet-and-mobile-inline,
.kt-visible-tablet-and-mobile-inline-block,
.kt-visible-tablet-and-mobile-table,
.kt-visible-tablet-and-mobile-table-cell,
.kt-visible-tablet-inline,
.kt-visible-tablet-inline-block,
.kt-visible-tablet-table,
.kt-visible-tablet-table-cell {
  display: none !important;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
  display: flex;
  flex-direction: column;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile).kt-grid--root {
  flex: 1;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item {
  flex: none;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--fluid {
  flex: 1 0 auto;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-1 {
  order: 1;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-2 {
  order: 2;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-3 {
  order: 3;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-4 {
  order: 4;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-5 {
  order: 5;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-6 {
  order: 6;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-7 {
  order: 7;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-8 {
  order: 8;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-9 {
  order: 9;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-10 {
  order: 10;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-11 {
  order: 11;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-12 {
  order: 12;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
  display: flex;
  flex-direction: row;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile).kt-grid--root {
  flex: 1;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item {
  flex: 0 0 auto;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--fluid {
  flex: 1 auto;
  min-width: 0;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-1 {
  order: 1;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-2 {
  order: 2;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-3 {
  order: 3;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-4 {
  order: 4;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-5 {
  order: 5;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-6 {
  order: 6;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-7 {
  order: 7;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-8 {
  order: 8;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-9 {
  order: 9;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-10 {
  order: 10;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-11 {
  order: 11;
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
  > .kt-grid__item.kt-grid__item--order-12 {
  order: 12;
}
.kt-grid.kt-grid--center {
  align-content: center;
}
.kt-grid.kt-grid--stretch {
  align-items: stretch;
}
.kt-grid > .kt-grid__item {
  max-width: 100%;
}
.kt-grid > .kt-grid__item.kt-grid__item--top {
  align-self: flex-start;
}
.kt-grid > .kt-grid__item.kt-grid__item--middle {
  align-self: center;
}
.kt-grid > .kt-grid__item.kt-grid__item--bottom {
  align-self: flex-end;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-1 {
  order: 1;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-2 {
  order: 2;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-3 {
  order: 3;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-4 {
  order: 4;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-5 {
  order: 5;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-6 {
  order: 6;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-7 {
  order: 7;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-8 {
  order: 8;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-9 {
  order: 9;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-10 {
  order: 10;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-11 {
  order: 11;
}
.kt-grid > .kt-grid__item.kt-grid__item--order-12 {
  order: 12;
}
.kt-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.kt-container.kt-container--fluid {
  width: 100%;
}
.kt-container.kt-container--full-height {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
}
.kt-datatable {
  display: none;
}
.kt-datatable.kt-datatable--default {
  display: block;
  margin-bottom: 25px;
}
.kt-datatable.kt-datatable--default > .kt-datatable__table {
  border-collapse: collapse;
  overflow: hidden;
  display: none;
  min-height: 300px;
  width: 100%;
}
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__body,
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__foot,
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__head {
  visibility: hidden;
  display: block;
}
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__body .kt-datatable__row,
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__foot .kt-datatable__row,
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__head .kt-datatable__row {
  width: 100%;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell {
  vertical-align: middle;
  padding: 10px;
  font-size: 1rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell:first-child,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell:first-child,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell:first-child {
  padding-left: 25px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell:last-child,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell:last-child,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell:last-child {
  padding-right: 25px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left
  > span {
  text-align: left;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span {
  text-align: right;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span
  > i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span
  > i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span
  > i {
  right: 0;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center
  > span {
  text-align: center;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort {
  cursor: pointer;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort
  i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort
  i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort
  i {
  font-size: 0.6rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--resizing,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--resizing,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--resizing {
  cursor: col-resize;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  > span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check {
  text-align: center;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span {
  overflow: visible;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span
  > .kt-checkbox,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span
  > .kt-checkbox,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span
  > .kt-checkbox {
  top: 2px;
  padding: 0;
  margin: 0;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > span {
  width: 12px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail {
  display: inline-block;
  text-align: right;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail
  > i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail
  > i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail
  > i {
  font-size: 1.4rem;
  width: 12px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown {
  display: inline-block;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu {
  margin-top: 0.5rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown.dropup
  .dropdown-menu,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown.dropup
  .dropdown-menu,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown.dropup
  .dropdown-menu {
  margin-top: auto;
  margin-bottom: 0.5rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu.dropdown-menu-right,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu.dropdown-menu-right,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu.dropdown-menu-right {
  margin-right: 65px;
}
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__body > .kt-datatable__lock,
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__foot > .kt-datatable__lock,
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__head > .kt-datatable__lock {
  border: 0;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  > span {
  font-weight: 500;
  vertical-align: middle;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell {
  padding: 16px 10px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  > span
  > i {
  display: inline-block;
  position: relative;
  right: -10px;
  line-height: 0;
  vertical-align: middle;
  font-size: 1.1rem;
}
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__body.ps {
  padding-bottom: 0;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell {
  font-weight: regular;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span
  > .kt-checkbox {
  top: 1px;
}
.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__body .kt-datatable__row-detail {
  display: block;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail {
  display: block;
  padding: 0 60px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  .kt-datatable__row {
  display: table-row;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  .kt-datatable__row
  > .kt-datatable__cell {
  padding: 8px 12px;
  text-align: left;
  vertical-align: top;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  .kt-datatable__row
  > .kt-datatable__cell
  > span {
  width: auto !important;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  .kt-datatable__row
  > .kt-datatable__cell:first-child {
  font-weight: 500;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  .kt-datatable__row
  > .kt-datatable__cell:last-child {
  font-weight: regular;
  padding-left: 20px;
}
.kt-datatable.kt-datatable--default.kt-datatable--error .kt-datatable__body {
  padding: 30px;
  text-align: center;
}
.kt-datatable.kt-datatable--default.kt-datatable--lock
  > .kt-datatable__table
  > .kt-datatable__body
  > .kt-datatable__lock,
.kt-datatable.kt-datatable--default.kt-datatable--lock
  > .kt-datatable__table
  > .kt-datatable__foot
  > .kt-datatable__lock,
.kt-datatable.kt-datatable--default.kt-datatable--lock
  > .kt-datatable__table
  > .kt-datatable__head
  > .kt-datatable__lock {
  display: inline-block;
  position: relative;
  vertical-align: top;
  overflow: hidden;
}
.kt-datatable.kt-datatable--default.kt-datatable--loaded,
.kt-datatable.kt-datatable--default.kt-datatable--loaded > .kt-datatable__table {
  display: block;
}
.kt-datatable.kt-datatable--default.kt-datatable--loaded > .kt-datatable__table > .kt-datatable__body,
.kt-datatable.kt-datatable--default.kt-datatable--loaded > .kt-datatable__table > .kt-datatable__foot,
.kt-datatable.kt-datatable--default.kt-datatable--loaded > .kt-datatable__table > .kt-datatable__head {
  visibility: visible;
  display: block;
  position: relative;
}
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row,
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row,
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row {
  display: table;
  table-layout: auto;
}
.kt-datatable.kt-datatable--default.kt-datatable--scroll > .kt-datatable__table {
  display: block;
}
.kt-datatable.kt-datatable--default.kt-datatable--scroll > .kt-datatable__table > .kt-datatable__foot,
.kt-datatable.kt-datatable--default.kt-datatable--scroll > .kt-datatable__table > .kt-datatable__head {
  overflow: hidden;
}
.kt-datatable.kt-datatable--default.kt-datatable--scroll
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row,
.kt-datatable.kt-datatable--default.kt-datatable--scroll
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row {
  position: relative;
}
.kt-datatable.kt-datatable--default.kt-datatable--scroll > .kt-datatable__table > .kt-datatable__body {
  overflow: hidden;
}
.kt-datatable.kt-datatable--default > .kt-datatable__pager {
  margin: 0;
  padding: 25px 25px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.kt-datatable.kt-datatable--default > .kt-datatable__pager.kt-datatable__pager--top {
  margin-bottom: 20px;
}
.kt-datatable.kt-datatable--default > .kt-datatable__pager > .kt-datatable__pager-nav {
  margin: 0;
  padding: 0;
  display: flex;
}
.kt-datatable.kt-datatable--default > .kt-datatable__pager > .kt-datatable__pager-nav > li {
  padding: 0;
  margin-right: 5px;
  display: inline-block;
}
.kt-datatable.kt-datatable--default > .kt-datatable__pager > .kt-datatable__pager-nav > li:last-child {
  margin-right: 0;
}
.kt-datatable.kt-datatable--default > .kt-datatable__pager > .kt-datatable__pager-nav > li > .kt-datatable__pager-link {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  border-radius: 3px;
  position: relative;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link
  > i {
  font-size: 0.6rem;
  text-align: center;
  display: inline-block;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--more-next,
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--more-prev {
  font-weight: 600;
}
.kt-datatable.kt-datatable--default > .kt-datatable__pager > .kt-datatable__pager-nav > li > .kt-pager-input {
  height: 2.25rem;
  width: 3.5rem;
  text-align: center;
  border-radius: 3px !important;
}
.kt-datatable.kt-datatable--default > .kt-datatable__pager > .kt-datatable__pager-info {
  display: flex;
  align-items: center;
}
.kt-datatable.kt-datatable--default > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size {
  margin-right: 10px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle {
  height: 2.25rem;
  padding: 0.45rem 1rem;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable > .kt-datatable__table {
  margin-bottom: 0;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__toggle-subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__toggle-subtable {
  display: flex;
  justify-content: center;
  align-items: center;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-subtable
  > i,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__toggle-subtable
  > i,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__toggle-subtable
  > i {
  line-height: 0;
  font-size: 1.4rem;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-subtable:hover,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__toggle-subtable:hover,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__toggle-subtable:hover {
  text-decoration: none;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row-subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row-subtable {
  display: table;
  width: 100%;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable
  > .kt-datatable__subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row-subtable
  > .kt-datatable__subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row-subtable
  > .kt-datatable__subtable {
  padding: 20px;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable
  > .kt-datatable__subtable
  > .kt-datatable
  > .kt-datatable__pager,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row-subtable
  > .kt-datatable__subtable
  > .kt-datatable
  > .kt-datatable__pager,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row-subtable
  > .kt-datatable__subtable
  > .kt-datatable
  > .kt-datatable__pager {
  padding-top: 10px;
  padding-bottom: 10px;
}
.kt-datatable > .kt-datatable__table {
  background-color: #fff;
}
.kt-datatable > .kt-datatable__table > .kt-datatable__body > .kt-datatable__lock.kt-datatable__lock--left,
.kt-datatable > .kt-datatable__table > .kt-datatable__foot > .kt-datatable__lock.kt-datatable__lock--left,
.kt-datatable > .kt-datatable__table > .kt-datatable__head > .kt-datatable__lock.kt-datatable__lock--left {
  z-index: 1;
  box-shadow: 0 0 17px 5px rgba(113, 106, 202, 0.14);
}
.kt-datatable > .kt-datatable__table > .kt-datatable__body > .kt-datatable__lock.kt-datatable__lock--right,
.kt-datatable > .kt-datatable__table > .kt-datatable__foot > .kt-datatable__lock.kt-datatable__lock--right,
.kt-datatable > .kt-datatable__table > .kt-datatable__head > .kt-datatable__lock.kt-datatable__lock--right {
  box-shadow: 0 0 17px 5px rgba(113, 106, 202, 0.14);
}
.kt-datatable > .kt-datatable__table > .kt-datatable__body > .kt-datatable__lock.kt-datatable__lock--scroll,
.kt-datatable > .kt-datatable__table > .kt-datatable__foot > .kt-datatable__lock.kt-datatable__lock--scroll,
.kt-datatable > .kt-datatable__table > .kt-datatable__head > .kt-datatable__lock.kt-datatable__lock--scroll {
  position: relative;
}
.kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__row,
.kt-datatable > .kt-datatable__table > .kt-datatable__foot .kt-datatable__row,
.kt-datatable > .kt-datatable__table > .kt-datatable__head .kt-datatable__row {
  border-bottom: 1px solid #f0f3ff;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > span {
  background: #f0f3ff;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > input:checked
  ~ span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > input:checked
  ~ span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > input:checked
  ~ span {
  background: #e8ecfa;
}
.kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__toggle-detail {
  vertical-align: middle;
}
.kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__toggle-detail i {
  transition: color 0.3s ease;
  color: #5d78ff;
}
.kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__toggle-detail i:before {
  line-height: 0;
  vertical-align: middle;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-detail.kt-datatable__toggle-detail--active
  i,
.kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__toggle-detail:hover i {
  transition: color 0.3s ease;
  color: #5d78ff;
}
.kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__row-detail .kt-datatable__detail table {
  border-left: 1px solid #f0f3ff;
  border-right: 1px solid #f0f3ff;
}
.kt-datatable > .kt-datatable__table > .kt-datatable__foot .kt-datatable__row > .kt-datatable__cell,
.kt-datatable > .kt-datatable__table > .kt-datatable__head .kt-datatable__row > .kt-datatable__cell {
  background: transparent;
}
.kt-datatable > .kt-datatable__table > .kt-datatable__foot .kt-datatable__row > .kt-datatable__cell > span,
.kt-datatable > .kt-datatable__table > .kt-datatable__head .kt-datatable__row > .kt-datatable__cell > span {
  color: #6c7293;
}
.kt-datatable > .kt-datatable__table > .kt-datatable__head .kt-datatable__row > .kt-datatable__cell > span > i {
  color: #5d78ff;
}
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-nav > li > .kt-datatable__pager-link {
  color: #93a2dd;
  transition: all 0.3s;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--first,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--last,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--next,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--prev {
  background: #f0f3ff;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--first:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--last:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--next:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--prev:hover {
  background: #5d78ff;
  color: #fff;
}
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-nav > li > .kt-datatable__pager-link:hover {
  transition: all 0.3s;
  background: #5d78ff;
  color: #fff;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--active {
  background: #5d78ff;
  color: #fff;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--disabled,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--disabled:hover {
  color: #93a2dd;
  background: #f0f3ff;
  opacity: 0.3;
}
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-nav > li > .kt-pager-input {
  transition: all 0.3s;
  background: #f0f3ff;
  border-color: #f0f3ff;
}
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-nav > li > .kt-pager-input:focus {
  transition: all 0.3s;
  background: #5d78ff;
  border-color: #5d78ff;
}
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size .btn.dropdown-toggle {
  font-size: 1rem;
  font-weight: 500;
  border: 0 !important;
  color: #93a2dd;
  background: #f0f3ff;
}
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size .btn.dropdown-toggle i {
  color: #fff;
}
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size .btn.dropdown-toggle:focus,
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size .btn.dropdown-toggle:hover,
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size.show > .btn.dropdown-toggle {
  border: 0 !important;
  color: #fff !important;
  background: #5d78ff;
}
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size .btn.dropdown-toggle:focus i,
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size .btn.dropdown-toggle:hover i,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size.show
  > .btn.dropdown-toggle
  i {
  color: #fff !important;
}
.kt-datatable > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size .dropdown-menu {
  z-index: 100;
}
.kt-datatable.kt-datatable--subtable > .kt-datatable__table > .kt-datatable__body .kt-datatable__toggle-subtable > i {
  color: #5d78ff;
}
.kt-datatable.kt-datatable--subtable > .kt-datatable__table > .kt-datatable__body .kt-datatable__row-subtable {
  border-top: 0;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell {
  transition: background 0.3s ease;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  > span {
  color: #6c7293;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--hover {
  transition: background 0.3s ease;
  background: transparent;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row.kt-datatable__row--even
  > .kt-datatable__cell {
  background: transparent;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row.kt-datatable__row--active
  > .kt-datatable__cell {
  background: #f7f8fa;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row.kt-datatable__row--hover:not(.kt-datatable__row--active) {
  transition: background 0.3s ease;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row.kt-datatable__row--hover:not(.kt-datatable__row--active)
  > .kt-datatable__cell {
  transition: background 0.3s ease;
  background: transparent;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sorted
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sorted
  > span {
  color: #5d78ff;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row.kt-datatable__row--subtable-expanded
  > .kt-datatable__cell {
  background: #f7f8fa !important;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable {
  background: transparent;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable
  > .kt-datatable__subtable
  > .kt-datatable {
  box-shadow: 0 0 15px 1px rgba(113, 106, 202, 0.1);
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable.kt-datatable__row-loading
  > .kt-datatable__subtable
  > .kt-datatable
  > .kt-datatable__table {
  background: transparent;
}
.kt-datatable .ps > .ps__rail-x > .ps__thumb-x,
.kt-datatable .ps > .ps__rail-x > .ps__thumb-x:focus,
.kt-datatable .ps > .ps__rail-x > .ps__thumb-x:hover,
.kt-datatable .ps > .ps__rail-y > .ps__thumb-y,
.kt-datatable .ps > .ps__rail-y > .ps__thumb-y:focus,
.kt-datatable .ps > .ps__rail-y > .ps__thumb-y:hover {
  background: #dbe2f7;
}
.kt-scrolltop {
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  z-index: 90;
  background: #5d78ff;
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);
  opacity: 0;
  transition: all 0.3s;
  border-radius: 4px;
}
.kt-scrolltop svg {
  height: 20px;
  width: 20px;
}
.kt-scrolltop > i {
  font-size: 1.3rem;
  color: #fff;
}
.kt-scrolltop g [fill] {
  fill: #fff;
}
.kt-scrolltop:hover g [fill],
.kt-scrolltop g [fill] {
  transition: fill 0.3s ease;
}
.kt-scrolltop:hover > i {
  color: #fff;
}
.kt-scrolltop--on .kt-scrolltop {
  opacity: 0.3;
  animation: kt-scrolltop-show-animation 0.4s ease-out 1;
  display: flex;
}
.kt-scrolltop--on .kt-scrolltop:hover {
  transition: all 0.3s;
  opacity: 1;
}
.kt-portlet {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
}
.kt-page-content-white .kt-portlet {
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.1);
}
.kt-portlet .kt-portlet__head {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  padding: 0 25px;
  border-bottom: 1px solid #ebedf2;
  min-height: 60px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.kt-portlet .kt-portlet__head.kt-portlet__head--noborder {
  border-bottom: 0;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-label {
  display: flex;
  align-items: center;
  align-content: flex-first;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #464457;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title small {
  font-weight: 300;
  padding-left: 0.5rem;
  font-size: 1rem;
  color: #a7abc3;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-icon {
  padding-right: 0.75rem;
  font-size: 1.3rem;
  color: #a7abc3;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar {
  display: flex;
  align-items: center;
  align-content: flex-end;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar .nav-pills,
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar .nav-tabs {
  margin: 0;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar .kt-portlet__head-wrapper {
  display: flex;
  align-items: center;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-progress {
  position: absolute;
  left: 0;
  right: 0;
}
.kt-portlet.kt-portlet--head-lg .kt-portlet__head {
  min-height: 80px;
}
.kt-portlet.kt-portlet--head-xl .kt-portlet__head {
  min-height: 100px;
}
.kt-portlet.kt-portlet--sticky .kt-portlet__head {
  height: 50px;
  min-height: 50px;
}
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--lg {
  height: 70px;
  min-height: 70px;
}
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--xl {
  height: 90px;
  min-height: 90px;
}
.kt-portlet.kt-portlet--head-overlay .kt-portlet__head {
  position: relative;
  z-index: 2;
  border: 0;
  height: 60px;
}
.kt-portlet.kt-portlet--head-overlay .kt-portlet__body {
  margin-top: -60px;
}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-lg .kt-portlet__head {
  height: 80px;
}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-lg .kt-portlet__body {
  margin-top: -80px;
}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-xl .kt-portlet__head {
  height: 100px;
}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-xl .kt-portlet__body {
  margin-top: -100px;
}
.kt-portlet .kt-portlet__body {
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 4px;
}
.kt-portlet .kt-portlet__body .kt-portlet__content {
  padding: 0;
  margin: 0;
}
.kt-portlet .kt-portlet__foot {
  padding: 25px;
  border-top: 1px solid #ebedf2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.kt-portlet .kt-portlet__foot .kt-portlet__foot-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--sm {
  padding-top: 10px;
  padding-bottom: 10px;
}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--md {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--no-border {
  border-top: 0;
}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--top {
  border-top: 0;
  border-bottom: 1px solid #ebedf2;
}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--solid {
  background-color: #f7f8fa;
  border-top: 0;
}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--solid.kt-portlet__foot--top {
  border-bottom: 0;
}
.kt-portlet.kt-portlet--bordered {
  box-shadow: none;
  border: 1px solid #ebedf2;
}
.kt-portlet.kt-portlet--unelevate {
  box-shadow: none;
}
.kt-portlet.kt-portlet--unround .kt-portlet__head {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.kt-portlet.kt-portlet--unround .kt-portlet__body,
.kt-portlet.kt-portlet--unround .kt-portlet__foot {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.kt-portlet.kt-portlet--marginless {
  margin-bottom: 0;
}
.kt-portlet .kt-portlet__body.kt-portlet__body--center {
  justify-content: center;
  align-items: center;
}
.kt-portlet .kt-portlet__body.kt-portlet__body--center-x {
  justify-content: center;
}
.kt-portlet .kt-portlet__body.kt-portlet__body--center-y {
  align-items: center;
}
.kt-portlet .kt-portlet__body .kt-portlet__body--hor-fit {
  margin-left: -25px;
  margin-right: -25px;
}
.kt-portlet .kt-portlet__body.kt-portlet__body--stick-bottom {
  position: relative;
  bottom: 0;
}
.kt-portlet .kt-portlet__body.kt-portlet__body--fluid {
  height: 100%;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
}
.kt-portlet .kt-portlet__body.kt-portlet__body--fill {
  flex-grow: 1;
}
.kt-portlet .kt-portlet__body.kt-portlet__body--fullheight {
  flex-grow: auto;
}
.kt-portlet.kt-portlet--fit .kt-portlet__body,
.kt-portlet.kt-portlet--fit .kt-portlet__foot,
.kt-portlet.kt-portlet--fit .kt-portlet__head {
  padding: 0;
}
.kt-portlet.kt-portlet--fit.kt-portlet--height-fluid-half {
  align-items: stretch;
}
.kt-portlet.kt-portlet--fit.kt-portlet--height-fluid-half .kt-portlet__body {
  height: 100%;
  flex-direction: column;
  align-items: stretch;
}
.kt-portlet.kt-portlet--contain {
  overflow: hidden;
}
.kt-portlet .kt-portlet__body.kt-portlet__body--fit,
.kt-portlet .kt-portlet__head.kt-portlet__head--fit {
  padding: 0;
}
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-x,
.kt-portlet .kt-portlet__body.kt-portlet__body--hor-fit {
  padding-left: 0;
  padding-right: 0;
}
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-y {
  padding-top: 0;
  padding-bottom: 0;
}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--fit {
  padding: 0;
}
.kt-portlet.kt-portlet--space {
  padding-left: 25px;
  padding-right: 25px;
}
.kt-portlet.kt-portlet--space .kt-portlet__body,
.kt-portlet.kt-portlet--space .kt-portlet__foot,
.kt-portlet.kt-portlet--space .kt-portlet__head {
  padding-left: 0;
  padding-right: 0;
}
.kt-portlet.kt-portlet--head-noborder .kt-portlet__head {
  border-bottom: 0;
}
.kt-portlet.kt-portlet--head-noborder .kt-portlet__body {
  padding-top: 12.5px;
}
.kt-portlet.kt-portlet--tabs .kt-portlet__head .kt-portlet__head-toolbar {
  display: flex;
  align-items: stretch;
}
.kt-portlet.kt-portlet--tabs-border-3x .kt-portlet__head {
  border-bottom: 3px solid #f7f8fa;
}
.kt-portlet.kt-portlet--tabs-border-3x .kt-portlet__head .nav.nav-tabs {
  margin-bottom: -3px;
}
.kt-portlet.kt-portlet--solid-brand {
  background: #5d78ff;
}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head {
  color: #fff;
  border-bottom: 1px solid transparent;
}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-title {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-icon i,
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0;
}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__body {
  padding-top: 5px;
}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__body,
.kt-portlet.kt-portlet--solid-brand .kt-portlet__wrapper {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__foot {
  color: #fff;
  border-top: 1px solid transparent;
  background: #3e5fff;
}
.kt-portlet.kt-portlet--tabs-border-3x-brand .kt-portlet__head {
  border-bottom: 3px solid rgba(93, 120, 255, 0.1);
}
.kt-portlet.kt-portlet--border-bottom-brand {
  border-bottom: 3px solid rgba(93, 120, 255, 0.2);
}
.kt-portlet.kt-portlet--solid-light {
  background: #fff;
}
.kt-portlet.kt-portlet--solid-light .kt-portlet__head {
  color: #282a3c;
  border-bottom: 1px solid transparent;
}
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-title {
  color: #282a3c;
}
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-icon i,
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-title small {
  color: #1c1d2a;
}
.kt-portlet.kt-portlet--solid-light .kt-portlet__body {
  padding-top: 5px;
}
.kt-portlet.kt-portlet--solid-light .kt-portlet__body,
.kt-portlet.kt-portlet--solid-light .kt-portlet__wrapper {
  color: #282a3c;
}
.kt-portlet.kt-portlet--solid-light .kt-portlet__foot {
  color: #282a3c;
  border-top: 1px solid transparent;
  background: #f0f0f0;
}
.kt-portlet.kt-portlet--tabs-border-3x-light .kt-portlet__head {
  border-bottom: 3px solid hsla(0, 0%, 100%, 0.1);
}
.kt-portlet.kt-portlet--border-bottom-light {
  border-bottom: 3px solid hsla(0, 0%, 100%, 0.2);
}
.kt-portlet.kt-portlet--solid-dark {
  background: #282a3c;
}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head {
  color: #fff;
  border-bottom: 1px solid transparent;
}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-title {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-icon i,
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0;
}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__body {
  padding-top: 5px;
}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__body,
.kt-portlet.kt-portlet--solid-dark .kt-portlet__wrapper {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__foot {
  color: #fff;
  border-top: 1px solid transparent;
  background: #1c1d2a;
}
.kt-portlet.kt-portlet--tabs-border-3x-dark .kt-portlet__head {
  border-bottom: 3px solid rgba(40, 42, 60, 0.1);
}
.kt-portlet.kt-portlet--border-bottom-dark {
  border-bottom: 3px solid rgba(40, 42, 60, 0.2);
}
.kt-portlet.kt-portlet--solid-primary {
  background: #5867dd;
}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head {
  color: #fff;
  border-bottom: 1px solid transparent;
}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-title {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-icon i,
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0;
}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__body {
  padding-top: 5px;
}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__body,
.kt-portlet.kt-portlet--solid-primary .kt-portlet__wrapper {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__foot {
  color: #fff;
  border-top: 1px solid transparent;
  background: #3f50d8;
}
.kt-portlet.kt-portlet--tabs-border-3x-primary .kt-portlet__head {
  border-bottom: 3px solid rgba(88, 103, 221, 0.1);
}
.kt-portlet.kt-portlet--border-bottom-primary {
  border-bottom: 3px solid rgba(88, 103, 221, 0.2);
}
.kt-portlet.kt-portlet--solid-success {
  background: #0abb87;
}
.kt-portlet.kt-portlet--solid-success .kt-portlet__head {
  color: #fff;
  border-bottom: 1px solid transparent;
}
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-title {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-icon i,
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0;
}
.kt-portlet.kt-portlet--solid-success .kt-portlet__body {
  padding-top: 5px;
}
.kt-portlet.kt-portlet--solid-success .kt-portlet__body,
.kt-portlet.kt-portlet--solid-success .kt-portlet__wrapper {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-success .kt-portlet__foot {
  color: #fff;
  border-top: 1px solid transparent;
  background: #089e72;
}
.kt-portlet.kt-portlet--tabs-border-3x-success .kt-portlet__head {
  border-bottom: 3px solid rgba(10, 187, 135, 0.1);
}
.kt-portlet.kt-portlet--border-bottom-success {
  border-bottom: 3px solid rgba(10, 187, 135, 0.2);
}
.kt-portlet.kt-portlet--solid-info {
  background: #5578eb;
}
.kt-portlet.kt-portlet--solid-info .kt-portlet__head {
  color: #fff;
  border-bottom: 1px solid transparent;
}
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-title {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-icon i,
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0;
}
.kt-portlet.kt-portlet--solid-info .kt-portlet__body {
  padding-top: 5px;
}
.kt-portlet.kt-portlet--solid-info .kt-portlet__body,
.kt-portlet.kt-portlet--solid-info .kt-portlet__wrapper {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-info .kt-portlet__foot {
  color: #fff;
  border-top: 1px solid transparent;
  background: #3a62e8;
}
.kt-portlet.kt-portlet--tabs-border-3x-info .kt-portlet__head {
  border-bottom: 3px solid rgba(85, 120, 235, 0.1);
}
.kt-portlet.kt-portlet--border-bottom-info {
  border-bottom: 3px solid rgba(85, 120, 235, 0.2);
}
.kt-portlet.kt-portlet--solid-warning {
  background: #ffb822;
}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head {
  color: #111;
  border-bottom: 1px solid transparent;
}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-title {
  color: #111;
}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-icon i,
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-title small {
  color: #020202;
}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__body {
  padding-top: 5px;
}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__body,
.kt-portlet.kt-portlet--solid-warning .kt-portlet__wrapper {
  color: #111;
}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__foot {
  color: #111;
  border-top: 1px solid transparent;
  background: #ffae03;
}
.kt-portlet.kt-portlet--tabs-border-3x-warning .kt-portlet__head {
  border-bottom: 3px solid rgba(255, 184, 34, 0.1);
}
.kt-portlet.kt-portlet--border-bottom-warning {
  border-bottom: 3px solid rgba(255, 184, 34, 0.2);
}
.kt-portlet.kt-portlet--solid-danger {
  background: #fd397a;
}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head {
  color: #fff;
  border-bottom: 1px solid transparent;
}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-title {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-icon i,
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0;
}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__body {
  padding-top: 5px;
}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__body,
.kt-portlet.kt-portlet--solid-danger .kt-portlet__wrapper {
  color: #fff;
}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__foot {
  color: #fff;
  border-top: 1px solid transparent;
  background: #fd1b66;
}
.kt-portlet.kt-portlet--tabs-border-3x-danger .kt-portlet__head {
  border-bottom: 3px solid rgba(253, 57, 122, 0.1);
}
.kt-portlet.kt-portlet--border-bottom-danger {
  border-bottom: 3px solid rgba(253, 57, 122, 0.2);
}
.kt-portlet.kt-portlet--sortable .kt-portlet__head {
  cursor: move;
}
.kt-portlet.kt-portlet--sortable-empty {
  visibility: hidden;
  height: 45px;
  min-height: 125px;
}
.kt-portlet .kt-portlet__head {
  transition: left 0.3s, right 0.3s, height 0.3s;
}
.kt-portlet.kt-portlet--sticky .kt-portlet__head {
  transition: left 0.3s, right 0.3s, height 0.3s;
  position: fixed;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  z-index: 101;
  background: #fff;
}
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  color: #fff;
}
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-icon,
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title small {
  color: hsla(0, 0%, 100%, 0.8);
}
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head:not(.kt-portlet__head--noborder) {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
}
.kt-portlet.kt-portlet--skin-solid .kt-portlet__body {
  color: #fff;
}
.kt-portlet .kt-portlet__space-x {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.kt-portlet .kt-portlet__space-y {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.kt-portlet.kt-portlet--collapsed > .kt-form,
.kt-portlet.kt-portlet--collapsed > .kt-portlet__body {
  display: none;
}
.kt-form .kt-form__group.kt-form__group--inline {
  display: table;
  padding: 0;
}
.kt-form .kt-form__group.kt-form__group--inline > .kt-form__label {
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  padding-right: 1rem;
}
.kt-form .kt-form__group.kt-form__group--inline > .kt-form__label.kt-form__label-no-wrap {
  white-space: nowrap;
}
.kt-form .kt-form__group.kt-form__group--inline > .kt-form__label > label {
  margin: 0;
}
.kt-form .kt-form__group.kt-form__group--inline > .kt-form__control {
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  width: 100%;
}
.kt-form .kt-form__actions.kt-form__actions--right {
  text-align: right;
}
.kt-form .kt-form__actions.kt-form__actions--center {
  text-align: center;
}
.kt-input-icon {
  position: relative;
  padding: 0;
  width: 100%;
}
.kt-input-icon > .kt-input-icon__icon {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  width: 3.2rem;
}
.kt-input-icon > .kt-input-icon__icon.kt-input-icon__icon--left {
  left: 0;
}
.kt-input-icon > .kt-input-icon__icon.kt-input-icon__icon--right {
  right: 0;
}
.kt-input-icon > .kt-input-icon__icon > span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.kt-input-icon > .kt-input-icon__icon > span i {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: #a7abc3;
}
.kt-input-icon > .kt-input-icon__icon [class^='la-'] {
  font-size: 1.4em;
}
.kt-input-icon > .kt-input-icon__icon [class^='fa-'] {
  font-size: 1.2em;
}
.kt-input-icon > .kt-input-icon__icon [class^='flaticon-'] {
  font-size: 1.3em;
}
.kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon {
  width: 4.2rem;
}
.kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class^='la-'] {
  font-size: 1.6em;
}
.kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class^='fa-'] {
  font-size: 1.3em;
}
.kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class^='flaticon-'] {
  font-size: 1.4em;
}
.kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon {
  width: 2.7rem;
}
.kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class^='la-'] {
  font-size: 1.1em;
}
.kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class^='fa-'],
.kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class^='flaticon-'] {
  font-size: 1em;
}
.kt-input-icon.kt-input-icon--left .form-control {
  padding-left: 2.8rem;
}
.kt-input-icon.kt-input-icon--left .form-control.form-control-lg {
  padding-left: 3.6rem;
}
.kt-input-icon.kt-input-icon--left .form-control.form-control-sm {
  padding-left: 2.5rem;
}
.kt-input-icon.kt-input-icon--right .form-control {
  padding-right: 2.8rem;
}
.kt-input-icon.kt-input-icon--right .form-control.form-control-lg {
  padding-right: 3.6rem;
}
.kt-input-icon.kt-input-icon--right .form-control.form-control-sm {
  padding-right: 2.5rem;
}
.kt-checkbox,
.kt-input-icon.kt-input-icon--fixed,
.kt-input-icon.kt-input-icon--fixed-large,
.kt-input-icon.kt-input-icon--fixed-small {
  display: inline-block;
}
.kt-checkbox {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}
.kt-checkbox.kt-checkbox--disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.kt-checkbox > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.kt-checkbox > span {
  border-radius: 3px;
  background: none;
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
}
.kt-checkbox > span:after {
  content: '';
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  margin-left: -2px;
  margin-top: -6px;
  width: 5px;
  height: 10px;
  border-width: 0 0 2px 2px !important;
  transform: rotate(-45deg);
}
.kt-checkbox > input:checked ~ span {
  transition: all 0.3s ease;
  background: none;
}
.kt-checkbox > input:checked ~ span:after {
  display: block;
}
.kt-checkbox:hover > input:not([disabled]):checked ~ span,
.kt-checkbox > input:checked ~ span {
  transition: all 0.3s ease;
}
.kt-checkbox > input:disabled ~ span {
  pointer-events: none;
}
.kt-checkbox.kt-checkbox--solid > span {
  border: 1px solid transparent;
}
.kt-checkbox.kt-checkbox--solid:hover > input:not([disabled]) ~ span,
.kt-checkbox.kt-checkbox--solid > input:focus ~ span {
  transition: all 0.3s ease;
}
.kt-checkbox.kt-checkbox--square > span {
  border-radius: 0;
}
.kt-checkbox.kt-checkbox--bold > span {
  border-width: 2px !important;
  transition: all 0.3s ease;
}
.kt-checkbox.kt-checkbox--single {
  width: 18px;
  height: 18px;
}
.kt-checkbox.kt-checkbox--single > span {
  top: 0;
}
.kt-checkbox-list {
  padding: 0;
}
.kt-checkbox-list .kt-checkbox {
  text-align: left;
  display: block;
}
.kt-checkbox-list .kt-checkbox:last-child {
  margin-bottom: 5px;
}
.kt-checkbox-inline {
  padding: 0;
}
.kt-checkbox-inline .kt-checkbox {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
}
.kt-checkbox-inline .kt-checkbox:last-child {
  margin-right: 0;
}
.kt-checkbox.kt-checkbox--disabled {
  opacity: 0.7;
}
.kt-checkbox > span {
  border: 1px solid #d1d7e2;
}
.kt-checkbox > span:after {
  border: solid #bfc7d7;
}
.kt-checkbox > input:disabled ~ span:after {
  border-color: #c8cfdd;
}
.kt-checkbox > input:checked ~ span {
  border: 1px solid #c8cfdd;
}
.kt-checkbox.kt-checkbox--bold > input:checked ~ span {
  border: 2px solid #c8cfdd;
}
.kt-checkbox > input:disabled ~ span {
  opacity: 0.6;
}
.kt-checkbox.kt-checkbox--solid > span {
  background: #e4e8ee;
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--solid > span:after {
  border: solid #99a6bf;
}
.kt-checkbox.kt-checkbox--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--solid > input:checked ~ span {
  background: #dee2ea;
}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--disabled {
  opacity: 0.7;
}
.kt-checkbox.kt-checkbox--brand > span {
  border: 1px solid #5d78ff;
}
.kt-checkbox.kt-checkbox--brand > span:after {
  border: solid #5d78ff;
}
.kt-checkbox.kt-checkbox--brand > input:disabled ~ span:after {
  border-color: #5d78ff;
}
.kt-checkbox.kt-checkbox--brand > input:checked ~ span {
  border: 1px solid #5d78ff;
}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--bold > input:checked ~ span {
  border: 2px solid #5d78ff;
}
.kt-checkbox.kt-checkbox--brand > input:disabled ~ span {
  opacity: 0.6;
}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--solid > span {
  background: #5d78ff;
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--solid > span:after {
  border: solid #fff;
}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--solid > input:checked ~ span {
  background: #5d78ff;
}
.kt-checkbox.kt-checkbox--light.kt-checkbox--disabled {
  opacity: 0.7;
}
.kt-checkbox.kt-checkbox--light > span {
  border: 1px solid #fff;
}
.kt-checkbox.kt-checkbox--light > span:after {
  border: solid #fff;
}
.kt-checkbox.kt-checkbox--light > input:disabled ~ span:after {
  border-color: #fff;
}
.kt-checkbox.kt-checkbox--light > input:checked ~ span {
  border: 1px solid #fff;
}
.kt-checkbox.kt-checkbox--light.kt-checkbox--bold > input:checked ~ span {
  border: 2px solid #fff;
}
.kt-checkbox.kt-checkbox--light > input:disabled ~ span {
  opacity: 0.6;
}
.kt-checkbox.kt-checkbox--light.kt-checkbox--solid > span {
  background: #fff;
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--light.kt-checkbox--solid > span:after {
  border: solid #282a3c;
}
.kt-checkbox.kt-checkbox--light.kt-checkbox--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--light.kt-checkbox--solid > input:checked ~ span {
  background: #fff;
}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--disabled {
  opacity: 0.7;
}
.kt-checkbox.kt-checkbox--dark > span {
  border: 1px solid #282a3c;
}
.kt-checkbox.kt-checkbox--dark > span:after {
  border: solid #282a3c;
}
.kt-checkbox.kt-checkbox--dark > input:disabled ~ span:after {
  border-color: #282a3c;
}
.kt-checkbox.kt-checkbox--dark > input:checked ~ span {
  border: 1px solid #282a3c;
}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--bold > input:checked ~ span {
  border: 2px solid #282a3c;
}
.kt-checkbox.kt-checkbox--dark > input:disabled ~ span {
  opacity: 0.6;
}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--solid > span {
  background: #282a3c;
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--solid > span:after {
  border: solid #fff;
}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--solid > input:checked ~ span {
  background: #282a3c;
}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--disabled {
  opacity: 0.7;
}
.kt-checkbox.kt-checkbox--primary > span {
  border: 1px solid #5867dd;
}
.kt-checkbox.kt-checkbox--primary > span:after {
  border: solid #5867dd;
}
.kt-checkbox.kt-checkbox--primary > input:disabled ~ span:after {
  border-color: #5867dd;
}
.kt-checkbox.kt-checkbox--primary > input:checked ~ span {
  border: 1px solid #5867dd;
}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--bold > input:checked ~ span {
  border: 2px solid #5867dd;
}
.kt-checkbox.kt-checkbox--primary > input:disabled ~ span {
  opacity: 0.6;
}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--solid > span {
  background: #5867dd;
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--solid > span:after {
  border: solid #fff;
}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--solid > input:checked ~ span {
  background: #5867dd;
}
.kt-checkbox.kt-checkbox--success.kt-checkbox--disabled {
  opacity: 0.7;
}
.kt-checkbox.kt-checkbox--success > span {
  border: 1px solid #0abb87;
}
.kt-checkbox.kt-checkbox--success > span:after {
  border: solid #0abb87;
}
.kt-checkbox.kt-checkbox--success > input:disabled ~ span:after {
  border-color: #0abb87;
}
.kt-checkbox.kt-checkbox--success > input:checked ~ span {
  border: 1px solid #0abb87;
}
.kt-checkbox.kt-checkbox--success.kt-checkbox--bold > input:checked ~ span {
  border: 2px solid #0abb87;
}
.kt-checkbox.kt-checkbox--success > input:disabled ~ span {
  opacity: 0.6;
}
.kt-checkbox.kt-checkbox--success.kt-checkbox--solid > span {
  background: #0abb87;
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--success.kt-checkbox--solid > span:after {
  border: solid #fff;
}
.kt-checkbox.kt-checkbox--success.kt-checkbox--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--success.kt-checkbox--solid > input:checked ~ span {
  background: #0abb87;
}
.kt-checkbox.kt-checkbox--info.kt-checkbox--disabled {
  opacity: 0.7;
}
.kt-checkbox.kt-checkbox--info > span {
  border: 1px solid #5578eb;
}
.kt-checkbox.kt-checkbox--info > span:after {
  border: solid #5578eb;
}
.kt-checkbox.kt-checkbox--info > input:disabled ~ span:after {
  border-color: #5578eb;
}
.kt-checkbox.kt-checkbox--info > input:checked ~ span {
  border: 1px solid #5578eb;
}
.kt-checkbox.kt-checkbox--info.kt-checkbox--bold > input:checked ~ span {
  border: 2px solid #5578eb;
}
.kt-checkbox.kt-checkbox--info > input:disabled ~ span {
  opacity: 0.6;
}
.kt-checkbox.kt-checkbox--info.kt-checkbox--solid > span {
  background: #5578eb;
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--info.kt-checkbox--solid > span:after {
  border: solid #fff;
}
.kt-checkbox.kt-checkbox--info.kt-checkbox--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--info.kt-checkbox--solid > input:checked ~ span {
  background: #5578eb;
}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--disabled {
  opacity: 0.7;
}
.kt-checkbox.kt-checkbox--warning > span {
  border: 1px solid #ffb822;
}
.kt-checkbox.kt-checkbox--warning > span:after {
  border: solid #ffb822;
}
.kt-checkbox.kt-checkbox--warning > input:disabled ~ span:after {
  border-color: #ffb822;
}
.kt-checkbox.kt-checkbox--warning > input:checked ~ span {
  border: 1px solid #ffb822;
}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--bold > input:checked ~ span {
  border: 2px solid #ffb822;
}
.kt-checkbox.kt-checkbox--warning > input:disabled ~ span {
  opacity: 0.6;
}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--solid > span {
  background: #ffb822;
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--solid > span:after {
  border: solid #111;
}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--solid > input:checked ~ span {
  background: #ffb822;
}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--disabled {
  opacity: 0.7;
}
.kt-checkbox.kt-checkbox--danger > span {
  border: 1px solid #fd397a;
}
.kt-checkbox.kt-checkbox--danger > span:after {
  border: solid #fd397a;
}
.kt-checkbox.kt-checkbox--danger > input:disabled ~ span:after {
  border-color: #fd397a;
}
.kt-checkbox.kt-checkbox--danger > input:checked ~ span {
  border: 1px solid #fd397a;
}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--bold > input:checked ~ span {
  border: 2px solid #fd397a;
}
.kt-checkbox.kt-checkbox--danger > input:disabled ~ span {
  opacity: 0.6;
}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--solid > span {
  background: #fd397a;
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--solid > span:after {
  border: solid #fff;
}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--solid > input:checked ~ span {
  background: #fd397a;
}
.kt-radio {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  text-align: left;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}
.kt-radio.kt-radio--disabled {
  cursor: not-allowed;
}
.kt-radio > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.kt-radio > span {
  background: none;
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50% !important;
}
.kt-radio > span:after {
  content: '';
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  border-radius: 100% !important;
}
.kt-radio > input:checked ~ span {
  transition: all 0.3s ease;
  background: none;
}
.kt-radio > input:checked ~ span:after {
  display: block;
}
.kt-radio:hover > input:not([disabled]):checked ~ span,
.kt-radio > input:checked ~ span {
  transition: all 0.3s ease;
}
.kt-radio > input:disabled ~ span {
  pointer-events: none;
}
.kt-radio.kt-radio--solid > span {
  border: 1px solid transparent;
}
.kt-radio.kt-radio--solid:hover > input:not([disabled]) ~ span,
.kt-radio.kt-radio--solid > input:focus ~ span {
  transition: all 0.3s ease;
}
.kt-radio.kt-radio--square > span {
  border-radius: 0;
}
.kt-radio.kt-radio--bold > span {
  border-width: 2px !important;
  transition: all 0.3s ease;
}
.kt-radio.kt-radio--single {
  width: 18px;
  height: 18px;
}
.kt-radio.kt-radio--single > span {
  top: 0;
}
.kt-radio-list {
  padding: 0;
}
.kt-radio-list .kt-radio {
  display: block;
  text-align: left;
}
.kt-radio-list .kt-radio:last-child {
  margin-bottom: 5px;
}
.kt-radio-inline {
  padding: 0;
}
.kt-radio-inline .kt-radio {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
}
.kt-radio-inline .kt-radio:last-child {
  margin-right: 0;
}
.kt-radio.kt-radio--disabled {
  opacity: 0.8;
}
.kt-radio > span {
  border: 1px solid #d1d7e2;
}
.kt-radio > span:after {
  border: solid #bfc7d7;
  background: #bfc7d7;
}
.kt-radio > input:disabled ~ span:after {
  border-color: #c8cfdd;
}
.kt-radio > input:checked ~ span {
  border: 1px solid #c8cfdd;
}
.kt-radio.kt-radio--bold > input:checked ~ span {
  border: 2px solid #c8cfdd;
}
.kt-radio > input:disabled ~ span {
  opacity: 0.6;
}
.kt-radio.kt-radio--solid > span {
  background: #e4e8ee;
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--solid > span:after {
  border: solid #99a6bf;
  background: #99a6bf;
}
.kt-radio.kt-radio--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--solid > input:checked ~ span {
  background: #dee2ea;
}
.kt-radio.kt-radio--brand.kt-radio--disabled {
  opacity: 0.8;
}
.kt-radio.kt-radio--brand > span {
  border: 1px solid #5d78ff;
}
.kt-radio.kt-radio--brand > span:after {
  border: solid #5d78ff;
  background: #5d78ff;
}
.kt-radio.kt-radio--brand > input:disabled ~ span:after {
  border-color: #5d78ff;
}
.kt-radio.kt-radio--brand > input:checked ~ span {
  border: 1px solid #5d78ff;
}
.kt-radio.kt-radio--brand.kt-radio--bold > input:checked ~ span {
  border: 2px solid #5d78ff;
}
.kt-radio.kt-radio--brand > input:disabled ~ span {
  opacity: 0.6;
}
.kt-radio.kt-radio--brand.kt-radio--solid > span {
  background: #5d78ff;
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--brand.kt-radio--solid > span:after {
  border: solid #fff;
  background: #fff;
}
.kt-radio.kt-radio--brand.kt-radio--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--brand.kt-radio--solid > input:checked ~ span {
  background: #5d78ff;
}
.kt-radio.kt-radio--light.kt-radio--disabled {
  opacity: 0.8;
}
.kt-radio.kt-radio--light > span {
  border: 1px solid #fff;
}
.kt-radio.kt-radio--light > span:after {
  border: solid #fff;
  background: #fff;
}
.kt-radio.kt-radio--light > input:disabled ~ span:after {
  border-color: #fff;
}
.kt-radio.kt-radio--light > input:checked ~ span {
  border: 1px solid #fff;
}
.kt-radio.kt-radio--light.kt-radio--bold > input:checked ~ span {
  border: 2px solid #fff;
}
.kt-radio.kt-radio--light > input:disabled ~ span {
  opacity: 0.6;
}
.kt-radio.kt-radio--light.kt-radio--solid > span {
  background: #fff;
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--light.kt-radio--solid > span:after {
  border: solid #282a3c;
  background: #282a3c;
}
.kt-radio.kt-radio--light.kt-radio--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--light.kt-radio--solid > input:checked ~ span {
  background: #fff;
}
.kt-radio.kt-radio--dark.kt-radio--disabled {
  opacity: 0.8;
}
.kt-radio.kt-radio--dark > span {
  border: 1px solid #282a3c;
}
.kt-radio.kt-radio--dark > span:after {
  border: solid #282a3c;
  background: #282a3c;
}
.kt-radio.kt-radio--dark > input:disabled ~ span:after {
  border-color: #282a3c;
}
.kt-radio.kt-radio--dark > input:checked ~ span {
  border: 1px solid #282a3c;
}
.kt-radio.kt-radio--dark.kt-radio--bold > input:checked ~ span {
  border: 2px solid #282a3c;
}
.kt-radio.kt-radio--dark > input:disabled ~ span {
  opacity: 0.6;
}
.kt-radio.kt-radio--dark.kt-radio--solid > span {
  background: #282a3c;
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--dark.kt-radio--solid > span:after {
  border: solid #fff;
  background: #fff;
}
.kt-radio.kt-radio--dark.kt-radio--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--dark.kt-radio--solid > input:checked ~ span {
  background: #282a3c;
}
.kt-radio.kt-radio--primary.kt-radio--disabled {
  opacity: 0.8;
}
.kt-radio.kt-radio--primary > span {
  border: 1px solid #5867dd;
}
.kt-radio.kt-radio--primary > span:after {
  border: solid #5867dd;
  background: #5867dd;
}
.kt-radio.kt-radio--primary > input:disabled ~ span:after {
  border-color: #5867dd;
}
.kt-radio.kt-radio--primary > input:checked ~ span {
  border: 1px solid #5867dd;
}
.kt-radio.kt-radio--primary.kt-radio--bold > input:checked ~ span {
  border: 2px solid #5867dd;
}
.kt-radio.kt-radio--primary > input:disabled ~ span {
  opacity: 0.6;
}
.kt-radio.kt-radio--primary.kt-radio--solid > span {
  background: #5867dd;
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--primary.kt-radio--solid > span:after {
  border: solid #fff;
  background: #fff;
}
.kt-radio.kt-radio--primary.kt-radio--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--primary.kt-radio--solid > input:checked ~ span {
  background: #5867dd;
}
.kt-radio.kt-radio--success.kt-radio--disabled {
  opacity: 0.8;
}
.kt-radio.kt-radio--success > span {
  border: 1px solid #0abb87;
}
.kt-radio.kt-radio--success > span:after {
  border: solid #0abb87;
  background: #0abb87;
}
.kt-radio.kt-radio--success > input:disabled ~ span:after {
  border-color: #0abb87;
}
.kt-radio.kt-radio--success > input:checked ~ span {
  border: 1px solid #0abb87;
}
.kt-radio.kt-radio--success.kt-radio--bold > input:checked ~ span {
  border: 2px solid #0abb87;
}
.kt-radio.kt-radio--success > input:disabled ~ span {
  opacity: 0.6;
}
.kt-radio.kt-radio--success.kt-radio--solid > span {
  background: #0abb87;
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--success.kt-radio--solid > span:after {
  border: solid #fff;
  background: #fff;
}
.kt-radio.kt-radio--success.kt-radio--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--success.kt-radio--solid > input:checked ~ span {
  background: #0abb87;
}
.kt-radio.kt-radio--info.kt-radio--disabled {
  opacity: 0.8;
}
.kt-radio.kt-radio--info > span {
  border: 1px solid #5578eb;
}
.kt-radio.kt-radio--info > span:after {
  border: solid #5578eb;
  background: #5578eb;
}
.kt-radio.kt-radio--info > input:disabled ~ span:after {
  border-color: #5578eb;
}
.kt-radio.kt-radio--info > input:checked ~ span {
  border: 1px solid #5578eb;
}
.kt-radio.kt-radio--info.kt-radio--bold > input:checked ~ span {
  border: 2px solid #5578eb;
}
.kt-radio.kt-radio--info > input:disabled ~ span {
  opacity: 0.6;
}
.kt-radio.kt-radio--info.kt-radio--solid > span {
  background: #5578eb;
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--info.kt-radio--solid > span:after {
  border: solid #fff;
  background: #fff;
}
.kt-radio.kt-radio--info.kt-radio--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--info.kt-radio--solid > input:checked ~ span {
  background: #5578eb;
}
.kt-radio.kt-radio--warning.kt-radio--disabled {
  opacity: 0.8;
}
.kt-radio.kt-radio--warning > span {
  border: 1px solid #ffb822;
}
.kt-radio.kt-radio--warning > span:after {
  border: solid #ffb822;
  background: #ffb822;
}
.kt-radio.kt-radio--warning > input:disabled ~ span:after {
  border-color: #ffb822;
}
.kt-radio.kt-radio--warning > input:checked ~ span {
  border: 1px solid #ffb822;
}
.kt-radio.kt-radio--warning.kt-radio--bold > input:checked ~ span {
  border: 2px solid #ffb822;
}
.kt-radio.kt-radio--warning > input:disabled ~ span {
  opacity: 0.6;
}
.kt-radio.kt-radio--warning.kt-radio--solid > span {
  background: #ffb822;
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--warning.kt-radio--solid > span:after {
  border: solid #111;
  background: #111;
}
.kt-radio.kt-radio--warning.kt-radio--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--warning.kt-radio--solid > input:checked ~ span {
  background: #ffb822;
}
.kt-radio.kt-radio--danger.kt-radio--disabled {
  opacity: 0.8;
}
.kt-radio.kt-radio--danger > span {
  border: 1px solid #fd397a;
}
.kt-radio.kt-radio--danger > span:after {
  border: solid #fd397a;
  background: #fd397a;
}
.kt-radio.kt-radio--danger > input:disabled ~ span:after {
  border-color: #fd397a;
}
.kt-radio.kt-radio--danger > input:checked ~ span {
  border: 1px solid #fd397a;
}
.kt-radio.kt-radio--danger.kt-radio--bold > input:checked ~ span {
  border: 2px solid #fd397a;
}
.kt-radio.kt-radio--danger > input:disabled ~ span {
  opacity: 0.6;
}
.kt-radio.kt-radio--danger.kt-radio--solid > span {
  background: #fd397a;
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--danger.kt-radio--solid > span:after {
  border: solid #fff;
  background: #fff;
}
.kt-radio.kt-radio--danger.kt-radio--solid > input:focus ~ span {
  border: 1px solid transparent !important;
}
.kt-radio.kt-radio--danger.kt-radio--solid > input:checked ~ span {
  background: #fd397a;
}
.kt-switch {
  display: inline-block;
  font-size: 1rem;
}
.kt-switch input:empty {
  margin-left: -999px;
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  opacity: 0;
}
.kt-switch input:empty ~ span {
  display: inline-block;
  position: relative;
  float: left;
  width: 1px;
  text-indent: 0;
  cursor: pointer;
  user-select: none;
}
.kt-switch input:empty ~ span:after,
.kt-switch input:empty ~ span:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: ' ';
  transition: all 0.1s ease-in;
}
.kt-switch.kt-switch--icon input:empty ~ span:after {
  font-family: LineAwesome;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  content: '';
}
.kt-switch.kt-switch--icon input:checked ~ span:after {
  content: '\f17b';
}
.kt-switch.kt-switch--icon-check input:checked ~ span:after {
  font-family: LineAwesome;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  content: '';
}
.kt-switch input:empty ~ span {
  line-height: 30px;
  margin: 2px 0;
  height: 30px;
  width: 57px;
  border-radius: 15px;
}
.kt-switch input:empty ~ span:after,
.kt-switch input:empty ~ span:before {
  width: 54px;
  border-radius: 15px;
}
.kt-switch input:empty ~ span:after {
  height: 24px;
  width: 24px;
  line-height: 26px;
  top: 3px;
  bottom: 3px;
  margin-left: 3px;
  font-size: 0.9em;
  text-align: center;
  vertical-align: middle;
}
.kt-switch input:checked ~ span:after {
  margin-left: 26px;
}
.kt-switch.kt-switch--lg input:empty ~ span {
  line-height: 40px;
  margin: 2px 0;
  height: 40px;
  width: 75px;
  border-radius: 20px;
}
.kt-switch.kt-switch--lg input:empty ~ span:after,
.kt-switch.kt-switch--lg input:empty ~ span:before {
  width: 72px;
  border-radius: 20px;
}
.kt-switch.kt-switch--lg input:empty ~ span:after {
  height: 34px;
  width: 34px;
  line-height: 34px;
  top: 3px;
  bottom: 3px;
  margin-left: 3px;
  font-size: 1em;
  text-align: center;
  vertical-align: middle;
}
.kt-switch.kt-switch--lg input:checked ~ span:after {
  margin-left: 34px;
}
.kt-switch.kt-switch--sm input:empty ~ span {
  line-height: 24px;
  margin: 2px 0;
  height: 24px;
  width: 40px;
  border-radius: 12px;
}
.kt-switch.kt-switch--sm input:empty ~ span:after,
.kt-switch.kt-switch--sm input:empty ~ span:before {
  width: 38px;
  border-radius: 12px;
}
.kt-switch.kt-switch--sm input:empty ~ span:after {
  height: 20px;
  width: 20px;
  line-height: 20px;
  top: 2px;
  bottom: 2px;
  margin-left: 2px;
  font-size: 0.8em;
  text-align: center;
  vertical-align: middle;
}
.kt-switch.kt-switch--sm input:checked ~ span:after {
  margin-left: 16px;
}
.kt-switch input:empty ~ span:before {
  background-color: #e8ebf1;
}
.kt-switch input:empty ~ span:after {
  color: #f8f9fb;
  background-color: #fff;
}
.kt-switch input:checked ~ span:before {
  background-color: #e8ebf1;
}
.kt-switch input:checked ~ span:after {
  background-color: #5d78ff;
  color: #fff;
}
.kt-switch input[disabled] {
  cursor: not-allowed;
}
.kt-switch input[disabled] ~ span:after,
.kt-switch input[disabled] ~ span:before {
  cursor: not-allowed;
  opacity: 0.7;
}
.kt-switch.kt-switch--brand:not(.kt-switch--outline) input:empty ~ span:before {
  background-color: #5d78ff;
}
.kt-switch.kt-switch--brand:not(.kt-switch--outline) input:empty ~ span:after {
  color: #5d78ff;
  background-color: #fff;
  opacity: 0.4;
}
.kt-switch.kt-switch--brand:not(.kt-switch--outline) input:checked ~ span:before {
  background-color: #5d78ff;
}
.kt-switch.kt-switch--brand:not(.kt-switch--outline) input:checked ~ span:after {
  opacity: 1;
}
.kt-switch.kt-switch--outline.kt-switch--brand input:empty ~ span:before {
  border: 2px solid #dee3eb;
  background-color: #e8ebf1;
}
.kt-switch.kt-switch--outline.kt-switch--brand input:empty ~ span:after {
  color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--brand input:checked ~ span:before {
  background-color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--brand input:checked ~ span:after {
  background-color: #5d78ff;
  opacity: 1;
}
.kt-switch.kt-switch--light:not(.kt-switch--outline) input:empty ~ span:before {
  background-color: #fff;
}
.kt-switch.kt-switch--light:not(.kt-switch--outline) input:empty ~ span:after {
  color: #fff;
  background-color: #282a3c;
  opacity: 0.4;
}
.kt-switch.kt-switch--light:not(.kt-switch--outline) input:checked ~ span:before {
  background-color: #fff;
}
.kt-switch.kt-switch--light:not(.kt-switch--outline) input:checked ~ span:after {
  opacity: 1;
}
.kt-switch.kt-switch--outline.kt-switch--light input:empty ~ span:before {
  border: 2px solid #dee3eb;
  background-color: #e8ebf1;
}
.kt-switch.kt-switch--outline.kt-switch--light input:empty ~ span:after {
  color: #282a3c;
}
.kt-switch.kt-switch--outline.kt-switch--light input:checked ~ span:before {
  background-color: #282a3c;
}
.kt-switch.kt-switch--outline.kt-switch--light input:checked ~ span:after {
  background-color: #fff;
  opacity: 1;
}
.kt-switch.kt-switch--dark:not(.kt-switch--outline) input:empty ~ span:before {
  background-color: #282a3c;
}
.kt-switch.kt-switch--dark:not(.kt-switch--outline) input:empty ~ span:after {
  color: #282a3c;
  background-color: #fff;
  opacity: 0.4;
}
.kt-switch.kt-switch--dark:not(.kt-switch--outline) input:checked ~ span:before {
  background-color: #282a3c;
}
.kt-switch.kt-switch--dark:not(.kt-switch--outline) input:checked ~ span:after {
  opacity: 1;
}
.kt-switch.kt-switch--outline.kt-switch--dark input:empty ~ span:before {
  border: 2px solid #dee3eb;
  background-color: #e8ebf1;
}
.kt-switch.kt-switch--outline.kt-switch--dark input:empty ~ span:after {
  color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--dark input:checked ~ span:before {
  background-color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--dark input:checked ~ span:after {
  background-color: #282a3c;
  opacity: 1;
}
.kt-switch.kt-switch--primary:not(.kt-switch--outline) input:empty ~ span:before {
  background-color: #5867dd;
}
.kt-switch.kt-switch--primary:not(.kt-switch--outline) input:empty ~ span:after {
  color: #5867dd;
  background-color: #fff;
  opacity: 0.4;
}
.kt-switch.kt-switch--primary:not(.kt-switch--outline) input:checked ~ span:before {
  background-color: #5867dd;
}
.kt-switch.kt-switch--primary:not(.kt-switch--outline) input:checked ~ span:after {
  opacity: 1;
}
.kt-switch.kt-switch--outline.kt-switch--primary input:empty ~ span:before {
  border: 2px solid #dee3eb;
  background-color: #e8ebf1;
}
.kt-switch.kt-switch--outline.kt-switch--primary input:empty ~ span:after {
  color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--primary input:checked ~ span:before {
  background-color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--primary input:checked ~ span:after {
  background-color: #5867dd;
  opacity: 1;
}
.kt-switch.kt-switch--success:not(.kt-switch--outline) input:empty ~ span:before {
  background-color: #0abb87;
}
.kt-switch.kt-switch--success:not(.kt-switch--outline) input:empty ~ span:after {
  color: #0abb87;
  background-color: #fff;
  opacity: 0.4;
}
.kt-switch.kt-switch--success:not(.kt-switch--outline) input:checked ~ span:before {
  background-color: #0abb87;
}
.kt-switch.kt-switch--success:not(.kt-switch--outline) input:checked ~ span:after {
  opacity: 1;
}
.kt-switch.kt-switch--outline.kt-switch--success input:empty ~ span:before {
  border: 2px solid #dee3eb;
  background-color: #e8ebf1;
}
.kt-switch.kt-switch--outline.kt-switch--success input:empty ~ span:after {
  color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--success input:checked ~ span:before {
  background-color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--success input:checked ~ span:after {
  background-color: #0abb87;
  opacity: 1;
}
.kt-switch.kt-switch--info:not(.kt-switch--outline) input:empty ~ span:before {
  background-color: #5578eb;
}
.kt-switch.kt-switch--info:not(.kt-switch--outline) input:empty ~ span:after {
  color: #5578eb;
  background-color: #fff;
  opacity: 0.4;
}
.kt-switch.kt-switch--info:not(.kt-switch--outline) input:checked ~ span:before {
  background-color: #5578eb;
}
.kt-switch.kt-switch--info:not(.kt-switch--outline) input:checked ~ span:after {
  opacity: 1;
}
.kt-switch.kt-switch--outline.kt-switch--info input:empty ~ span:before {
  border: 2px solid #dee3eb;
  background-color: #e8ebf1;
}
.kt-switch.kt-switch--outline.kt-switch--info input:empty ~ span:after {
  color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--info input:checked ~ span:before {
  background-color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--info input:checked ~ span:after {
  background-color: #5578eb;
  opacity: 1;
}
.kt-switch.kt-switch--warning:not(.kt-switch--outline) input:empty ~ span:before {
  background-color: #ffb822;
}
.kt-switch.kt-switch--warning:not(.kt-switch--outline) input:empty ~ span:after {
  color: #ffb822;
  background-color: #111;
  opacity: 0.4;
}
.kt-switch.kt-switch--warning:not(.kt-switch--outline) input:checked ~ span:before {
  background-color: #ffb822;
}
.kt-switch.kt-switch--warning:not(.kt-switch--outline) input:checked ~ span:after {
  opacity: 1;
}
.kt-switch.kt-switch--outline.kt-switch--warning input:empty ~ span:before {
  border: 2px solid #dee3eb;
  background-color: #e8ebf1;
}
.kt-switch.kt-switch--outline.kt-switch--warning input:empty ~ span:after {
  color: #111;
}
.kt-switch.kt-switch--outline.kt-switch--warning input:checked ~ span:before {
  background-color: #111;
}
.kt-switch.kt-switch--outline.kt-switch--warning input:checked ~ span:after {
  background-color: #ffb822;
  opacity: 1;
}
.kt-switch.kt-switch--danger:not(.kt-switch--outline) input:empty ~ span:before {
  background-color: #fd397a;
}
.kt-switch.kt-switch--danger:not(.kt-switch--outline) input:empty ~ span:after {
  color: #fd397a;
  background-color: #fff;
  opacity: 0.4;
}
.kt-switch.kt-switch--danger:not(.kt-switch--outline) input:checked ~ span:before {
  background-color: #fd397a;
}
.kt-switch.kt-switch--danger:not(.kt-switch--outline) input:checked ~ span:after {
  opacity: 1;
}
.kt-switch.kt-switch--outline.kt-switch--danger input:empty ~ span:before {
  border: 2px solid #dee3eb;
  background-color: #e8ebf1;
}
.kt-switch.kt-switch--outline.kt-switch--danger input:empty ~ span:after {
  color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--danger input:checked ~ span:before {
  background-color: #fff;
}
.kt-switch.kt-switch--outline.kt-switch--danger input:checked ~ span:after {
  background-color: #fd397a;
  opacity: 1;
}
.kt-option {
  display: flex;
  padding: 1.4em;
  border: 1px solid #ebedf2;
  border-radius: 4px;
}
.kt-option .kt-option__control {
  width: 2.7rem;
  padding-top: 0.1rem;
}
.kt-option .kt-option__control .kt-radio {
  display: block;
}
.kt-option .kt-option__label {
  width: 100%;
}
.kt-option .kt-option__label .kt-option__head {
  display: flex;
  justify-content: space-between;
}
.kt-option .kt-option__label .kt-option__head .kt-option__title {
  font-size: 1.1rem;
  font-weight: 400;
  color: #6c7293;
}
.kt-option .kt-option__label .kt-option__head .kt-option__focus {
  font-size: 1.1rem;
  font-weight: 500;
  color: #464457;
}
.kt-option .kt-option__label .kt-option__body {
  display: block;
  padding-top: 0.7rem;
  font-size: 0.9rem;
  color: #a7abc3;
}
.kt-option.kt-option--plain {
  border: 0;
  padding: 0;
  margin-top: -0.2rem;
  margin-bottom: 2rem;
  align-items: center;
}
.kt-option.kt-option--plain .kt-option__control {
  vertical-align: middle;
  width: 2.7rem;
}
.kt-option.kt-option--plain .kt-option__control .kt-radio {
  margin-bottom: 1.6rem;
}
.kt-option.kt-option--plain .kt-option__label .kt-option__body {
  padding-top: 0.2rem;
}
.kt-avatar {
  position: relative;
  display: inline-block;
}
.kt-avatar .kt-avatar__holder {
  width: 120px;
  height: 120px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: contain;
}
.kt-avatar .kt-avatar__upload {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}
.kt-avatar .kt-avatar__upload input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
.kt-avatar .kt-avatar__upload i {
  color: #5d78ff;
  font-size: 0.9rem;
}
.kt-avatar .kt-avatar__upload:hover {
  transition: all 0.3s;
  background-color: #5d78ff;
}
.kt-avatar .kt-avatar__upload:hover i {
  color: #fff;
}
.kt-avatar .kt-avatar__cancel {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: auto;
  right: -10px;
  bottom: -5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
  display: none;
}
.kt-avatar .kt-avatar__cancel i {
  transition: all 0.3s;
  color: #a7abc3;
  font-size: 0.9rem;
}
.kt-avatar .kt-avatar__cancel:hover i {
  transition: all 0.3s;
  color: #5d78ff;
}
.kt-avatar.kt-avatar--changed .kt-avatar__cancel {
  display: flex;
}
.kt-avatar.kt-avatar--brand .kt-avatar__upload i {
  color: #5d78ff;
}
.kt-avatar.kt-avatar--brand .kt-avatar__upload:hover {
  background-color: #5d78ff;
}
.kt-avatar.kt-avatar--brand .kt-avatar__upload:hover i,
.kt-avatar.kt-avatar--light .kt-avatar__upload i {
  color: #fff;
}
.kt-avatar.kt-avatar--light .kt-avatar__upload:hover {
  background-color: #fff;
}
.kt-avatar.kt-avatar--dark .kt-avatar__upload i,
.kt-avatar.kt-avatar--light .kt-avatar__upload:hover i {
  color: #282a3c;
}
.kt-avatar.kt-avatar--dark .kt-avatar__upload:hover {
  background-color: #282a3c;
}
.kt-avatar.kt-avatar--dark .kt-avatar__upload:hover i {
  color: #fff;
}
.kt-avatar.kt-avatar--primary .kt-avatar__upload i {
  color: #5867dd;
}
.kt-avatar.kt-avatar--primary .kt-avatar__upload:hover {
  background-color: #5867dd;
}
.kt-avatar.kt-avatar--primary .kt-avatar__upload:hover i {
  color: #fff;
}
.kt-avatar.kt-avatar--success .kt-avatar__upload i {
  color: #0abb87;
}
.kt-avatar.kt-avatar--success .kt-avatar__upload:hover {
  background-color: #0abb87;
}
.kt-avatar.kt-avatar--success .kt-avatar__upload:hover i {
  color: #fff;
}
.kt-avatar.kt-avatar--info .kt-avatar__upload i {
  color: #5578eb;
}
.kt-avatar.kt-avatar--info .kt-avatar__upload:hover {
  background-color: #5578eb;
}
.kt-avatar.kt-avatar--info .kt-avatar__upload:hover i {
  color: #fff;
}
.kt-avatar.kt-avatar--warning .kt-avatar__upload i {
  color: #ffb822;
}
.kt-avatar.kt-avatar--warning .kt-avatar__upload:hover {
  background-color: #ffb822;
}
.kt-avatar.kt-avatar--warning .kt-avatar__upload:hover i {
  color: #111;
}
.kt-avatar.kt-avatar--danger .kt-avatar__upload i {
  color: #fd397a;
}
.kt-avatar.kt-avatar--danger .kt-avatar__upload:hover {
  background-color: #fd397a;
}
.kt-avatar.kt-avatar--danger .kt-avatar__upload:hover i {
  color: #fff;
}
.kt-avatar.kt-avatar--circle .kt-avatar__holder {
  border-radius: 50%;
}
.kt-avatar.kt-avatar--circle .kt-avatar__upload {
  right: 0;
  top: 5px;
}
.kt-avatar.kt-avatar--circle .kt-avatar__cancel {
  right: 3px;
  bottom: 10px;
}
.kt-avatar.kt-avatar--outline .kt-avatar__holder {
  border: 3px solid #fff;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
}
.kt-spinner {
  position: relative;
}
.kt-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 50%;
}
.kt-spinner.kt-spinner--sm:before {
  width: 16px;
  height: 16px;
  margin-top: -8px;
}
.kt-spinner.kt-spinner--sm.kt-spinner--center:before {
  left: 50%;
  margin-left: -8px;
}
.kt-spinner.kt-spinner--sm.kt-spinner--left:before {
  left: 0;
  right: auto;
}
.kt-spinner.kt-spinner--sm.kt-spinner--right:before {
  left: auto;
  right: 0;
}
.kt-spinner:before {
  width: 20px;
  height: 20px;
  margin-top: -10px;
}
.kt-spinner.kt-spinner--center:before {
  left: 50%;
  margin-left: -10px;
}
.kt-spinner.kt-spinner--left:before {
  left: 0;
  right: auto;
}
.kt-spinner.kt-spinner--right:before {
  left: auto;
  right: 0;
}
.kt-spinner.kt-spinner--lg:before {
  width: 24px;
  height: 24px;
  margin-top: -12px;
}
.kt-spinner.kt-spinner--lg.kt-spinner--center:before {
  left: 50%;
  margin-left: -12px;
}
.kt-spinner.kt-spinner--lg.kt-spinner--left:before {
  left: 0;
  right: auto;
}
.kt-spinner.kt-spinner--lg.kt-spinner--right:before {
  left: auto;
  right: 0;
}
.kt-spinner:before {
  animation: kt-spinner 0.5s linear infinite;
}
.kt-spinner.kt-spinner--brand:before {
  border: 2px solid #5d78ff;
  border-right-color: transparent;
}
.kt-spinner.kt-spinner--light:before {
  border: 2px solid #fff;
  border-right-color: transparent;
}
.kt-spinner.kt-spinner--dark:before {
  border: 2px solid #282a3c;
  border-right-color: transparent;
}
.kt-spinner.kt-spinner--primary:before {
  border: 2px solid #5867dd;
  border-right-color: transparent;
}
.kt-spinner.kt-spinner--success:before {
  border: 2px solid #0abb87;
  border-right-color: transparent;
}
.kt-spinner.kt-spinner--info:before {
  border: 2px solid #5578eb;
  border-right-color: transparent;
}
.kt-spinner.kt-spinner--warning:before {
  border: 2px solid #ffb822;
  border-right-color: transparent;
}
.kt-spinner.kt-spinner--danger:before {
  border: 2px solid #fd397a;
  border-right-color: transparent;
}
.kt-spinner.kt-spinner--input.kt-spinner--right:before {
  left: auto;
  right: 1rem;
}
.kt-spinner.kt-spinner--input.kt-spinner--left:before {
  right: auto;
  left: 1rem;
}
.kt-spinner.kt-spinner--v2:before {
  animation: kt-spinner 0.5s linear infinite;
}
.kt-spinner.kt-spinner--v2.kt-spinner--brand:before {
  border: 2px solid #f6f8ff;
  border-top-color: #4e6bff;
}
.kt-spinner.kt-spinner--v2.kt-spinner--light:before {
  border: 2px solid #fff;
  border-top-color: #f7f7f7;
}
.kt-spinner.kt-spinner--v2.kt-spinner--dark:before {
  border: 2px solid #656a98;
  border-top-color: #222433;
}
.kt-spinner.kt-spinner--v2.kt-spinner--primary:before {
  border: 2px solid #d7dbf7;
  border-top-color: #4b5bda;
}
.kt-spinner.kt-spinner--v2.kt-spinner--success:before {
  border: 2px solid #67f7cd;
  border-top-color: #09ac7d;
}
.kt-spinner.kt-spinner--v2.kt-spinner--info:before {
  border: 2px solid #dee5fb;
  border-top-color: #476de9;
}
.kt-spinner.kt-spinner--v2.kt-spinner--warning:before {
  border: 2px solid #ffe9bb;
  border-top-color: #ffb313;
}
.kt-spinner.kt-spinner--v2.kt-spinner--danger:before {
  border: 2px solid #ffd0e0;
  border-top-color: #fd2a70;
}
.kt-loader {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  margin: 0 auto;
  border-radius: 100%;
  background-color: #f7f8fa;
  opacity: 0;
  animation: kt-loader-scaleout 0.9s ease-in-out infinite;
}
.kt-loader.kt-loader--lg {
  width: 2rem;
  height: 2rem;
}
.kt-loader.kt-loader--sm {
  width: 1rem;
  height: 1rem;
}
.kt-loader.kt-loader--brand {
  background-color: #5d78ff;
}
.kt-loader.kt-loader--light {
  background-color: #fff;
}
.kt-loader.kt-loader--dark {
  background-color: #282a3c;
}
.kt-loader.kt-loader--primary {
  background-color: #5867dd;
}
.kt-loader.kt-loader--success {
  background-color: #0abb87;
}
.kt-loader.kt-loader--info {
  background-color: #5578eb;
}
.kt-loader.kt-loader--warning {
  background-color: #ffb822;
}
.kt-loader.kt-loader--danger {
  background-color: #fd397a;
}
.kt-pulse {
  position: relative;
}
.kt-pulse .kt-pulse__ring {
  display: block;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  position: absolute;
  animation: kt-pulse 3.5s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  border: 3px solid #d8dce6;
}
.kt-pulse.kt-pulse--brand .kt-pulse__ring {
  border-color: rgba(93, 120, 255, 0.8);
}
.kt-pulse.kt-pulse--light .kt-pulse__ring {
  border-color: hsla(0, 0%, 100%, 0.8);
}
.kt-pulse.kt-pulse--dark .kt-pulse__ring {
  border-color: rgba(40, 42, 60, 0.8);
}
.kt-pulse.kt-pulse--primary .kt-pulse__ring {
  border-color: rgba(88, 103, 221, 0.8);
}
.kt-pulse.kt-pulse--success .kt-pulse__ring {
  border-color: rgba(10, 187, 135, 0.8);
}
.kt-pulse.kt-pulse--info .kt-pulse__ring {
  border-color: rgba(85, 120, 235, 0.8);
}
.kt-pulse.kt-pulse--warning .kt-pulse__ring {
  border-color: rgba(255, 184, 34, 0.8);
}
.kt-pulse.kt-pulse--danger .kt-pulse__ring {
  border-color: rgba(253, 57, 122, 0.8);
}
.kt-dialog {
  width: 130px;
  margin: 0 auto;
  position: fixed;
  z-index: 1100;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 4px;
}
.kt-dialog.kt-dialog--top-center {
  display: flex;
  top: -100px;
  left: 50%;
  margin-left: -65px;
  transition: top 0.6s ease;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.kt-dialog.kt-dialog--top-center.kt-dialog--shown {
  top: 0;
  transition: top 0.6s ease;
}
.kt-dialog.kt-dialog--loader {
  font-size: 1rem;
  padding: 0.75rem;
  font-weight: 400;
  color: #6c7293;
}
.kt-dialog.kt-dialog--loader.kt-dialog--brand {
  background-color: rgba(93, 120, 255, 0.1);
  color: #5d78ff;
  box-shadow: 0 0 20px 0 rgba(93, 120, 255, 0.3);
}
.kt-dialog.kt-dialog--loader.kt-dialog--light {
  background-color: hsla(0, 0%, 100%, 0.1);
  color: #fff;
  box-shadow: 0 0 20px 0 hsla(0, 0%, 100%, 0.3);
}
.kt-dialog.kt-dialog--loader.kt-dialog--dark {
  background-color: rgba(40, 42, 60, 0.1);
  color: #282a3c;
  box-shadow: 0 0 20px 0 rgba(40, 42, 60, 0.3);
}
.kt-dialog.kt-dialog--loader.kt-dialog--primary {
  background-color: rgba(88, 103, 221, 0.1);
  color: #5867dd;
  box-shadow: 0 0 20px 0 rgba(88, 103, 221, 0.3);
}
.kt-dialog.kt-dialog--loader.kt-dialog--success {
  background-color: rgba(10, 187, 135, 0.1);
  color: #0abb87;
  box-shadow: 0 0 20px 0 rgba(10, 187, 135, 0.3);
}
.kt-dialog.kt-dialog--loader.kt-dialog--info {
  background-color: rgba(85, 120, 235, 0.1);
  color: #5578eb;
  box-shadow: 0 0 20px 0 rgba(85, 120, 235, 0.3);
}
.kt-dialog.kt-dialog--loader.kt-dialog--warning {
  background-color: rgba(255, 184, 34, 0.1);
  color: #ffb822;
  box-shadow: 0 0 20px 0 rgba(255, 184, 34, 0.3);
}
.kt-dialog.kt-dialog--loader.kt-dialog--danger {
  background-color: rgba(253, 57, 122, 0.1);
  color: #fd397a;
  box-shadow: 0 0 20px 0 rgba(253, 57, 122, 0.3);
}
.kt-gmaps img {
  max-width: none;
}
.kt-gmaps.kt-gmaps--static > div {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: block;
}
.kt-repeater .kt-repeater__row {
  display: flex;
  flex-wrap: wrap;
}
.kt-repeater .kt-repeater__row > input {
  flex: 1;
}
.kt-repeater .kt-repeater__row .kt-repeater__close {
  background-color: initial;
  border: none;
}
.kt-repeater .kt-repeater__row .form-text {
  flex-basis: 100%;
}
.kt-repeater .kt-repeater__item {
  position: relative;
}
.kt-repeater .kt-repeater__item .btn-icon {
  height: auto;
}
.kt-repeater .kt-repeater__item:first-child .form-control {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.kt-repeater .kt-repeater__item:first-child .input-group-append {
  display: none;
}
.kt-repeater .kt-repeater__close--align-right {
  position: absolute;
  top: 0;
  right: 0;
}
.kt-header-menu-wrapper-close {
  display: none;
}
.kt-header__topbar {
  display: flex;
  align-items: stretch;
  padding: 0 15px 0 0;
}
.kt-header__topbar .kt-header__topbar-item {
  display: flex;
  align-items: stretch;
  margin: 0;
}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-wrapper {
  cursor: pointer;
  display: flex;
  align-items: stretch;
}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-wrapper .kt-badge.kt-badge--notify {
  position: absolute;
  left: 50%;
  margin-left: -2px;
  top: 14px;
}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 4px;
}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon i {
  transition: all 0.3s;
  font-size: 1.4rem;
  color: #fff;
}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon svg {
  height: 24px;
  width: 24px;
}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user {
  padding: 0 8px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: 44px;
  cursor: pointer;
  border-radius: 4px;
}
.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-user
  .kt-header__topbar-welcome {
  display: flex;
  align-self: center;
  padding: 0 0.55rem 0 0;
  font-weight: 500;
  font-size: 0.9rem;
  color: #636177;
}
.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-user
  .kt-header__topbar-username {
  display: flex;
  align-self: center;
  padding: 0 0.55rem 0 0;
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user img {
  align-self: center;
  max-height: 34px;
  border-radius: 4px;
}
.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-user
  .kt-badge.kt-badge--username {
  height: 34px;
  width: 34px;
  border-radius: 4px;
}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--langs .kt-header__topbar-icon img {
  border-radius: 4px;
  width: 20px;
}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--langs .dropdown-menu .kt-nav__link-icon {
  padding-right: 10px;
}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--langs .dropdown-menu .kt-nav__link-icon img {
  border-radius: 4px;
  width: 18px;
}
.kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon,
.kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon {
  transition: all 0.3s;
  background-color: rgba(33, 32, 41, 0.7);
}
.kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon i,
.kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon i {
  transition: all 0.3s;
  color: #fff;
}
.kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--user .kt-header__topbar-user,
.kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--user .kt-header__topbar-user {
  transition: all 0.3s;
}
.kt-aside__brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 10px;
  height: 65px;
  margin-top: 10px;
}
.kt-aside__brand .kt-aside__brand-logo {
  display: flex;
  justify-content: flex-begin;
}
.kt-aside__brand .kt-aside__brand-logo img {
  width: 95%;
}
.kt-aside--minimize .kt-aside__brand .kt-aside__brand-logo img {
  display: none;
}
.kt-aside__brand .kt-aside__brand-tools {
  display: flex;
  justify-content: flex-end;
}
.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler {
  display: inline-block;
  padding: 0;
  border: 0;
  background: none;
  outline: none !important;
  box-shadow: none;
}
.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler span {
  display: inline-block;
}
.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler span:nth-child(2) {
  display: none;
}
.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler svg {
  height: 26px;
  width: 26px;
  margin-right: -2px;
}
.kt-aside {
  width: var(--aside-width, 240px);
  padding-left: calc(1em - var(--scrollbar-width, 0px));
  transition: all 0.3s ease;
}
.kt-aside .kt-aside-menu {
  margin: 15px 0;
}
.kt-aside .kt-aside-menu .ps__rail-y {
  right: 3px;
}
.kt-aside .kt-aside__footer .kt-aside__footer-nav {
  padding: 7px 30px;
}
.kt-aside .kt-aside__footer .kt-aside__footer-item,
.kt-aside .kt-aside__footer .kt-aside__footer-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.kt-aside .kt-aside__footer .btn {
  height: 45px;
  width: 45px;
  background-color: #f0f0f6;
  border-radius: 50%;
}
.kt-aside .kt-aside__footer .btn i {
  font-size: 1.4rem;
  color: #5d78ff;
}
.kt-aside .kt-aside__footer .btn:hover {
  background-color: #5d78ff;
}
.kt-aside .kt-aside__footer .btn:hover i {
  transition: all 0.3s;
  color: #fff;
}
.kt-aside--minimize .kt-aside .kt-aside__footer .btn {
  display: none;
}
.kt-aside--minimize .kt-aside .kt-aside__footer .btn:last-child {
  display: inline-block;
  opacity: 0;
}
.kt-aside-menu .kt-menu__nav {
  margin: 0;
  list-style: none;
  padding: 0 0 15px 0;
}
.kt-aside-menu .kt-menu__nav .kt-menu__subnav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  margin: 0;
  list-style: none !important;
}
.kt-aside-menu .kt-menu__nav .kt-menu__inner,
.kt-aside-menu .kt-menu__nav .kt-menu__submenu {
  display: none;
  float: none;
  margin: 0;
  padding: 0;
}
.kt-aside-menu .kt-menu__nav .kt-menu__inner .kt-menu__content,
.kt-aside-menu .kt-menu__nav .kt-menu__submenu .kt-menu__content {
  padding: 0;
  margin: 0;
}
.kt-aside-menu .kt-menu__nav .kt-menu__inner .kt-menu__content > .kt-menu__item,
.kt-aside-menu .kt-menu__nav .kt-menu__submenu .kt-menu__content > .kt-menu__item {
  padding: 0;
  margin: 0;
  list-style: none;
}
.kt-aside-menu .kt-menu__nav .kt-menu__inner .kt-menu__content > .kt-menu__item.kt-menu--no-heading > .kt-menu__inner,
.kt-aside-menu
  .kt-menu__nav
  .kt-menu__submenu
  .kt-menu__content
  > .kt-menu__item.kt-menu--no-heading
  > .kt-menu__inner {
  display: flex;
}
.kt-aside-menu
  .kt-menu__nav
  .kt-menu__inner
  .kt-menu__content
  > .kt-menu__item.kt-menu--no-heading
  > .kt-menu__inner
  > li
  > .kt-menu__link,
.kt-aside-menu
  .kt-menu__nav
  .kt-menu__submenu
  .kt-menu__content
  > .kt-menu__item.kt-menu--no-heading
  > .kt-menu__inner
  > li
  > .kt-menu__link {
  margin: 0;
}
.kt-aside-menu .kt-menu__nav .kt-menu__item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  float: none;
  padding: 0;
}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading,
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link {
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  margin: 0;
  padding: 0;
  text-decoration: none;
  position: relative;
  outline: none;
}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading:hover,
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link:hover {
  text-decoration: none;
  cursor: pointer;
}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-text,
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-text {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0;
}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-badge,
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-badge {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet,
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-icon,
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-bullet,
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
  display: flex;
  align-items: center;
  line-height: 0;
}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow,
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 0;
}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__hor-arrow,
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow {
  display: none;
}
.kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__heading > .kt-menu__ver-arrow:before,
.kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__link > .kt-menu__ver-arrow:before {
  transform: rotate(-90deg);
}
.kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__inner,
.kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__submenu {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.kt-aside-menu .kt-menu__nav .kt-menu__item .kt-menu__submenu .kt-menu__item--parent {
  display: none;
}
.kt-aside-menu .kt-menu__nav .kt-menu__section {
  display: flex;
  padding: 0 25px;
}
.kt-aside-menu .kt-menu__nav .kt-menu__section.kt-menu__section--first {
  margin-top: 0 !important;
}
.kt-aside-menu .kt-menu__nav .kt-menu__section .kt-menu__section-text {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}
.kt-aside-menu .kt-menu__nav .kt-menu__section .kt-menu__section-icon {
  display: none;
  align-items: center;
  justify-content: center;
}
.kt-aside-menu .kt-menu__nav .kt-menu__separator {
  height: 0;
  overflow: hidden;
}
.kt-aside-menu .kt-menu__nav .kt-menu__separator.kt-menu__separator--marginles {
  margin: 0;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item {
  position: relative;
  margin: 0;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
  cursor: pointer;
  height: 44px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
  font-weight: 400;
  font-size: 1rem;
  text-transform: none;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
  flex: 0 0 35px;
  font-size: 1.3rem;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg {
  height: 23px;
  width: 23px;
  margin-left: -2px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet {
  vertical-align: middle;
  text-align: left;
  flex: 0 0 15px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet > span,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span {
  vertical-align: middle;
  display: inline-block;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span {
  width: 4px;
  height: 4px;
  border-radius: 100%;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span {
  width: 5px;
  height: 1px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-badge,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge {
  padding: 0 0 0 5px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow {
  flex: 0 0 20px;
  font-size: 0.7rem;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before {
  display: inline-block;
  transition: all 0.3s ease;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__item-here,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__item-here {
  display: none;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow:before,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow:before {
  transition: all 0.3s ease;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav {
  margin: 0;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link {
  cursor: pointer;
  height: 40px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-text,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-text {
  font-weight: 400;
  font-size: 1rem;
  text-transform: none;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-icon,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
  flex: 0 0 35px;
  font-size: 1.3rem;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-icon
  svg,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-icon
  svg {
  height: 24px;
  width: 24px;
  margin-left: -2px;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet {
  vertical-align: middle;
  text-align: left;
  flex: 0 0 20px;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-bullet
  > span {
  vertical-align: middle;
  display: inline-block;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span {
  width: 4px;
  height: 4px;
  border-radius: 100%;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span {
  width: 5px;
  height: 1px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-badge,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-badge {
  padding: 0 0 0 5px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow {
  flex: 0 0 20px;
  font-size: 0.7rem;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__ver-arrow:before,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__ver-arrow:before {
  display: inline-block;
  transition: all 0.3s ease;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__item-here,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__item-here {
  display: none;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__ver-arrow:before,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__ver-arrow:before {
  transition: all 0.3s ease;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section {
  margin: 20px 0 0;
  height: 40px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section .kt-menu__section-text {
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__separator {
  margin: 15px 0;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__separator .kt-menu__separator--marginles {
  margin: 0;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__section {
  margin: 20px 0 0;
  height: 40px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-text {
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__separator {
  margin: 15px 0;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__separator .kt-menu__separator--marginles {
  margin: 0;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
  padding: 9px 25px 9px 15px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav .kt-menu__content .kt-menu__heading {
  padding: 0 25px 0 40px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav .kt-menu__content .kt-menu__inner {
  padding: 0;
  margin: 0;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  .kt-menu__content
  .kt-menu__inner
  .kt-menu__link {
  padding: 0 25px 0 55px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link {
  padding: 0 25px 0 30px;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__link {
  padding: 0 25px 0 55px;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__link {
  padding: 0 25px 0 70px;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section {
  padding-left: 40px;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav {
  padding: 0;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__section {
  padding-left: 55px;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav {
  padding: 0;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__submenu
  .kt-menu__subnav
  > .kt-menu__section {
  padding-left: 70px;
}
.kt-aside-close {
  display: none;
}
.kt-content {
  padding: 15px;
}
.kt-subheader--enabled.kt-subheader--transparent:not(.kt-subheader--fixed) .kt-content {
  padding-top: 0;
}
.kt-footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
  background: #fff;
}
.kt-footer .kt-footer__copyright {
  display: flex;
  margin: 0;
  padding: 0 1.25rem 0 0;
  font-size: 1rem;
  font-weight: 400;
  justify-content: flex-start;
  color: #a7abc3;
}
.kt-footer .kt-footer__copyright .kt-link {
  color: #a7abc3;
}
.kt-footer .kt-footer__copyright .kt-link:hover {
  color: #5d78ff;
}
.kt-footer .kt-footer__copyright .kt-link:hover:after {
  border-bottom: 1px solid #5d78ff;
  opacity: 0.3;
}
.kt-footer .kt-footer__menu {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 0 0.75rem;
  margin: 0;
}
.kt-footer .kt-footer__menu .kt-footer__menu-link {
  margin: 0 0 0 1.25rem;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  color: #6c7293;
}
.kt-footer .kt-footer__menu .kt-footer__menu-link:hover {
  color: #5d78ff;
}
.kt-footer .kt-footer__menu .kt-footer__menu-link:hover:after {
  border-bottom: 1px solid #5d78ff;
  opacity: 0.3;
}
.kt-callout .kt-callout__body {
  display: flex;
  align-items: center;
}
.kt-callout .kt-callout__body .kt-callout__content {
  flex: 2;
}
.kt-callout .kt-callout__body .kt-callout__content .kt-callout__title {
  font-size: 1rem;
  font-weight: 500;
  color: #48465b;
  margin: 0.5rem 0;
}
.kt-callout .kt-callout__body .kt-callout__content .kt-callout__desc {
  font-size: 0.9rem;
  line-height: 2rem;
  color: #9392a0;
  font-weight: 400;
}
.kt-callout .kt-callout__body .kt-callout__content .kt-callout__desc:last-child {
  margin-bottom: 0;
}
.kt-callout .kt-callout__body .kt-callout__action {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.kt-callout .kt-callout__body .kt-callout__action .btn.btn-custom {
  padding: 0.8rem 1rem;
  font-size: 1.2rem;
  min-width: 65px;
}
.kt-callout--diagonal-bg {
  overflow: hidden;
  position: relative;
  background-color: #fff;
}
.kt-callout--diagonal-bg .kt-callout__body {
  position: relative;
  z-index: 2;
}
.kt-callout--diagonal-bg:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7f8fa;
  z-index: 1;
}
.kt-callout--diagonal-bg:after {
  content: ' ';
  width: 200%;
  height: 1000px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 45%;
  transform: rotate(20deg);
  background-color: #fff;
}
.kt-callout--brand {
  background-color: rgba(93, 120, 255, 0.07);
}
.kt-callout--brand.kt-callout--diagonal-bg {
  background-color: #fff;
}
.kt-callout--brand.kt-callout--diagonal-bg:before {
  background-color: rgba(93, 120, 255, 0.15);
}
.kt-callout--light {
  background-color: hsla(0, 0%, 100%, 0.07);
}
.kt-callout--light.kt-callout--diagonal-bg {
  background-color: #fff;
}
.kt-callout--light.kt-callout--diagonal-bg:before {
  background-color: hsla(0, 0%, 100%, 0.15);
}
.kt-callout--dark {
  background-color: rgba(40, 42, 60, 0.07);
}
.kt-callout--dark.kt-callout--diagonal-bg {
  background-color: #fff;
}
.kt-callout--dark.kt-callout--diagonal-bg:before {
  background-color: rgba(40, 42, 60, 0.15);
}
.kt-callout--primary {
  background-color: rgba(88, 103, 221, 0.07);
}
.kt-callout--primary.kt-callout--diagonal-bg {
  background-color: #fff;
}
.kt-callout--primary.kt-callout--diagonal-bg:before {
  background-color: rgba(88, 103, 221, 0.15);
}
.kt-callout--success {
  background-color: rgba(10, 187, 135, 0.07);
}
.kt-callout--success.kt-callout--diagonal-bg {
  background-color: #fff;
}
.kt-callout--success.kt-callout--diagonal-bg:before {
  background-color: rgba(10, 187, 135, 0.15);
}
.kt-callout--info {
  background-color: rgba(85, 120, 235, 0.07);
}
.kt-callout--info.kt-callout--diagonal-bg {
  background-color: #fff;
}
.kt-callout--info.kt-callout--diagonal-bg:before {
  background-color: rgba(85, 120, 235, 0.15);
}
.kt-callout--warning {
  background-color: rgba(255, 184, 34, 0.07);
}
.kt-callout--warning.kt-callout--diagonal-bg {
  background-color: #fff;
}
.kt-callout--warning.kt-callout--diagonal-bg:before {
  background-color: rgba(255, 184, 34, 0.15);
}
.kt-callout--danger {
  background-color: rgba(253, 57, 122, 0.07);
}
.kt-callout--danger.kt-callout--diagonal-bg {
  background-color: #fff;
}
.kt-callout--danger.kt-callout--diagonal-bg:before {
  background-color: rgba(253, 57, 122, 0.15);
}
.kt-wizard-v1 {
  flex-direction: column;
}
.kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}
.kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
}
.kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-svg-icon {
  height: 30px;
  width: 30px;
}
.kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:last-child:after {
  content: none;
}
.kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 2rem 1rem;
  text-align: center;
}
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-icon {
  font-size: 1.3rem;
  color: #a2a5b9;
  margin-bottom: 0.5rem;
}
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-icon
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #a2a5b9;
}
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-icon:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-label {
  font-size: 1.1rem;
  font-weight: 500;
  color: #74788d;
}
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item[data-ktwizard-state='current']
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-icon,
.kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state='current']:after,
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item[data-ktwizard-state='done']
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-icon,
.kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state='done']:after {
  color: #366cf3;
}
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item[data-ktwizard-state='current']
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-icon
  g
  [fill],
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item[data-ktwizard-state='done']
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-icon
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #366cf3;
}
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item[data-ktwizard-state='current']
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-icon:hover
  g
  [fill],
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item[data-ktwizard-state='done']
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-icon:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item[data-ktwizard-state='current']
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-label,
.kt-wizard-v1
  .kt-wizard-v1__nav
  .kt-wizard-v1__nav-items
  .kt-wizard-v1__nav-item[data-ktwizard-state='done']
  .kt-wizard-v1__nav-body
  .kt-wizard-v1__nav-label {
  color: #366cf3;
}
.kt-wizard-v1 .kt-wizard-v1__wrapper {
  display: flex;
  justify-content: center;
  background-color: #f9fafc;
}
.kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form {
  width: 60%;
  padding: 4rem 0 5rem;
}
.kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eeeef4;
}
.kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__form {
  margin-top: 3rem;
}
.kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__review .kt-wizard-v1__review-item {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eeeef4;
}
.kt-wizard-v1
  .kt-wizard-v1__wrapper
  .kt-form
  .kt-wizard-v1__content
  .kt-wizard-v1__review
  .kt-wizard-v1__review-item
  .kt-wizard-v1__review-title {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.7rem;
}
.kt-wizard-v1
  .kt-wizard-v1__wrapper
  .kt-form
  .kt-wizard-v1__content
  .kt-wizard-v1__review
  .kt-wizard-v1__review-item
  .kt-wizard-v1__review-content {
  line-height: 1.8rem;
}
.kt-wizard-v1
  .kt-wizard-v1__wrapper
  .kt-form
  .kt-wizard-v1__content
  .kt-wizard-v1__review
  .kt-wizard-v1__review-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.kt-wizard-v1.kt-wizard-v1--white .kt-wizard-v1__wrapper {
  background-color: #fff;
}
.btn.kt-spinner:not(.kt-spinner--center) {
  padding-left: 3rem;
}
.btn.kt-spinner:not(.kt-spinner--center):before {
  left: 1rem;
}
.btn.kt-spinner.kt-spinner--right {
  padding-left: 1rem;
  padding-right: 3rem;
}
.btn.kt-spinner.kt-spinner--right:before {
  left: auto;
  right: 1rem;
}
.btn.kt-spinner.kt-spinner--sm:not(.kt-spinner--center) {
  padding-left: 2.5rem;
}
.btn.kt-spinner.kt-spinner--sm:not(.kt-spinner--center):before {
  left: 1rem;
}
.btn.kt-spinner.kt-spinner--sm.kt-spinner--right {
  padding-left: 1rem;
  padding-right: 2.5rem;
}
.btn.kt-spinner.kt-spinner--sm.kt-spinner--right:before {
  left: auto;
  right: 1rem;
}
.btn.kt-spinner.kt-spinner--lg:not(.kt-spinner--center) {
  padding-left: 3.5rem;
}
.btn.kt-spinner.kt-spinner--lg:not(.kt-spinner--center):before {
  left: 1rem;
}
.btn.kt-spinner.kt-spinner--lg.kt-spinner--right {
  padding-left: 1rem;
  padding-right: 3.5rem;
}
.btn.kt-spinner.kt-spinner--lg.kt-spinner--right:before {
  left: auto;
  right: 1rem;
}
@media (max-width: 768px) {
  .kt-widget.kt-widget--user-profile-2 .kt-widget__head {
    margin-top: -30px;
  }
  .kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__img {
    max-width: 60px;
  }
  .kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic {
    max-width: 60px;
    max-height: 60px;
    font-size: 1.2rem;
  }
}
@media (max-width: 1024px) {
  .kt-user-card {
    padding: 1rem;
  }
  .kt-user-card .kt-user-card__avatar .kt-badge,
  .kt-user-card .kt-user-card__avatar img {
    width: 40px;
    height: 40px;
  }
  .kt-user-card .kt-user-card__name {
    font-size: 1.1rem;
    padding-left: 0.5rem;
  }
  .kt-user-card .kt-user-card__badge {
    padding-left: 0.5rem;
  }
}
@media (max-width: 1024px) {
  .kt-user-card-v2 .kt-user-card-v2__pic img {
    max-width: 30px;
  }
}
@media screen {
  .kt-offcanvas-panel,
  .kt-offcanvas-panel-close {
    transition: none !important;
  }
}
@media (max-width: 475px) {
  .kt-offcanvas-panel {
    width: 90% !important;
  }
}
@media (min-width: 1025px) {
  .kt-offcanvas-panel .kt-offcanvas-panel__body {
    padding-right: 1.5rem;
    margin-right: -1.5rem;
  }
}
@media (max-width: 1024px) {
  .kt-offcanvas-panel {
    padding: 1rem;
  }
}
@media (min-width: 1025px) {
  .kt-demo-panel .kt-demo-panel__body {
    padding-right: 1.5rem;
    margin-right: -1.5rem;
  }
}
@media screen {
  .kt-demo-panel,
  .kt-demo-panel-close {
    transition: none !important;
  }
}
@media (max-width: 400px) {
  .kt-demo-panel {
    width: 90% !important;
  }
}
@media (max-width: 1024px) {
  .kt-demo-panel-toggle {
    width: 30px;
    padding: 1rem 0;
  }
  .kt-demo-panel-toggle span {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 2px;
  }
  .kt-demo-panel {
    padding: 1rem;
  }
  .kt-demo-panel-toggle {
    top: 20%;
  }
}
@media screen {
  .kt-quick-panel,
  .kt-quick-panel-close {
    transition: none !important;
  }
}
@media (max-width: 475px) {
  .kt-quick-panel {
    width: 90% !important;
  }
}
@media (max-width: 1024px) {
  .kt-quick-panel .kt-quick-panel__nav {
    padding: 1rem 0;
  }
  .kt-quick-panel .kt-quick-panel__nav .nav .nav-item {
    margin-right: 0.5rem;
  }
  .kt-quick-panel .kt-quick-panel__nav .nav .nav-link {
    padding: 0.75rem;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  .kt-quick-panel .kt-quick-panel__nav .kt-quick-panel__close i {
    font-size: 1.1rem;
  }
  .kt-quick-panel .kt-quick-panel__content {
    padding: 1rem 0.5rem 1rem 1rem;
  }
  .kt-quick-panel .kt-quick-panel__content .tab-pane {
    padding-right: 0.5rem;
  }
}
@media (max-width: 1024px) {
  .kt-subheader {
    padding: 2px 15px;
  }
  .kt-subheader .kt-subheader__main .kt-subheader__title {
    padding: 0.5rem 1.25rem 0.5rem 0;
  }
  .kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs,
  .kt-subheader .kt-subheader__toolbar {
    padding: 0.5rem 0;
  }
  .kt-subheader--transparent .kt-subheader {
    padding: 10px 15px;
  }
}
@media (max-width: 768px) {
  .kt-subheader .kt-subheader__toolbar {
    padding-top: 0.5rem;
  }
}
@media (max-width: 1024px) {
  .kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item {
    padding: 1rem 0.5rem;
  }
}
@media screen {
  .kt-grid-nav-v2 .kt-grid-nav-v2__item {
    height: 180px;
  }
}
@media (max-width: 1399px) {
  .kt-timeline-v1:after {
    left: 0;
    bottom: 0;
  }
  .kt-timeline-v1 .kt-timeline-v1__items {
    position: static;
    margin-bottom: 40px;
    padding: 20px 0;
  }
  .kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__marker {
    left: 0;
    top: 0;
  }
  .kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item {
    width: 100%;
    left: 0 !important;
    top: 0 !important;
    margin-top: 50px !important;
    padding-left: 2.25rem !important;
    padding-right: 0 !important;
    position: relative;
  }
  .kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 15px !important;
    left: 0 !important;
    right: auto !important;
    margin-left: 3px !important;
    border: 17px solid transparent !important;
    border-right: 10px solid #f7f8fc !important;
  }
  .kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time {
    position: absolute;
    display: inline-block;
    margin-top: -2.14rem;
  }
  .kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-circle {
    left: -10px;
    top: 20px;
  }
  .kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time {
    right: 100%;
    left: 3.57rem;
  }
  .kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--first {
    margin-top: 20px !important;
  }
}
@media (max-width: 768px) {
  .kt-timeline-v3 .kt-timeline-v3__item {
    margin-left: 0;
  }
}
@media (min-width: 1025px) {
  .kt-margin-0-desktop {
    margin: 0 !important;
  }
  .kt-margin-t-0-desktop {
    margin-top: 0 !important;
  }
  .kt-margin-b-0-desktop {
    margin-bottom: 0 !important;
  }
  .kt-margin-l-0-desktop {
    margin-left: 0 !important;
  }
  .kt-margin-r-0-desktop {
    margin-right: 0 !important;
  }
  .kt-padding-0-desktop {
    padding: 0 !important;
  }
  .kt-padding-t-0-desktop {
    padding-top: 0 !important;
  }
  .kt-padding-b-0-desktop {
    padding-bottom: 0 !important;
  }
  .kt-padding-l-0-desktop {
    padding-left: 0 !important;
  }
  .kt-padding-r-0-desktop {
    padding-right: 0 !important;
  }
  .kt-margin-5-desktop {
    margin: 5px !important;
  }
  .kt-margin-t-5-desktop {
    margin-top: 5px !important;
  }
  .kt-margin-b-5-desktop {
    margin-bottom: 5px !important;
  }
  .kt-margin-l-5-desktop {
    margin-left: 5px !important;
  }
  .kt-margin-r-5-desktop {
    margin-right: 5px !important;
  }
  .kt-padding-5-desktop {
    padding: 5px !important;
  }
  .kt-padding-t-5-desktop {
    padding-top: 5px !important;
  }
  .kt-padding-b-5-desktop {
    padding-bottom: 5px !important;
  }
  .kt-padding-l-5-desktop {
    padding-left: 5px !important;
  }
  .kt-padding-r-5-desktop {
    padding-right: 5px !important;
  }
  .kt-margin-10-desktop {
    margin: 10px !important;
  }
  .kt-margin-t-10-desktop {
    margin-top: 10px !important;
  }
  .kt-margin-b-10-desktop {
    margin-bottom: 10px !important;
  }
  .kt-margin-l-10-desktop {
    margin-left: 10px !important;
  }
  .kt-margin-r-10-desktop {
    margin-right: 10px !important;
  }
  .kt-padding-10-desktop {
    padding: 10px !important;
  }
  .kt-padding-t-10-desktop {
    padding-top: 10px !important;
  }
  .kt-padding-b-10-desktop {
    padding-bottom: 10px !important;
  }
  .kt-padding-l-10-desktop {
    padding-left: 10px !important;
  }
  .kt-padding-r-10-desktop {
    padding-right: 10px !important;
  }
  .kt-margin-15-desktop {
    margin: 15px !important;
  }
  .kt-margin-t-15-desktop {
    margin-top: 15px !important;
  }
  .kt-margin-b-15-desktop {
    margin-bottom: 15px !important;
  }
  .kt-margin-l-15-desktop {
    margin-left: 15px !important;
  }
  .kt-margin-r-15-desktop {
    margin-right: 15px !important;
  }
  .kt-padding-15-desktop {
    padding: 15px !important;
  }
  .kt-padding-t-15-desktop {
    padding-top: 15px !important;
  }
  .kt-padding-b-15-desktop {
    padding-bottom: 15px !important;
  }
  .kt-padding-l-15-desktop {
    padding-left: 15px !important;
  }
  .kt-padding-r-15-desktop {
    padding-right: 15px !important;
  }
  .kt-margin-20-desktop {
    margin: 20px !important;
  }
  .kt-margin-t-20-desktop {
    margin-top: 20px !important;
  }
  .kt-margin-b-20-desktop {
    margin-bottom: 20px !important;
  }
  .kt-margin-l-20-desktop {
    margin-left: 20px !important;
  }
  .kt-margin-r-20-desktop {
    margin-right: 20px !important;
  }
  .kt-padding-20-desktop {
    padding: 20px !important;
  }
  .kt-padding-t-20-desktop {
    padding-top: 20px !important;
  }
  .kt-padding-b-20-desktop {
    padding-bottom: 20px !important;
  }
  .kt-padding-l-20-desktop {
    padding-left: 20px !important;
  }
  .kt-padding-r-20-desktop {
    padding-right: 20px !important;
  }
  .kt-margin-25-desktop {
    margin: 25px !important;
  }
  .kt-margin-t-25-desktop {
    margin-top: 25px !important;
  }
  .kt-margin-b-25-desktop {
    margin-bottom: 25px !important;
  }
  .kt-margin-l-25-desktop {
    margin-left: 25px !important;
  }
  .kt-margin-r-25-desktop {
    margin-right: 25px !important;
  }
  .kt-padding-25-desktop {
    padding: 25px !important;
  }
  .kt-padding-t-25-desktop {
    padding-top: 25px !important;
  }
  .kt-padding-b-25-desktop {
    padding-bottom: 25px !important;
  }
  .kt-padding-l-25-desktop {
    padding-left: 25px !important;
  }
  .kt-padding-r-25-desktop {
    padding-right: 25px !important;
  }
  .kt-margin-30-desktop {
    margin: 30px !important;
  }
  .kt-margin-t-30-desktop {
    margin-top: 30px !important;
  }
  .kt-margin-b-30-desktop {
    margin-bottom: 30px !important;
  }
  .kt-margin-l-30-desktop {
    margin-left: 30px !important;
  }
  .kt-margin-r-30-desktop {
    margin-right: 30px !important;
  }
  .kt-padding-30-desktop {
    padding: 30px !important;
  }
  .kt-padding-t-30-desktop {
    padding-top: 30px !important;
  }
  .kt-padding-b-30-desktop {
    padding-bottom: 30px !important;
  }
  .kt-padding-l-30-desktop {
    padding-left: 30px !important;
  }
  .kt-padding-r-30-desktop {
    padding-right: 30px !important;
  }
  .kt-margin-35-desktop {
    margin: 35px !important;
  }
  .kt-margin-t-35-desktop {
    margin-top: 35px !important;
  }
  .kt-margin-b-35-desktop {
    margin-bottom: 35px !important;
  }
  .kt-margin-l-35-desktop {
    margin-left: 35px !important;
  }
  .kt-margin-r-35-desktop {
    margin-right: 35px !important;
  }
  .kt-padding-35-desktop {
    padding: 35px !important;
  }
  .kt-padding-t-35-desktop {
    padding-top: 35px !important;
  }
  .kt-padding-b-35-desktop {
    padding-bottom: 35px !important;
  }
  .kt-padding-l-35-desktop {
    padding-left: 35px !important;
  }
  .kt-padding-r-35-desktop {
    padding-right: 35px !important;
  }
  .kt-margin-40-desktop {
    margin: 40px !important;
  }
  .kt-margin-t-40-desktop {
    margin-top: 40px !important;
  }
  .kt-margin-b-40-desktop {
    margin-bottom: 40px !important;
  }
  .kt-margin-l-40-desktop {
    margin-left: 40px !important;
  }
  .kt-margin-r-40-desktop {
    margin-right: 40px !important;
  }
  .kt-padding-40-desktop {
    padding: 40px !important;
  }
  .kt-padding-t-40-desktop {
    padding-top: 40px !important;
  }
  .kt-padding-b-40-desktop {
    padding-bottom: 40px !important;
  }
  .kt-padding-l-40-desktop {
    padding-left: 40px !important;
  }
  .kt-padding-r-40-desktop {
    padding-right: 40px !important;
  }
  .kt-margin-45-desktop {
    margin: 45px !important;
  }
  .kt-margin-t-45-desktop {
    margin-top: 45px !important;
  }
  .kt-margin-b-45-desktop {
    margin-bottom: 45px !important;
  }
  .kt-margin-l-45-desktop {
    margin-left: 45px !important;
  }
  .kt-margin-r-45-desktop {
    margin-right: 45px !important;
  }
  .kt-padding-45-desktop {
    padding: 45px !important;
  }
  .kt-padding-t-45-desktop {
    padding-top: 45px !important;
  }
  .kt-padding-b-45-desktop {
    padding-bottom: 45px !important;
  }
  .kt-padding-l-45-desktop {
    padding-left: 45px !important;
  }
  .kt-padding-r-45-desktop {
    padding-right: 45px !important;
  }
  .kt-margin-50-desktop {
    margin: 50px !important;
  }
  .kt-margin-t-50-desktop {
    margin-top: 50px !important;
  }
  .kt-margin-b-50-desktop {
    margin-bottom: 50px !important;
  }
  .kt-margin-l-50-desktop {
    margin-left: 50px !important;
  }
  .kt-margin-r-50-desktop {
    margin-right: 50px !important;
  }
  .kt-padding-50-desktop {
    padding: 50px !important;
  }
  .kt-padding-t-50-desktop {
    padding-top: 50px !important;
  }
  .kt-padding-b-50-desktop {
    padding-bottom: 50px !important;
  }
  .kt-padding-l-50-desktop {
    padding-left: 50px !important;
  }
  .kt-padding-r-50-desktop {
    padding-right: 50px !important;
  }
  .kt-margin-55-desktop {
    margin: 55px !important;
  }
  .kt-margin-t-55-desktop {
    margin-top: 55px !important;
  }
  .kt-margin-b-55-desktop {
    margin-bottom: 55px !important;
  }
  .kt-margin-l-55-desktop {
    margin-left: 55px !important;
  }
  .kt-margin-r-55-desktop {
    margin-right: 55px !important;
  }
  .kt-padding-55-desktop {
    padding: 55px !important;
  }
  .kt-padding-t-55-desktop {
    padding-top: 55px !important;
  }
  .kt-padding-b-55-desktop {
    padding-bottom: 55px !important;
  }
  .kt-padding-l-55-desktop {
    padding-left: 55px !important;
  }
  .kt-padding-r-55-desktop {
    padding-right: 55px !important;
  }
  .kt-margin-60-desktop {
    margin: 60px !important;
  }
  .kt-margin-t-60-desktop {
    margin-top: 60px !important;
  }
  .kt-margin-b-60-desktop {
    margin-bottom: 60px !important;
  }
  .kt-margin-l-60-desktop {
    margin-left: 60px !important;
  }
  .kt-margin-r-60-desktop {
    margin-right: 60px !important;
  }
  .kt-padding-60-desktop {
    padding: 60px !important;
  }
  .kt-padding-t-60-desktop {
    padding-top: 60px !important;
  }
  .kt-padding-b-60-desktop {
    padding-bottom: 60px !important;
  }
  .kt-padding-l-60-desktop {
    padding-left: 60px !important;
  }
  .kt-padding-r-60-desktop {
    padding-right: 60px !important;
  }
  .kt-margin-65-desktop {
    margin: 65px !important;
  }
  .kt-margin-t-65-desktop {
    margin-top: 65px !important;
  }
  .kt-margin-b-65-desktop {
    margin-bottom: 65px !important;
  }
  .kt-margin-l-65-desktop {
    margin-left: 65px !important;
  }
  .kt-margin-r-65-desktop {
    margin-right: 65px !important;
  }
  .kt-padding-65-desktop {
    padding: 65px !important;
  }
  .kt-padding-t-65-desktop {
    padding-top: 65px !important;
  }
  .kt-padding-b-65-desktop {
    padding-bottom: 65px !important;
  }
  .kt-padding-l-65-desktop {
    padding-left: 65px !important;
  }
  .kt-padding-r-65-desktop {
    padding-right: 65px !important;
  }
  .kt-margin-70-desktop {
    margin: 70px !important;
  }
  .kt-margin-t-70-desktop {
    margin-top: 70px !important;
  }
  .kt-margin-b-70-desktop {
    margin-bottom: 70px !important;
  }
  .kt-margin-l-70-desktop {
    margin-left: 70px !important;
  }
  .kt-margin-r-70-desktop {
    margin-right: 70px !important;
  }
  .kt-padding-70-desktop {
    padding: 70px !important;
  }
  .kt-padding-t-70-desktop {
    padding-top: 70px !important;
  }
  .kt-padding-b-70-desktop {
    padding-bottom: 70px !important;
  }
  .kt-padding-l-70-desktop {
    padding-left: 70px !important;
  }
  .kt-padding-r-70-desktop {
    padding-right: 70px !important;
  }
  .kt-margin-75-desktop {
    margin: 75px !important;
  }
  .kt-margin-t-75-desktop {
    margin-top: 75px !important;
  }
  .kt-margin-b-75-desktop {
    margin-bottom: 75px !important;
  }
  .kt-margin-l-75-desktop {
    margin-left: 75px !important;
  }
  .kt-margin-r-75-desktop {
    margin-right: 75px !important;
  }
  .kt-padding-75-desktop {
    padding: 75px !important;
  }
  .kt-padding-t-75-desktop {
    padding-top: 75px !important;
  }
  .kt-padding-b-75-desktop {
    padding-bottom: 75px !important;
  }
  .kt-padding-l-75-desktop {
    padding-left: 75px !important;
  }
  .kt-padding-r-75-desktop {
    padding-right: 75px !important;
  }
  .kt-margin-80-desktop {
    margin: 80px !important;
  }
  .kt-margin-t-80-desktop {
    margin-top: 80px !important;
  }
  .kt-margin-b-80-desktop {
    margin-bottom: 80px !important;
  }
  .kt-margin-l-80-desktop {
    margin-left: 80px !important;
  }
  .kt-margin-r-80-desktop {
    margin-right: 80px !important;
  }
  .kt-padding-80-desktop {
    padding: 80px !important;
  }
  .kt-padding-t-80-desktop {
    padding-top: 80px !important;
  }
  .kt-padding-b-80-desktop {
    padding-bottom: 80px !important;
  }
  .kt-padding-l-80-desktop {
    padding-left: 80px !important;
  }
  .kt-padding-r-80-desktop {
    padding-right: 80px !important;
  }
  .kt-margin-85-desktop {
    margin: 85px !important;
  }
  .kt-margin-t-85-desktop {
    margin-top: 85px !important;
  }
  .kt-margin-b-85-desktop {
    margin-bottom: 85px !important;
  }
  .kt-margin-l-85-desktop {
    margin-left: 85px !important;
  }
  .kt-margin-r-85-desktop {
    margin-right: 85px !important;
  }
  .kt-padding-85-desktop {
    padding: 85px !important;
  }
  .kt-padding-t-85-desktop {
    padding-top: 85px !important;
  }
  .kt-padding-b-85-desktop {
    padding-bottom: 85px !important;
  }
  .kt-padding-l-85-desktop {
    padding-left: 85px !important;
  }
  .kt-padding-r-85-desktop {
    padding-right: 85px !important;
  }
  .kt-margin-90-desktop {
    margin: 90px !important;
  }
  .kt-margin-t-90-desktop {
    margin-top: 90px !important;
  }
  .kt-margin-b-90-desktop {
    margin-bottom: 90px !important;
  }
  .kt-margin-l-90-desktop {
    margin-left: 90px !important;
  }
  .kt-margin-r-90-desktop {
    margin-right: 90px !important;
  }
  .kt-padding-90-desktop {
    padding: 90px !important;
  }
  .kt-padding-t-90-desktop {
    padding-top: 90px !important;
  }
  .kt-padding-b-90-desktop {
    padding-bottom: 90px !important;
  }
  .kt-padding-l-90-desktop {
    padding-left: 90px !important;
  }
  .kt-padding-r-90-desktop {
    padding-right: 90px !important;
  }
  .kt-margin-95-desktop {
    margin: 95px !important;
  }
  .kt-margin-t-95-desktop {
    margin-top: 95px !important;
  }
  .kt-margin-b-95-desktop {
    margin-bottom: 95px !important;
  }
  .kt-margin-l-95-desktop {
    margin-left: 95px !important;
  }
  .kt-margin-r-95-desktop {
    margin-right: 95px !important;
  }
  .kt-padding-95-desktop {
    padding: 95px !important;
  }
  .kt-padding-t-95-desktop {
    padding-top: 95px !important;
  }
  .kt-padding-b-95-desktop {
    padding-bottom: 95px !important;
  }
  .kt-padding-l-95-desktop {
    padding-left: 95px !important;
  }
  .kt-padding-r-95-desktop {
    padding-right: 95px !important;
  }
  .kt-margin-100-desktop {
    margin: 100px !important;
  }
  .kt-margin-t-100-desktop {
    margin-top: 100px !important;
  }
  .kt-margin-b-100-desktop {
    margin-bottom: 100px !important;
  }
  .kt-margin-l-100-desktop {
    margin-left: 100px !important;
  }
  .kt-margin-r-100-desktop {
    margin-right: 100px !important;
  }
  .kt-padding-100-desktop {
    padding: 100px !important;
  }
  .kt-padding-t-100-desktop {
    padding-top: 100px !important;
  }
  .kt-padding-b-100-desktop {
    padding-bottom: 100px !important;
  }
  .kt-padding-l-100-desktop {
    padding-left: 100px !important;
  }
  .kt-padding-r-100-desktop {
    padding-right: 100px !important;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .kt-margin-0-tablet {
    margin: 0 !important;
  }
  .kt-margin-t-0-tablet {
    margin-top: 0 !important;
  }
  .kt-margin-b-0-tablet {
    margin-bottom: 0 !important;
  }
  .kt-margin-l-0-tablet {
    margin-left: 0 !important;
  }
  .kt-margin-r-0-tablet {
    margin-right: 0 !important;
  }
  .kt-padding-0-tablet {
    padding: 0 !important;
  }
  .kt-padding-t-0-tablet {
    padding-top: 0 !important;
  }
  .kt-padding-b-0-tablet {
    padding-bottom: 0 !important;
  }
  .kt-padding-l-0-tablet {
    padding-left: 0 !important;
  }
  .kt-padding-r-0-tablet {
    padding-right: 0 !important;
  }
  .kt-margin-5-tablet {
    margin: 5px !important;
  }
  .kt-margin-t-5-tablet {
    margin-top: 5px !important;
  }
  .kt-margin-b-5-tablet {
    margin-bottom: 5px !important;
  }
  .kt-margin-l-5-tablet {
    margin-left: 5px !important;
  }
  .kt-margin-r-5-tablet {
    margin-right: 5px !important;
  }
  .kt-padding-5-tablet {
    padding: 5px !important;
  }
  .kt-padding-t-5-tablet {
    padding-top: 5px !important;
  }
  .kt-padding-b-5-tablet {
    padding-bottom: 5px !important;
  }
  .kt-padding-l-5-tablet {
    padding-left: 5px !important;
  }
  .kt-padding-r-5-tablet {
    padding-right: 5px !important;
  }
  .kt-margin-10-tablet {
    margin: 10px !important;
  }
  .kt-margin-t-10-tablet {
    margin-top: 10px !important;
  }
  .kt-margin-b-10-tablet {
    margin-bottom: 10px !important;
  }
  .kt-margin-l-10-tablet {
    margin-left: 10px !important;
  }
  .kt-margin-r-10-tablet {
    margin-right: 10px !important;
  }
  .kt-padding-10-tablet {
    padding: 10px !important;
  }
  .kt-padding-t-10-tablet {
    padding-top: 10px !important;
  }
  .kt-padding-b-10-tablet {
    padding-bottom: 10px !important;
  }
  .kt-padding-l-10-tablet {
    padding-left: 10px !important;
  }
  .kt-padding-r-10-tablet {
    padding-right: 10px !important;
  }
  .kt-margin-15-tablet {
    margin: 15px !important;
  }
  .kt-margin-t-15-tablet {
    margin-top: 15px !important;
  }
  .kt-margin-b-15-tablet {
    margin-bottom: 15px !important;
  }
  .kt-margin-l-15-tablet {
    margin-left: 15px !important;
  }
  .kt-margin-r-15-tablet {
    margin-right: 15px !important;
  }
  .kt-padding-15-tablet {
    padding: 15px !important;
  }
  .kt-padding-t-15-tablet {
    padding-top: 15px !important;
  }
  .kt-padding-b-15-tablet {
    padding-bottom: 15px !important;
  }
  .kt-padding-l-15-tablet {
    padding-left: 15px !important;
  }
  .kt-padding-r-15-tablet {
    padding-right: 15px !important;
  }
  .kt-margin-20-tablet {
    margin: 20px !important;
  }
  .kt-margin-t-20-tablet {
    margin-top: 20px !important;
  }
  .kt-margin-b-20-tablet {
    margin-bottom: 20px !important;
  }
  .kt-margin-l-20-tablet {
    margin-left: 20px !important;
  }
  .kt-margin-r-20-tablet {
    margin-right: 20px !important;
  }
  .kt-padding-20-tablet {
    padding: 20px !important;
  }
  .kt-padding-t-20-tablet {
    padding-top: 20px !important;
  }
  .kt-padding-b-20-tablet {
    padding-bottom: 20px !important;
  }
  .kt-padding-l-20-tablet {
    padding-left: 20px !important;
  }
  .kt-padding-r-20-tablet {
    padding-right: 20px !important;
  }
  .kt-margin-25-tablet {
    margin: 25px !important;
  }
  .kt-margin-t-25-tablet {
    margin-top: 25px !important;
  }
  .kt-margin-b-25-tablet {
    margin-bottom: 25px !important;
  }
  .kt-margin-l-25-tablet {
    margin-left: 25px !important;
  }
  .kt-margin-r-25-tablet {
    margin-right: 25px !important;
  }
  .kt-padding-25-tablet {
    padding: 25px !important;
  }
  .kt-padding-t-25-tablet {
    padding-top: 25px !important;
  }
  .kt-padding-b-25-tablet {
    padding-bottom: 25px !important;
  }
  .kt-padding-l-25-tablet {
    padding-left: 25px !important;
  }
  .kt-padding-r-25-tablet {
    padding-right: 25px !important;
  }
  .kt-margin-30-tablet {
    margin: 30px !important;
  }
  .kt-margin-t-30-tablet {
    margin-top: 30px !important;
  }
  .kt-margin-b-30-tablet {
    margin-bottom: 30px !important;
  }
  .kt-margin-l-30-tablet {
    margin-left: 30px !important;
  }
  .kt-margin-r-30-tablet {
    margin-right: 30px !important;
  }
  .kt-padding-30-tablet {
    padding: 30px !important;
  }
  .kt-padding-t-30-tablet {
    padding-top: 30px !important;
  }
  .kt-padding-b-30-tablet {
    padding-bottom: 30px !important;
  }
  .kt-padding-l-30-tablet {
    padding-left: 30px !important;
  }
  .kt-padding-r-30-tablet {
    padding-right: 30px !important;
  }
  .kt-margin-35-tablet {
    margin: 35px !important;
  }
  .kt-margin-t-35-tablet {
    margin-top: 35px !important;
  }
  .kt-margin-b-35-tablet {
    margin-bottom: 35px !important;
  }
  .kt-margin-l-35-tablet {
    margin-left: 35px !important;
  }
  .kt-margin-r-35-tablet {
    margin-right: 35px !important;
  }
  .kt-padding-35-tablet {
    padding: 35px !important;
  }
  .kt-padding-t-35-tablet {
    padding-top: 35px !important;
  }
  .kt-padding-b-35-tablet {
    padding-bottom: 35px !important;
  }
  .kt-padding-l-35-tablet {
    padding-left: 35px !important;
  }
  .kt-padding-r-35-tablet {
    padding-right: 35px !important;
  }
  .kt-margin-40-tablet {
    margin: 40px !important;
  }
  .kt-margin-t-40-tablet {
    margin-top: 40px !important;
  }
  .kt-margin-b-40-tablet {
    margin-bottom: 40px !important;
  }
  .kt-margin-l-40-tablet {
    margin-left: 40px !important;
  }
  .kt-margin-r-40-tablet {
    margin-right: 40px !important;
  }
  .kt-padding-40-tablet {
    padding: 40px !important;
  }
  .kt-padding-t-40-tablet {
    padding-top: 40px !important;
  }
  .kt-padding-b-40-tablet {
    padding-bottom: 40px !important;
  }
  .kt-padding-l-40-tablet {
    padding-left: 40px !important;
  }
  .kt-padding-r-40-tablet {
    padding-right: 40px !important;
  }
  .kt-margin-45-tablet {
    margin: 45px !important;
  }
  .kt-margin-t-45-tablet {
    margin-top: 45px !important;
  }
  .kt-margin-b-45-tablet {
    margin-bottom: 45px !important;
  }
  .kt-margin-l-45-tablet {
    margin-left: 45px !important;
  }
  .kt-margin-r-45-tablet {
    margin-right: 45px !important;
  }
  .kt-padding-45-tablet {
    padding: 45px !important;
  }
  .kt-padding-t-45-tablet {
    padding-top: 45px !important;
  }
  .kt-padding-b-45-tablet {
    padding-bottom: 45px !important;
  }
  .kt-padding-l-45-tablet {
    padding-left: 45px !important;
  }
  .kt-padding-r-45-tablet {
    padding-right: 45px !important;
  }
  .kt-margin-50-tablet {
    margin: 50px !important;
  }
  .kt-margin-t-50-tablet {
    margin-top: 50px !important;
  }
  .kt-margin-b-50-tablet {
    margin-bottom: 50px !important;
  }
  .kt-margin-l-50-tablet {
    margin-left: 50px !important;
  }
  .kt-margin-r-50-tablet {
    margin-right: 50px !important;
  }
  .kt-padding-50-tablet {
    padding: 50px !important;
  }
  .kt-padding-t-50-tablet {
    padding-top: 50px !important;
  }
  .kt-padding-b-50-tablet {
    padding-bottom: 50px !important;
  }
  .kt-padding-l-50-tablet {
    padding-left: 50px !important;
  }
  .kt-padding-r-50-tablet {
    padding-right: 50px !important;
  }
  .kt-margin-55-tablet {
    margin: 55px !important;
  }
  .kt-margin-t-55-tablet {
    margin-top: 55px !important;
  }
  .kt-margin-b-55-tablet {
    margin-bottom: 55px !important;
  }
  .kt-margin-l-55-tablet {
    margin-left: 55px !important;
  }
  .kt-margin-r-55-tablet {
    margin-right: 55px !important;
  }
  .kt-padding-55-tablet {
    padding: 55px !important;
  }
  .kt-padding-t-55-tablet {
    padding-top: 55px !important;
  }
  .kt-padding-b-55-tablet {
    padding-bottom: 55px !important;
  }
  .kt-padding-l-55-tablet {
    padding-left: 55px !important;
  }
  .kt-padding-r-55-tablet {
    padding-right: 55px !important;
  }
  .kt-margin-60-tablet {
    margin: 60px !important;
  }
  .kt-margin-t-60-tablet {
    margin-top: 60px !important;
  }
  .kt-margin-b-60-tablet {
    margin-bottom: 60px !important;
  }
  .kt-margin-l-60-tablet {
    margin-left: 60px !important;
  }
  .kt-margin-r-60-tablet {
    margin-right: 60px !important;
  }
  .kt-padding-60-tablet {
    padding: 60px !important;
  }
  .kt-padding-t-60-tablet {
    padding-top: 60px !important;
  }
  .kt-padding-b-60-tablet {
    padding-bottom: 60px !important;
  }
  .kt-padding-l-60-tablet {
    padding-left: 60px !important;
  }
  .kt-padding-r-60-tablet {
    padding-right: 60px !important;
  }
  .kt-margin-65-tablet {
    margin: 65px !important;
  }
  .kt-margin-t-65-tablet {
    margin-top: 65px !important;
  }
  .kt-margin-b-65-tablet {
    margin-bottom: 65px !important;
  }
  .kt-margin-l-65-tablet {
    margin-left: 65px !important;
  }
  .kt-margin-r-65-tablet {
    margin-right: 65px !important;
  }
  .kt-padding-65-tablet {
    padding: 65px !important;
  }
  .kt-padding-t-65-tablet {
    padding-top: 65px !important;
  }
  .kt-padding-b-65-tablet {
    padding-bottom: 65px !important;
  }
  .kt-padding-l-65-tablet {
    padding-left: 65px !important;
  }
  .kt-padding-r-65-tablet {
    padding-right: 65px !important;
  }
  .kt-margin-70-tablet {
    margin: 70px !important;
  }
  .kt-margin-t-70-tablet {
    margin-top: 70px !important;
  }
  .kt-margin-b-70-tablet {
    margin-bottom: 70px !important;
  }
  .kt-margin-l-70-tablet {
    margin-left: 70px !important;
  }
  .kt-margin-r-70-tablet {
    margin-right: 70px !important;
  }
  .kt-padding-70-tablet {
    padding: 70px !important;
  }
  .kt-padding-t-70-tablet {
    padding-top: 70px !important;
  }
  .kt-padding-b-70-tablet {
    padding-bottom: 70px !important;
  }
  .kt-padding-l-70-tablet {
    padding-left: 70px !important;
  }
  .kt-padding-r-70-tablet {
    padding-right: 70px !important;
  }
  .kt-margin-75-tablet {
    margin: 75px !important;
  }
  .kt-margin-t-75-tablet {
    margin-top: 75px !important;
  }
  .kt-margin-b-75-tablet {
    margin-bottom: 75px !important;
  }
  .kt-margin-l-75-tablet {
    margin-left: 75px !important;
  }
  .kt-margin-r-75-tablet {
    margin-right: 75px !important;
  }
  .kt-padding-75-tablet {
    padding: 75px !important;
  }
  .kt-padding-t-75-tablet {
    padding-top: 75px !important;
  }
  .kt-padding-b-75-tablet {
    padding-bottom: 75px !important;
  }
  .kt-padding-l-75-tablet {
    padding-left: 75px !important;
  }
  .kt-padding-r-75-tablet {
    padding-right: 75px !important;
  }
  .kt-margin-80-tablet {
    margin: 80px !important;
  }
  .kt-margin-t-80-tablet {
    margin-top: 80px !important;
  }
  .kt-margin-b-80-tablet {
    margin-bottom: 80px !important;
  }
  .kt-margin-l-80-tablet {
    margin-left: 80px !important;
  }
  .kt-margin-r-80-tablet {
    margin-right: 80px !important;
  }
  .kt-padding-80-tablet {
    padding: 80px !important;
  }
  .kt-padding-t-80-tablet {
    padding-top: 80px !important;
  }
  .kt-padding-b-80-tablet {
    padding-bottom: 80px !important;
  }
  .kt-padding-l-80-tablet {
    padding-left: 80px !important;
  }
  .kt-padding-r-80-tablet {
    padding-right: 80px !important;
  }
  .kt-margin-85-tablet {
    margin: 85px !important;
  }
  .kt-margin-t-85-tablet {
    margin-top: 85px !important;
  }
  .kt-margin-b-85-tablet {
    margin-bottom: 85px !important;
  }
  .kt-margin-l-85-tablet {
    margin-left: 85px !important;
  }
  .kt-margin-r-85-tablet {
    margin-right: 85px !important;
  }
  .kt-padding-85-tablet {
    padding: 85px !important;
  }
  .kt-padding-t-85-tablet {
    padding-top: 85px !important;
  }
  .kt-padding-b-85-tablet {
    padding-bottom: 85px !important;
  }
  .kt-padding-l-85-tablet {
    padding-left: 85px !important;
  }
  .kt-padding-r-85-tablet {
    padding-right: 85px !important;
  }
  .kt-margin-90-tablet {
    margin: 90px !important;
  }
  .kt-margin-t-90-tablet {
    margin-top: 90px !important;
  }
  .kt-margin-b-90-tablet {
    margin-bottom: 90px !important;
  }
  .kt-margin-l-90-tablet {
    margin-left: 90px !important;
  }
  .kt-margin-r-90-tablet {
    margin-right: 90px !important;
  }
  .kt-padding-90-tablet {
    padding: 90px !important;
  }
  .kt-padding-t-90-tablet {
    padding-top: 90px !important;
  }
  .kt-padding-b-90-tablet {
    padding-bottom: 90px !important;
  }
  .kt-padding-l-90-tablet {
    padding-left: 90px !important;
  }
  .kt-padding-r-90-tablet {
    padding-right: 90px !important;
  }
  .kt-margin-95-tablet {
    margin: 95px !important;
  }
  .kt-margin-t-95-tablet {
    margin-top: 95px !important;
  }
  .kt-margin-b-95-tablet {
    margin-bottom: 95px !important;
  }
  .kt-margin-l-95-tablet {
    margin-left: 95px !important;
  }
  .kt-margin-r-95-tablet {
    margin-right: 95px !important;
  }
  .kt-padding-95-tablet {
    padding: 95px !important;
  }
  .kt-padding-t-95-tablet {
    padding-top: 95px !important;
  }
  .kt-padding-b-95-tablet {
    padding-bottom: 95px !important;
  }
  .kt-padding-l-95-tablet {
    padding-left: 95px !important;
  }
  .kt-padding-r-95-tablet {
    padding-right: 95px !important;
  }
  .kt-margin-100-tablet {
    margin: 100px !important;
  }
  .kt-margin-t-100-tablet {
    margin-top: 100px !important;
  }
  .kt-margin-b-100-tablet {
    margin-bottom: 100px !important;
  }
  .kt-margin-l-100-tablet {
    margin-left: 100px !important;
  }
  .kt-margin-r-100-tablet {
    margin-right: 100px !important;
  }
  .kt-padding-100-tablet {
    padding: 100px !important;
  }
  .kt-padding-t-100-tablet {
    padding-top: 100px !important;
  }
  .kt-padding-b-100-tablet {
    padding-bottom: 100px !important;
  }
  .kt-padding-l-100-tablet {
    padding-left: 100px !important;
  }
  .kt-padding-r-100-tablet {
    padding-right: 100px !important;
  }
}
@media (max-width: 1024px) {
  .kt-margin-0-tablet-and-mobile {
    margin: 0 !important;
  }
  .kt-margin-t-0-tablet-and-mobile {
    margin-top: 0 !important;
  }
  .kt-margin-b-0-tablet-and-mobile {
    margin-bottom: 0 !important;
  }
  .kt-margin-l-0-tablet-and-mobile {
    margin-left: 0 !important;
  }
  .kt-margin-r-0-tablet-and-mobile {
    margin-right: 0 !important;
  }
  .kt-padding-0-tablet-and-mobile {
    padding: 0 !important;
  }
  .kt-padding-t-0-tablet-and-mobile {
    padding-top: 0 !important;
  }
  .kt-padding-b-0-tablet-and-mobile {
    padding-bottom: 0 !important;
  }
  .kt-padding-l-0-tablet-and-mobile {
    padding-left: 0 !important;
  }
  .kt-padding-r-0-tablet-and-mobile {
    padding-right: 0 !important;
  }
  .kt-margin-5-tablet-and-mobile {
    margin: 5px !important;
  }
  .kt-margin-t-5-tablet-and-mobile {
    margin-top: 5px !important;
  }
  .kt-margin-b-5-tablet-and-mobile {
    margin-bottom: 5px !important;
  }
  .kt-margin-l-5-tablet-and-mobile {
    margin-left: 5px !important;
  }
  .kt-margin-r-5-tablet-and-mobile {
    margin-right: 5px !important;
  }
  .kt-padding-5-tablet-and-mobile {
    padding: 5px !important;
  }
  .kt-padding-t-5-tablet-and-mobile {
    padding-top: 5px !important;
  }
  .kt-padding-b-5-tablet-and-mobile {
    padding-bottom: 5px !important;
  }
  .kt-padding-l-5-tablet-and-mobile {
    padding-left: 5px !important;
  }
  .kt-padding-r-5-tablet-and-mobile {
    padding-right: 5px !important;
  }
  .kt-margin-10-tablet-and-mobile {
    margin: 10px !important;
  }
  .kt-margin-t-10-tablet-and-mobile {
    margin-top: 10px !important;
  }
  .kt-margin-b-10-tablet-and-mobile {
    margin-bottom: 10px !important;
  }
  .kt-margin-l-10-tablet-and-mobile {
    margin-left: 10px !important;
  }
  .kt-margin-r-10-tablet-and-mobile {
    margin-right: 10px !important;
  }
  .kt-padding-10-tablet-and-mobile {
    padding: 10px !important;
  }
  .kt-padding-t-10-tablet-and-mobile {
    padding-top: 10px !important;
  }
  .kt-padding-b-10-tablet-and-mobile {
    padding-bottom: 10px !important;
  }
  .kt-padding-l-10-tablet-and-mobile {
    padding-left: 10px !important;
  }
  .kt-padding-r-10-tablet-and-mobile {
    padding-right: 10px !important;
  }
  .kt-margin-15-tablet-and-mobile {
    margin: 15px !important;
  }
  .kt-margin-t-15-tablet-and-mobile {
    margin-top: 15px !important;
  }
  .kt-margin-b-15-tablet-and-mobile {
    margin-bottom: 15px !important;
  }
  .kt-margin-l-15-tablet-and-mobile {
    margin-left: 15px !important;
  }
  .kt-margin-r-15-tablet-and-mobile {
    margin-right: 15px !important;
  }
  .kt-padding-15-tablet-and-mobile {
    padding: 15px !important;
  }
  .kt-padding-t-15-tablet-and-mobile {
    padding-top: 15px !important;
  }
  .kt-padding-b-15-tablet-and-mobile {
    padding-bottom: 15px !important;
  }
  .kt-padding-l-15-tablet-and-mobile {
    padding-left: 15px !important;
  }
  .kt-padding-r-15-tablet-and-mobile {
    padding-right: 15px !important;
  }
  .kt-margin-20-tablet-and-mobile {
    margin: 20px !important;
  }
  .kt-margin-t-20-tablet-and-mobile {
    margin-top: 20px !important;
  }
  .kt-margin-b-20-tablet-and-mobile {
    margin-bottom: 20px !important;
  }
  .kt-margin-l-20-tablet-and-mobile {
    margin-left: 20px !important;
  }
  .kt-margin-r-20-tablet-and-mobile {
    margin-right: 20px !important;
  }
  .kt-padding-20-tablet-and-mobile {
    padding: 20px !important;
  }
  .kt-padding-t-20-tablet-and-mobile {
    padding-top: 20px !important;
  }
  .kt-padding-b-20-tablet-and-mobile {
    padding-bottom: 20px !important;
  }
  .kt-padding-l-20-tablet-and-mobile {
    padding-left: 20px !important;
  }
  .kt-padding-r-20-tablet-and-mobile {
    padding-right: 20px !important;
  }
  .kt-margin-25-tablet-and-mobile {
    margin: 25px !important;
  }
  .kt-margin-t-25-tablet-and-mobile {
    margin-top: 25px !important;
  }
  .kt-margin-b-25-tablet-and-mobile {
    margin-bottom: 25px !important;
  }
  .kt-margin-l-25-tablet-and-mobile {
    margin-left: 25px !important;
  }
  .kt-margin-r-25-tablet-and-mobile {
    margin-right: 25px !important;
  }
  .kt-padding-25-tablet-and-mobile {
    padding: 25px !important;
  }
  .kt-padding-t-25-tablet-and-mobile {
    padding-top: 25px !important;
  }
  .kt-padding-b-25-tablet-and-mobile {
    padding-bottom: 25px !important;
  }
  .kt-padding-l-25-tablet-and-mobile {
    padding-left: 25px !important;
  }
  .kt-padding-r-25-tablet-and-mobile {
    padding-right: 25px !important;
  }
  .kt-margin-30-tablet-and-mobile {
    margin: 30px !important;
  }
  .kt-margin-t-30-tablet-and-mobile {
    margin-top: 30px !important;
  }
  .kt-margin-b-30-tablet-and-mobile {
    margin-bottom: 30px !important;
  }
  .kt-margin-l-30-tablet-and-mobile {
    margin-left: 30px !important;
  }
  .kt-margin-r-30-tablet-and-mobile {
    margin-right: 30px !important;
  }
  .kt-padding-30-tablet-and-mobile {
    padding: 30px !important;
  }
  .kt-padding-t-30-tablet-and-mobile {
    padding-top: 30px !important;
  }
  .kt-padding-b-30-tablet-and-mobile {
    padding-bottom: 30px !important;
  }
  .kt-padding-l-30-tablet-and-mobile {
    padding-left: 30px !important;
  }
  .kt-padding-r-30-tablet-and-mobile {
    padding-right: 30px !important;
  }
  .kt-margin-35-tablet-and-mobile {
    margin: 35px !important;
  }
  .kt-margin-t-35-tablet-and-mobile {
    margin-top: 35px !important;
  }
  .kt-margin-b-35-tablet-and-mobile {
    margin-bottom: 35px !important;
  }
  .kt-margin-l-35-tablet-and-mobile {
    margin-left: 35px !important;
  }
  .kt-margin-r-35-tablet-and-mobile {
    margin-right: 35px !important;
  }
  .kt-padding-35-tablet-and-mobile {
    padding: 35px !important;
  }
  .kt-padding-t-35-tablet-and-mobile {
    padding-top: 35px !important;
  }
  .kt-padding-b-35-tablet-and-mobile {
    padding-bottom: 35px !important;
  }
  .kt-padding-l-35-tablet-and-mobile {
    padding-left: 35px !important;
  }
  .kt-padding-r-35-tablet-and-mobile {
    padding-right: 35px !important;
  }
  .kt-margin-40-tablet-and-mobile {
    margin: 40px !important;
  }
  .kt-margin-t-40-tablet-and-mobile {
    margin-top: 40px !important;
  }
  .kt-margin-b-40-tablet-and-mobile {
    margin-bottom: 40px !important;
  }
  .kt-margin-l-40-tablet-and-mobile {
    margin-left: 40px !important;
  }
  .kt-margin-r-40-tablet-and-mobile {
    margin-right: 40px !important;
  }
  .kt-padding-40-tablet-and-mobile {
    padding: 40px !important;
  }
  .kt-padding-t-40-tablet-and-mobile {
    padding-top: 40px !important;
  }
  .kt-padding-b-40-tablet-and-mobile {
    padding-bottom: 40px !important;
  }
  .kt-padding-l-40-tablet-and-mobile {
    padding-left: 40px !important;
  }
  .kt-padding-r-40-tablet-and-mobile {
    padding-right: 40px !important;
  }
  .kt-margin-45-tablet-and-mobile {
    margin: 45px !important;
  }
  .kt-margin-t-45-tablet-and-mobile {
    margin-top: 45px !important;
  }
  .kt-margin-b-45-tablet-and-mobile {
    margin-bottom: 45px !important;
  }
  .kt-margin-l-45-tablet-and-mobile {
    margin-left: 45px !important;
  }
  .kt-margin-r-45-tablet-and-mobile {
    margin-right: 45px !important;
  }
  .kt-padding-45-tablet-and-mobile {
    padding: 45px !important;
  }
  .kt-padding-t-45-tablet-and-mobile {
    padding-top: 45px !important;
  }
  .kt-padding-b-45-tablet-and-mobile {
    padding-bottom: 45px !important;
  }
  .kt-padding-l-45-tablet-and-mobile {
    padding-left: 45px !important;
  }
  .kt-padding-r-45-tablet-and-mobile {
    padding-right: 45px !important;
  }
  .kt-margin-50-tablet-and-mobile {
    margin: 50px !important;
  }
  .kt-margin-t-50-tablet-and-mobile {
    margin-top: 50px !important;
  }
  .kt-margin-b-50-tablet-and-mobile {
    margin-bottom: 50px !important;
  }
  .kt-margin-l-50-tablet-and-mobile {
    margin-left: 50px !important;
  }
  .kt-margin-r-50-tablet-and-mobile {
    margin-right: 50px !important;
  }
  .kt-padding-50-tablet-and-mobile {
    padding: 50px !important;
  }
  .kt-padding-t-50-tablet-and-mobile {
    padding-top: 50px !important;
  }
  .kt-padding-b-50-tablet-and-mobile {
    padding-bottom: 50px !important;
  }
  .kt-padding-l-50-tablet-and-mobile {
    padding-left: 50px !important;
  }
  .kt-padding-r-50-tablet-and-mobile {
    padding-right: 50px !important;
  }
  .kt-margin-55-tablet-and-mobile {
    margin: 55px !important;
  }
  .kt-margin-t-55-tablet-and-mobile {
    margin-top: 55px !important;
  }
  .kt-margin-b-55-tablet-and-mobile {
    margin-bottom: 55px !important;
  }
  .kt-margin-l-55-tablet-and-mobile {
    margin-left: 55px !important;
  }
  .kt-margin-r-55-tablet-and-mobile {
    margin-right: 55px !important;
  }
  .kt-padding-55-tablet-and-mobile {
    padding: 55px !important;
  }
  .kt-padding-t-55-tablet-and-mobile {
    padding-top: 55px !important;
  }
  .kt-padding-b-55-tablet-and-mobile {
    padding-bottom: 55px !important;
  }
  .kt-padding-l-55-tablet-and-mobile {
    padding-left: 55px !important;
  }
  .kt-padding-r-55-tablet-and-mobile {
    padding-right: 55px !important;
  }
  .kt-margin-60-tablet-and-mobile {
    margin: 60px !important;
  }
  .kt-margin-t-60-tablet-and-mobile {
    margin-top: 60px !important;
  }
  .kt-margin-b-60-tablet-and-mobile {
    margin-bottom: 60px !important;
  }
  .kt-margin-l-60-tablet-and-mobile {
    margin-left: 60px !important;
  }
  .kt-margin-r-60-tablet-and-mobile {
    margin-right: 60px !important;
  }
  .kt-padding-60-tablet-and-mobile {
    padding: 60px !important;
  }
  .kt-padding-t-60-tablet-and-mobile {
    padding-top: 60px !important;
  }
  .kt-padding-b-60-tablet-and-mobile {
    padding-bottom: 60px !important;
  }
  .kt-padding-l-60-tablet-and-mobile {
    padding-left: 60px !important;
  }
  .kt-padding-r-60-tablet-and-mobile {
    padding-right: 60px !important;
  }
  .kt-margin-65-tablet-and-mobile {
    margin: 65px !important;
  }
  .kt-margin-t-65-tablet-and-mobile {
    margin-top: 65px !important;
  }
  .kt-margin-b-65-tablet-and-mobile {
    margin-bottom: 65px !important;
  }
  .kt-margin-l-65-tablet-and-mobile {
    margin-left: 65px !important;
  }
  .kt-margin-r-65-tablet-and-mobile {
    margin-right: 65px !important;
  }
  .kt-padding-65-tablet-and-mobile {
    padding: 65px !important;
  }
  .kt-padding-t-65-tablet-and-mobile {
    padding-top: 65px !important;
  }
  .kt-padding-b-65-tablet-and-mobile {
    padding-bottom: 65px !important;
  }
  .kt-padding-l-65-tablet-and-mobile {
    padding-left: 65px !important;
  }
  .kt-padding-r-65-tablet-and-mobile {
    padding-right: 65px !important;
  }
  .kt-margin-70-tablet-and-mobile {
    margin: 70px !important;
  }
  .kt-margin-t-70-tablet-and-mobile {
    margin-top: 70px !important;
  }
  .kt-margin-b-70-tablet-and-mobile {
    margin-bottom: 70px !important;
  }
  .kt-margin-l-70-tablet-and-mobile {
    margin-left: 70px !important;
  }
  .kt-margin-r-70-tablet-and-mobile {
    margin-right: 70px !important;
  }
  .kt-padding-70-tablet-and-mobile {
    padding: 70px !important;
  }
  .kt-padding-t-70-tablet-and-mobile {
    padding-top: 70px !important;
  }
  .kt-padding-b-70-tablet-and-mobile {
    padding-bottom: 70px !important;
  }
  .kt-padding-l-70-tablet-and-mobile {
    padding-left: 70px !important;
  }
  .kt-padding-r-70-tablet-and-mobile {
    padding-right: 70px !important;
  }
  .kt-margin-75-tablet-and-mobile {
    margin: 75px !important;
  }
  .kt-margin-t-75-tablet-and-mobile {
    margin-top: 75px !important;
  }
  .kt-margin-b-75-tablet-and-mobile {
    margin-bottom: 75px !important;
  }
  .kt-margin-l-75-tablet-and-mobile {
    margin-left: 75px !important;
  }
  .kt-margin-r-75-tablet-and-mobile {
    margin-right: 75px !important;
  }
  .kt-padding-75-tablet-and-mobile {
    padding: 75px !important;
  }
  .kt-padding-t-75-tablet-and-mobile {
    padding-top: 75px !important;
  }
  .kt-padding-b-75-tablet-and-mobile {
    padding-bottom: 75px !important;
  }
  .kt-padding-l-75-tablet-and-mobile {
    padding-left: 75px !important;
  }
  .kt-padding-r-75-tablet-and-mobile {
    padding-right: 75px !important;
  }
  .kt-margin-80-tablet-and-mobile {
    margin: 80px !important;
  }
  .kt-margin-t-80-tablet-and-mobile {
    margin-top: 80px !important;
  }
  .kt-margin-b-80-tablet-and-mobile {
    margin-bottom: 80px !important;
  }
  .kt-margin-l-80-tablet-and-mobile {
    margin-left: 80px !important;
  }
  .kt-margin-r-80-tablet-and-mobile {
    margin-right: 80px !important;
  }
  .kt-padding-80-tablet-and-mobile {
    padding: 80px !important;
  }
  .kt-padding-t-80-tablet-and-mobile {
    padding-top: 80px !important;
  }
  .kt-padding-b-80-tablet-and-mobile {
    padding-bottom: 80px !important;
  }
  .kt-padding-l-80-tablet-and-mobile {
    padding-left: 80px !important;
  }
  .kt-padding-r-80-tablet-and-mobile {
    padding-right: 80px !important;
  }
  .kt-margin-85-tablet-and-mobile {
    margin: 85px !important;
  }
  .kt-margin-t-85-tablet-and-mobile {
    margin-top: 85px !important;
  }
  .kt-margin-b-85-tablet-and-mobile {
    margin-bottom: 85px !important;
  }
  .kt-margin-l-85-tablet-and-mobile {
    margin-left: 85px !important;
  }
  .kt-margin-r-85-tablet-and-mobile {
    margin-right: 85px !important;
  }
  .kt-padding-85-tablet-and-mobile {
    padding: 85px !important;
  }
  .kt-padding-t-85-tablet-and-mobile {
    padding-top: 85px !important;
  }
  .kt-padding-b-85-tablet-and-mobile {
    padding-bottom: 85px !important;
  }
  .kt-padding-l-85-tablet-and-mobile {
    padding-left: 85px !important;
  }
  .kt-padding-r-85-tablet-and-mobile {
    padding-right: 85px !important;
  }
  .kt-margin-90-tablet-and-mobile {
    margin: 90px !important;
  }
  .kt-margin-t-90-tablet-and-mobile {
    margin-top: 90px !important;
  }
  .kt-margin-b-90-tablet-and-mobile {
    margin-bottom: 90px !important;
  }
  .kt-margin-l-90-tablet-and-mobile {
    margin-left: 90px !important;
  }
  .kt-margin-r-90-tablet-and-mobile {
    margin-right: 90px !important;
  }
  .kt-padding-90-tablet-and-mobile {
    padding: 90px !important;
  }
  .kt-padding-t-90-tablet-and-mobile {
    padding-top: 90px !important;
  }
  .kt-padding-b-90-tablet-and-mobile {
    padding-bottom: 90px !important;
  }
  .kt-padding-l-90-tablet-and-mobile {
    padding-left: 90px !important;
  }
  .kt-padding-r-90-tablet-and-mobile {
    padding-right: 90px !important;
  }
  .kt-margin-95-tablet-and-mobile {
    margin: 95px !important;
  }
  .kt-margin-t-95-tablet-and-mobile {
    margin-top: 95px !important;
  }
  .kt-margin-b-95-tablet-and-mobile {
    margin-bottom: 95px !important;
  }
  .kt-margin-l-95-tablet-and-mobile {
    margin-left: 95px !important;
  }
  .kt-margin-r-95-tablet-and-mobile {
    margin-right: 95px !important;
  }
  .kt-padding-95-tablet-and-mobile {
    padding: 95px !important;
  }
  .kt-padding-t-95-tablet-and-mobile {
    padding-top: 95px !important;
  }
  .kt-padding-b-95-tablet-and-mobile {
    padding-bottom: 95px !important;
  }
  .kt-padding-l-95-tablet-and-mobile {
    padding-left: 95px !important;
  }
  .kt-padding-r-95-tablet-and-mobile {
    padding-right: 95px !important;
  }
  .kt-margin-100-tablet-and-mobile {
    margin: 100px !important;
  }
  .kt-margin-t-100-tablet-and-mobile {
    margin-top: 100px !important;
  }
  .kt-margin-b-100-tablet-and-mobile {
    margin-bottom: 100px !important;
  }
  .kt-margin-l-100-tablet-and-mobile {
    margin-left: 100px !important;
  }
  .kt-margin-r-100-tablet-and-mobile {
    margin-right: 100px !important;
  }
  .kt-padding-100-tablet-and-mobile {
    padding: 100px !important;
  }
  .kt-padding-t-100-tablet-and-mobile {
    padding-top: 100px !important;
  }
  .kt-padding-b-100-tablet-and-mobile {
    padding-bottom: 100px !important;
  }
  .kt-padding-l-100-tablet-and-mobile {
    padding-left: 100px !important;
  }
  .kt-padding-r-100-tablet-and-mobile {
    padding-right: 100px !important;
  }
}
@media (max-width: 768px) {
  .kt-margin-0-mobile {
    margin: 0 !important;
  }
  .kt-margin-t-0-mobile {
    margin-top: 0 !important;
  }
  .kt-margin-b-0-mobile {
    margin-bottom: 0 !important;
  }
  .kt-margin-l-0-mobile {
    margin-left: 0 !important;
  }
  .kt-margin-r-0-mobile {
    margin-right: 0 !important;
  }
  .kt-padding-0-mobile {
    padding: 0 !important;
  }
  .kt-padding-t-0-mobile {
    padding-top: 0 !important;
  }
  .kt-padding-b-0-mobile {
    padding-bottom: 0 !important;
  }
  .kt-padding-l-0-mobile {
    padding-left: 0 !important;
  }
  .kt-padding-r-0-mobile {
    padding-right: 0 !important;
  }
  .kt-margin-5-mobile {
    margin: 5px !important;
  }
  .kt-margin-t-5-mobile {
    margin-top: 5px !important;
  }
  .kt-margin-b-5-mobile {
    margin-bottom: 5px !important;
  }
  .kt-margin-l-5-mobile {
    margin-left: 5px !important;
  }
  .kt-margin-r-5-mobile {
    margin-right: 5px !important;
  }
  .kt-padding-5-mobile {
    padding: 5px !important;
  }
  .kt-padding-t-5-mobile {
    padding-top: 5px !important;
  }
  .kt-padding-b-5-mobile {
    padding-bottom: 5px !important;
  }
  .kt-padding-l-5-mobile {
    padding-left: 5px !important;
  }
  .kt-padding-r-5-mobile {
    padding-right: 5px !important;
  }
  .kt-margin-10-mobile {
    margin: 10px !important;
  }
  .kt-margin-t-10-mobile {
    margin-top: 10px !important;
  }
  .kt-margin-b-10-mobile {
    margin-bottom: 10px !important;
  }
  .kt-margin-l-10-mobile {
    margin-left: 10px !important;
  }
  .kt-margin-r-10-mobile {
    margin-right: 10px !important;
  }
  .kt-padding-10-mobile {
    padding: 10px !important;
  }
  .kt-padding-t-10-mobile {
    padding-top: 10px !important;
  }
  .kt-padding-b-10-mobile {
    padding-bottom: 10px !important;
  }
  .kt-padding-l-10-mobile {
    padding-left: 10px !important;
  }
  .kt-padding-r-10-mobile {
    padding-right: 10px !important;
  }
  .kt-margin-15-mobile {
    margin: 15px !important;
  }
  .kt-margin-t-15-mobile {
    margin-top: 15px !important;
  }
  .kt-margin-b-15-mobile {
    margin-bottom: 15px !important;
  }
  .kt-margin-l-15-mobile {
    margin-left: 15px !important;
  }
  .kt-margin-r-15-mobile {
    margin-right: 15px !important;
  }
  .kt-padding-15-mobile {
    padding: 15px !important;
  }
  .kt-padding-t-15-mobile {
    padding-top: 15px !important;
  }
  .kt-padding-b-15-mobile {
    padding-bottom: 15px !important;
  }
  .kt-padding-l-15-mobile {
    padding-left: 15px !important;
  }
  .kt-padding-r-15-mobile {
    padding-right: 15px !important;
  }
  .kt-margin-20-mobile {
    margin: 20px !important;
  }
  .kt-margin-t-20-mobile {
    margin-top: 20px !important;
  }
  .kt-margin-b-20-mobile {
    margin-bottom: 20px !important;
  }
  .kt-margin-l-20-mobile {
    margin-left: 20px !important;
  }
  .kt-margin-r-20-mobile {
    margin-right: 20px !important;
  }
  .kt-padding-20-mobile {
    padding: 20px !important;
  }
  .kt-padding-t-20-mobile {
    padding-top: 20px !important;
  }
  .kt-padding-b-20-mobile {
    padding-bottom: 20px !important;
  }
  .kt-padding-l-20-mobile {
    padding-left: 20px !important;
  }
  .kt-padding-r-20-mobile {
    padding-right: 20px !important;
  }
  .kt-margin-25-mobile {
    margin: 25px !important;
  }
  .kt-margin-t-25-mobile {
    margin-top: 25px !important;
  }
  .kt-margin-b-25-mobile {
    margin-bottom: 25px !important;
  }
  .kt-margin-l-25-mobile {
    margin-left: 25px !important;
  }
  .kt-margin-r-25-mobile {
    margin-right: 25px !important;
  }
  .kt-padding-25-mobile {
    padding: 25px !important;
  }
  .kt-padding-t-25-mobile {
    padding-top: 25px !important;
  }
  .kt-padding-b-25-mobile {
    padding-bottom: 25px !important;
  }
  .kt-padding-l-25-mobile {
    padding-left: 25px !important;
  }
  .kt-padding-r-25-mobile {
    padding-right: 25px !important;
  }
  .kt-margin-30-mobile {
    margin: 30px !important;
  }
  .kt-margin-t-30-mobile {
    margin-top: 30px !important;
  }
  .kt-margin-b-30-mobile {
    margin-bottom: 30px !important;
  }
  .kt-margin-l-30-mobile {
    margin-left: 30px !important;
  }
  .kt-margin-r-30-mobile {
    margin-right: 30px !important;
  }
  .kt-padding-30-mobile {
    padding: 30px !important;
  }
  .kt-padding-t-30-mobile {
    padding-top: 30px !important;
  }
  .kt-padding-b-30-mobile {
    padding-bottom: 30px !important;
  }
  .kt-padding-l-30-mobile {
    padding-left: 30px !important;
  }
  .kt-padding-r-30-mobile {
    padding-right: 30px !important;
  }
  .kt-margin-35-mobile {
    margin: 35px !important;
  }
  .kt-margin-t-35-mobile {
    margin-top: 35px !important;
  }
  .kt-margin-b-35-mobile {
    margin-bottom: 35px !important;
  }
  .kt-margin-l-35-mobile {
    margin-left: 35px !important;
  }
  .kt-margin-r-35-mobile {
    margin-right: 35px !important;
  }
  .kt-padding-35-mobile {
    padding: 35px !important;
  }
  .kt-padding-t-35-mobile {
    padding-top: 35px !important;
  }
  .kt-padding-b-35-mobile {
    padding-bottom: 35px !important;
  }
  .kt-padding-l-35-mobile {
    padding-left: 35px !important;
  }
  .kt-padding-r-35-mobile {
    padding-right: 35px !important;
  }
  .kt-margin-40-mobile {
    margin: 40px !important;
  }
  .kt-margin-t-40-mobile {
    margin-top: 40px !important;
  }
  .kt-margin-b-40-mobile {
    margin-bottom: 40px !important;
  }
  .kt-margin-l-40-mobile {
    margin-left: 40px !important;
  }
  .kt-margin-r-40-mobile {
    margin-right: 40px !important;
  }
  .kt-padding-40-mobile {
    padding: 40px !important;
  }
  .kt-padding-t-40-mobile {
    padding-top: 40px !important;
  }
  .kt-padding-b-40-mobile {
    padding-bottom: 40px !important;
  }
  .kt-padding-l-40-mobile {
    padding-left: 40px !important;
  }
  .kt-padding-r-40-mobile {
    padding-right: 40px !important;
  }
  .kt-margin-45-mobile {
    margin: 45px !important;
  }
  .kt-margin-t-45-mobile {
    margin-top: 45px !important;
  }
  .kt-margin-b-45-mobile {
    margin-bottom: 45px !important;
  }
  .kt-margin-l-45-mobile {
    margin-left: 45px !important;
  }
  .kt-margin-r-45-mobile {
    margin-right: 45px !important;
  }
  .kt-padding-45-mobile {
    padding: 45px !important;
  }
  .kt-padding-t-45-mobile {
    padding-top: 45px !important;
  }
  .kt-padding-b-45-mobile {
    padding-bottom: 45px !important;
  }
  .kt-padding-l-45-mobile {
    padding-left: 45px !important;
  }
  .kt-padding-r-45-mobile {
    padding-right: 45px !important;
  }
  .kt-margin-50-mobile {
    margin: 50px !important;
  }
  .kt-margin-t-50-mobile {
    margin-top: 50px !important;
  }
  .kt-margin-b-50-mobile {
    margin-bottom: 50px !important;
  }
  .kt-margin-l-50-mobile {
    margin-left: 50px !important;
  }
  .kt-margin-r-50-mobile {
    margin-right: 50px !important;
  }
  .kt-padding-50-mobile {
    padding: 50px !important;
  }
  .kt-padding-t-50-mobile {
    padding-top: 50px !important;
  }
  .kt-padding-b-50-mobile {
    padding-bottom: 50px !important;
  }
  .kt-padding-l-50-mobile {
    padding-left: 50px !important;
  }
  .kt-padding-r-50-mobile {
    padding-right: 50px !important;
  }
  .kt-margin-55-mobile {
    margin: 55px !important;
  }
  .kt-margin-t-55-mobile {
    margin-top: 55px !important;
  }
  .kt-margin-b-55-mobile {
    margin-bottom: 55px !important;
  }
  .kt-margin-l-55-mobile {
    margin-left: 55px !important;
  }
  .kt-margin-r-55-mobile {
    margin-right: 55px !important;
  }
  .kt-padding-55-mobile {
    padding: 55px !important;
  }
  .kt-padding-t-55-mobile {
    padding-top: 55px !important;
  }
  .kt-padding-b-55-mobile {
    padding-bottom: 55px !important;
  }
  .kt-padding-l-55-mobile {
    padding-left: 55px !important;
  }
  .kt-padding-r-55-mobile {
    padding-right: 55px !important;
  }
  .kt-margin-60-mobile {
    margin: 60px !important;
  }
  .kt-margin-t-60-mobile {
    margin-top: 60px !important;
  }
  .kt-margin-b-60-mobile {
    margin-bottom: 60px !important;
  }
  .kt-margin-l-60-mobile {
    margin-left: 60px !important;
  }
  .kt-margin-r-60-mobile {
    margin-right: 60px !important;
  }
  .kt-padding-60-mobile {
    padding: 60px !important;
  }
  .kt-padding-t-60-mobile {
    padding-top: 60px !important;
  }
  .kt-padding-b-60-mobile {
    padding-bottom: 60px !important;
  }
  .kt-padding-l-60-mobile {
    padding-left: 60px !important;
  }
  .kt-padding-r-60-mobile {
    padding-right: 60px !important;
  }
  .kt-margin-65-mobile {
    margin: 65px !important;
  }
  .kt-margin-t-65-mobile {
    margin-top: 65px !important;
  }
  .kt-margin-b-65-mobile {
    margin-bottom: 65px !important;
  }
  .kt-margin-l-65-mobile {
    margin-left: 65px !important;
  }
  .kt-margin-r-65-mobile {
    margin-right: 65px !important;
  }
  .kt-padding-65-mobile {
    padding: 65px !important;
  }
  .kt-padding-t-65-mobile {
    padding-top: 65px !important;
  }
  .kt-padding-b-65-mobile {
    padding-bottom: 65px !important;
  }
  .kt-padding-l-65-mobile {
    padding-left: 65px !important;
  }
  .kt-padding-r-65-mobile {
    padding-right: 65px !important;
  }
  .kt-margin-70-mobile {
    margin: 70px !important;
  }
  .kt-margin-t-70-mobile {
    margin-top: 70px !important;
  }
  .kt-margin-b-70-mobile {
    margin-bottom: 70px !important;
  }
  .kt-margin-l-70-mobile {
    margin-left: 70px !important;
  }
  .kt-margin-r-70-mobile {
    margin-right: 70px !important;
  }
  .kt-padding-70-mobile {
    padding: 70px !important;
  }
  .kt-padding-t-70-mobile {
    padding-top: 70px !important;
  }
  .kt-padding-b-70-mobile {
    padding-bottom: 70px !important;
  }
  .kt-padding-l-70-mobile {
    padding-left: 70px !important;
  }
  .kt-padding-r-70-mobile {
    padding-right: 70px !important;
  }
  .kt-margin-75-mobile {
    margin: 75px !important;
  }
  .kt-margin-t-75-mobile {
    margin-top: 75px !important;
  }
  .kt-margin-b-75-mobile {
    margin-bottom: 75px !important;
  }
  .kt-margin-l-75-mobile {
    margin-left: 75px !important;
  }
  .kt-margin-r-75-mobile {
    margin-right: 75px !important;
  }
  .kt-padding-75-mobile {
    padding: 75px !important;
  }
  .kt-padding-t-75-mobile {
    padding-top: 75px !important;
  }
  .kt-padding-b-75-mobile {
    padding-bottom: 75px !important;
  }
  .kt-padding-l-75-mobile {
    padding-left: 75px !important;
  }
  .kt-padding-r-75-mobile {
    padding-right: 75px !important;
  }
  .kt-margin-80-mobile {
    margin: 80px !important;
  }
  .kt-margin-t-80-mobile {
    margin-top: 80px !important;
  }
  .kt-margin-b-80-mobile {
    margin-bottom: 80px !important;
  }
  .kt-margin-l-80-mobile {
    margin-left: 80px !important;
  }
  .kt-margin-r-80-mobile {
    margin-right: 80px !important;
  }
  .kt-padding-80-mobile {
    padding: 80px !important;
  }
  .kt-padding-t-80-mobile {
    padding-top: 80px !important;
  }
  .kt-padding-b-80-mobile {
    padding-bottom: 80px !important;
  }
  .kt-padding-l-80-mobile {
    padding-left: 80px !important;
  }
  .kt-padding-r-80-mobile {
    padding-right: 80px !important;
  }
  .kt-margin-85-mobile {
    margin: 85px !important;
  }
  .kt-margin-t-85-mobile {
    margin-top: 85px !important;
  }
  .kt-margin-b-85-mobile {
    margin-bottom: 85px !important;
  }
  .kt-margin-l-85-mobile {
    margin-left: 85px !important;
  }
  .kt-margin-r-85-mobile {
    margin-right: 85px !important;
  }
  .kt-padding-85-mobile {
    padding: 85px !important;
  }
  .kt-padding-t-85-mobile {
    padding-top: 85px !important;
  }
  .kt-padding-b-85-mobile {
    padding-bottom: 85px !important;
  }
  .kt-padding-l-85-mobile {
    padding-left: 85px !important;
  }
  .kt-padding-r-85-mobile {
    padding-right: 85px !important;
  }
  .kt-margin-90-mobile {
    margin: 90px !important;
  }
  .kt-margin-t-90-mobile {
    margin-top: 90px !important;
  }
  .kt-margin-b-90-mobile {
    margin-bottom: 90px !important;
  }
  .kt-margin-l-90-mobile {
    margin-left: 90px !important;
  }
  .kt-margin-r-90-mobile {
    margin-right: 90px !important;
  }
  .kt-padding-90-mobile {
    padding: 90px !important;
  }
  .kt-padding-t-90-mobile {
    padding-top: 90px !important;
  }
  .kt-padding-b-90-mobile {
    padding-bottom: 90px !important;
  }
  .kt-padding-l-90-mobile {
    padding-left: 90px !important;
  }
  .kt-padding-r-90-mobile {
    padding-right: 90px !important;
  }
  .kt-margin-95-mobile {
    margin: 95px !important;
  }
  .kt-margin-t-95-mobile {
    margin-top: 95px !important;
  }
  .kt-margin-b-95-mobile {
    margin-bottom: 95px !important;
  }
  .kt-margin-l-95-mobile {
    margin-left: 95px !important;
  }
  .kt-margin-r-95-mobile {
    margin-right: 95px !important;
  }
  .kt-padding-95-mobile {
    padding: 95px !important;
  }
  .kt-padding-t-95-mobile {
    padding-top: 95px !important;
  }
  .kt-padding-b-95-mobile {
    padding-bottom: 95px !important;
  }
  .kt-padding-l-95-mobile {
    padding-left: 95px !important;
  }
  .kt-padding-r-95-mobile {
    padding-right: 95px !important;
  }
  .kt-margin-100-mobile {
    margin: 100px !important;
  }
  .kt-margin-t-100-mobile {
    margin-top: 100px !important;
  }
  .kt-margin-b-100-mobile {
    margin-bottom: 100px !important;
  }
  .kt-margin-l-100-mobile {
    margin-left: 100px !important;
  }
  .kt-margin-r-100-mobile {
    margin-right: 100px !important;
  }
  .kt-padding-100-mobile {
    padding: 100px !important;
  }
  .kt-padding-t-100-mobile {
    padding-top: 100px !important;
  }
  .kt-padding-b-100-mobile {
    padding-bottom: 100px !important;
  }
  .kt-padding-l-100-mobile {
    padding-left: 100px !important;
  }
  .kt-padding-r-100-mobile {
    padding-right: 100px !important;
  }
}
@media (min-width: 1025px) {
  .kt-visible-desktop {
    display: block !important;
  }
  .kt-visible-desktop-inline-block {
    display: inline-block !important;
  }
  .kt-visible-desktop-inline {
    display: inline !important;
  }
  .kt-visible-desktop-table {
    display: table !important;
  }
  .kt-visible-desktop-table-cell {
    display: table-cell !important;
  }
  .kt-hidden-desktop {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .kt-visible-tablet {
    display: block !important;
  }
  .kt-visible-tablet-inline-block {
    display: inline-block !important;
  }
  .kt-visible-tablet-inline {
    display: inline !important;
  }
  .kt-visible-tablet-table {
    display: table !important;
  }
  .kt-visible-tablet-table-cell {
    display: table-cell !important;
  }
  .kt-hidden-tablet {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .kt-visible-tablet-and-mobile {
    display: block !important;
  }
  .kt-visible-tablet-and-mobile-inline-block {
    display: inline-block !important;
  }
  .kt-visible-tablet-and-mobile-inline {
    display: inline !important;
  }
  .kt-visible-tablet-and-mobile-table {
    display: table !important;
  }
  .kt-visible-tablet-and-mobile-table-cell {
    display: table-cell !important;
  }
  .kt-hidden-tablet-and-mobile {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .kt-visible-mobile {
    display: block !important;
  }
  .kt-visible-mobile-inline-block {
    display: inline-block !important;
  }
  .kt-visible-mobile-inline {
    display: inline !important;
  }
  .kt-visible-mobile-table {
    display: table !important;
  }
  .kt-visible-mobile-table-cell {
    display: table-cell !important;
  }
  .kt-hidden-mobile {
    display: none !important;
  }
}
@media screen {
  .kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile)
    > .kt-grid__item.kt-grid__item--fluid {
    min-width: none;
  }
}
@media (min-width: 1025px) {
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop {
    display: flex;
    flex-direction: column;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item {
    flex: none;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid-desktop {
    flex: 1 0 auto;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-1-desktop {
    order: 1;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-2-desktop {
    order: 2;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-3-desktop {
    order: 3;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-4-desktop {
    order: 4;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-5-desktop {
    order: 5;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-6-desktop {
    order: 6;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-7-desktop {
    order: 7;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-8-desktop {
    order: 8;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-9-desktop {
    order: 9;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-10-desktop {
    order: 10;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-11-desktop {
    order: 11;
  }
  .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-12-desktop {
    order: 12;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop {
    display: flex;
    flex-direction: row;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item {
    flex: 0 0 auto;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid-desktop {
    flex: 1 auto;
    min-width: 0;
  }
}
@media screen and (min-width: 1025px) {
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid-desktop {
    min-width: none;
  }
}
@media (min-width: 1025px) {
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-1-desktop {
    order: 1;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-2-desktop {
    order: 2;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-3-desktop {
    order: 3;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-4-desktop {
    order: 4;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-5-desktop {
    order: 5;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-6-desktop {
    order: 6;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-7-desktop {
    order: 7;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-8-desktop {
    order: 8;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-9-desktop {
    order: 9;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-10-desktop {
    order: 10;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-11-desktop {
    order: 11;
  }
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-12-desktop {
    order: 12;
  }
  .kt-grid.kt-grid--center {
    align-content: center;
  }
  .kt-grid.kt-grid--stretch {
    align-items: stretch;
  }
  .kt-grid > .kt-grid__item {
    max-width: 100%;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--top {
    align-self: flex-start;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--middle {
    align-self: center;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--bottom {
    align-self: flex-end;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-1 {
    order: 1;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-2 {
    order: 2;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-3 {
    order: 3;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-4 {
    order: 4;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-5 {
    order: 5;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-6 {
    order: 6;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-7 {
    order: 7;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-8 {
    order: 8;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-9 {
    order: 9;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-10 {
    order: 10;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-11 {
    order: 11;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-12 {
    order: 12;
  }
}
@media (min-width: 1400px) {
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl {
    display: flex;
    flex-direction: column;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item {
    flex: none;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid-desktop-xl {
    flex: 1 0 auto;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-1-desktop-xl {
    order: 1;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-2-desktop-xl {
    order: 2;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-3-desktop-xl {
    order: 3;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-4-desktop-xl {
    order: 4;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-5-desktop-xl {
    order: 5;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-6-desktop-xl {
    order: 6;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-7-desktop-xl {
    order: 7;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-8-desktop-xl {
    order: 8;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-9-desktop-xl {
    order: 9;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-10-desktop-xl {
    order: 10;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-11-desktop-xl {
    order: 11;
  }
  .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-12-desktop-xl {
    order: 12;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl {
    display: flex;
    flex-direction: row;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item {
    flex: 0 0 auto;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid-desktop-xl {
    flex: 1 auto;
    min-width: 0;
  }
}
@media screen and (min-width: 1400px) {
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid-desktop-xl {
    min-width: none;
  }
}
@media (min-width: 1400px) {
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-1-desktop-xl {
    order: 1;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-2-desktop-xl {
    order: 2;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-3-desktop-xl {
    order: 3;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-4-desktop-xl {
    order: 4;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-5-desktop-xl {
    order: 5;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-6-desktop-xl {
    order: 6;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-7-desktop-xl {
    order: 7;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-8-desktop-xl {
    order: 8;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-9-desktop-xl {
    order: 9;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-10-desktop-xl {
    order: 10;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-11-desktop-xl {
    order: 11;
  }
  .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-12-desktop-xl {
    order: 12;
  }
  .kt-grid.kt-grid--center {
    align-content: center;
  }
  .kt-grid.kt-grid--stretch {
    align-items: stretch;
  }
  .kt-grid > .kt-grid__item {
    max-width: 100%;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--top {
    align-self: flex-start;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--middle {
    align-self: center;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--bottom {
    align-self: flex-end;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-1 {
    order: 1;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-2 {
    order: 2;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-3 {
    order: 3;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-4 {
    order: 4;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-5 {
    order: 5;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-6 {
    order: 6;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-7 {
    order: 7;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-8 {
    order: 8;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-9 {
    order: 9;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-10 {
    order: 10;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-11 {
    order: 11;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-12 {
    order: 12;
  }
}
@media (min-width: 769px) {
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet {
    display: flex;
    flex-direction: column;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item {
    flex: none;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--fluid-desktop-and-tablet {
    flex: 1 0 auto;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-1-desktop-and-tablet {
    order: 1;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-2-desktop-and-tablet {
    order: 2;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-3-desktop-and-tablet {
    order: 3;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-4-desktop-and-tablet {
    order: 4;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-5-desktop-and-tablet {
    order: 5;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-6-desktop-and-tablet {
    order: 6;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-7-desktop-and-tablet {
    order: 7;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-8-desktop-and-tablet {
    order: 8;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-9-desktop-and-tablet {
    order: 9;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-10-desktop-and-tablet {
    order: 10;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-11-desktop-and-tablet {
    order: 11;
  }
  .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-12-desktop-and-tablet {
    order: 12;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet {
    display: flex;
    flex-direction: row;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item {
    flex: 0 0 auto;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--fluid-desktop-and-tablet {
    flex: 1 auto;
    min-width: 0;
  }
}
@media screen and (min-width: 769px) {
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--fluid-desktop-and-tablet {
    min-width: none;
  }
}
@media (min-width: 769px) {
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-1-desktop-and-tablet {
    order: 1;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-2-desktop-and-tablet {
    order: 2;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-3-desktop-and-tablet {
    order: 3;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-4-desktop-and-tablet {
    order: 4;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-5-desktop-and-tablet {
    order: 5;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-6-desktop-and-tablet {
    order: 6;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-7-desktop-and-tablet {
    order: 7;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-8-desktop-and-tablet {
    order: 8;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-9-desktop-and-tablet {
    order: 9;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-10-desktop-and-tablet {
    order: 10;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-11-desktop-and-tablet {
    order: 11;
  }
  .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet
    > .kt-grid__item.kt-grid__item--order-12-desktop-and-tablet {
    order: 12;
  }
  .kt-grid.kt-grid--center {
    align-content: center;
  }
  .kt-grid.kt-grid--stretch {
    align-items: stretch;
  }
  .kt-grid > .kt-grid__item {
    max-width: 100%;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--top {
    align-self: flex-start;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--middle {
    align-self: center;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--bottom {
    align-self: flex-end;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-1 {
    order: 1;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-2 {
    order: 2;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-3 {
    order: 3;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-4 {
    order: 4;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-5 {
    order: 5;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-6 {
    order: 6;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-7 {
    order: 7;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-8 {
    order: 8;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-9 {
    order: 9;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-10 {
    order: 10;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-11 {
    order: 11;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-12 {
    order: 12;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet {
    display: flex;
    flex-direction: column;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item {
    flex: none;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid-tablet {
    flex: 1 0 auto;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-1-tablet {
    order: 1;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-2-tablet {
    order: 2;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-3-tablet {
    order: 3;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-4-tablet {
    order: 4;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-5-tablet {
    order: 5;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-6-tablet {
    order: 6;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-7-tablet {
    order: 7;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-8-tablet {
    order: 8;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-9-tablet {
    order: 9;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-10-tablet {
    order: 10;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-11-tablet {
    order: 11;
  }
  .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-12-tablet {
    order: 12;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet {
    display: flex;
    flex-direction: row;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item {
    flex: 0 0 auto;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid-tablet {
    flex: 1 auto;
    min-width: 0;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid-tablet {
    min-width: none;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-1-tablet {
    order: 1;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-2-tablet {
    order: 2;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-3-tablet {
    order: 3;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-4-tablet {
    order: 4;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-5-tablet {
    order: 5;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-6-tablet {
    order: 6;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-7-tablet {
    order: 7;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-8-tablet {
    order: 8;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-9-tablet {
    order: 9;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-10-tablet {
    order: 10;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-11-tablet {
    order: 11;
  }
  .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-12-tablet {
    order: 12;
  }
  .kt-grid.kt-grid--center {
    align-content: center;
  }
  .kt-grid.kt-grid--stretch {
    align-items: stretch;
  }
  .kt-grid > .kt-grid__item {
    max-width: 100%;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--top {
    align-self: flex-start;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--middle {
    align-self: center;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--bottom {
    align-self: flex-end;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-1 {
    order: 1;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-2 {
    order: 2;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-3 {
    order: 3;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-4 {
    order: 4;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-5 {
    order: 5;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-6 {
    order: 6;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-7 {
    order: 7;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-8 {
    order: 8;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-9 {
    order: 9;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-10 {
    order: 10;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-11 {
    order: 11;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-12 {
    order: 12;
  }
}
@media (max-width: 1024px) {
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile {
    display: flex;
    flex-direction: column;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item {
    flex: none;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--fluid-tablet-and-mobile {
    flex: 1 0 auto;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-1-tablet-and-mobile {
    order: 1;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-2-tablet-and-mobile {
    order: 2;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-3-tablet-and-mobile {
    order: 3;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-4-tablet-and-mobile {
    order: 4;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-5-tablet-and-mobile {
    order: 5;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-6-tablet-and-mobile {
    order: 6;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-7-tablet-and-mobile {
    order: 7;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-8-tablet-and-mobile {
    order: 8;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-9-tablet-and-mobile {
    order: 9;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-10-tablet-and-mobile {
    order: 10;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-11-tablet-and-mobile {
    order: 11;
  }
  .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-12-tablet-and-mobile {
    order: 12;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile {
    display: flex;
    flex-direction: row;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item {
    flex: 0 0 auto;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--fluid-tablet-and-mobile {
    flex: 1 auto;
    min-width: 0;
  }
}
@media screen and (max-width: 1024px) {
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--fluid-tablet-and-mobile {
    min-width: none;
  }
}
@media (max-width: 1024px) {
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-1-tablet-and-mobile {
    order: 1;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-2-tablet-and-mobile {
    order: 2;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-3-tablet-and-mobile {
    order: 3;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-4-tablet-and-mobile {
    order: 4;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-5-tablet-and-mobile {
    order: 5;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-6-tablet-and-mobile {
    order: 6;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-7-tablet-and-mobile {
    order: 7;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-8-tablet-and-mobile {
    order: 8;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-9-tablet-and-mobile {
    order: 9;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-10-tablet-and-mobile {
    order: 10;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-11-tablet-and-mobile {
    order: 11;
  }
  .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile
    > .kt-grid__item.kt-grid__item--order-12-tablet-and-mobile {
    order: 12;
  }
  .kt-grid.kt-grid--center {
    align-content: center;
  }
  .kt-grid.kt-grid--stretch {
    align-items: stretch;
  }
  .kt-grid > .kt-grid__item {
    max-width: 100%;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--top {
    align-self: flex-start;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--middle {
    align-self: center;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--bottom {
    align-self: flex-end;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-1 {
    order: 1;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-2 {
    order: 2;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-3 {
    order: 3;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-4 {
    order: 4;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-5 {
    order: 5;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-6 {
    order: 6;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-7 {
    order: 7;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-8 {
    order: 8;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-9 {
    order: 9;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-10 {
    order: 10;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-11 {
    order: 11;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-12 {
    order: 12;
  }
}
@media (max-width: 768px) {
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile {
    display: flex;
    flex-direction: column;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item {
    flex: none;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid-mobile {
    flex: 1 0 auto;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-1-mobile {
    order: 1;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-2-mobile {
    order: 2;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-3-mobile {
    order: 3;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-4-mobile {
    order: 4;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-5-mobile {
    order: 5;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-6-mobile {
    order: 6;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-7-mobile {
    order: 7;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-8-mobile {
    order: 8;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-9-mobile {
    order: 9;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-10-mobile {
    order: 10;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-11-mobile {
    order: 11;
  }
  .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-12-mobile {
    order: 12;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile {
    display: flex;
    flex-direction: row;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile.kt-grid--root {
    flex: 1;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item {
    flex: 0 0 auto;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid-mobile {
    flex: 1 auto;
    min-width: 0;
  }
}
@media screen and (max-width: 768px) {
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid,
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid-mobile {
    min-width: none;
  }
}
@media (max-width: 768px) {
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-1-mobile {
    order: 1;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-2-mobile {
    order: 2;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-3-mobile {
    order: 3;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-4-mobile {
    order: 4;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-5-mobile {
    order: 5;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-6-mobile {
    order: 6;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-7-mobile {
    order: 7;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-8-mobile {
    order: 8;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-9-mobile {
    order: 9;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-10-mobile {
    order: 10;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-11-mobile {
    order: 11;
  }
  .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-12-mobile {
    order: 12;
  }
  .kt-grid.kt-grid--center {
    align-content: center;
  }
  .kt-grid.kt-grid--stretch {
    align-items: stretch;
  }
  .kt-grid > .kt-grid__item {
    max-width: 100%;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--top {
    align-self: flex-start;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--middle {
    align-self: center;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--bottom {
    align-self: flex-end;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-1 {
    order: 1;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-2 {
    order: 2;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-3 {
    order: 3;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-4 {
    order: 4;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-5 {
    order: 5;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-6 {
    order: 6;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-7 {
    order: 7;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-8 {
    order: 8;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-9 {
    order: 9;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-10 {
    order: 10;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-11 {
    order: 11;
  }
  .kt-grid > .kt-grid__item.kt-grid__item--order-12 {
    order: 12;
  }
}
@media (min-width: 0) {
  .kt-container:not(.kt-container--fluid):not(.kt-container--responsive) {
    width: -60px;
  }
}
@media (min-width: 576px) {
  .kt-container:not(.kt-container--fluid):not(.kt-container--responsive) {
    width: 516px;
  }
}
@media (min-width: 768px) {
  .kt-container:not(.kt-container--fluid):not(.kt-container--responsive) {
    width: 708px;
  }
}
@media (min-width: 1024px) {
  .kt-container:not(.kt-container--fluid):not(.kt-container--responsive) {
    width: 964px;
  }
}
@media (min-width: 1399px) {
  .kt-container:not(.kt-container--fluid):not(.kt-container--responsive) {
    width: 1339px;
  }
}
@media (min-width: 0) {
  .kt-container.kt-container--responsive.kt-container--xs {
    width: -60px;
  }
}
@media (min-width: 576px) {
  .kt-container.kt-container--responsive.kt-container--sm {
    width: 516px;
  }
}
@media (min-width: 768px) {
  .kt-container.kt-container--responsive.kt-container--md {
    width: 708px;
  }
}
@media (min-width: 1024px) {
  .kt-container.kt-container--responsive.kt-container--lg {
    width: 964px;
  }
}
@media (min-width: 1399px) {
  .kt-container.kt-container--responsive.kt-container--xl {
    width: 1339px;
  }
}
@media (max-width: 768px) {
  .kt-datatable.kt-datatable--default {
    margin-bottom: 15px;
  }
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__body
    .kt-datatable__row
    > .kt-datatable__cell:first-child,
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__foot
    .kt-datatable__row
    > .kt-datatable__cell:first-child,
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__head
    .kt-datatable__row
    > .kt-datatable__cell:first-child {
    padding-left: 15px;
  }
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__body
    .kt-datatable__row
    > .kt-datatable__cell:last-child,
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__foot
    .kt-datatable__row
    > .kt-datatable__cell:last-child,
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__head
    .kt-datatable__row
    > .kt-datatable__cell:last-child {
    padding-right: 15px;
  }
  .kt-datatable.kt-datatable--default > .kt-datatable__pager {
    margin: 0;
    padding: 15px 15px 7.5px;
  }
  .kt-datatable.kt-datatable--default > .kt-datatable__pager .kt-datatable__pager-info,
  .kt-datatable.kt-datatable--default > .kt-datatable__pager .kt-datatable__pager-nav {
    margin-top: 10px;
  }
}
@media (max-width: 1024px) {
  .kt-scrolltop {
    bottom: 30px;
    right: 15px;
  }
}
@media (min-width: 1025px) {
  .kt-portlet.kt-portlet--height-fluid {
    height: calc(100% - 20px);
  }
  .kt-portlet.kt-portlet--height-fluid .kt-portlet__body {
    flex-grow: 1;
  }
  .kt-portlet.kt-portlet--height-fluid-half {
    height: calc(50% - 20px);
  }
  .kt-portlet.kt-portlet--height-fluid-half .kt-portlet__body {
    flex-grow: 1;
  }
}
@media (max-width: 1024px) {
  .kt-portlet {
    margin-bottom: 10px;
  }
  .kt-portlet .kt-portlet__head {
    padding: 0 15px;
    min-height: 50px;
  }
  .kt-portlet .kt-portlet__head.kt-portlet__head--lg {
    min-height: 60px;
  }
  .kt-portlet .kt-portlet__head.kt-portlet__head--xl {
    min-height: 80px;
  }
  .kt-portlet .kt-portlet__body {
    padding: 15px;
  }
  .kt-portlet .kt-portlet__body .kt-portlet__body--hor-fit {
    margin-left: -15px;
    margin-right: -15px;
  }
  .kt-portlet .kt-portlet__foot {
    padding: 15px;
  }
  .kt-portlet.kt-portlet--marginless {
    margin-bottom: 0;
  }
  .kt-portlet.kt-portlet--sticky .kt-portlet__head {
    height: 40px;
  }
  .kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--lg {
    height: 50px;
  }
  .kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--xl {
    height: 70px;
  }
  .kt-portlet.kt-portlet--head-overlay .kt-portlet__head {
    height: 50px;
  }
  .kt-portlet.kt-portlet--head-overlay .kt-portlet__body {
    margin-top: -50px;
  }
  .kt-portlet.kt-portlet--head-overlay.kt-portlet--head-lg .kt-portlet__head {
    height: 60px;
  }
  .kt-portlet.kt-portlet--head-overlay.kt-portlet--head-lg .kt-portlet__body {
    margin-top: -60px;
  }
  .kt-portlet.kt-portlet--head-overlay.kt-portlet--head-xl .kt-portlet__head {
    height: 80px;
  }
  .kt-portlet.kt-portlet--head-overlay.kt-portlet--head-xl .kt-portlet__body {
    margin-top: -80px;
  }
  .kt-portlet.kt-portlet--head--noborder .kt-portlet__body {
    padding-top: 7.5px;
  }
  .kt-portlet .kt-portlet__space-x {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .kt-portlet .kt-portlet__space-y {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
@media (max-width: 768px) {
  .kt-portlet.kt-portlet--head-break-sm .kt-portlet__head {
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 0 !important;
    height: auto;
    flex-wrap: wrap;
  }
  .kt-portlet.kt-portlet--head-break-sm .kt-portlet__head .kt-portlet__head-label {
    margin-bottom: 1rem;
  }
}
@media (min-width: 1025px) {
  .kt-form.kt-form--label-right .form-group label:not(.kt-checkbox):not(.kt-radio):not(.kt-option) {
    text-align: right;
  }
}
@media screen {
  .kt-pulse .kt-pulse__ring {
    top: 2px;
    left: 2px;
  }
}
@media (min-width: 1025px) {
  .kt-header--fixed .kt-wrapper {
    padding-top: 65px;
  }
  .kt-header--fixed.kt-subheader--fixed.kt-subheader--enabled .kt-wrapper {
    padding-top: 109px;
  }
  .kt-wrapper {
    transition: all 0.3s ease;
  }
  .kt-aside--fixed .kt-wrapper {
    padding-left: var(--aside-width, 240px);
  }
  .kt-aside--fixed.kt-aside--minimize .kt-wrapper {
    padding-left: 70px;
    transition: all 0.3s ease;
  }
  .kt-subheader {
    margin: 0;
  }
  .kt-header--fixed.kt-subheader--fixed .kt-subheader {
    position: fixed;
    height: 54px;
    top: 50px;
    left: 0;
    right: 0;
    transition: all 0.3s ease;
    z-index: 94;
    background-color: #fff;
    box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
    padding-top: 0;
    padding-bottom: 0;
  }
  .kt-header--fixed.kt-header--minimize.kt-subheader--fixed .kt-subheader {
    top: 55px;
    transition: top 0.3s ease;
  }
  .kt-aside--enabled.kt-subheader--fixed .kt-subheader {
    left: var(--aside-width, 240px);
  }
  .kt-subheader--fixed.kt-aside--minimize-hover .kt-subheader,
  .kt-subheader--fixed.kt-aside--minimize .kt-subheader {
    left: 70px;
    transition: all 0.3s ease;
  }
}
@media (max-width: 1024px) {
  .kt-header-mobile--fixed .kt-wrapper {
    padding-top: 50px;
  }
}
@media (min-width: 1025px) {
  .kt-header {
    display: flex;
    justify-content: space-between;
    height: var(--header-height);
    transition: all 0.3s ease;
    position: relative;
    z-index: 2;
  }
  .kt-header.kt-header--fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 97;
  }
  .kt-aside--enabled .kt-header.kt-header--fixed {
    left: var(--aside-width, 240px);
  }
  .kt-header--fixed.kt-header--minimize .kt-header {
    height: 55px;
    transition: height 0.3s ease;
  }
  .kt-header--fixed.kt-aside--minimize .kt-header {
    left: 70px;
    transition: all 0.3s ease;
  }
}
@media (min-width: 1025px) {
  .kt-header-mobile {
    display: none;
  }
}
@media (max-width: 1024px) {
  .kt-header-mobile {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    height: 65px;
    position: relative;
    z-index: 1;
  }
  .kt-header-mobile--fixed .kt-header-mobile {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 97;
  }
  .kt-header-mobile .kt-header-mobile__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .kt-header-mobile .kt-header-mobile__toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler {
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background: none;
    outline: none !important;
    width: 24px;
    height: 24px;
    transition: all 0.4s ease;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span {
    display: block;
    position: absolute;
    top: 10px;
    height: 3px;
    min-height: 3px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.4s ease;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span:after,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span:before {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    min-height: 3px;
    content: '';
    border-radius: 3px;
    transition: all 0.4s ease;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span:before {
    top: -7px;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span:after {
    bottom: -7px;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left span:before {
    transition: all 0.4s ease;
    left: auto;
    right: 0;
    width: 50%;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left span:after {
    transition: all 0.4s ease;
    left: auto;
    right: 0;
    width: 75%;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--left:hover
    span:after,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--left:hover
    span:before {
    width: 100%;
    transition: all 0.4s ease;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--left.kt-header-mobile__toggler--active
    span:before {
    transition: all 0.4s ease;
    left: 0;
    right: auto;
    width: 50%;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--left.kt-header-mobile__toggler--active
    span:after {
    transition: all 0.4s ease;
    left: 0;
    right: auto;
    width: 75%;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--right span:before {
    left: 0;
    right: auto;
    width: 50%;
    transition: width 0.4s ease;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--right span:after {
    left: 0;
    right: auto;
    width: 75%;
    transition: width 0.4s ease;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--right:hover
    span:after,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--right:hover
    span:before {
    width: 100%;
    transition: width 0.4s ease;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--right.kt-header-mobile__toggler--active
    span:before {
    left: auto;
    right: 0;
    width: 50%;
    transition: width 0.4s ease;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--right.kt-header-mobile__toggler--active
    span:after {
    left: auto;
    right: 0;
    width: 75%;
    transition: width 0.4s ease;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span:after,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span:before {
    background: hsla(0, 0%, 100%, 0.2);
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span:after,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span:before,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span:after,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span:before {
    background: #5d78ff;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler {
    margin: 0 0 0 1.5rem;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler {
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background: none;
    outline: none !important;
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0 0.5rem 0 1rem;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler i {
    transition: all 0.3s;
    color: hsla(0, 0%, 100%, 0.2);
    font-size: 1.5rem;
    line-height: 0;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__topbar-toggler.kt-header-mobile__topbar-toggler--active
    i,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler:hover i {
    transition: all 0.3s;
    color: #5d78ff;
  }
}
@media (min-width: 1025px) {
  .kt-header-menu {
    display: flex;
    align-items: stretch;
    height: 100%;
    margin: 0 0 0 25px;
  }
  .kt-header-menu .kt-menu__nav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link {
    display: flex;
    position: relative;
    vertical-align: middle;
    align-items: stretch;
    outline: none !important;
    text-decoration: none;
    cursor: pointer;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link .kt-menu__link-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link .kt-menu__link-badge {
    display: flex;
    align-items: center;
    line-height: 0;
    white-space: nowrap;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link .kt-menu__hor-arrow,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link .kt-menu__link-icon {
    display: flex;
    align-items: center;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link .kt-menu__ver-arrow {
    display: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link:active,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link:focus,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link:hover {
    text-decoration: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link > .kt-menu__link-text {
    width: auto;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--icon-only > .kt-menu__link,
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--icon-only > .kt-menu__link > .kt-menu__link-icon {
    justify-content: center;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu {
    transform: translateZ(0);
    display: none;
    z-index: 98;
    position: absolute;
    top: 100%;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav {
    background-color: #eee;
    list-style: none !important;
    margin: 0;
    padding: 20px 0;
    border-radius: 4px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu {
    width: 275px;
    margin: 0 auto;
    left: auto;
    right: auto;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--full {
    margin: 0 auto;
    width: auto;
    left: 20px;
    right: 20px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed {
    left: auto;
    right: auto;
    width: auto;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--right {
    right: 0;
    left: auto;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--right.kt-menu__submenu--pull {
    margin-right: -40px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--left {
    right: auto;
    left: 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--left.kt-menu__submenu--pull {
    margin-left: -40px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--center {
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--submenu {
    position: relative;
    padding: 0;
    margin: 0;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--submenu
    > .kt-menu__submenu {
    top: 0;
    display: none;
    margin-top: 0;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--submenu
    > .kt-menu__submenu.kt-menu__submenu--left {
    right: 100%;
    left: auto;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--submenu
    > .kt-menu__submenu.kt-menu__submenu--right {
    left: 100%;
    right: auto;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__content {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__content > .kt-menu__item {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__content > .kt-menu__item:last-child {
    border-right: 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    text-decoration: none;
    position: relative;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading .kt-menu__link-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading .kt-menu__link-badge {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading .kt-menu__link-icon {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 0 10px 0 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading .kt-menu__hor-arrow {
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading .kt-menu__ver-arrow {
    display: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item {
    display: flex;
    flex-grow: 1;
    margin: 0;
    padding: 10px 20px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link {
    display: flex;
    align-items: center;
    flex-grow: 1;
    text-decoration: none;
    position: relative;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link:active,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link:focus,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link:hover,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link:active,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link:focus,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link:hover {
    outline: none;
    text-decoration: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__link-text,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-text {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
    text-transform: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__link-badge,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-badge {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0 0 0 5px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__link-icon,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-icon {
    display: flex;
    align-items: center;
    flex: 0 0 33px;
    padding: 0;
    font-size: 1.4rem;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__link-bullet,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__link-bullet {
    display: flex;
    align-items: center;
    line-height: 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__hor-arrow,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__hor-arrow {
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__ver-arrow,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__ver-arrow {
    display: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner {
    padding: 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__submenu {
    margin-left: 1px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__submenu.kt-menu__submenu--left {
    margin-right: 1px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--submenu.kt-menu__item--tabs
    > .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--submenu
    > .kt-menu__submenu {
    top: 100%;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--submenu.kt-menu__item--tabs
    > .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--submenu
    > .kt-menu__submenu.kt-menu__submenu--left {
    right: 100%;
    left: 0;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--submenu.kt-menu__item--tabs
    > .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--submenu
    > .kt-menu__submenu.kt-menu__submenu--right {
    left: 100%;
    right: 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item {
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--rel {
    position: relative;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link:hover {
    transition: all 0.3s ease;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
    font-size: 1.4rem;
    width: 30px;
    padding: 0;
    line-height: 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg {
    height: 23px;
    width: 23px;
    margin-left: -2px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet {
    width: 20px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span {
    vertical-align: middle;
    display: inline-block;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    width: 4px;
    height: 4px;
    border-radius: 100%;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    width: 5px;
    height: 1px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
    font-weight: 400;
    font-size: 1rem;
    text-transform: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge {
    padding: 0 0 0 5px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow {
    font-size: 0.8rem;
    width: 20px;
    justify-content: flex-end;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__item-here {
    display: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav {
    padding: 0;
    border-radius: 4px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item {
    padding: 0;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link {
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 11px 30px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link:hover {
    transition: all 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    font-size: 1.4rem;
    width: 33px;
    padding: 0;
    line-height: 0;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg {
    height: 23px;
    width: 23px;
    margin-left: -2px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet {
    width: 20px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet
    > span {
    vertical-align: middle;
    display: inline-block;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    width: 4px;
    height: 4px;
    border-radius: 100%;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    width: 9px;
    height: 1px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    font-weight: 400;
    font-size: 1rem;
    text-transform: none;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-badge {
    padding: 0 0 0 5px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__hor-arrow {
    font-size: 0.8rem;
    width: 20px;
    justify-content: flex-end;
    padding: 0 0 0 10px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__item-here {
    display: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content {
    align-items: stretch;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    > .kt-menu__item {
    flex-grow: 1;
    flex-basis: 0;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    > .kt-menu__item:last-child {
    border-right: 0 !important;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__heading {
    padding: 30px 30px 10px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__heading
    > .kt-menu__link-text {
    font-weight: 500;
    font-size: 1.07rem;
    text-transform: none;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__heading
    > .kt-menu__link-icon {
    font-size: 1.35rem;
    flex: 0 0 30px;
    padding: 0;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__heading
    .kt-menu__link-badge {
    padding: 0 0 0 5px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner {
    padding: 0 0 20px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item {
    padding: 0;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link {
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 11px 30px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link:hover {
    transition: all 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    font-size: 1.4rem;
    width: 33px;
    padding: 0;
    line-height: 0;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg {
    height: 23px;
    width: 23px;
    margin-left: -2px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet {
    width: 20px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet
    > span {
    vertical-align: middle;
    display: inline-block;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    width: 4px;
    height: 4px;
    border-radius: 100%;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    width: 9px;
    height: 1px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    font-weight: 400;
    font-size: 1rem;
    text-transform: none;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-badge {
    padding: 0 0 0 5px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__hor-arrow {
    font-size: 0.8rem;
    width: 20px;
    justify-content: flex-end;
    padding: 0 0 0 10px;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__item-here {
    display: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--classic > .kt-menu__subnav {
    padding: 20px 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--inline {
    display: flex;
    flex: 0 0 auto;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu.kt-menu__submenu--inline
    > .kt-menu__subnav
    > .kt-menu__item {
    display: flex;
    padding: 5px 0;
  }
  .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--resize {
    display: none;
  }
  .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--active-tab .kt-menu__submenu,
  .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--hover .kt-menu__submenu {
    display: block;
    animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-down 0.3s ease-out 1;
  }
}
@media screen and (min-width: 1025px) {
  .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--active-tab .kt-menu__submenu,
  .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--hover .kt-menu__submenu {
    animation: none;
  }
}
@media (min-width: 1025px) {
  .kt-header-menu
    .kt-menu__nav
    .kt-menu__item.kt-menu__item--active-tab
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__submenu,
  .kt-header-menu
    .kt-menu__nav
    .kt-menu__item.kt-menu__item--hover
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__submenu {
    display: block;
    animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-up 0.3s ease-out 1;
  }
}
@media screen and (min-width: 1025px) {
  .kt-header-menu
    .kt-menu__nav
    .kt-menu__item.kt-menu__item--active-tab
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__submenu,
  .kt-header-menu
    .kt-menu__nav
    .kt-menu__item.kt-menu__item--hover
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__submenu {
    animation: none;
  }
}
@media (min-width: 1025px) and (max-width: 1399px) {
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--auto,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu.kt-menu__submenu--auto.kt-menu__submenu--mimimal-desktop-wide,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-xl,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-xxl,
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--full {
    margin: 0 auto;
    width: auto;
    left: 20px;
    right: 20px;
  }
}
@media (min-width: 0) {
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-xs {
    width: -60px;
  }
}
@media (min-width: 576px) {
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-sm {
    width: 516px;
  }
}
@media (min-width: 768px) {
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-md {
    width: 708px;
  }
}
@media (min-width: 1024px) {
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-lg {
    width: 964px;
  }
}
@media (min-width: 1399px) {
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-xl {
    width: 1339px;
  }
}
@media (max-width: 1024px) {
  .kt-header-menu-mobile .kt-menu__nav {
    margin: 0;
    list-style: none;
    padding: 30px 0;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__subnav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
    margin: 0;
    list-style: none !important;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__inner,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__submenu {
    display: none;
    float: none;
    margin: 0;
    padding: 0;
    width: auto !important;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__inner .kt-menu__content,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__submenu .kt-menu__content {
    padding: 0;
    margin: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__inner .kt-menu__content > .kt-menu__item,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__submenu .kt-menu__content > .kt-menu__item {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    .kt-menu__inner
    .kt-menu__content
    > .kt-menu__item.kt-menu--no-heading
    > .kt-menu__inner,
  .kt-header-menu-mobile
    .kt-menu__nav
    .kt-menu__submenu
    .kt-menu__content
    > .kt-menu__item.kt-menu--no-heading
    > .kt-menu__inner {
    display: flex;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    .kt-menu__inner
    .kt-menu__content
    > .kt-menu__item.kt-menu--no-heading
    > .kt-menu__inner
    > li
    > .kt-menu__link,
  .kt-header-menu-mobile
    .kt-menu__nav
    .kt-menu__submenu
    .kt-menu__content
    > .kt-menu__item.kt-menu--no-heading
    > .kt-menu__inner
    > li
    > .kt-menu__link {
    margin: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    float: none;
    padding: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    margin: 0;
    padding: 0;
    text-decoration: none;
    position: relative;
    outline: none;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading:hover,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link:hover {
    text-decoration: none;
    cursor: pointer;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-text,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-badge,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-badge {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-icon,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-bullet,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
    display: flex;
    align-items: center;
    line-height: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__hor-arrow,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow {
    display: none;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    > .kt-menu__ver-arrow:before,
  .kt-header-menu-mobile
    .kt-menu__nav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    > .kt-menu__ver-arrow:before {
    transform: rotate(-90deg);
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__inner,
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__submenu {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__item .kt-menu__submenu .kt-menu__item--parent {
    display: none;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__section {
    display: flex;
    padding: 0 27px;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__section.kt-menu__section--first {
    margin-top: 0 !important;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__section .kt-menu__section-text {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__section .kt-menu__section-icon {
    display: none;
    align-items: center;
    justify-content: center;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__separator {
    height: 0;
    overflow: hidden;
  }
  .kt-header-menu-mobile .kt-menu__nav .kt-menu__separator.kt-menu__separator--marginles {
    margin: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item {
    position: relative;
    margin: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link {
    cursor: pointer;
    height: 44px;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
    font-weight: 400;
    font-size: 1.02rem;
    text-transform: none;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
    flex: 0 0 35px;
    font-size: 1.4rem;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg {
    margin-left: -2px;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet {
    vertical-align: middle;
    text-align: left;
    flex: 0 0 15px;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet > span,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span {
    vertical-align: middle;
    display: inline-block;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    width: 4px;
    height: 4px;
    border-radius: 100%;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    width: 5px;
    height: 1px;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-badge,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge {
    padding: 0 0 0 5px;
    text-align: right;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow {
    flex: 0 0 20px;
    font-size: 0.7rem;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before {
    display: inline-block;
    transition: all 0.3s ease;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__item-here,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__item-here {
    display: none;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__ver-arrow:before,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__ver-arrow:before {
    transition: all 0.3s ease;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav {
    margin: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link {
    cursor: pointer;
    height: 40px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    font-weight: 400;
    font-size: 1rem;
    text-transform: none;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    flex: 0 0 35px;
    font-size: 1.35rem;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon
    svg,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg {
    margin-left: -2px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet {
    vertical-align: middle;
    text-align: left;
    flex: 0 0 20px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet
    > span {
    vertical-align: middle;
    display: inline-block;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    width: 4px;
    height: 4px;
    border-radius: 100%;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    width: 5px;
    height: 1px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-badge,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-badge {
    padding: 0 0 0 5px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__ver-arrow {
    flex: 0 0 20px;
    font-size: 0.7rem;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__ver-arrow:before,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__ver-arrow:before {
    display: inline-block;
    transition: all 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__item-here,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__item-here {
    display: none;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__ver-arrow:before,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__ver-arrow:before {
    transition: all 0.3s ease;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section {
    margin: 20px 0 0;
    height: 40px;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section .kt-menu__section-text {
    font-size: 0.8rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__separator {
    margin: 15px 0;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__separator
    .kt-menu__separator--marginles {
    margin: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__section {
    margin: 20px 0 0;
    height: 40px;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__section .kt-menu__section-text {
    font-size: 0.83rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__separator {
    margin: 15px 0;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__separator .kt-menu__separator--marginles {
    margin: 0;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link {
    padding: 9px 30px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__content
    .kt-menu__heading {
    padding: 0 30px 0 45px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner {
    padding: 0;
    margin: 0;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    .kt-menu__link {
    padding: 0 30px 0 60px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link {
    padding: 0 30px 0 45px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link {
    padding: 0 30px 0 60px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link {
    padding: 0 30px 0 75px;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section {
    padding-left: 45px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav {
    padding: 0;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__section {
    padding-left: 60px;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav {
    padding: 0;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__section {
    padding-left: 75px;
  }
}
@media (max-width: 1024px) {
  .kt-header-menu-mobile {
    background-color: #fff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
    color: #5e6383;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
    color: #a8aabb;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg g [fill],
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: #a8aabb;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill],
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg:hover g [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #a8aabb;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow {
    color: #a8aabb;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open {
    transition: background-color 0.3s;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-icon,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-text,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow {
    color: #5d78ff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here {
    transition: background-color 0.3s;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-icon,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-text,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__ver-arrow,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__ver-arrow {
    color: #5d78ff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active {
    transition: background-color 0.3s;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-icon,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-text,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__ver-arrow,
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__ver-arrow {
    color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover {
    transition: background-color 0.3s;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__ver-arrow {
    color: #5d78ff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__arrow {
    color: #fff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__section .kt-menu__section-text {
    color: #a4a7bd;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__section .kt-menu__section-icon {
    color: #aaacc2;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    color: #6b6f8d;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #a8aabb;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #a8aabb;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #a8aabb;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__ver-arrow {
    color: #a8aabb;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open {
    transition: background-color 0.3s;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__ver-arrow {
    color: #5d78ff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here {
    transition: background-color 0.3s;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active {
    transition: background-color 0.3s;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__ver-arrow {
    color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover {
    transition: background-color 0.3s;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-header-menu-mobile
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__ver-arrow {
    color: #5d78ff;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section .kt-menu__section-text {
    color: #a4a7bd;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section .kt-menu__section-icon {
    color: #aaacc2;
  }
  .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }
}
@media (max-width: 1024px) {
  .kt-header-menu-wrapper {
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    backface-visibility: hidden;
    width: 275px !important;
    transition: all 0.3s ease;
    right: -295px;
  }
  .kt-header-menu-wrapper.kt-header-menu-wrapper--on {
    transition: all 0.3s ease;
    right: 0;
  }
}
@media screen and (max-width: 1024px) {
  .kt-header-menu-wrapper {
    transition: none !important;
  }
}
@media (max-width: 1024px) {
  .kt-header-menu-wrapper--left .kt-header-menu-wrapper {
    left: -295px;
    right: auto;
  }
  .kt-header-menu-wrapper--left .kt-header-menu-wrapper.kt-header-menu-wrapper--on {
    transition: all 0.3s ease;
    left: 0;
    right: auto;
  }
  .kt-header-menu-wrapper-close {
    width: 25px;
    height: 25px;
    top: 1px;
    z-index: 1002;
    transition: all 0.3s ease;
    position: fixed;
    border: 0;
    box-shadow: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -25px;
  }
}
@media screen and (max-width: 1024px) {
  .kt-header-menu-wrapper-close {
    transition: none !important;
  }
}
@media (max-width: 1024px) {
  .kt-header-menu-wrapper-close > i {
    line-height: 0;
    font-size: 1.4rem;
  }
  .kt-header-menu-wrapper-close:hover {
    text-decoration: none;
  }
  .kt-header-menu-wrapper--left .kt-header-menu-wrapper-close {
    right: auto;
    left: -25px;
  }
  .kt-header-menu-wrapper--on .kt-header-menu-wrapper-close {
    transition: all 0.3s ease;
    right: 249px;
  }
  .kt-header-menu-wrapper--on.kt-header-menu-wrapper--left .kt-header-menu-wrapper-close {
    right: auto;
    left: 249px;
  }
  .kt-header-menu-wrapper-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1000;
    animation: kt-animate-fade-in 0.3s linear 1;
  }
  .kt-header-menu-wrapper {
    background: #fff;
    box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.75);
  }
  .kt-header-menu-wrapper-overlay {
    background: rgba(0, 0, 0, 0.1);
  }
  .kt-header-menu-wrapper-close {
    background-color: #e8e8f2;
  }
  .kt-header-menu-wrapper-close > i {
    color: #968ad4;
  }
  .kt-header-menu-wrapper-close:hover {
    background-color: #5d78ff;
  }
  .kt-header-menu-wrapper-close:hover > i {
    color: #fff;
  }
}
@media (max-width: 325px) {
  .kt-header-menu-wrapper {
    width: 90% !important;
  }
}
@media (max-width: 1024px) {
  .kt-header-menu-wrapper .kt-header-logo {
    display: none;
  }
}
@media (max-width: 1024px) {
  .kt-header__topbar {
    padding: 0 15px;
    background-color: #2b2a35;
    box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-top: -50px;
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: flex-end;
  }
  .kt-header-mobile--fixed .kt-header__topbar {
    position: fixed;
    z-index: 96;
  }
  .kt-header__topbar--mobile-on .kt-header__topbar {
    margin-top: 0;
    transition: all 0.3s ease;
  }
  .kt-header__topbar .kt-header__topbar-item {
    margin: 0 2px;
    padding: 0;
  }
  .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-wrapper {
    display: flex;
    align-items: stretch;
  }
  .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-wrapper .kt-badge.kt-badge--notify {
    top: 6px;
  }
  .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon {
    height: 34px;
    width: 34px;
  }
  .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon i {
    font-size: 1.3rem;
  }
  .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user {
    margin-left: 2px;
    margin-right: 0;
    padding: 0;
  }
  .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user {
    padding: 0 2px;
    height: 34px;
  }
  .kt-header__topbar
    .kt-header__topbar-item.kt-header__topbar-item--user
    .kt-header__topbar-user
    .kt-header__topbar-welcome {
    padding: 0 0.35rem 0 0;
    font-size: 0.9rem;
  }
  .kt-header__topbar
    .kt-header__topbar-item.kt-header__topbar-item--user
    .kt-header__topbar-user
    .kt-header__topbar-username {
    padding: 0 0.55rem 0 0;
    font-size: 0.9rem;
  }
  .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user img {
    max-height: 30px;
  }
  .kt-header__topbar
    .kt-header__topbar-item.kt-header__topbar-item--user
    .kt-header__topbar-user
    .kt-badge.kt-badge--username {
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 1025px) {
  .kt-aside__brand {
    transition: all 0.3s ease;
  }
  .kt-header--fixed.kt-aside--static .kt-aside__brand {
    position: fixed;
    top: 0;
    left: 0;
    width: 255px;
    z-index: 97;
  }
  .kt-header--fixed.kt-aside--minimize .kt-aside__brand,
  .kt-header--fixed.kt-aside--minimize .kt-aside__brand .kt-aside__brand-tools {
    justify-content: center;
  }
  .kt-header--fixed.kt-aside--minimize
    .kt-aside__brand
    .kt-aside__brand-tools
    .kt-aside__brand-aside-toggler
    span:first-child {
    display: none;
  }
  .kt-header--fixed.kt-aside--minimize
    .kt-aside__brand
    .kt-aside__brand-tools
    .kt-aside__brand-aside-toggler
    span:nth-child(2) {
    display: inline-block;
  }
  .kt-header--fixed.kt-aside--static.kt-aside--minimize .kt-aside__brand {
    transition: all 0.3s ease;
    width: 70px;
  }
}
@media (max-width: 1024px) {
  .kt-aside__brand {
    display: none;
  }
}
@media (min-width: 1025px) {
  .kt-aside--fixed .kt-aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 98;
  }
  .kt-aside--static .kt-aside {
    position: relative;
    z-index: 1;
  }
  .kt-aside--minimize .kt-aside {
    width: 70px;
    transition: all 0.3s ease;
  }
  .kt-aside--minimizing .kt-aside {
    overflow: hidden;
  }
  .kt-aside--minimizing .kt-aside .kt-aside-menu .kt-menu__nav {
    width: 255px;
    overflow: hidden;
  }
  .kt-aside .ps > .ps__rail-y,
  .kt-aside .ps > .ps__rail-y:focus,
  .kt-aside .ps > .ps__rail-y:hover {
    width: 4px;
  }
  .kt-aside .ps > .ps__rail-y > .ps__thumb-y {
    width: 4px;
    border-radius: 4px;
  }
  .kt-aside .ps > .ps__rail-y > .ps__thumb-y:focus,
  .kt-aside .ps > .ps__rail-y > .ps__thumb-y:hover {
    width: 4px;
  }
  .kt-aside--fixed.kt-aside--minimize-hover .kt-aside {
    width: 255px;
    transition: all 0.3s ease;
  }
  .kt-aside--fixed.kt-aside--minimize-hover .kt-header {
    transition: all 0.3s ease;
    left: 70px;
  }
  .kt-aside--fixed.kt-aside--minimize-hover .kt-wrapper {
    transition: all 0.3s ease;
    padding-left: 70px;
  }
}
@media (min-width: 1025px) {
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion'])
    > .kt-menu__link
    > .kt-menu__ver-arrow:before,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion'])
    > .kt-menu__link
    > .kt-menu__ver-arrow:before {
    transform: translateZ(0);
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion'])
    > .kt-menu__submenu,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion'])
    > .kt-menu__submenu {
    display: none !important;
    transform: translateZ(0);
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover {
    position: relative;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu {
    top: -10px;
    position: absolute;
    z-index: 98;
    display: flex !important;
    width: 245px;
    margin-left: 245px;
    animation: kt-aside-menu-submenu-fade-in 0.3s ease 1, kt-aside-menu-submenu-move-up 0.3s ease-out 1;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu.kt-menu__submenu--up,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu.kt-menu__submenu--up {
    bottom: 0;
    top: auto;
    bottom: -10px;
    animation: kt-aside-menu-submenu-fade-in 0.3s ease 1, kt-aside-menu-submenu-move-down 0.3s ease-out 1;
  }
}
@media screen and (min-width: 1025px) {
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu.kt-menu__submenu--up,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu.kt-menu__submenu--up {
    animation: none;
  }
}
@media (min-width: 1025px) {
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav {
    padding: 20px 0;
    border-radius: 4px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item {
    margin: 0;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link {
    justify-content: flex-start;
    padding: 7px 30px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__link-title,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__link-title {
    display: flex;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu_link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu_link-text {
    transform: translateZ(0);
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__link-badge,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__link-bullet,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__link-badge,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__link-bullet,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__link
    .kt-menu__ver-arrow {
    display: flex;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav {
    padding: 20px 0;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link {
    padding: 7px 30px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav {
    padding: 20px 0;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link {
    padding: 7px 30px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__section,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__section {
    padding: 0 25px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link {
    cursor: pointer;
    height: 40px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    font-weight: 400;
    font-size: 1rem;
    text-transform: none;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    flex: 0 0 35px;
    font-size: 1.35rem;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon
    svg,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon
    svg,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg {
    margin-left: -2px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet {
    vertical-align: middle;
    text-align: left;
    flex: 0 0 20px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet
    > span {
    vertical-align: middle;
    display: inline-block;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    width: 4px;
    height: 4px;
    border-radius: 100%;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    width: 5px;
    height: 1px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-badge,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-badge,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-badge,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-badge {
    padding: 0 0 0 5px;
    text-align: right;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__ver-arrow {
    flex: 0 0 20px;
    font-size: 0.7rem;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__ver-arrow:before,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__ver-arrow:before,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__ver-arrow:before,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__ver-arrow:before {
    display: inline-block;
    transition: all 0.3s ease;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__item-here,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__item-here,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__heading
    .kt-menu__item-here,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__item-here {
    display: none;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__ver-arrow:before,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__ver-arrow:before,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__ver-arrow:before,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__ver-arrow:before {
    transition: all 0.3s ease;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__section,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__section {
    margin: 20px 0 0;
    height: 40px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__section
    .kt-menu__section-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__section
    .kt-menu__section-text {
    font-size: 0.8rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__separator,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__separator {
    margin: 15px 0;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__separator
    .kt-menu__separator--marginles,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__separator
    .kt-menu__separator--marginles {
    margin: 0;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    > .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu {
    margin-left: 255px;
    top: -5px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu.kt-menu__submenu--up,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    > .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu.kt-menu__submenu--up {
    bottom: -10px;
  }
}
@media (min-width: 1025px) {
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav {
    padding: 15px 0;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
    width: 100%;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet,
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
    display: none;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge {
    padding: 0;
    position: relative;
    right: 7px;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge .kt-badge {
    text-indent: -9999px;
    position: relative;
    padding: 0;
    min-width: 6px;
    width: 6px;
    min-height: 6px;
    height: 6px;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow {
    display: none;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu {
    display: none !important;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__arrow {
    display: inline-block;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover {
    position: relative;
    z-index: 96;
    width: 316px;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover.kt-menu__item--open {
    background: transparent;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__link {
    width: 70px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__link
    .kt-menu__link-title {
    display: none;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu {
    top: 0;
    bottom: auto;
    display: flex !important;
    margin-left: 71px !important;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--parent {
    display: flex;
    margin: 0;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--parent
    > .kt-menu__link {
    height: 40px;
    padding: 0 30px 10px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--parent
    > .kt-menu__link
    .kt-menu__link-text {
    font-weight: 400;
    font-size: 1.05rem;
    text-transform: none;
    cursor: text !important;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--parent
    > .kt-menu__link
    .kt-menu__link-badge {
    padding: 0 0 0 5px;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--parent
    + .kt-menu__item {
    margin-top: 0;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__arrow {
    display: none;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__section {
    justify-content: center;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-text {
    display: none;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    animation: kt-animate-fade-in 0.7s;
  }
}
@media (max-width: 1024px) {
  .kt-aside {
    z-index: 1001;
    position: fixed;
    top: 0px;
    bottom: 0;
    overflow-y: auto;
    backface-visibility: hidden;
    width: 275px !important;
    transition: all 0.3s ease;
    left: -295px;
  }
  .kt-aside.kt-aside--on {
    transition: all 0.3s ease;
    left: 0;
  }
}
@media screen and (max-width: 1024px) {
  .kt-aside {
    transition: all 0.3s ease !important;
  }
}
@media (max-width: 1024px) {
  .kt-aside--left .kt-aside {
    left: -295px;
    right: auto;
  }
  .kt-aside--left .kt-aside.kt-aside--on {
    transition: all 0.3s ease;
    left: 0;
    right: auto;
  }
  .kt-aside-close {
    width: 25px;
    height: 25px;
    top: 1px;
    z-index: 1002;
    transition: all 0.3s ease;
    position: fixed;
    border: 0;
    box-shadow: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -25px;
  }
}
@media screen and (max-width: 1024px) {
  .kt-aside-close {
    transition: all 0.3s ease !important;
  }
}
@media (max-width: 1024px) {
  .kt-aside-close > i {
    line-height: 0;
    font-size: 1.4rem;
  }
  .kt-aside-close:hover {
    text-decoration: none;
  }
  .kt-aside--left .kt-aside-close {
    right: auto;
    left: -25px;
  }
  .kt-aside--on .kt-aside-close {
    transition: all 0.3s ease;
    right: 249px;
  }
  .kt-aside--on.kt-aside--left .kt-aside-close {
    right: auto;
    left: 249px;
  }
  .kt-aside-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1000;
    animation: kt-animate-fade-in 0.3s linear 1;
  }
  .kt-aside {
    background: #fff;
    box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.75);
  }
  .kt-aside-overlay {
    background: rgba(0, 0, 0, 0.1);
  }
  .kt-aside-close {
    background-color: #e8e8f2;
  }
  .kt-aside-close > i {
    color: #968ad4;
  }
  .kt-aside-close:hover {
    background-color: #5d78ff;
  }
  .kt-aside-close:hover > i {
    color: #fff;
  }
}
@media (max-width: 325px) {
  .kt-aside {
    width: 90% !important;
  }
}
@media (min-width: 1025px) {
  .kt-footer--fixed .kt-content {
    padding-bottom: 60px;
  }
}
@media (max-width: 1024px) {
  .kt-content {
    padding: 15px;
  }
  .kt-subheader--enabled.kt-subheader--transparent .kt-content {
    padding-top: 0;
  }
}
@media (min-width: 1025px) {
  .kt-footer--fixed .kt-footer {
    position: fixed;
    transition: all 0.3s ease;
    height: 60px;
    bottom: 0;
    right: 0;
    left: 255px;
    box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.13);
  }
  .kt-aside--minimize-hover.kt-footer--fixed .kt-footer,
  .kt-aside--minimize.kt-footer--fixed .kt-footer {
    transition: all 0.3s ease;
    left: 70px;
  }
}
@media (max-width: 1024px) {
  .kt-footer {
    padding: 15px;
  }
}
@media (max-width: 768px) {
  .kt-footer {
    flex-direction: column;
    justify-content: center;
  }
  .kt-footer .kt-footer__copyright {
    justify-content: center;
    padding: 0;
    order: 2;
  }
  .kt-footer .kt-footer__menu {
    justify-content: center;
    order: 1;
    padding: 0;
    margin: 0 0 0.75rem;
  }
  .kt-footer .kt-footer__menu .kt-footer__menu-link:first-child {
    padding-left: 0;
    margin-left: 0;
  }
}
@media (max-width: 1399px) {
  .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items {
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 0;
  }
  .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item {
    flex: 0 0 100%;
    position: relative;
    width: 100%;
  }
  .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:after {
    position: absolute;
    left: 2rem;
  }
  .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:last-child:after {
    content: '\f105';
    font-family: Font awesome\5 Free;
    font-weight: 900;
    font-size: 2rem;
    color: #a2a5b9;
  }
  .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body {
    flex-direction: row;
    justify-content: flex-start;
    flex: 0 0 100%;
    padding: 0.5rem 2rem;
  }
  .kt-wizard-v1
    .kt-wizard-v1__nav
    .kt-wizard-v1__nav-items
    .kt-wizard-v1__nav-item
    .kt-wizard-v1__nav-body
    .kt-wizard-v1__nav-icon {
    font-size: 1.5rem;
    margin-left: 1rem;
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions .btn {
    margin: 0 0.5rem 1rem;
  }
}
@media (max-width: 768px) {
  .kt-wizard-v1 .kt-wizard-v1__wrapper {
    padding: 2rem;
  }
  .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form {
    width: 100%;
    padding: 2rem 1rem 4rem;
  }
}
.dropdown-menu:not(.dropdown-menu-top-unround) .kt-head {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.dropdown-menu .kt-grid-nav {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.modal .kt-datatable > .kt-datatable__pager > .kt-datatable__pager-info .kt-datatable__pager-size .dropdown-menu {
  z-index: 1051;
}
.form-inline .kt-checkbox {
  margin-left: 15px;
  margin-right: 15px;
}
.input-group .kt-checkbox {
  margin-bottom: 0 !important;
  padding-left: 0;
}
.form-group.row .kt-checkbox-inline {
  margin-top: 0.75rem;
}
.form-group.row .kt-checkbox-list {
  margin-top: 2px;
}
.form-inline .kt-radio {
  margin-left: 15px;
  margin-right: 15px;
}
.input-group .kt-radio {
  margin-bottom: 0 !important;
  padding-left: 0;
}
.form-group.row .kt-radio-inline {
  margin-top: 0.75rem;
}
.form-group.row .kt-radio-list {
  margin-top: 2px;
}
.form-group.row .kt-switch {
  margin-top: 0.15rem;
}
.form-group.row .kt-switch.kt-switch--lg {
  margin-top: 0;
  position: relative;
  top: -0.3rem;
}
.form-group.row .kt-switch.kt-switch--sm {
  margin-top: 0.3rem;
}
.is-valid .kt-option {
  border-color: #0abb87;
}
.is-invalid .kt-option {
  border-color: #fd397a;
}
.btn .kt-loader {
  position: relative;
  line-height: 0;
}
td > .kt-checkbox.kt-checkbox--single,
td > .kt-radio.kt-radio--single,
th > .kt-checkbox.kt-checkbox--single,
th > .kt-radio.kt-radio--single {
  right: -5px;
}
@keyframes kt-spinner {
  to {
    transform: rotate(1turn);
  }
}
@keyframes kt-loader-scaleout {
  0% {
    opacity: 0.3;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes kt-pulse {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 1025px) {
  body:not(.kt-aside--enabled) .kt-header-menu-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  body:not(.kt-aside--enabled) .kt-header-menu-wrapper .kt-header-logo {
    padding: 0 25px;
    display: flex;
    align-items: center;
    margin-right: 25px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item:first-child {
    padding-left: 0;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu > .kt-menu__subnav {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .kt-header-menu.kt-header-menu--layout-default .kt-menu__nav > .kt-menu__item > .kt-menu__link {
    padding: 0.65rem 1.1rem;
  }
  .kt-header-menu.kt-header-menu--layout-tab .kt-menu__nav > .kt-menu__item {
    margin: 0 1rem;
    align-items: stretch;
  }
  .kt-header-menu.kt-header-menu--layout-tab .kt-menu__nav > .kt-menu__item > .kt-menu__link {
    align-items: stretch;
    background-color: initial !important;
    border-radius: 0 !important;
    padding: 0 !important;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;
  }
  .kt-header-menu.kt-header-menu--layout-tab .kt-menu__nav > .kt-menu__item:first-child {
    margin-left: 0 !important;
  }
  .kt-header-menu.kt-header-menu--layout-tab .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
    background-color: initial !important;
    border-bottom: 1px solid #5d78ff !important;
  }
}
@keyframes kt-aside-menu-submenu-fade-out {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes kt-aside-menu-submenu-fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes kt-aside-menu-submenu-move-up {
  0% {
    margin-top: 10px;
  }
  to {
    margin-top: 0;
  }
}
@keyframes kt-aside-menu-submenu-move-down {
  0% {
    margin-bottom: 10px;
  }
  to {
    margin-bottom: 0;
  }
}
@keyframes kt-header-menu-submenu-fade-out {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes kt-header-menu-submenu-fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes kt-header-menu-submenu-move-up {
  0% {
    margin-top: -10px;
  }
  to {
    margin-top: 0;
  }
}
@keyframes kt-header-menu-submenu-move-down {
  0% {
    margin-top: 10px;
  }
  to {
    margin-top: 0;
  }
}
@keyframes kt-animate-fade-out {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes kt-animate-fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes kt-animate-fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes kt-animate-fade-out-up {
  0% {
    opacity: 1;
    transform: translateY(15px);
  }
  to {
    opacity: 0;
    transform: translateY(0);
  }
}
@keyframes kt-animate-blink {
  50% {
    opacity: 0;
  }
}
@keyframes kt-animate-shake {
  0% {
    transform: rotate(13deg);
  }
  to {
    transform: rotate(-13deg);
  }
}
@keyframes kt-scrolltop-show-animation {
  0% {
    margin-bottom: -15px;
  }
  to {
    margin-bottom: 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: kt-get-color();
  border-radius: 0.25rem;
}
.kt-aside {
  background-image: url('~@/assets/images/bg/sidebar-1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.kt-aside .ps > .ps__rail-x {
  background: transparent;
}
.kt-aside .ps > .ps__rail-x:focus,
.kt-aside .ps > .ps__rail-x:hover {
  opacity: 1;
  background: transparent;
}
.kt-aside .ps > .ps__rail-x:focus > .ps__thumb-x,
.kt-aside .ps > .ps__rail-x:hover > .ps__thumb-x {
  opacity: 1;
}
.kt-aside .ps > .ps__rail-x > .ps__thumb-x,
.kt-aside .ps > .ps__rail-x > .ps__thumb-x:focus,
.kt-aside .ps > .ps__rail-x > .ps__thumb-x:hover {
  background: #637099;
  opacity: 1;
}
.kt-aside .ps > .ps__rail-y {
  background: transparent;
}
.kt-aside .ps > .ps__rail-y:focus,
.kt-aside .ps > .ps__rail-y:hover {
  background: transparent;
  opacity: 1;
}
.kt-aside .ps > .ps__rail-y:focus > .ps__thumb-y,
.kt-aside .ps > .ps__rail-y:hover > .ps__thumb-y {
  opacity: 1;
}
.kt-aside .ps > .ps__rail-y > .ps__thumb-y,
.kt-aside .ps > .ps__rail-y > .ps__thumb-y:focus,
.kt-aside .ps > .ps__rail-y > .ps__thumb-y:hover {
  background: #637099;
  opacity: 1;
}
.kt-aside .kt-aside__footer {
  background-color: #1f2231;
}
.kt-aside .kt-aside__footer .btn {
  transition: all 0.3s;
  background-color: initial;
}
.kt-aside .kt-aside__footer .btn i {
  transition: all 0.3s;
  color: #364b8b;
}
.kt-aside .kt-aside__footer .btn:hover,
.kt-aside .kt-aside__footer .show .btn {
  transition: all 0.3s;
  background-color: #191b27;
}
.kt-aside .kt-aside__footer .btn:hover i,
.kt-aside .kt-aside__footer .show .btn i {
  transition: all 0.3s;
  color: #5d78ff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
  color: #fff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
  color: #ababab;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg g [fill],
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #494b74;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill],
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg:hover g [fill] {
  transition: fill 0.3s ease;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span {
  background-color: #5c5e81;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow {
  color: #5c5e81;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open {
  transition: background-color 0.3s;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link {
  background-color: #1b1b28;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-text,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-text {
  color: #fff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-icon,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon {
  color: #5d78ff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-icon svg g [fill],
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-icon
  svg:hover
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-icon
  svg:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span {
  background-color: #5d78ff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow {
  color: #fff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here {
  transition: background-color 0.3s;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link {
  background-color: #1b1b28;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-text,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-text {
  color: #fff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-icon,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon {
  color: #5d78ff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-icon svg g [fill],
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading
  .kt-menu__link-icon
  svg:hover
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__link-icon
  svg:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span {
  background-color: #5d78ff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__ver-arrow,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__ver-arrow {
  color: #fff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active {
  transition: background-color 0.3s;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
  background-color: rgba(93, 120, 255, 0.5);
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-text,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text {
  color: #fff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-icon,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon {
  color: #c3c3c3;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-icon
  svg
  g
  [fill],
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-icon
  svg:hover
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-icon
  svg:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span {
  background-color: #5d78ff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__ver-arrow,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__ver-arrow {
  color: #fff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover {
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link {
  background-color: #1b1b28;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-text {
  color: #fff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-icon,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-icon {
  color: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-icon
  svg
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-icon
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-icon
  svg:hover
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-icon
  svg:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span {
  background-color: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__ver-arrow,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__ver-arrow {
  color: #fff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__arrow {
  color: #1e1e2d;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-icon,
.kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-text {
  color: #4c4e6f;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__separator {
  border-bottom: 1px solid #4c4e6f;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-text,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-text {
  color: #9899ac;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-icon,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-icon {
  color: #494b74;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-icon
  svg
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-icon
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #494b74;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-icon
  svg:hover
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-icon
  svg:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span {
  background-color: #5c5e81;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow {
  color: #5c5e81;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open {
  transition: background-color 0.3s;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link {
  background-color: #1b1b28;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-text {
  color: #fff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-icon,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-icon {
  color: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-icon
  svg
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-icon
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-icon
  svg:hover
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-icon
  svg:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span {
  background-color: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__heading
  .kt-menu__ver-arrow,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__ver-arrow {
  color: #fff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here {
  transition: background-color 0.3s;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__heading,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__link {
  background-color: #1b1b28;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading
  .kt-menu__link-icon,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__link-icon {
  color: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading
  .kt-menu__link-icon
  svg
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__link-icon
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading
  .kt-menu__link-icon
  svg:hover
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__link-icon
  svg:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span {
  background-color: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__heading
  .kt-menu__ver-arrow,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__ver-arrow {
  color: #fff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active {
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link {
  background-color: #1b1b28;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-text {
  color: #fff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-icon,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-icon {
  color: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-icon
  svg
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-icon
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-icon
  svg:hover
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-icon
  svg:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span {
  background-color: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading
  .kt-menu__ver-arrow,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__ver-arrow {
  color: #fff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover {
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link {
  background-color: #1b1b28;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-text,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-text {
  color: #fff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-icon,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-icon {
  color: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-icon
  svg
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-icon
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-icon
  svg:hover
  g
  [fill],
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-icon
  svg:hover
  g
  [fill] {
  transition: fill 0.3s ease;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--dot
  > span,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-bullet.kt-menu__link-bullet--line
  > span {
  background-color: #5d78ff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__heading
  .kt-menu__ver-arrow,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__ver-arrow {
  color: #fff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section .kt-menu__section-icon,
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section .kt-menu__section-text {
  color: #4c4e6f;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__separator {
  border-bottom: 1px solid #4c4e6f;
}
@media (min-width: 1025px) {
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item {
    background: transparent;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link > .kt-menu__link-icon {
    color: #7c87a6;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover,
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open {
    background: transparent !important;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover
    > .kt-menu__link
    > .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    > .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active,
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here {
    background: transparent !important;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    > .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    > .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item:hover {
    background: transparent;
  }
  .kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link > .kt-menu__link-icon {
    color: #5d78ff !important;
  }
}
@media (min-width: 1025px) {
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav {
    background-color: #fff;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    color: #696e92;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #a8aabb;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #a8aabb;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #a8aabb;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__ver-arrow {
    color: #a8aabb;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open {
    transition: background-color 0.3s;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--open
    > .kt-menu__link
    .kt-menu__ver-arrow {
    color: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here {
    transition: background-color 0.3s;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active {
    transition: background-color 0.3s;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__ver-arrow {
    color: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover {
    transition: background-color 0.3s;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link {
    background-color: #f8f8fb;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__heading
    .kt-menu__ver-arrow,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
    > .kt-menu__link
    .kt-menu__ver-arrow {
    color: #5d78ff;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__section
    .kt-menu__section-text,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__section
    .kt-menu__section-text {
    color: #a4a7bd;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__section
    .kt-menu__section-icon,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__section
    .kt-menu__section-icon {
    color: #aaacc2;
  }
  .kt-aside--minimize
    .kt-aside-menu
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__separator,
  .kt-aside-menu.kt-aside-menu--dropdown
    .kt-menu__nav
    .kt-menu__item:not([data-ktmenu-submenu-mode='accordion']).kt-menu__item--hover
    > .kt-menu__submenu
    .kt-menu__subnav
    .kt-menu__separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }
}
.kt-aside-close {
  background-color: hsla(0, 0%, 100%, 0.2);
}
.kt-aside-close i {
  color: hsla(0, 0%, 100%, 0.7);
}
.kt-aside-close:hover {
  background-color: #5d78ff;
}
.kt-aside-close:hover i {
  color: #fff;
}
@media (min-width: 1025px) {
  .kt-aside__brand {
    box-shadow: none;
  }
  .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler svg g [fill] {
    transition: fill 0.3s ease;
    fill: #494b74;
  }
  .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler svg:hover g [fill] {
    transition: fill 0.3s ease;
  }
  .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler:hover svg g [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler:hover svg:hover g [fill] {
    transition: fill 0.3s ease;
  }
}
@media (max-width: 1024px) {
  .kt-header-mobile {
    background-color: #1a1a27;
    box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.1);
  }
  .kt-header__topbar--mobile-on .kt-header-mobile {
    box-shadow: none;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler span,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler span:after,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler span:before {
    background: #484f66;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toolbar-toggler.kt-header-mobile__toolbar-toggler--active
    span,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toolbar-toggler.kt-header-mobile__toolbar-toggler--active
    span:after,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toolbar-toggler.kt-header-mobile__toolbar-toggler--active
    span:before,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler:hover span,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler:hover span:after,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler:hover span:before {
    background: #fff;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-topbar-toggler i {
    color: #484f66;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toolbar-topbar-toggler.kt-header-mobile__toolbar-topbar-toggler--active
    i,
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-topbar-toggler:hover i {
    color: #fff;
  }
}
@media (min-width: 1025px) {
  .kt-header {
    background-color: #fff;
    border-bottom: 1px solid #eff0f6;
  }
  .kt-header--fixed:not(.kt-subheader--fixed) .kt-header {
    border-bottom: 0;
    box-shadow: 0 0 40px 0 rgba(82, 63, 105, 0.1);
  }
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
    border-radius: 4px !important;
  }
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
    color: #6c7293;
    font-weight: 500;
  }
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__hor-icon {
    color: #c8cde8;
  }
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link {
    background-color: rgba(77, 89, 149, 0.06);
  }
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__hor-arrow,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__hor-icon,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__hor-arrow,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__hor-icon,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-text,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link .kt-menu__hor-arrow,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link .kt-menu__hor-icon,
  .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link .kt-menu__link-text {
    color: #5d78ff;
  }
}
.kt-aside-secondary--enabled .kt-header .kt-header__topbar {
  padding-right: 0;
}
.kt-header .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon i {
  transition: all 0.3s;
  color: #c8cad5;
}
.kt-header .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon svg g [fill] {
  fill: #5d78ff;
}
.kt-header
  .kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-user
  .kt-header__topbar-welcome {
  transition: all 0.3s;
  color: #959cb6;
}
.kt-header
  .kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-user
  .kt-header__topbar-username {
  transition: all 0.3s;
  color: #6c7293;
}
.kt-header
  .kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-user
  .kt-badge.kt-badge--username {
  height: 30px;
  width: 30px;
  font-size: 1rem;
  font-weight: 600;
}
.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--quick-panel .kt-header__topbar-icon i {
  color: #c8cad5;
}
.kt-header .kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon,
.kt-header .kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon {
  transition: all 0.3s;
  background-color: rgba(77, 89, 149, 0.06);
}
.kt-header .kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon i,
.kt-header .kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon i {
  transition: all 0.3s;
  color: #5d78ff;
}
.kt-header .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--user .kt-header__topbar-user,
.kt-header .kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--user .kt-header__topbar-user {
  transition: all 0.3s;
  background-color: rgba(77, 89, 149, 0.06);
}
.kt-aside-secondary--enabled
  .kt-header
  .kt-header__topbar
  .kt-header__topbar-item.show.kt-header__topbar-item--quick-panel,
.kt-aside-secondary--enabled
  .kt-header
  .kt-header__topbar
  .kt-header__topbar-item:hover.kt-header__topbar-item--quick-panel {
  transition: all 0.3s;
  background-color: #5d78ff;
}
.kt-aside-secondary--enabled
  .kt-header
  .kt-header__topbar
  .kt-header__topbar-item.show.kt-header__topbar-item--quick-panel
  .kt-header__topbar-icon,
.kt-aside-secondary--enabled
  .kt-header
  .kt-header__topbar
  .kt-header__topbar-item:hover.kt-header__topbar-item--quick-panel
  .kt-header__topbar-icon {
  transition: all 0.3s;
  background-color: initial !important;
}
.kt-aside-secondary--enabled
  .kt-header
  .kt-header__topbar
  .kt-header__topbar-item.show.kt-header__topbar-item--quick-panel
  .kt-header__topbar-icon
  i,
.kt-aside-secondary--enabled
  .kt-header
  .kt-header__topbar
  .kt-header__topbar-item:hover.kt-header__topbar-item--quick-panel
  .kt-header__topbar-icon
  i {
  transition: all 0.3s;
  color: #fff;
}
.kt-header
  .kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--search
  .kt-input-icon
  .kt-input-icon__icon
  i {
  color: #a7abc3;
}
@media (max-width: 1024px) {
  .kt-header__topbar {
    background-color: #fff;
    box-shadow: none;
  }
  .kt-header__topbar--mobile-on .kt-header__topbar {
    box-shadow: 0 0 40px 0 rgba(82, 63, 105, 0.1);
    border-top: 1px solid #ebedf2;
  }
}
@media (min-width: 1025px) {
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
    background-color: none;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg:hover g [fill],
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg g [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #9096b8;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
    color: #9096b8;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow {
    color: #6c7293;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
    background-color: none;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text,
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link {
    background-color: none;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon svg g [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-text,
  .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link {
    background-color: none;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav {
    background-color: #fff;
    box-shadow: 0 15px 50px 0 rgba(82, 63, 105, 0.15);
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5f6281;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__hor-arrow {
    color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    > .kt-menu__item {
    border-right: 1px solid #f6f6f9;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__heading
    > .kt-menu__link-text {
    color: #5f6281;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__heading
    > .kt-menu__link-icon,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5f6281;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__hor-arrow {
    color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
}
