.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: rgb(229, 229, 229);
  border: none;
  cursor: default;
  height: 30px;
  outline: none;
  padding: 0;
  margin-top: 15px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;

  .fa-times {
    color: rgb(255, 255, 255);
    cursor: pointer;
    width: 1em;
    font-size: 20px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;

    &:hover {
      color: rgb(253, 57, 122);
    }
  }

  .chip-icon {
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: rgb(10, 187, 135);
    font-size: 1.45rem;
    flex-shrink: 0;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    width: 30px;
    height: 30px;

    i {
      color: rgb(255, 255, 255);
    }
  }

  .chip-content {
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;

    span {
      color: rgb(73, 80, 87);
    }
  }

  .chip-close {
    display: flex;
    align-items: center;
  }
  .custom-chip {
    border-radius: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .bg-defult {
    background-color: #e5e5e5;
  }
}
