@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Regular'), url('~@/assets/fonts/poppins/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins Light'), url('~@/assets/fonts/poppins/Poppins-Light.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins SemiBold'), url('~@/assets/fonts/poppins/Poppins-SemiBold.woff') format('woff');
}
