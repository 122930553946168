$font-path: '~@/assets/fonts/persian';
$faw: '#{$font-path}/fontawesome5';
$persian: '#{$font-path}/persian';

@import '~@/assets/fonts/fontawesome5/all.css';

// Fontawesome
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url('~@/assets/fonts/fontawesome5/fa-light-300.woff2') format('woff2');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('~@/assets/fonts/fontawesome5/fa-regular-400.woff2') format('woff2');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('~@/assets/fonts/fontawesome5/fa-solid-900.woff2') format('woff2');
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('~@/assets/fonts/fontawesome5/fa-brands-400.woff2') format('woff2');
}

@font-face {
  font-family: 'Font Awesome 5 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('~@/assets/fonts/fontawesome5/fa-duotone-900.woff2') format('woff2');
}

/*
 * IRANSans
*/
@font-face {
  font-family: 'IRANSans';
  font-style: normal;
  font-weight: bold;
  src: url('~@/assets/fonts/persian/IRANSansWeb(FaNum)_Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'IRANSans';
  font-style: normal;
  font-weight: 500;
  src: url('~@/assets/fonts/persian/IRANSansWeb(FaNum)_Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'IRANSans';
  font-style: normal;
  font-weight: 300;
  src: url('~@/assets/fonts/persian/IRANSansWeb(FaNum)_Light.woff2') format('woff2');
}

@font-face {
  font-family: 'IRANSans';
  font-style: normal;
  font-weight: 200;
  src: url('~@/assets/fonts/persian/IRANSansWeb(FaNum)_UltraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'IRANSans';
  font-style: normal;
  font-weight: normal;
  src: url('~@/assets/fonts/persian/IRANSansWeb(FaNum).woff2') format('woff2');
}

@font-face {
  font-family: 'summernote';
  font-style: normal;
  font-weight: normal;
  src: url('~@/assets/fonts/summernote/summernote.woff?4c7e83314b68cfa6a0d18a8b4690044b') format('woff');
}

@font-face {
  font-family: 'LineAwesome';
  src: url('~@/assets/fonts/line-awesome/line-awesome.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-family-sans-serif:
    iransans,
    -apple-system,
    blinkmacsystemfont,
    'Segoe UI',
    roboto,
    'Helvetica Neue',
    arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  --font-family-monospace: sfmono-regular, menlo, monaco, consolas, 'Liberation Mono', 'Courier New', monospace;
}
