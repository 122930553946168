.height3 {
  height: 300px;
}

.height6 {
  height: 600px;
}

.width40 {
  width: 40px;
}

.width60 {
  width: 60px;
}

.width80 {
  width: 80px;
}

.width85 {
  width: 85px;
}

.width100 {
  width: 100px;
}
.minwidth200 {
  min-width: 200px;
}
.minwidth300 {
  min-width: 300px;
}
.maxw-200 {
  max-width: 200px;
}
.width120 {
  width: 120px;
}
.width150 {
  width: 150px;
}
.table-fixed {
  table-layout: fixed;
}
.full-cell {
  width: 1%;
  white-space: nowrap;
}
.wrap-cell {
  overflow-wrap: anywhere;
}
.cursor-pointer {
  cursor: pointer;
}
.borderless > * {
  border: 0 !important;
}
.en-font {
  font-family: poppins, sans-serif;

  /* rtl:ignore */
  text-align: left !important;
}
.modal-header-centerd {
  flex: 1;
  display: flex;
  justify-content: center;
}
@media print {
  /* rtl:ignore */
  div[dir='ltr'] {
    text-align: left;
  }
}
.line-height-md {
  line-height: 25px;
}
.nooutline:focus {
  outline: none !important;
}

.multiline-text {
  white-space: pre-line;
}

.kt-media img {
  border-radius: 4px;
  width: 30px;
  height: 30px;
}

header {
  &.modal-header {
    .kt-ribbon {
      top: -4px;
    }
  }
}

.kt-ribbon__target {
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
  &.ribbon-left {
    top: -2px; /* rtl:ignore */
    left: 10px; /* rtl:ignore */
  }
  &.ribbon-right {
    top: -2px; /* rtl:ignore */
    right: 10px; /* rtl:ignore */
  }
}
[dir] .te-ww-container .tui-editor-contents:first-child {
  font-family: var(--font-family-sans-serif, IRANSans);
}
