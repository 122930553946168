//
// Dialog Details
//
// this is keentheme kt-widget1 renamed to av-details

.av-details {
  padding: kt-get($kt-portlet, space, desktop);

  &.av-details--fit {
    padding: 0;
  }

  &.av-details--paddingless {
    padding: 0;
  }

  .av-details__item {
    padding: 1.1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    .av-details__info {
      .av-details__title {
        font-size: 1.1rem;
        font-weight: 500;
        color: kt-base-color(label, 3);
      }

      .av-details__desc {
        font-size: 1rem;
        font-weight: normal;
        color: kt-base-color(label, 2);
      }
    }

    .av-details__number {
      font-size: 1.4rem;
      font-weight: 600;
      color: kt-base-color(label, 2);
    }

    &:last-child {
      border-bottom: 0;
    }

    border-bottom: 0.07rem dashed kt-base-color(grey, 2);
  }
}

@include kt-tablet-and-mobile {
  .av-details {
    padding: kt-get($kt-portlet, space, mobile);
  }
}
