// Dashboard portlets

.dashboard-portlet .kt-portlet__head {
  min-height: 40px;
  padding: 0 20px;

  .kt-portlet__head-label .kt-portlet__head-title {
    font-size: 1rem;
    color: rgb(255, 255, 255);
  }
}
